angular.module('webremote.dashboard').controller('webremote.dashboard.widgets.fernreport.playlist_edit.controller',
    function ($scope, $uibModalInstance) {
        $scope.getClassByState = function (index) {
            if ($scope.playlistStream !== undefined && $scope.playlistStream.member.state === 'playing' && $scope.playlistStream.member.elements[0].type.source.type.file.currentFile === index) {
                return 'circled-icon-play'
            }
            return ''
        }

        $scope.playStopIndex = function (index) {
            if ($scope.playlistStream.member.state === 'playing' && $scope.playlistStream.member.elements[0].type.source.type.file.currentFile === index) {
                $scope.playlistStream.member.pendingState = 'inactive'
                $scope.playlistStream.update()
            } else {
                if ($scope.playlistStream.member.state !== 'inactive') {
                    if ($scope.playlistStream.member.pendingState !== 'inactive') {
                        $scope.playlistStream.member.pendingState = 'inactive'
                        $scope.playlistStream.update()
                    }
                    setTimeout(function () {
                        $scope.playStopIndex(index)
                    }, 100)
                } else {
                    $scope.playlistStream.member.elements[0].type.source.type.file.seekToFile = index
                    $scope.playlistStream.member.pendingState = 'playing'
                    $scope.playlistStream.update()
                }
            }
        }

        $scope.close = function () {
            $uibModalInstance.close()
        }
    })
