export default class Delay {
  constructor (initialValue, stream) {
    this.stream = stream
    this.id = this.stream.member.uuid
    this.slider = {
      value: 0,
      options: {
        floor: 0,
        ceil: 10,
        step: 0.05,
        precision: 2,
        showSelectionBar: true,
        vertical: true,
        showTicks: false,
        showTicksValues: false,
        keyboardSupport: true,
        id: this.id,
        translate: function (value) {
          return value + '&nbsp;s'
        },

        onChange: function () { },

        onEnd: function () {
          if (this.slider !== undefined) {
            stream.setDelay(this.slider.value * 1000)
          }
        }.bind(this)
      }
    }
    this.slider.value = initialValue / 1000
  }

  updateCallback (value) {
    this.slider.value = (Math.round(parseFloat(value) * 20) / 20)
  }
}
