angular.module('webremote.settings').controller('updateCtrl',
  function ($scope, $uibModalInstance, $window, httpService, webSocketService, toastr, $uibModal, $interval) {
    $scope.maxTimeout = 60
    $scope.lastUpdate = new Date()
    $scope.timeout = false
    $scope.progressBarClass = 'progress-bar-primary active'
    $scope.progress = { message: 'Initializing updater..', now: 0, done: false, error: false }

    $scope.TimerFunction = function () {
      const now = new Date()
      // divided by 1000 because the value is given in ms

      const thisTimeout = (now - $scope.lastUpdate) / 1000

      if ($scope.maxTimeout <= thisTimeout) {
        $scope.timeout = true
        toastr.error('Timeout while updating', 'Error', {
          closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
        })
        // Interrupt timer - timeout
        $interval.cancel(timer)
      }
    }
    var timer = $interval($scope.TimerFunction, 1000)

    $scope.$watch('progress', function () {
      if ($scope.progress.done) {
        $scope.progressBarClass = 'progress-bar-success'
        $interval.cancel(timer)
      } else if ($scope.progress.error) {
        $scope.progressBarClass = 'progress-bar-danger'
        $interval.cancel(timer)
      }
    }, true)

    if (!$scope.$$phase) {
      $scope.$evalAsync()
    }

    $scope.closeModal = function () {
      $interval.cancel(timer)
      $uibModalInstance.close()
    }

    // CallBack Function for Mixer Object
    $scope.updateProgress = function (data) {
      // store last update
      $scope.lastUpdate = new Date()
      if ($scope.timeout) {
        $scope.timeout = false
        timer = $interval($scope.TimerFunction, 1000)
      }
      $scope.progress = data

      if (!$scope.$$phase) {
        $scope.$evalAsync()
      }
    }

    $scope.rebootSystem = function () {
      $scope.closeModal()
      $scope.modalInstance = $uibModal.open({
        templateUrl: 'modules/layout/rebootModal/template.html',
        controller: 'webremote.modules.layout.rebootModal.controller',
        size: 'md',
        scope: $scope,
        backdrop: 'static',
        keyboard: false
      })
    }

    // Register WebSocket Callbacks
    webSocketService.on('UpdaterProgress', $scope.updateProgress)

    // Deregister WebSocket Callbacks
    $scope.$on('$destroy', function () {
      webSocketService.off('UpdaterProgress', $scope.updateProgress)
    })
  })
