'use strict'

angular.module('webremote.service.audioElementLibrary', []).factory('audioElementLibraryService', function ($rootScope, toastr, schemeParser, objectManagerService, webSocketService) {
  var service = {}
  service.objectManager = objectManagerService.getObjectManager

  service.audioElementLibrary = []
  $rootScope.audioElementLibrary = {}
  $rootScope.audioElementLibraryCount = 0
  service.currentLicenses = new Map()

  service.getLicenses = async function () {
    return service.objectManager().collection('license').then(collection => {
      for (const license of Array.from(collection.values())) {
        service.currentLicenses.set(license.member.uuid, license)
      }
    })
  }

  service.fetchAudioElements = async function () {
    return service.getLicenses().then(() => {
      service.objectManager().getScheme('stream').then(payload => {
        // Shorthand
        const scheme = payload.elements.elementType.fields
        delete scheme.type.fields.fork
        service.audioElementLibrary = []

        // Iterate through audio elementy categories
        for (const a in scheme.type.fields) {
          // Iterate through element types
          for (const b in scheme.type.fields[a].fields.type.fields) {
            let element = {}
            if (b !== 'unset') {
              // Parse global attributes
              element = schemeParser.parse(scheme)
              element.type = {}

              // Parse category attributes
              element.type[a] = schemeParser.parse(scheme.type.fields[a].fields)
              element.type[a].type = {}

              // Parse specific attributes
              element.type[a].type[b] = schemeParser.parse(scheme.type.fields[a].fields.type.fields[b].fields)

              // Custom: set display name
              element.displayName = scheme.displayName.conditionalDefault.type[a].type[b]

              if (scheme.type.fields[a].fields.type.fields[b].hidden) {
                continue
              }

              // Check, if license is required
              if (Object.prototype.hasOwnProperty.call(scheme.type.fields[a].fields.type.fields[b], 'licenseRequired')) {
                // Check, if required license is available
                if (scheme.type.fields[a].fields.type.fields[b].licenseRequired.some(uuid => Array.from(service.currentLicenses.keys()).includes(uuid))) {
                  service.audioElementLibrary.push(element)
                }
              } else {
                service.audioElementLibrary.push(element)
              }
            }
          }
        }
      }, function (errorData) {
        toastr.error("Couldn't load audio elements", 'Error', {
          closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
        })
      })
    })
  }

  // Init: fetch audio elements
  service.fetchAudioElements()

  service.updateLicenses = function (obj) {
    if (obj.objectClass === 'license') {
      service.fetchAudioElements()
    }
  }

  // Decorators for external access
  service.GetAudioElementLibraryManager = function () {
    return service.audioElementLibrary
  }

  webSocketService.on('ObjectOnUpdate', service.updateLicenses)
  webSocketService.on('ObjectOnCreate', service.updateLicenses)
  webSocketService.on('ObjectOnDestroy', service.updateLicenses)

  return service
})
