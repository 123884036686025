
angular.module('webremote.directive.objectmanager.formElements')
  .controller('webremote.directive.objectmanager.formElements.passwordVerify.controller', ['$scope', 'objectManagerService', function ($scope, objectManagerService) {
    $scope.objectManager = objectManagerService.getObjectManager()
    $scope.passwords = { passwordFirst: '', passwordSecond: '', accepted: false }

    // Reset both passwords fields, if data object changed
    $scope.$watch('data', function (newValue, oldValue) {
      // Hide Alert, if object changed
      if (newValue.uuid !== oldValue.uuid) {
        $scope.hideAlert()
        $scope.clearPasswords()
      }
    }, true)

    $scope.hideAlert = function () {
      $scope.passwords.accepted = false
    }

    $scope.clearPasswords = function () {
      $scope.passwords.passwordFirst = ''
      $scope.passwords.passwordSecond = ''
    }

    // Watch data variable
    $scope.$watchCollection('passwords', function () {
      if ($scope.passwords.passwordFirst === $scope.passwords.passwordSecond && $scope.passwords.passwordFirst.length > 0) {
        $scope.data[$scope.schema.key] = $scope.passwords.passwordFirst
        $scope.passwords.accepted = true
      } else {
        $scope.passwords.accepted = false
        $scope.data[$scope.schema.key] = ''
      }
    }, true)
  }])

  .directive('omConfigformPasswordverify', function () {
    return {
      restrict: 'E',
      transclude: 'true',
      scope: {
        data: '=data',
        schema: '<schema'
      },
      templateUrl: 'directives/objectmanager/formElements/passwordVerify/template.html'
    }
  })
