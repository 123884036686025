'use strict';

(function () {
  var app = angular.module('webremote.phonebook', [])
  app.directive('customOnChange', function () {
    return {
      restrict: 'A',
      link: function (scope, element, attrs) {
        var onChangeHandler = scope.$eval(attrs.customOnChange)
        element.bind('change', onChangeHandler)
      }
    }
  })
})()

require('./controller_phonebookManagement')
