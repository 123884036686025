'use strict'

angular.module('webremote.service.streamgroup', [])
  .factory('streamGroupService', function (objectManagerService) {
    var service = {}

    // Initial call to ensure both collection are initialized
    objectManagerService.collection('streamGroup')
    objectManagerService.collection('stream')

    // StreamGroup related methods
    service.filterByType = function (type) {
      return objectManagerService.collectionSync('streamGroup').filter(g => type.includes(g.type()))
    }

    return service
  })
