angular.module('webremote.settings').controller('webremote.settings.user.controller',
  function ($scope, $log, $document, objectManagerService, modalService) {
    $scope.selectedUser = {}
    $scope.selectedUser.avatar = 'assets/img/User@2x.png'
    $scope.objectManager = objectManagerService.getObjectManager()
    $scope.validForm = true
    $scope.viewLevel = { levelArray: [] }

    $scope.formInvalid = function () {
      $scope.validForm = false
    }

    $scope.formValid = function () {
      $scope.validForm = true
    }

    $scope.uploadFile = function (event) {
      var files = event.target.files

      if (files[0]) {
        if (files[0].type === 'image/jpeg' || files[0].type === 'image/png') {
          $scope.image = files[0]

          var reader = new window.FileReader()

          reader.onloadend = function () {
            $scope.selectedUser.member.avatar = reader.result
          }

          try {
            reader.readAsDataURL(files[0]) // reads the data as a URL
          } catch (e) {
            $log.debug('error on image preview: ' + e)
            $scope.resetAvatar()
          }
        } else {
          $log.debug('wrong file type')
        }
      } else {
        $scope.resetAvatar()
      }
    }

    $scope.resetAvatar = function () {
      $scope.selectedUser.member.avatar = ''
    }

    $scope.newUser = function () {
      $scope.objectManager.getDefault('user').then(v => {
        const user = { member: angular.copy(v) }
        $scope.$evalAsync(function () {
          $scope.selectedUser = user
          $scope.resetAvatar()
          $scope.activePill = 'new'
        })
      })
    }

    $scope.selectUser = function (uuid) {
      $scope.objectManager.object(uuid, 'user').then(user => {
        $scope.$evalAsync(function () {
          $scope.selectedUser = angular.copy(user)
        })
      })
    }

    $scope.sendForm = function () {
      if ($scope.selectedUser.member.uuid.length > 0) {
        $scope.selectedUser.successNotifications = true
        $scope.selectedUser.update().then(() => {
          $scope.selectedUser.successNotifications = false
        })
      } else {
        $scope.objectManager.create('user', $scope.selectedUser.member, true).then(() => {
          $scope.newUser()
          $scope.scrollToTop()
        })
      }
    }

    $scope.deleteUser = function (id) {
      const modalConfig = {
        title: 'Confirm',
        text: 'Are you sure to delete this user?'
      }

      modalService.confirmModal(modalConfig, $scope, function (response) {
        if (response) {
          $scope.selectedUser.successNotifications = true
          $scope.selectedUser.destroy().then(() => { $scope.selectedUser.successNotifications = false })
          $scope.newUser()
        }
      })
    }

    $scope.scrollToTop = function () {
      $document.scrollTopAnimated(0, 400)
    }

    $scope.newUser()
  })
