import LogHistogram from './LogHistrogram.class'
import DataLoader from './DataLoader.class'

angular.module('webremote.directive.debug.logHistogram', [])
  .controller('webremote.directive.debug.logHistogram.controller', function ($scope, objectManagerService) {
    $scope.om = objectManagerService
    $scope.data = []
    $scope.periods = [
      {
        label: '5 m',
        value: 5,
        unit: 'minutes'
      },
      {
        label: '30 m',
        value: 30,
        unit: 'minutes'
      },
      {
        label: '1 h',
        value: 1,
        unit: 'hours'
      },
      {
        label: '24 h',
        value: 24,
        unit: 'hours'
      }
    ]

    $scope.chart = new LogHistogram('chart', 'line')
    $scope.dataLoader = new DataLoader($scope.om)
    $scope.dataLoader.chart = $scope.chart

    $scope.$watch('events', function (nD, oD) { $scope.dataLoader.filter.eventCodes = nD })
    $scope.$watch('objectUuids', function (nD, oD) { $scope.dataLoader.filter.objectUuids = nD })
    $scope.$watch('objectClasses', function (nD, oD) { $scope.dataLoader.filter.objectClasses = nD })
    $scope.$watch('selectedPeriod', function (nD, oD) { $scope.dataLoader.timeBound = nD })
    $scope.$watch('groupBy', function (nD, oD) { $scope.dataLoader.groupBy = nD })
    $scope.$watch('level', function (nD, oD) { $scope.dataLoader.filter.level = nD })

    $scope.selectedPeriod = $scope.periods[1]

    $scope.getBase64 = function (graph) {
      try {
        return graph.instance.toBase64Image()
      } catch (e) {
        return ''
      }
    }
  })

  .directive('logHistogram', function () {
    return {
      restrict: 'E',
      scope: {
        events: '=events',
        objectClasses: '=objectClasses',
        objectUuids: '=objectUuids',
        start: '=start',
        showPeriods: '=showPeriods',
        overridePeriods: '=overridePeriods',
        groupBy: '=groupBy',
        level: '=level'
      },
      templateUrl: 'directives/debug/logHistogram/template.html'
    }
  })
