const module = angular.module('webremote.directive.objectmanager.wizard')

module.controller('webremote.directive.objectmanager.wizard.container.controller', ['$scope', function ($scope, objectManagerService) {
  $scope.pointer = {
    mainMenuIndex: undefined,
    subMenuIndex: undefined
  }
}])

  .directive('omWizardContainer', function () {
    return {
      restrict: 'E',
      controller: 'webremote.directive.objectmanager.wizard.container.controller',
      scope: {
        menuConfig: '=',
        selectedMenuEntry: '=',
        object: '=',
        pointer: '=',
        actions: '=',
        level: '='
      },
      templateUrl: 'directives/objectmanager/wizard/container/template.html'
    }
  })
