export default class RtpSource {
  constructor (stream) {
    this.stream = stream
    this.id = this.stream.member.uuid
    this.ssrc = ''
    this.octetsReceived = 0
    this.packetsReceived = 0
    this.bitrate = 0.0
    this.jitter = 0.0
    this.packetsLost = 0
    this.lastLossTime = ''
  }

  update (rtpStatsRx) {
    this.ssrc = rtpStatsRx.ssrc
    this.octetsReceived = rtpStatsRx.octetsReceived
    this.packetsReceived = rtpStatsRx.packetsReceived
    this.bitrate = Math.round(rtpStatsRx.bitrate * 10000) / 10000
    this.jitter = Math.round(rtpStatsRx.jitter * 10000) / 10000
    this.packetsLost = rtpStatsRx.packetsLost
    this.lastLossTime = rtpStatsRx.lastLossTime
  }
}
