angular.module('webremote.settings').controller('webremote.settings.metadata.controller',
  function ($scope, $uibModal, toastr, $document, modalService, objectManagerService) {
    $scope.viewLevel = { levelArray: [] }
    $scope.selectedMetadata = {}
    $scope.objectManager = objectManagerService.getObjectManager()
    $scope.schema = {}

    $scope.objectManager.getScheme('metadata').then((schema) => {
      $scope.$evalAsync(function () {
        $scope.schema = schema
      })
    })

    $scope.newMetadata = function () {
      $scope.objectManager.createDefaultObject('metadata').then(obj => {
        $scope.$evalAsync(function () {
          $scope.selectedMetadata = obj
          $scope.scrollToTop()
        })
      })
    }

    $scope.selectMetadata = function (uuid) {
      $scope.objectManager.object(uuid, 'metadata').then(metadata => {
        $scope.$evalAsync(function () {
          $scope.selectedMetadata = angular.copy(metadata)
        })
      })
    }

    $scope.createAction = {
      cb: () => {
        $scope.objectManager.create('metadata', $scope.selectedMetadata.member).then(v => {
          $scope.newMetadata()
          $scope.scrollToTop()
          toastr.success('Metadata added.', 'Success', {
            closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
          })
        }, (errorData) => {
          toastr.error(errorData.message.text, 'Error', {
            closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
          })
        })
      }
    }

    $scope.destroyAction = {
      cb: () => {
        const modalConfig = {
          title: 'Confirm',
          text: 'Are you sure to delete this Metadata?'
        }

        modalService.confirmModal(modalConfig, $scope, function (response) {
          if (response) {
            $scope.selectedMetadata.destroy()
            $scope.newMetadata()
          }
        })
      }
    }

    $scope.updateAction = {
      cb: () => {
        $scope.selectedMetadata.update()
        $scope.scrollToTop()
      }
    }

    $scope.cloneAction = {
      cb: () => {
        $scope.selectedMetadata.clone()
        $scope.scrollToTop()
      }
    }

    $scope.scrollToTop = function () {
      $document.scrollTopAnimated(0, 400)
    }

    $scope.newMetadata()
  })
