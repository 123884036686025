import BaseClass from './Base.class'

export default class Dashboard extends BaseClass {
  constructor (data) {
    super(data)

    this._dependencies.$http.get('modules/dashboard/widgets/widgets.json', { cache: true }).then(function (data) {
      this.localWidgetRepo = data.data
    }.bind(this))

    // We do not need update messages on success for dashboard widgets
    this.successNotifications = false
    // TODO: REMOVE THIS LATER; IF PERMISSION ISSUE IS FIXED
    this.errorNotifications = false
  }

  getWidgets () {
    return this.member.widgets
  }

  getInformationForWidget (index) {
    try {
      return JSON.parse(this.member.widgets[index].options)
    } catch (e) {
      return {}
    }
  }

  readWidgets () {
    var cleanUp = false
    const widgets = []

    if (this.member.widgets !== undefined && this.member.widgets.length > 0 && this.localWidgetRepo !== undefined) {
      for (var i = 0; i < this.member.widgets.length; i++) {
        // Find remote widget entry in local widget collection
        const result = this.localWidgetRepo.find(obj => {
          return obj.id === this.member.widgets[i].id
        })

        // Remove remote widget from collection, if no local entry is existing
        if (result === undefined) {
          // Set flag to update dashboard object
          cleanUp = true

          // Remove misssing widget
          this.member.widgets.splice(i, 1)

          // Decrease index, because widget collection length was reduced by one
          i--
        } else {
          // if device is fernreport or it has less than 5 monitor channels the audio interface widget needs only to be half size
          const om = this._dependencies.$injector.get('objectManagerService').getObjectManager()
          const collection = om.collectionSync('audioInputPort')
          var nrAudioInputPort = 0
          for (const audioInputPort of collection) {
            if ((!Object.prototype.hasOwnProperty.call(audioInputPort.member.type, 'virtual') && audioInputPort.member.monitorLevel) || Object.prototype.hasOwnProperty.call(audioInputPort.member.type, 'geminiAnalogMic') || Object.prototype.hasOwnProperty.call(audioInputPort.member.type, 'geminiAnalogLine') || Object.prototype.hasOwnProperty.call(audioInputPort.member.type, 'geminiAes')) {
              nrAudioInputPort += 1
            }
          }

          const deviceService = this._dependencies.$injector.get('deviceService')
          if (result.id === 1 && nrAudioInputPort <= 4) {
            result.size = 6
          } else if (result.id === 1 && (nrAudioInputPort <= 6)) {
            result.size = 8
          } else if (result.id === 1) {
            result.size = 12
          }

          widgets.push(result)
        }
      }

      // Update clean widget collection
      if (cleanUp) {
        this._dependencies.httpService.callFunction('objects/dashboard/' + this.member.uuid, this.member, 'PUT').then(function (serviceData) {

        }, function (errorData) {

        })
      }
    }

    return widgets
  }

  storeInformationForWidget (index, payload) {
    const widget = this.member.widgets[index]

    if (widget !== undefined) {
      widget.options = payload
      this.patch([{ op: 'replace', path: '/widgets', value: this.member.widgets }])
    }
  }
}
