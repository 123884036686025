import BaseClass from './Base.class'

export default class AudioPort extends BaseClass {
  constructor (data) {
    super(data)
    this._channelCache = new Map()
    this.createChannelCache()
  }

  createChannelCache () {
    // Reset Channel Cache
    this._channelCache.clear()

    if (this.member.channels && this.member.channels.hasOwnProperty('mono')) {
      this._channelCache.set(this.member.card + '_' + this.member.channels.mono.id,
        {
          name: this.member.channels.mono.name,
          userName: this.member.channels.mono.userName,
          type: Object.keys(this.member.type)[0]
        }
      )
    }

    if (this.member.channels && this.member.channels.hasOwnProperty('stereo')) {
      this._channelCache.set(this.member.card + '_' + this.member.channels.stereo.idL,
        {
          name: this.member.channels.stereo.nameL,
          userName: this.member.channels.stereo.userNameL,
          type: Object.keys(this.member.type)[0]
        }
      )
      this._channelCache.set(this.member.card + '_' + this.member.channels.stereo.idR,
        {
          name: this.member.channels.stereo.nameR,
          userName: this.member.channels.stereo.userNameR,
          type: Object.keys(this.member.type)[0]
        }
      )
    }

    if (this.member.channels && this.member.channels.hasOwnProperty('madi')) {
      for (const index in this.member.channels.madi.ids) {
        this._channelCache.set(this.member.card + '_' + this.member.channels.madi.ids[index],
          {
            name: this.member.channels.madi.names[index],
            userName: this.member.channels.madi.userNames[index],
            type: Object.keys(this.member.type)[0]
          }
        )
      }
    }
  }

  getInformationForChannel (soundCard, channelId) {
    // TODO: REMOVE SECOND CONDITION
    if (this._channelCache.has(soundCard + '_' + channelId) && Object.keys(this.member.type)[0] !== 'geminiAes') {
      return this._channelCache.get(soundCard + '_' + channelId)
    } else {
      return new Error('Channel ID not found in this Interface')
    }
  }

  isMemberOfCard (uuid) {
    return this.member.card === uuid
  }

  // Override
  updateCallback (data) {
    for (var k in data) {
      this.member[k] = data[k]
    }

    this.createChannelCache()
  }
}
