import * as jsonpatch from 'fast-json-patch'
export default class BaseClass {
  constructor (data) {
    // Create internal members
    this._notifications = { error: true, success: true }
    this._dependencies = data.dependencies

    this._validationErrors = []

    // Parse attributes from object
    this.member = {}

    for (var k in data.obj) {
      this.member[k] = data.obj[k]
    }
  }

  get valid () {
    return this._validationErrors.length === 0
  }

  get errorNotifications () {
    return this._notifications.error
  }

  set errorNotifications (value) {
    this._notifications.error = value
  }

  get successNotifications () {
    return this._notifications.success
  }

  set successNotifications (value) {
    this._notifications.success = value
  }

  getAttribute (key) {
    return this.member.attributes.find(x => x.key === '__ui_' + key + '__')
  }

  setAttribute (key, value, patchObject) {
    const attributeIndex = this.member.attributes.findIndex(x => x.key === '__ui_' + key + '__')
    if (attributeIndex !== -1) {
      this.member.attributes.splice(attributeIndex, 1)
    }

    this.member.attributes.push({ key: '__ui_' + key + '__', value: value })

    if (patchObject || patchObject === undefined) {
      this.patch([
        { op: 'replace', path: '/attributes', value: this.member.attributes }
      ])
    }
  }

  validationErrors (levels, path) {
    return this._validationErrors
      .filter(x => path !== undefined ? x.path === path : true)
      .filter(x => levels ? levels.includes(x.level) : true)
  }

  _toastrSuccess (action, resolveVal) {
    if (this._notifications.success) {
      this._dependencies.toastr.success(action + ' ' + this.member.objectClass + ' successful', 'Success', {
        closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
      })
    }
    return (resolveVal)
  }

  _toastrError (action, errorData, raiseException) {
    if (this._notifications.error) {
      this._dependencies.toastr.error(errorData.message.text, 'Error ' + action + ' ' + this.member.objectClass, {
        closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
      })
    }
    if (raiseException) {
      return (errorData)
    } else {
      return (false)
    }
  }

  silent (funcName) {
    const executable = funcName.bind(this)
    executable()
  }

  get visible () {
    return true
  }

  async validate () {
    try {
      const response = await this._dependencies.httpService.callFunction('objects/' + this.member.objectClass + '/validate', this.member, 'POST')
      this._validationErrors = response.data.messages

      if (response.data.jsonPatch.length > 0) {
        jsonpatch.applyPatch(this.member, response.data.jsonPatch)
      }
    } catch (e) {
      console.error(e)
    }
    return this._validationErrors
  }

  async clone (raiseException, { removeLabel = '' } = {}) {
    return this._dependencies.httpService.callFunction('objects/' + this.member.objectClass + '/' + this.member.uuid + '/clone', '', 'POST')
      .then(function (serviceData) {
        if (removeLabel) {
          var filteredTags = this.member.tags.filter(function (tag, index, arr) {
            if (tag !== removeLabel) {
              return value
            }
          })
          this._dependencies.httpService.callFunction('objects/stream/' + serviceData.headers().location.split('/').pop(),
            [{ op: 'replace', path: '/tags', value: filteredTags }], 'PATCH')
            .then(function (serviceData) { },
              function (errorData) {
                console.log(errorData)
              })
        }
        return (this._toastrSuccess('Cloning', serviceData.headers().location.split('/').pop()))
      }.bind(this), function (errorData) {
        return (this._toastrError('cloning', errorData, raiseException))
      }.bind(this))
  }

  async patch (payload, raiseException) {
    return this._dependencies.httpService.callFunction('objects/' + this.member.objectClass + '/' + this.member.uuid, payload, 'PATCH')
      .then(function (serviceData) {
        return (this._toastrSuccess('Patching', true))
      }.bind(this), function (errorData) {
        return (this._toastrError('patching', errorData, raiseException))
      }.bind(this))
  }

  async update (raiseException) {
    return this._dependencies.httpService.callFunction('objects/' + this.member.objectClass + '/' + this.member.uuid, this.member, 'PUT')
      .then(function (serviceData) {
        return (this._toastrSuccess('Updating', true))
      }.bind(this), function (errorData) {
        return (this._toastrError('updating', errorData, raiseException))
      }.bind(this))
  }

  async destroy (raiseException) {
    return this._dependencies.httpService.callFunction('objects/' + this.member.objectClass + '/' + this.member.uuid, [], 'DELETE')
      .then(function (serviceData) {
        return (this._toastrSuccess('Destroying', true))
      }.bind(this), function (errorData) {
        return (this._toastrError('destroying', errorData, raiseException))
      }.bind(this))
  }

  updateCallback (data) {
    for (var k in data) {
      this.member[k] = data[k]
    }
  }
}
