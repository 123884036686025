angular.module('webremote.layout').controller('webremote.modules.layout.wifi.controller',
  function ($scope, $uibModalInstance, objectManagerService) {
    objectManagerService.collection('netPhy').then(netPhys => {
      for (const netPhy of Array.from(netPhys.values())) {
        if (Object.prototype.hasOwnProperty.call(netPhy.member.type, 'netWifi')) {
          $scope.wifiNetPhy = angular.copy(netPhy)
          objectManagerService.collection('network', true).then(networks => {
            $scope.$evalAsync(function () {
              $scope.wifiNetwork = angular.copy(Array.from(networks.values()).find((e) => { return e.member.device === $scope.wifiNetPhy.member.uuid }))
              // Always set network config to DHCP since this is the most common case
              $scope.wifiNetwork.member.name = 'Default DHCP on (' + $scope.wifiNetPhy.member.devName + ')'
              $scope.wifiNetwork.member.config = { dhcp: { useDns: true, useNtp: true, useRoutes: true, routeMetric: $scope.dhcpRouteMetricDefault } }
            })
          })
          if (!Object.prototype.hasOwnProperty.call($scope.wifiNetPhy.member.type.netWifi.mode, 'client')) {
            $scope.wifiNetPhy.member.type.netWifi.mode = {
              client: {
                wlanCommon: {
                  essid: '',
                  encryptionMode: {
                    auto: {
                      password: ''
                    }
                  }
                }
              }
            }
          }
          break
        }
      }
    })

    $scope.onFormInvalid = () => {
      $scope.actions.update.disabled = true
    }

    $scope.onFormValid = () => {
      $scope.actions.update.disabled = false
    }

    $scope.actions = {
      update: {
        disabled: false,
        cb: () =>
          // Validate object
          $scope.wifiNetPhy.validate().then((errors) => {
            // Check if result includes entries with "error" level
            if ($scope.wifiNetPhy.validationErrors(['error', 'critical']).length === 0) {
              // Update user object, if anything is fine
              $scope.wifiNetPhy.update().then(() => {
                $scope.wifiNetwork.validate().then((errors) => {
                  // Check if result includes entries with "error" level
                  if ($scope.wifiNetwork.validationErrors(['error', 'critical']).length === 0) {
                    // Everything seems to be fine, so store WifiNetwork to wifi object
                    objectManagerService.collection('wifi', true).then(wifis => {
                      $scope.$evalAsync(function () {
                        var wifi = Array.from(wifis.values()).find((e) => {
                          return e.member.name === $scope.wifiNetPhy.member.type.netWifi.mode.client.wlanCommon.essid
                        })
                        if (wifi !== undefined) {
                          wifi.member.security = Object.keys($scope.wifiNetPhy.member.type.netWifi.mode.client.wlanCommon.encryptionMode)[0]
                          wifi.member.password = $scope.wifiNetPhy.member.type.netWifi.mode.client.wlanCommon.encryptionMode[Object.keys($scope.wifiNetPhy.member.type.netWifi.mode.client.wlanCommon.encryptionMode)[0]].password
                          wifi.member.stored = true
                          wifi.update()
                        } else {
                          wifi = {
                            name: $scope.wifiNetPhy.member.type.netWifi.mode.client.wlanCommon.essid,
                            security: Object.keys($scope.wifiNetPhy.member.type.netWifi.mode.client.wlanCommon.encryptionMode)[0],
                            password: $scope.wifiNetPhy.member.type.netWifi.mode.client.wlanCommon.encryptionMode[Object.keys($scope.wifiNetPhy.member.type.netWifi.mode.client.wlanCommon.encryptionMode)[0]].password,
                            stored: true
                          }
                          objectManagerService.create('wifi', wifi, true).then(() => {})
                        }
                      })
                    })
                    // Update user object, if anything is fine
                    $scope.wifiNetwork.update().then(() => {
                      objectManagerService.callMethod('applyNetworkSettings', {}, { notifications: true }).then(function (serviceData) {
                      })
                      $uibModalInstance.close()
                    })
                  }
                }, () => {
                })
              })
            }
          }, () => {
          })
      },
      close: {
        disabled: false,
        cb: () => $uibModalInstance.close()
      }
    }
  })
