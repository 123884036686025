angular.module('webremote.dashboard').controller('widget_dtmf',
  function ($scope, $uibModal, httpService, toastr, objectManagerService) {
    // init values
    $scope.storedOptions = {}
    $scope.selectSequence = false
    $scope.loading = true
    $scope.signs = ''
    $scope.selectedSequence = {}
    $scope.preparedSequences = []
    $scope.objectManager = objectManagerService.GetObjectManager
    $scope.objectManager().collection('stream')
    $scope.regex = '[0123456789ABCDabcd#*]*'

    $scope.objectManager().collection('session').then((v) => {
      $scope.objectManager().object(Array.from(v.values())[0].member.user_uuid, 'user').then((v) => {
        $scope.user = v
      })
    })

    $scope.$watch('activeDashboard', function () {
      $scope.objectManager().object($scope.activeDashboard, 'dashboard').then((v) => {
        $scope.dashboard = v
      })
    })

    $scope.objectManager().object($scope.activeDashboard, 'dashboard').then((v) => {
      $scope.$evalAsync(() => {
        $scope.dashboard = v

        // Wait, until widgetIndex isset
        $scope.$watch('widgetIndex', function () {
          $scope.$evalAsync(() => {
            $scope.storedOptions = $scope.dashboard.getInformationForWidget($scope.widgetIndex)
            if ($scope.storedOptions.selectedStream !== undefined) {
              $scope.objectManager().object($scope.storedOptions.selectedStream, 'stream').then((v) => {
                $scope.$evalAsync(() => {
                  $scope.selectedPipe = v
                })
              })
            }
          })
        })
      })
    })

    $scope.storeSelectedStream = function (uuid) {
      if (uuid.length > 0) {
        $scope.storedOptions.selectedStream = uuid
        $scope.dashboard.storeInformationForWidget($scope.widgetIndex, JSON.stringify($scope.storedOptions))
      }
    }

    $scope.isDTMFPipe = function () {
      return function (item) {
        return item.hasElement('source', 'dtmf')
      }
    }

    $scope.newPreset = function () {
      $scope.user.member.dtmfPresets.push({ name: $scope.newPresetName, sequence: $scope.signs })
      $scope.user.update()

      $scope.newPresetName = ''
      $scope.signs = ''
      $scope.selectPresetName = false
    }

    $scope.playSequence = function () {
      $scope.selectedPipe.playDTMFSequence($scope.signs)
      $scope.signs = ''
    }

    // Modal functions
    $scope.editSequences = function (type) {
      $scope.modalInstance = $uibModal.open({
        templateUrl: 'modules/dashboard/widgets/dtmf/modalSequences.html',
        controller: 'dtmfModalSequenceCtrl',
        size: 'md',
        scope: $scope
      })

      // get result
      $scope.modalInstance.result.then(function (returnedData) {
        if (returnedData !== $scope.preparedSequences) {
          $scope.preparedSequences = returnedData
          $scope.selectedSequence = $scope.preparedSequences[0]
        }
      }, function (errorData) {
        // Workaround - how to get a change on dismiss?
        $scope.getPhonebook()
      })
    }
  })
