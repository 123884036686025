angular.module('webremote.settings').controller('webremote.settings.demux.controller',
  function ($scope, $uibModal, toastr, $document, modalService, objectManagerService) {
    $scope.viewLevel = { levelArray: [] }
    $scope.selectedDemux = {}
    $scope.objectManager = objectManagerService.getObjectManager()
    $scope.schema = {}

    $scope.objectManager.getScheme('demux').then((schema) => {
      $scope.$evalAsync(function () {
        $scope.schema = schema
      })
    })

    $scope.newDemux = function () {
      $scope.objectManager.createDefaultObject('demux').then(obj => {
        $scope.$evalAsync(function () {
          $scope.selectedDemux = obj
          $scope.scrollToTop()
        })
      })
    }

    $scope.selectDemux = function (uuid) {
      $scope.objectManager.object(uuid, 'demux').then(demux => {
        $scope.$evalAsync(function () {
          $scope.selectedDemux = angular.copy(demux)
        })
      })
    }

    $scope.createAction = {
      cb: () => {
        $scope.objectManager.create('demux', $scope.selectedDemux.member).then(v => {
          $scope.newDemux()
          $scope.scrollToTop()
          toastr.success('Demux added.', 'Success', {
            closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
          })
        }, (errorData) => {
          toastr.error(errorData.message.text, 'Error', {
            closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
          })
        })
      }
    }

    $scope.destroyAction = {
      cb: () => {
        const modalConfig = {
          title: 'Confirm',
          text: 'Are you sure to delete this Demux?'
        }

        modalService.confirmModal(modalConfig, $scope, function (response) {
          if (response) {
            $scope.selectedDemux.destroy()
            $scope.newDemux()
          }
        })
      }
    }

    $scope.updateAction = {
      cb: () => {
        $scope.selectedDemux.update()
        $scope.scrollToTop()
      }
    }

    $scope.cloneAction = {
      cb: () => {
        $scope.selectedDemux.clone()
        $scope.scrollToTop()
      }
    }

    $scope.scrollToTop = function () {
      $document.scrollTopAnimated(0, 400)
    }

    $scope.newDemux()
  })
