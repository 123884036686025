angular.module('webremote.dashboard').controller('widget_balanceControl',
  function ($scope, $log, objectManagerService) {
    $scope.om = objectManagerService

    $scope.selectedPipes = { pipe_1: '', pipe_2: '', pipe_3: '', pipe_4: '' }
    $scope.canvasWidth = document.getElementById('canvasBalanceContainer').offsetWidth

    $scope.timeoutCode
    $scope.delayInMs = 300

    $scope.storeSelectedPipes = function () {
      window.sessionStorage.setItem('pipe_1', $scope.selectedPipes.pipe_1)
      window.sessionStorage.setItem('pipe_2', $scope.selectedPipes.pipe_2)
      window.sessionStorage.setItem('pipe_3', $scope.selectedPipes.pipe_3)
      window.sessionStorage.setItem('pipe_4', $scope.selectedPipes.pipe_4)
    }

    // Load Pipes
    $scope.selectedPipes.pipe_1 = window.sessionStorage.getItem('pipe_1')
    $scope.selectedPipes.pipe_2 = window.sessionStorage.getItem('pipe_2')
    $scope.selectedPipes.pipe_3 = window.sessionStorage.getItem('pipe_3')
    $scope.selectedPipes.pipe_4 = window.sessionStorage.getItem('pipe_4')

    $scope.validPipes = function () {
      return $scope.om.collectionSync('stream').filter(element => element.hasElement('processing', 'volumecontrol'))
    }

    $scope.updatePipe = function (uuid, angle, distanceToCenterPercent) {
      clearTimeout($scope.timeoutCode)

      $scope.timeoutCode = setTimeout(function () {
        let l = (1 - (angle / 180)) * (100 - distanceToCenterPercent) - 20
        let r = ((angle / 180)) * (100 - distanceToCenterPercent) - 20

        if (l >= 0) l = 0
        if (r >= 0) r = 0

        var stream = $scope.om.objectSync(uuid, 'stream')
        if (stream !== undefined) {
          console.log(stream)
          stream.errorNotifications = false
          stream.successNotifications = false
          stream.getElement('processing', 'volumecontrol').type.processing.type.volumecontrol.volumes[0] = l
          stream.getElement('processing', 'volumecontrol').type.processing.type.volumecontrol.volumes[1] = r
          stream.update().then(() => {
            stream.errorNotifications = true
            stream.successNotifications = true
          })
        }
      }, $scope.delayInMs)
    }

    $scope.draw = function () {
      var canvas = document.getElementById('canvas')
      var ctx = canvas.getContext('2d')

      var width = document.getElementById('canvasBalanceContainer').offsetWidth
      var height = document.getElementById('canvasBalanceContainer').offsetWidth / 2.15
      ctx.canvas.width = width
      ctx.canvas.height = height

      ctx.strokeStyle = '#7D7D7D'

      ctx.beginPath()
      ctx.arc(width / 2, height - 20, width / 4, 355, 2 * Math.PI)
      ctx.stroke()

      ctx.beginPath()
      ctx.arc(width / 2, height - 20, width / 3, 355, 2 * Math.PI)
      ctx.stroke()

      ctx.beginPath()
      ctx.arc(width / 2, height - 20, width / 2.4, 355, 2 * Math.PI)
      ctx.stroke()

      ctx.font = '15px Verdana'
      ctx.fillStyle = '#7D7D7D'
      ctx.fillText('100', width / 4.41, height * 0.99)
      ctx.fillText('50', width / 6.58, height * 0.99)
      ctx.fillText('0', width / 13, height * 0.99)

      ctx.fillText('100', width / 1.37, height * 0.99)
      ctx.fillText('50', width / 1.22, height * 0.99)
      ctx.fillText('0', width / 1.1, height * 0.99)
    }

    window.addEventListener('resize', $scope.draw)

    $scope.$on('$destroy', function (event) {
      window.removeEventListener('resize', $scope.draw)
      $log.debug('Resize EventListener removed for BalanceControl')
    })

    $scope.users = {
      0: {
        id: 0,
        name: 'Bill Krewitz',
        mute: false
      },
      1: {
        id: 1,
        name: 'George Austin',
        mute: false
      },
      2: {
        id: 2,
        name: 'Kerry Westing',
        mute: false
      },
      3: {
        id: 3,
        name: 'Drake Costa',
        mute: false
      }
    }
  }).directive('ngDraggable', function ($document, $window, $log) {
  function makeDraggable (scope, element, attr) {
    var startX = 0
    var startY = 0
    var x = scope.x
    var y = scope.y

    element.css({
      position: 'absolute',
      cursor: 'pointer',
      top: y + 'px',
      left: x + 'px'
    })

    element.on('mousedown touchstart', function (event) {
      event.preventDefault()
      startX = event.pageX - x
      startY = event.pageY - y

      $document.on('mousemove touchmove', mouseMoving)
      $document.on('mouseup touchend', mouseDropped)
    })

    function mouseMoving (event) {
      const width = document.getElementById('canvasBalanceContainer').offsetWidth
      const height = document.getElementById('canvasBalanceContainer').offsetHeight

      x = event.pageX - startX
      y = event.pageY - startY

      // get real element size
      const elemWidth = element.children().children().width() / 2
      const elemHeight = elemWidth / 2

      if (y >= (height + (elemHeight / 2))) {
        y = (height + (elemHeight / 2))
      } else if (y <= (elemHeight / 2)) {
        y = (elemHeight / 2)
      }

      if (x >= (width - elemWidth)) {
        x = (width - elemWidth)
      } else if (x <= (0 - (elemWidth))) {
        x = (0 - (elemWidth))
      }

      element.css({
        top: y + 'px',
        left: x + 'px'
      })

      let procentValue
      const centerWidth = (width / 2)
      const centerHeight = (height - 20)
      const centerElementWidth = (elemWidth / 2)
      const centerElementHeight = (elemHeight / 2)

      /*
                                        if ((centerWidth - x) > 0) {
                                          $log.debug('L/R-Alignment: ' + (centerWidth - x) + ' (left)')
                                        } else if ((centerWidth - x) < 0) {
                                          $log.debug('L/R-Alignment: ' + (x - centerWidth) + ' (right)')
                                        } else {
                                          $log.debug('L/R-Alignment: 0 (center)')
                                        }
                                        */

      const distanceToCenter = Math.sqrt(
        Math.pow((centerWidth - (x + centerElementWidth)), 2) + Math.pow((centerHeight - (y + centerElementHeight)), 2)
      )

      const angle = (Math.atan2(height - 10 - y, width / 2 - x) * 180 / Math.PI)
      const distanceCenterPercent = distanceToCenter * 100 / (width / 2)

      scope.callback(scope.uuid, angle, distanceCenterPercent)

      if (distanceToCenter <= width / 4) {
        procentValue = 100
      } else if (distanceToCenter >= (width / 2.4)) {
        procentValue = 0
      } else {
        const nullValue = (width / 4)
        // procentValue = 100 - (( 100 / (width/2.4) ) * distanceToCenter);
        procentValue = 100 - ((100 * (distanceToCenter - nullValue)) / ((width / 2.4) - nullValue))
      }

      // $log.debug('Value: ' + procentValue.toFixed(2) + '%')
    }

    function mouseDropped () {
      $document.unbind('mousemove', mouseMoving)
      $document.unbind('mouseup', mouseDropped)
    }
  }

  return {
    scope: {
      callback: '=callback',
      uuid: '=uuid',
      x: '=x',
      y: '=y'
    },
    link: makeDraggable
  }
})
