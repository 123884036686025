
angular.module('webremote.directive.general.logComment', [])
  .controller('webremote.directive.general.logComment.controller', function ($scope, $uibModal) {
    $scope.logComment = function () {
      $scope.modalInstance = $uibModal.open({
        templateUrl: 'directives/general/logComment/modal/template.html',
        controller: 'modals.logComment.controller',
        size: 'sm',
        backdrop: ($scope.logCommentCancelable() ? true : 'static'),
        keyboard: $scope.logCommentCancelable(),
        scope: $scope
      })

      $scope.modalInstance.result.then(function (uuid) {

      }, function (errorData) {
        return ''
      })
    }
  }).directive('logComment', function () {
    return {
      restrict: 'A',
      scope: {
        logCommentUuid: '&',
        logCommentObjectclass: '&',
        logCommentCallback: '&',
        logCommentCancelable: '&',
        logCommentEnable: '&'
      },
      controller: 'webremote.directive.general.logComment.controller',
      link: function (scope, element, attrs) {
        $(element).on('click', function (e) {
          if (scope.logCommentEnable() === undefined || scope.logCommentEnable()) {
            scope.logComment()
          }
        })
      }
    }
  })

require('./modal/controller')
