import BaseClass from './Base.class'

export default class SipAccount extends BaseClass {
  set echo (value) {
    this.member.echo = Boolean(value)
    this.update()
  }

  async reregistration () {
    return this.patch([{ op: 'replace', path: '/enabled', value: false }]).then(() => {
      return this.patch([{ op: 'replace', path: '/enabled', value: true }])
    })
  }
}
