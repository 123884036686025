var app = angular.module('webremote.fallback', [])

app.controller('fallbackCtrl', function ($scope, $window, $location, $rootScope, webSocketService) {
  $scope.company = window.company
  $scope.websocketMode = true

  $scope.getWebSocketMode = function () {
    if (window.sessionStorage.getItem('transportMode') !== null &&
      window.sessionStorage.getItem('transportMode') === '["polling"]' &&
      window.sessionStorage.getItem('transportModeUpgrade') !== null &&
      window.sessionStorage.getItem('transportModeUpgrade') === 'false') {
      $scope.websocketMode = false
    } else {
      $scope.websocketMode = true
    }
  }

  $scope.getWebSocketMode()

  $scope.setWebSocketMode = function () {
    if (!$scope.websocketMode) {
      window.sessionStorage.setItem('transportMode', JSON.stringify(['polling']))
      window.sessionStorage.setItem('transportModeUpgrade', false)
    } else {
      window.sessionStorage.removeItem('transportMode')
      window.sessionStorage.removeItem('transportModeUpgrade')
    }
  }

  $scope.connectEvent = function (e) {
    $window.open($window.location.protocol + '//' + $window.location.host, '_self')
  }

  $scope.connectErrorEvent = function (e) {
    if (e.message.includes('Connection rejected by server')) {
      $location.path('/login')
    }
  }

  $scope.ip = function () {
    return $location.host()
  }

  $scope.protocol = function () {
    return $location.protocol()
  }

  $scope.browser = function () {
    return $window.navigator.userAgent
  }

  $scope.reload = function () {
    $window.open($window.location.protocol + '//' + $window.location.host, '_self')
  }

  $scope.lastPing = function () {
    return $rootScope.deviceError.lastPing
  }

  webSocketService.on('connect_error', $scope.connectErrorEvent)
  webSocketService.on('connect', $scope.connectEvent)

  $scope.$on('$destroy', function () {
    webSocketService.off('connect_error', $scope.connectErrorEvent)
    webSocketService.off('connect', $scope.connectEvent)
  })
})
