import moment from 'moment'
import _ from 'lodash'
import stc from 'string-to-color'

export const groupByOption = Object.freeze({
  NONE: 0,
  UUID: 1,
  CLASS: 2,
  EVENT: 3
})

export default class DataLoader {
  constructor (objectManager) {
    this.om = objectManager
    this.entryLimit = 1000
    this.rawData = []
    this._chart = null
    this._timeBound = moment()

    this.filter = {
      eventCodes: [],
      objectClasses: [],
      objectUuids: [],
      level: 'debug'
    }

    this._groupBy = groupByOption.UUID

    this._overrides = {

    }
  }

  set chart (chartRef) {
    this._chart = chartRef
  }

  set groupBy (v) {
    if (!v) {
      this._groupBy = groupByOption.NONE
    } else {
      this._groupBy = v
    }

    this.update()
  }

  set timeBound (data) {
    if (!data) {
      this._timeBound = moment()
    } else {
      this._timeBound = moment().subtract(data.value, data.unit)
    }
    this.update()
  }

  update () {
    const payload = {
      methodClass: 'readLog',
      events: this.filter.eventCodes || [],
      objectClasses: this.filter.objectClasses || [],
      objectUuids: this.filter.objectUuids || [],
      limit: this.entryLimit,
      level: this.filter.level,
      start: {
        now: null
      }
    }
    return this.om.callMethod('readLog', payload, { notificationsError: true }).then(v => {
      this.rawData = v.entries
      // Reduce timestamp precision and round to minute
        .map(
          x => {
            x.timestamp = moment(parseInt((parseInt(x.timestamp) / 1000))).startOf('minute').format('x')
            return x
          })
      // filter entries according to time period
        .filter(x => { return x.timestamp >= parseInt(this._timeBound.format('x')) })

      const dataSets = this.createDataSourcesPipe(this.groupDataPipe(this.rawData))
      this._chart.updateData(dataSets)
    })
  }

  groupDataPipe (data) {
    if (this._groupBy === groupByOption.UUID) return _.groupBy(this.rawData, 'objectUuid')
    if (this._groupBy === groupByOption.CLASS) return _.groupBy(this.rawData, 'objectClass')
    if (this._groupBy === groupByOption.EVENT) return _.groupBy(this.rawData, 'event')
    return [this.rawData]
  }

  createDataSourcesPipe (groupedData) {
    const result = []
    for (const dataSet in groupedData) {
      const dataCounted = _.countBy(groupedData[dataSet], 'timestamp')
      const dataFormatted = Object.keys(dataCounted).map(x => { return { t: new Date(+x), y: dataCounted[x] } })

      if (dataFormatted.length > 0) {
        result.push({
          label: this.resolveLabel(dataSet, groupedData[dataSet][0].objectClass, groupedData[dataSet][0].event),
          data: dataFormatted,
          borderColor: this.resolveColor(dataSet, groupedData[dataSet][0].objectClass),
          pointBackgroundColor: this.resolveColor(dataSet, groupedData[dataSet][0].objectClass),
          borderWidth: 2,
          lineTension: 0,
          fill: false
        })
      }
    }
    return result
  }

  resolveColor (uuid, className) {
    let result = ''
    try {
      result = this.om.objectSync(uuid, className).member.displayColor
      if (!result) throw Error('color undefined')
    } catch (e) {
      result = stc(uuid)
    }
    return result
  }

  resolveLabel (uuid, className, event) {
    let result = 'unkown'

    if (this._groupBy === groupByOption.UUID) {
      try {
        result = this.om.objectSync(uuid, className).member.name
        if (!result) throw new Error('label undefined')
      } catch (e) {
        result = uuid.substring(0, 5) + '...'
      }
    }

    if (this._groupBy === groupByOption.CLASS) {
      result = className
    }

    if (this._groupBy === groupByOption.EVENT) {
      result = event.length > 0 ? event : 'unkown'
    }

    return result
  }
}
