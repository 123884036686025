
angular.module('webremote.directive.objectmanager.formElements')
  .controller('webremote.directive.objectmanager.formElements.uuid.controller', function ($scope, $filter, objectManagerService, omFormService) {
    $scope.omFormService = omFormService
    $scope.objectPath = ''
    $scope.objectManager = objectManagerService.getObjectManager()
    $scope.selectEmptyObject = { member: { uuid: '', name: 'Select ...' } }

    $scope.$watch('objectPathInput', v => {
      $scope.objectPath = omFormService.cleanPath(v)
    })

    // Watch data variable
    $scope.$watch('data[schema.key]', function (newValue) {
      if (newValue === undefined || newValue.length === 0) {
        if (!$scope.schema.allowEmpty) {
          $scope.objectManager.collection($scope.schema.refClass).then(entries => {
            let options = Array.from(entries.values())
              .filter(x => $scope.filterByTag($scope.schema.tag, x))
              .filter(x => $scope.filterByElementPresetType($scope.schema.elementPresetType))
            options = $filter('orderBy')(options, 'member.name')

            $scope.$evalAsync(() => {
              if (options.length > 0) {
                $scope.data[$scope.schema.key] = options[0].member.uuid
              }
            })
          })
        }
      }
    }, true)

    $scope.selectValueCb = (value) => {
      $scope.data[$scope.schema.key] = value.member.uuid
    }

    // Filter Functions
    $scope.filterByTag = function (tag, data) {
      switch (tag) {
        case 'groupStreams':
          return function (item) {
            return item.member.uuid.length === 0 || (item.member.streamGroup === data.uuid)
          }
        case 'sipTx':
          return function (item) {
            return item.member.uuid.length === 0 || (item.isTemplate && Object.prototype.hasOwnProperty.call(item.getElement('sink', 'sip'), 'type') && item.member.validationState === 'valid')
          }
        case 'sipRx':
          return function (item) {
            return item.member.uuid.length === 0 || (item.isTemplate && Object.prototype.hasOwnProperty.call(item.getElement('source', 'sip'), 'type') && item.member.validationState === 'valid')
          }
        case 'dtmf':
          return function (item) {
            return item.member.uuid.length === 0 || Object.prototype.hasOwnProperty.call(item.getElement('source', 'dtmf'), 'type')
          }
        case 'noSelfRef':
          return function (item) {
            return item.member.uuid.length === 0 || !Object.prototype.hasOwnProperty.call(item.getElement('source', 'ref'), 'type')
          }
        case 'noModem':
          return function (item) {
            if (item.member.uuid.length === 0) {
              return true
            } else {
              const device = $scope.objectManager.objectSync(item.member.device, 'netPhy')
              return device !== undefined && Object.keys(device.member.type)[0] !== 'modem'
            }
          }
        default:
          return function (item) {
            return true
          }
      }
    }

    $scope.filterByElementPresetType = function (elementPresetType) {
      return function (item) {
        if (elementPresetType !== undefined && elementPresetType.length > 0) {
          const category = elementPresetType.split('/')[0]
          const type = elementPresetType.split('/').pop()
          let result = false
          try {
            result = Object.prototype.hasOwnProperty.call(item.member.data.type, category) && Object.prototype.hasOwnProperty.call(item.member.data.type[category].type, type)
          } catch (e) {
            return false
          }
          return result
        } else {
          return true
        }
      }
    }
  })

  .directive('omConfigformUuid', function () {
    return {
      restrict: 'E',
      scope: {
        data: '=data',
        schema: '<schema',
        object: '<',
        selector: '<',
        objectPathInput: '<objectPath'
      },
      templateUrl: 'directives/objectmanager/formElements/uuid/template.html'
    }
  })
