
angular.module('webremote.directive.objectmanager')
  .controller('webremote.directive.objectmanager.genericEditModal.controller', ['$scope', '$uibModal', function ($scope, $uibModal) {
    $scope.openModal = function () {
      $scope.omModalDataWorkingCopy = angular.copy($scope.omModalData)

      if ($scope.omModalView === 'single') {
        // ----------------
        //  Single View
        // ----------------
        $scope.modalInstance = $uibModal.open({
          templateUrl: 'directives/objectmanager/genericEditModal/views/single/template.html',
          controller: 'webremote.directive.objectmanager.genericEditModal.views.single.controller',
          scope: $scope
        })
      } else if ($scope.omModalView === 'multi') {
        // ----------------
        //  Multi View
        // ----------------
        $scope.modalInstance = $uibModal.open({
          templateUrl: 'directives/objectmanager/genericEditModal/views/multi/template.html',
          controller: 'webremote.directive.objectmanager.genericEditModal.views.multi.controller',
          size: 'lg',
          scope: $scope
        })
      }

      $scope.modalInstance.result.then(() => {}, () => {})
    }
  }]).directive('omModal', function () {
    return {
      restrict: 'A',
      priority: 100,
      scope: {
        omModalView: '@',
        omModalData: '=', // Exclusive for Single View
        omModalClass: '=',
        omModalUuid: '=',
        omModalShape: '=',
        omModalSelector: '=',
        omModalLiveUpdate: '=',
        omModalUi: '=', // Exclusive for Multi View
        omModalEvents: '='
      },
      controller: 'webremote.directive.objectmanager.genericEditModal.controller',
      link: {
        post: function (scope, element, attrs) {
          $(element).on('click', function (e) {
            scope.openModal()
          })
        }
      }
    }
  })
require('./plugins/presets/controller')
require('./views/single/controller')
require('./views/multi/controller')
