
angular.module('webremote.directive.objectmanager.formElements')
  .controller('webremote.directive.objectmanager.formElements.struct.controller', function ($scope, omFormService) {
    $scope.omFormService = omFormService
  })

  .directive('omConfigformStruct', function () {
    return {
      restrict: 'E',
      transclude: 'true',
      scope: {
        data: '=',
        schema: '<',
        object: '<',
        debug: '<debug',
        selector: '=',
        objectPath: '<',
        expertLevel: '=expertLevel',
        type: '='
      },
      templateUrl: 'directives/objectmanager/formElements/struct/template.html'
    }
  })
