'use strict'

var app = angular.module('webremote.connect', ['ui.sortable'])

app.factory('PIPELINE', function ($log) {
  var id = -1
  var tab = 0
  var groupUuid = null

  return {
    getTab: function () {
      return tab
    },
    setTab: function (value) {
      tab = value
    },

    setGroup: function (uuid) {
      groupUuid = uuid
    },

    getGroup: function (uuid) {
      return groupUuid
    },

    getPipeline: function () {
      return id
    },
    setPipeline: function (value) {
      id = value
    }
  }
})

// filter for Search Engine to highlight text
app.filter('highlight', function ($sce) {
  return function (source, search) {
    if (search) {
      source = source.replace(new RegExp('(' + search + ')', 'gi'), '<span class="highlighted">$1</span>')
    }

    return $sce.trustAsHtml(source)
  }
})

// Filter for replace \n \r\n & \r with <br>
app.filter('newLine', function ($sce) {
  return function (text) {
    text = text.replace(/(?:\\r\\n|\\r|\\n)/g, '<br>')
    return $sce.trustAsHtml(text)
  }
})

require('./controller')
require('./edit/controller')
require('./edit/modals/generalOptions/controller')
require('./edit/modals/elementConfig/controller')
require('./modals/createGroup/controller')
require('./modals/editGroup/controller')
