
import _ from 'lodash'
angular.module('webremote.directive.objectmanager')
  .controller('webremote.directive.objectmanager.configform', function ($scope, objectManagerService, omFormService) {
    var objectPath = require('object-path')
    $scope.objectManager = objectManagerService.getObjectManager()
    $scope.scheme = []

    $scope.$watch('objectPathInput', v => {
      if (v) $scope.objectPath = v + '/'
    })

    $scope.$watch('selector', selector => {
      if (!$scope.objectPathInput) {
        if (selector) {
          $scope.objectPath = '/' + omFormService.cleanPath(selector).replace(/\./gi, '/') + '/'
        } else {
          $scope.objectPath = '/'
        }
      }
      $scope.loadScheme()
    })

    $scope.$watch('type', function () {
      $scope.loadScheme()
    })

    $scope.$watch('shape', function () {
      $scope.loadScheme()
    })

    $scope.$watchCollection('expertLevel', function (v) {
      $scope.loadScheme()
    })

    $scope.$watch('object.member', _.debounce(() => {
      if ($scope.validation !== false && $scope.object) {
        $scope.object.validate().then((v) => {
          $scope.$evalAsync(() => {
            if (v.some(x => omFormService.validationErrorLevels.includes(x.level))) {
              if ($scope.callbacks !== undefined) {
                $scope.callbacks.onInvalid()
              }
            } else {
              if ($scope.configForm.$valid) {
                if ($scope.callbacks !== undefined) {
                  $scope.callbacks.onValid()
                }
              }
            }
          })
        })
      }
    }, 1000)
    , true)

    // ==============
    // Process Schema
    // ==============
    $scope.loadScheme = function () {
      if ($scope.type !== undefined) {
        objectManagerService.getObjectManager().getScheme($scope.type).then((v) => {
          if ($scope.selector === undefined) {
            $scope.$evalAsync(() => {
              $scope.scheme = $scope.processSchema(v)
            })
          } else {
            $scope.$evalAsync(() => {
              $scope.scheme = $scope.processSchema(objectPath.get(v, $scope.selector))
            })
          }
        })
      }
    }

    $scope.processSchema = function (schema) {
      var ary = []
      angular.forEach(schema, function (val, key) {
        val.key = key
        ary.push(val)
      })

      // Process expertLevel
      if (Array.isArray($scope.expertLevel)) {
        // Notice wildcard '*'
        if ($scope.expertLevel.indexOf('*') === -1) {
          if ($scope.expertLevel.indexOf('default') === -1) {
            ary = ary.filter(v => $scope.expertLevel.indexOf(v.expertLevel) !== -1)
          } else {
            ary = ary.filter(v => $scope.expertLevel.indexOf(v.expertLevel) !== -1 || v.expertLevel === undefined)
          }
        }
      }

      // Process shape
      if ($scope.shape !== undefined) {
        if ($scope.shape.mode === 'include' || $scope.shape.mode === undefined) {
          ary = ary.filter(v => $scope.shape.shape[v.key])
        } else {
          ary = ary.filter(v => !$scope.shape.shape[v.key])
        }
      }

      // Process debug level
      if ($scope.debug !== true) {
        ary = ary.filter(v => (!v.hidden && !v.internal) || ($scope.shape !== undefined && $scope.shape.mode === 'include' && $scope.shape.shape[v.key]))
      }

      if ($scope.hasElements !== undefined && ary.length > 0) {
        $scope.hasElements.set()
      }

      return ary
    }

    $scope.getSelector = function () {
      if ($scope.selector !== undefined && $scope.selector.length > 0) {
        return $scope.selector + '.'
      } else {
        return ''
      }
    }

    $scope.tooltipHeadline = function (expertLevel) {
      if (expertLevel === 'advanced') {
        return '<b>Advanced Option</b><br>'
      } else if (expertLevel === 'expert') {
        return '<b>Expert Option</b><br>'
      } else {
        return ''
      }
    }

    $scope.$watch('configForm.$valid', function (newVal, oldVal) {
      try {
        if (newVal) {
          if ($scope.callbacks !== undefined) {
            $scope.callbacks.onValid()
          }
        } else {
          if ($scope.callbacks !== undefined) {
            $scope.callbacks.onInvalid()
          }
        }
      } catch (e) {
      }
    })
  })

  .directive('objectmanagerConfigform', function () {
    return {
      restrict: 'E',
      scope: {
        object: '=',
        validation: '<',
        data: '=',
        type: '=',
        objectPathInput: '<objectPath',
        selector: '=',
        expertLevel: '<',
        shape: '<',
        debug: '<',
        ui: '<',
        hasElements: '=',
        callbacks: '='
      },
      templateUrl: 'directives/objectmanager/configform/template.html'
    }
  })
