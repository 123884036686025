angular.module('webremote.settings').controller('webremote.settings.audio.controller',
  function ($scope, $log, $document, httpService, toastr, objectManagerService, modalService) {
    $scope.objectManager = objectManagerService.GetObjectManager
    $scope.activePill = ''
    $scope.selectedSoundCard = ''

    $scope.selectSoundCard = async function (uuid) {
      return $scope.objectManager().object(uuid, 'soundCard').then((v) => {
        $scope.selectedSoundCard = angular.copy(v)
        $scope.activePill = uuid
        if ($scope.selectedSoundCard.member.available) {
          $scope.deleteSoundCard = undefined
          $scope.saveSoundCard = $scope.saveSoundCard_template
        } else {
          $scope.deleteSoundCard = $scope.deleteSoundCard_template
          $scope.saveSoundCard = undefined
        }
        $scope.$evalAsync()
        return true
      })
    }

    $scope.initializeSouncardSelection = function () {
      // Initial SoundCard selection
      $scope.objectManager().collection('soundCard').then((v) => {
        if (typeof Array.from(v.values())[0] !== 'undefined') {
          $scope.$evalAsync(() => {
            $scope.selectSoundCard(Array.from(v.values())[0].member.uuid)
          })
        }
      })
    }

    $scope.saveSoundCard = undefined

    $scope.saveSoundCard_template = {
      disabled: false,
      cb: () => {
        $scope.selectedSoundCard.update()
        $scope.selectSoundCard($scope.selectedSoundCard.member.uuid)
      }
    }

    $scope.deleteSoundCard = undefined

    $scope.deleteSoundCard_template = {
      label: 'Delete',
      cb: () => {
        const modalConfig = {
          title: 'Confirm',
          text: 'Are you sure to delete this audio interface?'
        }

        modalService.confirmModal(modalConfig, $scope, function (response) {
          if (response) {
            $scope.selectedSoundCard.destroy()
            $scope.initializeSouncardSelection()
          }
        })
      }
    }

    $scope.toggleMuteMono = function (obj) {
      obj.member.type[$scope.getFirstKey(obj.member.type)].mute = !obj.member.type[$scope.getFirstKey(obj.member.type)].mute
      obj.update()
    }

    $scope.getFirstKey = function (obj) {
      if (obj !== undefined) {
        return Object.keys(obj)[0]
      } else {
        return ''
      }
    }

    $scope.isPortMemberOfAudioInterface = function () {
      return function (item) {
        return item.isMemberOfCard($scope.selectedSoundCard.member.uuid)
      }
    }
  })
