import moment from 'moment'
import Chart from 'chart.js'
import zoom from 'chartjs-plugin-zoom'

Chart.plugins.register({
  afterDraw: function (chart) {
  	if (chart.data.datasets.length === 0) {
    	// No data is present
      var ctx = chart.chart.ctx
      var width = chart.chart.width
      var height = chart.chart.height
      chart.clear()

      ctx.save()
      ctx.textAlign = 'center'
      ctx.textBaseline = 'middle'
      ctx.font = "16px normal 'Helvetica Nueue'"
      ctx.fillText('No data to display', width / 2, height / 2)
      ctx.restore()
    }
  }
})

export default class LogHistogram {
  constructor (cssId, type) {
    this.cssId = cssId
    this.type = type
    this.ctx = document.getElementById(this.cssId).getContext('2d')
    this.options = {
      type: this.type,

      data: {
        labels: [],
        datasets: []
      },

      options: {
        legend: {
          display: true,
          position: 'bottom'
        },
        scales: {
          xAxes: [{
            type: 'time',
            time: { unit: 'minute', displayFormats: { minute: 'HH:mm' } },
            display: true,
            ticks: {
              maxTicksLimit: 10
            }

          }],
          yAxes: [{
            display: true,
            scaleLabel: {
              display: false,
              labelString: 'Value'
            },
            ticks: {
              beginAtZero: true,
              callback: function (value) { if (value % 1 === 0) { return value } }
            }
          }]
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItems, data) {
              return tooltipItems.yLabel === 1 ? tooltipItems.yLabel + ' Entry' : tooltipItems.yLabel + ' Entries'
            }
          }
        },
        plugins: {
          zoom: {
            pan: {
              enabled: true,
              mode: 'xy'
            },
            zoom: {
              enabled: true,
              mode: 'xy',
              threshold: 2
            }
          }
        }
      }
    }
    this.instance = new Chart(this.ctx, this.options)
  }

  resetZoom () {
    this.instance.resetZoom()
  }

  updateData (data) {
    this.options.data.datasets = data

    this.instance.update()
  }
}
