angular.module('webremote.settings').controller('webremote.settings.system.controller',
  function ($scope, objectManagerService, $window, httpService, webSocketService, Upload, $uibModal, $rootScope, toastr, $interval, modalService, deviceService) {
    $scope.viewLevel = { levelArray: [] }
    $scope.om = objectManagerService
    $scope.om.collection('systemConfig').then(obj => {
      const now = new Date()
      $scope.export.hostname = Array.from(obj.values())[0].member.hostname
      $scope.export.timestamp = now
      $scope.systemConfig = angular.copy(Array.from(obj.values())[0])
    })

    $scope.selectedUpdateFile = null
    $scope.updateUploadProgress = null
    $scope.selectedRelease = null
    $scope.releases = []
    $scope.noConnection = null
    $scope.deviceId = ''
    $scope.onlyNumbers = new RegExp('\\D', 'g')
    $scope.export = { base64String: '', hostname: '', timestamp: '', password: '', backup: '' }
    $scope.backupPassword = ''
    $scope.om.getDefault('configImport').then(obj => {
      $scope.importOptions = {}
      $scope.importOptions.member = obj
      $scope.importOptions.title = 'configImport'
    })

    $scope.createBackup = function () {
      $scope.objectManager.callMethod('configExport', { password: $scope.export.password }, { notifications: true }).then(function (serviceData) {
        $scope.export.base64String = serviceData.data
      }, function (errorData) {
        $scope.export.backup.base64String = ''
      })
    }

    // On enter key apply settings before modal is applied and closed
    $scope.checkKey = function (keyEvent, action) {
      if (keyEvent.which === 13) {
        if (action !== undefined && action === 1 && $scope.export.password !== '')
        {
          $scope.createBackup()
        }
        keyEvent.preventDefault();
      }
    }

    $scope.clearLog = function () {
      const modalConfig = {
        title: 'Confirm',
        text: 'Are you sure to clear the log ?'
      }

      modalService.confirmModal(modalConfig, $scope, function (response) {
        if (response) {
          $scope.objectManager.callMethod('clearLog', {}, { notifications: true })
        }
      })
    }

    $scope.importBackup = function () {
      // Prepare fallback page, if ssl certs will be imported
      if ($scope.importOptions.member.system) {
        $rootScope.deviceError.status.user = true
        $rootScope.deviceError.status.ethernet = true
        $rootScope.deviceError.status.codec = false
        $rootScope.deviceError.message = 'The new SSL certificates are now applied through the import. You can now reestablish the connection to the WebRemote.'
      }

      $scope.importOptions.member.password = $scope.export.password
      $scope.importOptions.member.data = $scope.export.backup.base64

      $scope.objectManager.callMethod('configImport', $scope.importOptions.member, { notifications: true }).then(function (serviceData) {
        $scope.export.backup.base64String = ''
        $scope.export.password = ''
        $window.location.reload()
      }, function (errorData) {
        $scope.export.backup.base64String = ''
        $scope.export.password = ''
      })
    }

    $scope.getAvailableReleases = function () {
      $scope.objectManager.callMethod('getAvailableReleases', {}, { exceptions: true }).then(function (serviceData) {
        if (serviceData.releases.length < 1) {
          $scope.noConnection = true
        } else {
          $scope.releases = serviceData.releases
          $scope.selectedRelease = serviceData.releases[0]
          $scope.noConnection = false
        }
      }, function (errorData) {
        $scope.noConnection = true
      })
    }

    $scope.selectRelease = function (release) {
      $scope.selectedRelease = release
    }

    httpService.callFunction('GetSwVersions').then(function (serviceData) {
      $scope.swVersion = serviceData.data.main
    }, function (errorData) {
      toastr.error("Can't load the device information", 'Error', {
        closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
      })
    })

    httpService.callFunction('GetShortenedHwid').then(function (serviceData) {
      $scope.deviceId = deviceService.meta.machine + '-' + serviceData.data.shwid
    }, function (errorData) {
      toastr.error("Can't load the device information", 'Error', {
        closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
      })
    })

    $scope.callRestWithDeviceAction = function (target, type) {
      var deviceActionObj = {}
      deviceActionObj.dbusobject = {}
      deviceActionObj.dbusobject.id = ''
      deviceActionObj.dbusobject.classname = 'DbusDeviceAction'
      deviceActionObj.type = type
      deviceActionObj.target = target

      httpService.callFunction('DeviceAction', [JSON.stringify(deviceActionObj)]).then(function (serviceData) {
        // Nothing todo - success
      }, function (errorData) {
        toastr.error("Couldn't Perform Device Action: " + deviceActionObj.type, 'Error', {
          closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
        })
      })
    }

    $scope.deleteAllStreams = function () {
      $scope.callRestWithDeviceAction('configd', 'deleteStreams')

      toastr.info('Page is reloading', 'Please wait', {
        closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
      })

      var timer = $interval(function () {
        // Interrupt timer - timeout
        $interval.cancel(timer)

        $window.location.href = httpService.protocol + '://' + httpService.rootUrl + '/#/settings?module=device'
      }, 2000)
    }

    $scope.factoryReset = function () {
      $scope.modalInstance = $uibModal.open({
        templateUrl: 'modules/settings/tabs/system/modal_factoryResetOptions.html',
        controller: 'ctrlfactoryResetOptions',
        scope: $scope
      })

      // Get Feedback!
      $scope.modalInstance.result.then(function (resetOptions) {
        const modalConfig = {
          title: 'Confirm',
          text: 'Are you sure you want to restore the Device to its factory settings?'
        }

        modalService.confirmModal(modalConfig, $scope, function (response) {
          if (response) {
            const payload = { keepNetworkConfig: false, data: '' }

            if (resetOptions.mode === 'keep-network') {
              payload.keepNetworkConfig = true
            } else if (resetOptions.data.length > 0) {
              payload.keepNetworkConfig = false
              payload.data = resetOptions.data
            } else {
              payload.keepNetworkConfig = false
            }

            $scope.objectManager.callMethod('factoryReset', payload, { notifications: true }).then(function (serviceData) {
              webSocketService.EnableFallback(false)

              $scope.preventRebootCall = true

              $scope.modalInstance = $uibModal.open({
                templateUrl: 'modules/layout/rebootModal/template.html',
                controller: 'webremote.modules.layout.rebootModal.controller',
                size: 'md',
                scope: $scope,
                backdrop: 'static',
                keyboard: false
              })

              // Get Feedback!
              $scope.modalInstance.result.then(function () {
                toastr.success('Connected with device', 'Success', {
                  closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
                })

                $window.location.href = httpService.protocol + '://' + httpService.rootUrl
              }, function (errorData) {
                // What should happen on 'cancel'
                // prevent angularjs error (dropback)
              })
            }, function (errorData) {
              console.log(errorData)
              toastr.error("Couldn't call 'factoryReset'", 'Error', {
                closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
              })
            })
          }
        })
      }, function (errorData) {
        // What should happen on 'cancel'
        // prevent angularjs error (dropback)
      })
    }

    $scope.selectUpdateFile = function (event) {
      var file = event.target.files[0]
      $scope.selectedUpdateFile = file
    }

    $scope.processUpdateFile = function () {
      var modalConfig = {
        title: 'Confirm',
        text: "Are you sure to install update '" + $scope.selectedUpdateFile.name + "'?\n\nIf this is a downgrade, the device will perform an automatic factory reset after the version update process is complete. We recommend you perform a config export before you continue with a version downgrade.\n\nYou can verify whether this is an upgrade or a downgrade by comparing the number of your current version with the version number of the selected update."
      }
      modalService.confirmModal(modalConfig, $scope, function (response) {
        if (response) {
          if ($scope.selectedUpdateFile != null) {
            $scope.updateUploadProgress = 0
            Upload.upload({ url: 'webapi/http/UploadUpdateFile', method: 'POST', data: { file: $scope.selectedUpdateFile } }).then(
              function (resp) {
                $scope.selectedUpdateFile = null
                $scope.updateUploadProgress = null
                $scope.modalUpdater()
              }, function (resp) {
                toastr.error(resp.data.text, 'Error', {
                  closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
                })
                $scope.selectedUpdateFile = null
                $scope.updateUploadProgress = null
              }, function (evt) {
                $scope.updateUploadProgress = parseInt(99.0 * evt.loaded / evt.total)
              })
          }
        }
      })
    }

    $scope.installSoftwareUpdate = function () {
      var modalConfig = {
        title: 'Confirm',
        text: "Are you sure to install update '" + $scope.selectedRelease.name + "'?\n\nIf this is a downgrade, the device will perform an automatic factory reset after the version update process is complete. We recommend you perform a config export before you continue with a version downgrade.\n\nYou can verify whether this is an upgrade or a downgrade by comparing the number of your current version with the version number of the selected update."
      }
      modalService.confirmModal(modalConfig, $scope, function (response) {
        if (response) {
          var payload = {
            type: { online: { branch: $scope.selectedRelease.branch, release: $scope.selectedRelease.release } }
          }

          $scope.objectManager.callMethod('installSoftwareUpdate', payload, { exceptions: true }).then(
            function (serviceData) {
              $scope.modalUpdater()
            }, function (errorData) {
              if (errorData.message.text.includes('already in progress')) {
                toastr.info(errorData.message.text, 'Info', {
                  closeButton: true,
                  progressBar: true,
                  positionClass: 'toast-bottom-right'
                })
                $scope.modalUpdater()
              } else {
                toastr.error(errorData.message.text, 'Error', {
                  closeButton: true,
                  progressBar: true,
                  positionClass: 'toast-bottom-right'
                })
              }
            })
        }
      })
    }

    $scope.settingsImport = function () {
      const modalConfig = {
        title: 'Confirm',
        text: 'Are you sure to import this config-file? All settings will be overwritten and this device perform a reboot on its own.'
      }

      modalService.confirmModal(modalConfig, $scope, function (response) {
        if (response) {
          httpService.callFunction('ConfigImport', $scope.settingsFile).then(function (serviceData) {
            $scope.preventRebootCall = true

            $scope.modalInstance = $uibModal.open({
              templateUrl: 'modules/layout/rebootModal/template.html',
              controller: 'webremote.modules.layout.rebootModal.controller',
              size: 'md',
              scope: $scope,
              backdrop: 'static',
              keyboard: false
            })

            // Get Feedback!
            $scope.modalInstance.result.then(function () {
              toastr.success('Connected with device', 'Success', {
                closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
              })

              $window.location.href = httpService.protocol + '://' + httpService.rootUrl
            })
          }, function (errorData) {
            toastr.error("Can't import the config", 'Error', {
              closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
            })
          })
        }
      })
    }

    if (!deviceService.meta.machine.startsWith('container-')) {
      $scope.getAvailableReleases()
    }

    $scope.modalUpdater = function (type) {
      $scope.modalInstance = $uibModal.open({
        templateUrl: 'modules/settings/tabs/system/modalDeviceUpdate.html',
        controller: 'updateCtrl',
        size: 'lg',
        scope: $scope,
        backdrop: 'static',
        keyboard: false
      })
    }

    $scope.shutdown = function () {
      const modalConfig = {
        title: 'Confirm',
        text: 'Are you sure to shutdown the system?'
      }

      modalService.confirmModal(modalConfig, $scope, function (response) {
        if (response) {
          $scope.objectManager.callMethod('systemShutdown', {}, { notifications: true })
        }
      })
    }

    // rework
    $scope.rebootSystem = function () {
      const modalConfig = {
        title: 'Confirm',
        text: 'Are you sure to reboot the system?'
      }

      modalService.confirmModal(modalConfig, $scope, function (response) {
        if (response) {
          webSocketService.EnableFallback(false)

          $scope.preventRebootCall = false

          $scope.modalInstance = $uibModal.open({
            templateUrl: 'modules/layout/rebootModal/template.html',
            controller: 'webremote.modules.layout.rebootModal.controller',
            size: 'md',
            scope: $scope,
            backdrop: 'static',
            keyboard: false
          })

          // Get Feedback!
          $scope.modalInstance.result.then(function () {
            toastr.success('Connected with device', 'Success', {
              closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
            })

            $window.location.href = httpService.protocol + '://' + httpService.rootUrl
          }, function (errorData) {
            // What should happen on 'cancel'
            // prevent angularjs error (dropback)
          })
        }
      })
    }
  })
