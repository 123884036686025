'use strict'

var app = angular.module('webremote.layout', [])
require('./header.controller')
require('./activeMarker.controller')
require('./changePassword/controller')
require('./rebootModal/controller')
require('./wifi/controller')

app.directive('layoutHeader', ['$location', '$rootScope', function ($location, $window, $rootScope) {
  return {
    restrict: 'E',
    templateUrl: 'modules/layout/header.html',
    link: function ($scope, element, attrs) {
      $scope.company = window.company
    }
  }
}])

app.directive('audioChannelSelector', function ($location, $compile, $rootScope, audioportService) {
  return {
    restrict: 'E',
    templateUrl: 'modules/layout/audioChannelSelector.html',
    scope: {
      itemsPerPage: '@?itemsperpage',
      showTitle: '@?showtitle',
      maxChannels: '@?maxchannels',
      selectedChannels: '=?ngModel'
    },
    link: function (scope, elem, attr) {
      // set default values
      if (typeof (scope.maxChannels) === 'undefined') {
        scope.maxChannels = '192'
      }

      scope.maxChannels = parseInt(scope.maxChannels)

      if (typeof (scope.itemsPerPage) === 'undefined') {
        scope.itemsPerPage = '32'
      }

      scope.itemsPerPage = parseInt(scope.itemsPerPage)

      if (scope.maxChannels < scope.itemsPerPage) {
        scope.channelsPerPage = scope.maxChannels
      } else {
        scope.channelsPerPage = scope.itemsPerPage
      }

      if (typeof (scope.showTitle) === 'undefined') {
        scope.showTitle = 'true'
      }

      if (typeof (scope.selectedChannels) === 'undefined') {
        scope.selectedChannels = []
      }

      // Default Values
      scope.currentPage = 1
      scope.channelList = []

      scope.greenBubbleMax = 0
      scope.yellowBubbleMin = 1
      scope.yellowBubbleMax = 1
      scope.redBubbleMin = 2

      // fill channelList array with available Channels
      for (let i = 0; i < scope.maxChannels; i++) {
        scope.channelList.push(createObject(i))
      }

      // private function for channel objects
      function createObject (channel) {
        return {
          pipeCount: 0,
          channel: channel,
          selected: false
        }
      }

      // trigger function on channel-click
      scope.channelSelect = function (obj) {
        scope.channelList[obj.channel].selected = !obj.selected

        if (scope.channelList[obj.channel].selected) {
          scope.selectedChannels.push(obj.channel)
        } else {
          scope.selectedChannels.splice(scope.selectedChannels.indexOf(obj.channel), 1)
        }
      }
    }
  }
})
