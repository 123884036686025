angular.module('webremote.connect').controller('modalCtrl', function ($scope, $uibModalInstance, objectManagerService, webSocketService, toastr) {
  $scope.loadingFinished = undefined
  $scope.elementScheme = undefined
  $scope.objectManager = objectManagerService
  $scope.om = objectManagerService
  $scope.newPresetFlag = false
  $scope.preset = { member: { objectClass: 'elementPreset', data: {} } }
  $scope.presetDropdownState = false
  $scope.isCollapsed = true
  $scope.hasAdvElements = false

  $scope.viewLevel = {
    levelArray: []
  }

  // Create Working-Copy

  $scope.importStreamData = function () {
    $scope.om.object($scope.obj.member.uuid, 'stream').then(stream => {
      $scope.obj = angular.copy(stream)
    })
  }

  $scope.importStreamData()

  $scope.specificFormIdentifier = ''
  $scope.configFormValidationStates = {
    a: true,
    b: true
  }

  if ($scope.obj.elements[$scope.elementIndex].preset.length !== 0) {
    $scope.objectManager.object($scope.obj.elements[$scope.elementIndex].preset, 'elementPreset').then((preset) => {
      // Create Working-Copy
      $scope.preset = angular.copy(preset)
      $scope.presetDropdownState = false
    })
  }

  // Preset functions

  // Filter correct preset types
  $scope.filterPresetTypes = function (item) {
    const element = $scope.obj.elements[$scope.elementIndex]
    const category = $scope.obj.getElementCategory(element)

    return Object.prototype.hasOwnProperty.call(item.member.data.type, category) &&
    Object.prototype.hasOwnProperty.call(item.member.data.type[category].type, Object.keys(element.type[category].type)[0])
  }

  $scope.resetPresetState = function () {
    $scope.newPresetFlag = false
    $scope.presetDropdownState = false
    $scope.obj.elements[$scope.elementIndex].preset = ''
    $scope.preset = { member: { objectClass: 'elementPreset', data: {} } }
  }

  $scope.newPreset = function () {
    $scope.newPresetFlag = true
    $scope.presetDropdownState = false
    $scope.preset = { member: { objectClass: 'elementPreset', data: {} } }
  }

  $scope.destroyPreset = function (preset) {
    $scope.presetDropdownState = false
    preset.destroy().then((v) => {
      $scope.resetPresetState()
    })
  }

  $scope.connectPreset = function (preset) {
    $scope.obj.connectPreset($scope.elementIndex, preset.member.uuid).then(() => {
      $scope.preset = preset
      $scope.presetDropdownState = false
    }, (errorData) => {
      toastr.error(errorData.message.text, 'Error', {
        closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
      })
    })
  }

  $scope.disconnectPreset = function () {
    $scope.obj.disconnectPreset($scope.elementIndex).then(() => {
      $scope.resetPresetState()
    }, (errorData) => {
      toastr.error(errorData.message.text, 'Error', {
        closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
      })
    })
  }

  $scope.savePreset = function () {
    $scope.newPresetFlag = false
    $scope.preset.member.data = angular.copy($scope.obj.elements[$scope.elementIndex])

    // Save / Create
    if ($scope.preset.member.uuid === undefined) {
      $scope.objectManager.create('elementPreset', $scope.preset.member).then(uuid => {
        $scope.objectManager.collection('elementPreset', true).then(presetCollection => {
          $scope.connectPreset(presetCollection.get(uuid), true)
        })
      }, (errorData) => {
        toastr.error(errorData.message.text, 'Error', {
          closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
        })
      })
    } else {
      $scope.preset.update()
    }
  }

  // Validation state callbacks
  $scope.invalidFormA = function () { $scope.configFormValidationStates.a = false }
  $scope.validFormA = function () { $scope.configFormValidationStates.a = true }
  $scope.invalidFormB = function () { $scope.configFormValidationStates.b = false }
  $scope.validFormB = function () { $scope.configFormValidationStates.b = true }

  // Callback for visibility of advanced options section
  $scope.hasElements = function () {
    $scope.hasAdvElements = true
  }

  $scope.selector = 'elements.elementType.fields.type.fields.' + $scope.obj.getElementCategory($scope.obj.elements[$scope.elementIndex]) + '.fields'

  $scope.objectManager.getScheme('stream').then(v => {
    $scope.elementScheme = v.elements.elementType.fields.type.fields
    $scope.findSpecificFormIdentifier($scope.obj.elements[$scope.elementIndex])
  })

  $scope.findSpecificFormIdentifier = function (input) {
    $scope.specificFormIdentifier = ''
    for (const element in $scope.obj.elements[$scope.elementIndex].type[$scope.obj.getElementCategory($scope.obj.elements[$scope.elementIndex])].type) {
      if ($scope.elementScheme !== undefined && $scope.elementScheme[$scope.obj.getElementCategory($scope.obj.elements[$scope.elementIndex])].fields.type.fields.hasOwnProperty(element)) {
        $scope.specificSelector = 'elements.elementType.fields.type.fields.' + $scope.obj.getElementCategory($scope.obj.elements[$scope.elementIndex]) + '.fields.type.fields.' + element + '.fields'
      }
    }
  }

  // Helper function to get first key of object
  $scope.getFirstKey = function (obj) {
    return Object.keys(obj)[0]
  }

  // Enable update action for enter key
  $scope.checkKey = function (keyEvent) {
    if (keyEvent.which === 13) { $scope.closeModal() }
  }

  // Close modal, with saving
  $scope.closeModal = function () {
    // Update preset, if selected
    if ($scope.preset.member.uuid !== undefined) {
      $scope.savePreset()
    }

    // Return data
    $uibModalInstance.close($scope.obj)
  }

  // Close modal, without saving
  $scope.dismissModal = function () {
    $uibModalInstance.dismiss()
  }

  $scope.evaluateStreamUpdate = function (obj) {
    if (obj.uuid === $scope.obj.member.uuid) {
      $scope.importStreamData()
    }
  }

  // Additional webSocket listener, to import changes to editing copy of data object, if preset was assigned/unassigned
  webSocketService.on('ObjectOnUpdate', $scope.evaluateStreamUpdate)

  // Remove webSocket listener, if controller will be destroyed
  $scope.$on('$destroy', function () {
    webSocketService.off('ObjectOnUpdate', $scope.evaluateStreamUpdate)
  })
})
