
angular.module('webremote.directive.archive').controller('webremote.directive.archive.fileNavigation.controller', function ($scope, fileService, webSocketService) {
  $scope.maxPathLevel = 8

  $scope.navigateTo = (index) => {
    $scope.navigation.path.splice(index + 1, $scope.navigation.path.length - index)
  }

  $scope.lastPathLevels = () => {
    return $scope.navigation.path.slice(Math.max($scope.navigation.path.length - $scope.maxPathLevel, 0))
  }
}).directive('fileNavigation', function () {
  return {
    restrict: 'E',
    scope: {
      navigation: '='
    },
    templateUrl: 'directives/archive/fileNavigation/template.html'
  }
})
