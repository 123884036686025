
angular.module('webremote.directive.objectmanager.utilities')
  .controller('webremote.directive.utilities.buttonActionBar.controller', ['$scope', 'objectManagerService', 'httpService', '$uibModal', function ($scope, objectManagerService, httpService, $uibModal) {
    $scope.uac = {
      create: {
        action: 'disable',
        objects: [],
        methods: []
      },
      update: {
        action: 'disable',
        objects: [],
        methods: []
      },
      destroy: {
        action: 'disable',
        objects: [],
        methods: []
      },
      clone: {
        action: 'disable',
        objects: [],
        methods: []
      },
      call: {
        action: 'disable',
        objects: [],
        methods: []
      }
    }

    // Use custom uac conditions, if set
    $scope.$watch('createAction', () => {
      if ($scope.createAction && $scope.createAction.uac) {
        $scope.uac.create = $scope.createAction.uac
      }
    }, true)

    $scope.$watch('updateAction', () => {
      if ($scope.updateAction && $scope.updateAction.uac) {
        $scope.uac.update = $scope.updateAction.uac
      }
    }, true)

    $scope.$watch('destroyAction', () => {
      if ($scope.destroyAction && $scope.destroyAction.uac) {
        $scope.uac.destroy = $scope.destroyAction.uac
      }
    }, true)

    $scope.$watch('cloneAction', () => {
      if ($scope.cloneAction && $scope.cloneAction.uac) {
        $scope.uac.clone = $scope.cloneAction.uac
      }
    }, true)

    $scope.$watch('callAction', () => {
      if ($scope.callAction && $scope.callAction.uac) {
        $scope.uac.call = $scope.callAction.uac
      }
    }, true)

    // Apply default user access conditions, if no special conditions are set
    $scope.$watch('data', (v) => {
      if ($scope.data) {
        if (!$scope.cloneAction || !$scope.cloneAction.uac) {
          $scope.uac.clone = {
            action: 'disable',
            objects: [{ object: $scope.data.objectClass, rights: ['create'] }],
            methods: []
          }
        }

        if (!$scope.createAction || !$scope.createAction.uac) {
          $scope.uac.create = {
            action: 'disable',
            objects: [{ object: $scope.data.objectClass, rights: ['create'] }],
            methods: []
          }
        }

        if (!$scope.updateAction || !$scope.updateAction.uac) {
          $scope.uac.update = {
            action: 'disable',
            objects: [{ object: $scope.data.objectClass, rights: ['update'] }],
            methods: []
          }
        }

        if (!$scope.destroyAction || !$scope.destroyAction.uac) {
          $scope.uac.destroy = {
            action: 'disable',
            objects: [{ object: $scope.data.objectClass, rights: ['destroy'] }],
            methods: []
          }
        }

        if (!$scope.callAction || !$scope.callAction.uac) {
          $scope.uac.call = {
            action: 'disable',
            objects: [],
            methods: [$scope.data.methodClass]
          }
        }
      }
    })
  }])

  .directive('omButtonActionBar', function () {
    return {
      restrict: 'E',
      scope: {
        data: '<data',
        createAction: '<createAction',
        updateAction: '<updateAction',
        destroyAction: '<destroyAction',
        cloneAction: '<cloneAction',
        closeAction: '<closeAction',
        callAction: '<callAction'
      },
      templateUrl: 'directives/objectmanager/utilities/buttonActionBar/template.html'
    }
  })
