'use strict'

angular.module('webremote.service.authentication', []).factory('authenticationService', function (webSocketService, objectManagerService, httpService, $location) {
  class AuthenticationService {
    constructor () {
      this.refreshIntervalRef = null
      this.authenticated = false

      this.intialize().then(() => {
        this.validateSession()
      })
    }

    async intialize () {
      return objectManagerService.collection('session')
    }

    get session () {
      return Array.from(objectManagerService.collectionSync('session').values())[0]
    }

    async login (username, password) {
      const payload = {
        objectClass: 'session',
        username: username,
        password: password
      }

      await objectManagerService.create('session', payload)
    }

    async logout () {
      webSocketService.EnableFallback(false)
      this.stopRefreshInterval()
      this.session.destroy().then(() => {
        $location.path('/login')
      })
    }

    startRefreshInterval () {
      const duration = parseInt(this.session.member.duration)*1000*0.8
      if (duration < Math.pow(2, 32)) {
        this.refreshIntervalRef = setInterval(function () {
          this.session.refresh()
        }.bind(this), duration)
      }
    }

    stopRefreshInterval () {
      if (this.refreshIntervalRef) {
        clearInterval(this.refreshIntervalRef)
      }
    }

    async validateSession () {
      return httpService.callFunction('objects/session').then(() => {
        this.authenticated = true
        this.stopRefreshInterval()
        this.startRefreshInterval()
        return true
      }, function (errorData) {
        this.authenticated = false
        this.stopRefreshInterval()
        return false
      }.bind(this))
    }
  }

  var service = new AuthenticationService()
  return service
})
