angular.module('webremote.directive.objectmanager.formElements').controller('webremote.directive.objectmanager.formElements.channelSelector.modals.channelPicker.controller', function ($scope, $uibModalInstance, objectManagerService) {
  $scope.objectManager = objectManagerService.getObjectManager()
  $scope.maxLengthOfChannelName = 10
  $scope.maxLengthOfChannelNameStereo = 30
  $scope.maxLengthOfChannelNameMono = 30
  $scope.maxChannels = 64
  $scope.data = angular.copy($scope.data)

  $scope.getPortCollection = function () {
    if ($scope.type === 'audioInputPort') {
      return $scope.objectManager.collectionSync('audioInputPort').filter(port => port.member.card === $scope.data[$scope.key].soundcard)
    } else if ($scope.type === 'audioOutputPort') {
      return $scope.objectManager.collectionSync('audioOutputPort').filter(port => port.member.card === $scope.data[$scope.key].soundcard)
    } else {
      return []
    }
  }

  $scope.selectFirstSoundcardTab = function () {
    if ($scope.data[$scope.key].soundcard.length === 0) {
      $scope.objectManager.collection('soundCard').then(soundcardCollection => {
        $scope.data[$scope.key].soundcard = Array.from(soundcardCollection.values())[0].member.uuid
      })
    }
  }

  $scope.addChannelAllowed = function () {
    return $scope.data[$scope.key].mapping.length < $scope.maxChannels
  }

  function arrayRemove (arr, value) {
    return arr.filter(function (ele) {
      return !ele.includes(value)
    })
  }

  $scope.destroyVirtual = function (port) {
    // Exit, if port is not virtual
    if (!Object.prototype.hasOwnProperty.call(port.member.type, 'virtual')) return

    // Select associated OutputPort
    if (port.member.objectClass === 'audioInputPort') {
      $scope.objectManager.object(port.member.type.virtual.audioOutputPort, 'audioOutputPort').then((obj) => {
        obj.destroy()
        $scope.data[$scope.key].mapping = arrayRemove($scope.data[$scope.key].mapping, obj.member.uuid)
      })
    } else {
      port.destroy()
      $scope.data[$scope.key].mapping = arrayRemove($scope.data[$scope.key].mapping, port.member.uuid)
    }
  }

  // Modal Methods
  $scope.closeModal = function () {
    $uibModalInstance.close($scope.data[$scope.key])
  }

  $scope.dismissModal = function () {
    $uibModalInstance.dismiss()
  }
})
