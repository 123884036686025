
angular.module('webremote.directive.objectmanager.formElements')
  .controller('webremote.directive.objectmanager.formElements.upload.controller', ['$scope', 'objectManagerService', function ($scope, objectManagerService) {
    $scope.selectFile = function (file, data, value) {
      var reader = new window.FileReader()

      reader.onloadend = function () {
        data[value] = reader.result.replace(/.*base64,/, '')
      }

      try {
        if (typeof file === 'object') {
          data.mimeType = file.type
          reader.readAsDataURL(file)
        }
      } catch (e) {
        console.debug('error on image preview: ' + e)
      }
    }
  }])

  .directive('omConfigformUpload', function () {
    return {
      restrict: 'E',
      transclude: 'true',
      scope: {
        data: '=data',
        schema: '<schema'
      },
      templateUrl: 'directives/objectmanager/formElements/upload/template.html'
    }
  })
