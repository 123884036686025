
angular.module('webremote.directive.objectmanager.formElements')
  .controller('webremote.directive.objectmanager.formElements.callProfile.controller', function ($scope, omFormService, objectManagerService, audioElementLibraryService) {
    $scope.encoder = []
    $scope.encoderElementIndex = 0
    $scope.outsideSelection = false
    $scope.omFormService = omFormService

    $scope.modalEvents = {
      // Copy data from modal to data object
      update: function (value) {
        $scope.data.encoderElements[$scope.encoderElementIndex].type.encoder
          .type[Object.keys($scope.data.encoderElements[$scope.encoderElementIndex].type.encoder.type)[0]] = value
        $scope.validateCallProfile()
      }
    }

    $scope.getEncoder = function () {
      objectManagerService.getObjectManager().object('ecf714f4-c11d-461b-9491-02a0f0c75318', 'callProfile').then(v => {
        let defaultEncoder = v.member.encoderElements

        if (defaultEncoder.length > 0) {
          defaultEncoder = defaultEncoder.map(x => x.displayName)
          // $scope.encoder = angular.copy(audioElementLibraryService.audioElementLibrary.filter(x => $scope.isEncoder(x)) || []).filter(codec => defaultEncoder.includes(codec.displayName))
          $scope.encoder = angular.copy(audioElementLibraryService.audioElementLibrary.filter(x => $scope.isEncoder(x)) || []).filter(codec => codec.displayName !== 'FLAC').filter(codec => codec.displayName !== 'Vorbis')
        }
      })
    }

    $scope.isEncoder = function (entry) {
      return Object.prototype.hasOwnProperty.call(entry, 'type') && Object.prototype.hasOwnProperty.call(entry.type, 'encoder')
    }

    $scope.stopClickPropagation = function ($event) {
      $event.preventDefault()
      $event.stopPropagation()
    }

    $scope.increaseChannels = function ($event, codec) {
      $scope.stopClickPropagation($event)
      codec.type.encoder.forcedNChannels = codec.type.encoder.forcedNChannels + 1
    }

    $scope.decreaseChannels = function ($event, codec) {
      $scope.stopClickPropagation($event)
      if (codec.type.encoder.forcedNChannels > 1) {
        codec.type.encoder.forcedNChannels = codec.type.encoder.forcedNChannels - 1
      }
    }

    $scope.encoderSortableListOptions = {
      placeholder: 'app',
      connectWith: '.apps-container',

      stop: function (e, ui) {
        $scope.getEncoder()
      },

      over: function (event, ui) {
        $scope.outsideEncoderPool = false
      },

      out: function (event, ui) {
        $scope.outsideEncoderPool = true
      },

      update: function (event, ui) {
        if ($scope.outsideSelection) {
          ui.item.sortable.cancel()
        }
      }
    }

    $scope.callProfileSortableListOptions = {
      helper: 'clone',
      handle: '> .handle',
      'ui-floating': false,
      over: function (event, ui) {
        $scope.outsideSelection = false
      },
      out: function (event, ui) {
        $scope.outsideSelection = true
      },
      beforeStop: function (event, ui) {
        if ($scope.outsideSelection) {
          $scope.removeCodecAtPosition(ui.item.sortable.index)
        }
      },
      receive: function (event, ui) {
        if (!$scope.outsideSelection) {
          $scope.importDefaultValuesToPosition(ui.item.sortable.dropindex).then(() => {
          })
        }
      }
    }

    $scope.validateCallProfile = function () {
      if (!$scope.disableValidation) {
        return objectManagerService.getObjectManager().callMethod('validateCallProfile', { profile: $scope.data }).then((data) => {
          $scope.$evalAsync(function () {
            try {
              for (let i = 0; i < data.profile.encoderElements.length; i++) {
                $scope.data.encoderElements[i] = data.profile.encoderElements[i]
              }
              if (data.validationErrors.length > 0) {
                $scope.valid.state = false
                $scope.valid.errors = data.validationErrors
              } else {
                $scope.valid.state = true
                $scope.valid.errors = []
              }
            } catch (e) { }
          })
        })
      }
    }
    $scope.errorMessagesForElement = (index) => {
      return $scope.object.validationErrors(omFormService.validationErrorLevels, $scope.objectPath + '/' + index).map(x => x.message).join(',')
    }

    $scope.removeCodecAtPosition = function (index) {
      $scope.data.encoderElements.splice(index, 1)
    }

    $scope.addEncoderWithLongPress = function (obj) {
      if ('ontouchstart' in window) {
        $scope.addEncoder(obj)
      }
    }

    $scope.addEncoder = function (obj) {
      $scope.data.encoderElements.push(obj)
      $scope.importDefaultValuesToPosition($scope.data.encoderElements.length - 1).then(() => {
        $scope.validateCallProfile()
      })
    }

    $scope.removeEncoderWithLongPress = function (index) {
      if ('ontouchstart' in window) {
        $scope.data.encoderElements.splice(index, 1)
        $scope.validateCallProfile()
      }
    }

    $scope.importDefaultValuesToPosition = function (index) {
      return objectManagerService.getObjectManager().object('ecf714f4-c11d-461b-9491-02a0f0c75318', 'callProfile').then(v => {
        $scope.$evalAsync(function () {
          function uuidv4() {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
              var r = Math.random() * 16 | 0; var v = c === 'x' ? r : (r & 0x3 | 0x8)
              return v.toString(16)
            })
          }
          const defaultCodecIndex = v.member.encoderElements.findIndex(x => x.displayName === $scope.data.encoderElements[index].displayName || (x.displayName === 'MP3' && $scope.data.encoderElements[index].displayName === 'MP2') || (x.displayName.startsWith('PCM') && $scope.data.encoderElements[index].displayName === 'RAW'))

          if (v.member.encoderElements[defaultCodecIndex] !== undefined) {
            $scope.data.encoderElements[index].type.encoder.rtpPayloadType = v.member.encoderElements[defaultCodecIndex].type.encoder.rtpPayloadType
            $scope.data.encoderElements[index].type.encoder.forcedRate = v.member.encoderElements[defaultCodecIndex].type.encoder.forcedRate
            $scope.data.encoderElements[index].type.encoder.forcedNChannels = v.member.encoderElements[defaultCodecIndex].type.encoder.forcedNChannels
            $scope.data.encoderElements[index].type.encoder.bitrate = v.member.encoderElements[defaultCodecIndex].type.encoder.bitrate
          }
          $scope.data.encoderElements[index].uuid = uuidv4()
        })
      })
    }

    $scope.getFirstKey = (obj) => {
      return Object.keys(obj.type.encoder.type)[0]
    }

    $scope.$watch('data[schema.key]', function () {
      $scope.getEncoder()
    }, true)
  })

  .directive('omConfigformCallProfile', function () {
    return {
      restrict: 'E',
      transclude: true,
      scope: {
        object: '=',
        data: '=data',
        schema: '<schema',
        valid: '=valid',
        objectPath: '=',
        disableValidation: '=disableValidation',
        errorsOverride: '=errorsOverride'
      },
      templateUrl: 'directives/objectmanager/formElements/callProfile/template.html'
    }
  })
