
angular.module('webremote.directive.objectmanager.utilities')
  .controller('webremote.directive.utilities.errorIndicator.controller', ['$scope', function ($scope) {

  }])

  .directive('omErrorIndicator', function () {
    return {
      restrict: 'E',
      scope: {
        errors: '=errors',
        filter: '=filter',
        messageFn: '=messageFn',
        previousText: '=previousText',
        obj: '<obj'
      },
      templateUrl: 'directives/objectmanager/utilities/errorIndicator/template.html'
    }
  })
