angular.module('webremote.dashboard').controller('widget_tipOfTheDay', 
function ($scope, $http, $log, $uibModal, $location) {

    $http.get('modules/dashboard/widgets/tipOfTheDay/tips.json', {cache: false}).then(function(data) {
        $scope.currentTip =  data.data[Math.floor(Math.random()*data.data.length)];
    });

    $scope.open = function(type){
            $scope.modalInstance = $uibModal.open({
              templateUrl: "Modal.html",
              controller: 'tipModalCtrl',
                size: 'lg',
              scope: $scope
            });

        $log.debug("Modal 'TipOfTheDay' opened! (Controller: tipModalCtrl)");
    };

});
