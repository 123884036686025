angular.module('webremote.directive.objectmanager.formElements')
  .controller('webremote.directive.objectmanager.formElements.wifiList',
    function ($scope, objectManagerService) {
      $scope.om = objectManagerService
      $scope.WifiSsid = $scope.model[$scope.key]
      $scope.wifis = []

      $scope.$watchCollection('om.collectionSync("wifi")', wifis => {
        $scope.wifis = wifis
      })

      $scope.storeSelectedWifi = function (wifi) {
        $scope.WifiSsid = wifi.member.name

        $scope.model.essid = wifi.member.name
        if (wifi.member.security === 'none') {
          $scope.model.encryptionMode = {
            none: {}
          }
        } else {
          $scope.model.encryptionMode = {
            [wifi.member.security]: {
              password: wifi.member.password
            }
          }
        }
      }

      $scope.getWifisFiltered = function () {
        if ($scope.WifiSsid !== undefined) {
          $scope.model.essid = $scope.WifiSsid
        }
        if ($scope.wifis !== undefined) {
          var filteredWifis = $scope.wifis
          if ($scope.WifiSsid && Array.from($scope.wifis.values()).find((e) => { return e.member.name === $scope.WifiSsid }) === undefined) {
            filteredWifis = filteredWifis.filter((wifi) => wifi.member.name.toLowerCase().includes($scope.WifiSsid.toLowerCase()))
          }
          return filteredWifis
        } else {
          return []
        }
      }
    })

  .directive('wifiList', function () {
    return {
      restrict: 'E',
      scope: {
        model: '=',
        key: '='
      },
      templateUrl: 'directives/objectmanager/formElements/wifiList/template.html'
    }
  })
