
angular.module('webremote.directive.objectmanager.views')
  .controller('webremote.directive.objectmanager.views.collapse.controller', ['$scope', '$timeout', 'objectManagerService', 'httpService', '$uibModal', function ($scope, $timeout, objectManagerService, httpService, $uibModal) {
    $scope.collapsed = true
    $scope.content = false
    $scope.delayReference = null

    $scope.$watch('next', v => {
      if (v !== undefined) {
        $timeout.cancel($scope.delayReference)
        if (v) {
          $scope.collapsed = true
          $scope.delayReference = $timeout(() => {
            $scope.content = false
          }, 100)
        } else {
          $scope.content = true
          $scope.delayReference = $timeout(() => {
            $scope.collapsed = false
          }, 100)
        }
      }
    })
  }]).directive('omViewCollapse', function () {
    return {
      restrict: 'E',
      controller: 'webremote.directive.objectmanager.views.collapse.controller',
      transclude: {
        title: '?omViewTitle',
        content: 'omViewContent',
        icon: '?omViewIcon',
        button: '?omViewButton'
      },
      templateUrl: 'directives/objectmanager/views/collapse/template.html'
    }
  })
