export default class VolumeControl {
  constructor (channel, initialValue, stream) {
    this.stream = stream
    this.channel = channel
    this.id = this.stream.member.uuid + '_' + channel
    this.slider = {
      value: 0,
      options: {
        disabled: (channel > 1 && stream.getElement('processing', 'volumecontrol').type.processing.type.volumecontrol.locked),
        floor: -80,
        ceil: 20,
        step: 1,
        showSelectionBar: true,
        vertical: true,
        showTicks: false,
        showTicksValues: false,
        keyboardSupport: true,
        id: this.id,

        onChange: function () { },

        onEnd: function () {
          stream.setVolume(this.channel, this.slider.value)
        }.bind(this)
      }
    }

    this.slider.value = initialValue
  }

  updateCallback (value) {
    this.slider.value = (Math.round(parseFloat(value) * 10) / 10)
  }
}
