angular.module('webremote.directive.streamGroups.pipepicker').controller('modals.pipepicker.controller', function ($scope, $uibModalInstance, objectManagerService) {
  $scope.om = objectManagerService
  $scope.choosenPipe = undefined

  // Modal Methods
  $scope.closeModal = function () {
    $uibModalInstance.close($scope.choosenPipe)
  }

  $scope.dismissModal = function () {
    $uibModalInstance.dismiss()
  }
})
