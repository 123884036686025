
angular.module('webremote.directive.objectmanager')
  .controller('webremote.directive.objectmanager.genericEditModal.views.multi.controller', function ($scope, $uibModalInstance, objectManagerService, httpService, toastr) {
    $scope.active = 0
    $scope.selectedItem = {}
    $scope.viewLevel = { levelArray: [] }
    var ClipboardJS = require('clipboard')
    $scope.clipboardManager = new ClipboardJS('.btn')

    $scope.objectManager = objectManagerService

    // Init request for objects
    $scope.objectManager.collection($scope.omModalClass)
    $scope.objectManager.getDefault($scope.omModalClass)

    $scope.testSsh = function (uuid) {
      $scope.objectManager.callMethod('testSshLogin', { sshLogin: uuid }, { notifications: true })
    }

    $scope.newItem = function () {
      $scope.objectManager.getDefault($scope.omModalClass).then((v) => {
        $scope.$evalAsync(() => {
          $scope.selectedItem = { member: angular.copy(v) }
        })
      })

      $scope.active = 0
    }

    $scope.selectItem = function (item) {
      $scope.selectedItem = angular.copy(item)
      $scope.active = item.member.uuid
    }

    if ($scope.omModalUuid !== undefined && $scope.omModalUuid.length > 0) {
      $scope.objectManager.object($scope.omModalUuid, $scope.omModalClass).then(v => {
        $scope.$evalAsync(() => {
          $scope.selectItem(v)
        })
      })
    } else {
      $scope.newItem()
    }

    $scope.actions = {

      clone: {
        cb: () => {
          $scope.selectedItem.clone()
        }
      },
      close: {
        cb: () => {
          $uibModalInstance.dismiss()
        }
      },
      create: {
        cb: () => {
          $scope.objectManager.create($scope.omModalClass, $scope.selectedItem.member, true).then((v) => {
            $scope.selectedItem.member.uuid = v
            if ($scope.omModalEvents && $scope.omModalEvents.update) $scope.omModalEvents.update($scope.selectedItem)
            $uibModalInstance.close()
          })
        }
      },
      remove: {
        cb: () => {
          $scope.selectedItem.destroy().then((v) => {
            $scope.newItem()
          })
        }
      },
      update: {
        cb: () => {
          $scope.selectedItem.update().then((v) => {
            if ($scope.omModalEvents && $scope.omModalEvents.update) $scope.omModalEvents.update($scope.selectedItem)
            $uibModalInstance.close()
          })
        }
      }
    }
  })
