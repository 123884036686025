angular.module('webremote.dashboard').controller('widget_networkStatus',
  function ($scope, $http, $log, statsService, authenticationService) {
    $scope.statsFromService = statsService.getStats
    $scope.dashboard = authenticationService.dashboardObject

    $scope.$watch('activeDashboard', function () {
      $scope.objectManager().object($scope.activeDashboard, 'dashboard').then((v) => {
        $scope.dashboard = v
      })
    })

    $scope.objectManager().object($scope.activeDashboard, 'dashboard').then((v) => {
      $scope.dashboard = v

      $scope.$watch('widgetIndex', function () {
        $scope.storedOptions = $scope.dashboard.getInformationForWidget($scope.widgetIndex)
        $scope.activeSlide = ($scope.storedOptions.activeSlide === undefined ? 0 : $scope.storedOptions.activeSlide)
      })

      $scope.$watch('activeSlide', function () {
        $scope.storedOptions.activeSlide = $scope.activeSlide
        $scope.dashboard.storeInformationForWidget($scope.widgetIndex, JSON.stringify($scope.storedOptions))
      })
    })
  })
