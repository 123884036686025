import BaseClass from './Base.class'

export default class CallPreset extends BaseClass {
  indexOfPhonebookEntry (uuid) {
    return this.member.entries.findIndex(function (element) {
      return element.phoneBookEntry === uuid
    })
  }

  isPhoneBookEntryMember (uuid) {
    return this.indexOfPhonebookEntry(uuid) !== -1
  }

  countActiveEntries () {
    let activePhoneBookEntries = 0
    var oms = this._dependencies.$injector.get('objectManagerService')
    for (const entry of this.member.entries) {
      const phEntry = oms.getObjectManager().objectSync(entry.phoneBookEntry, 'phoneBookEntry')
      if (phEntry !== undefined && phEntry.member.sip.activeCall.length > 0) {
        activePhoneBookEntries++
      }
    }
    return activePhoneBookEntries
  }

  async addPhonebookEntry (uuid, lock = true) {
    if (uuid === undefined) {
      return new Error('uuid is missing')
    }

    if (this.indexOfPhonebookEntry(uuid) !== -1) {
      return false
    } else {
      this.member.entries.push({ phoneBookEntry: uuid, editingLocked: lock })
      // apply changes
      return this.update()
    }
  }

  async removePhonebookEntry (uuid) {
    if (uuid === undefined) {
      return new Error('uuid is missing')
    }

    const phonebookEntryIndex = this.indexOfPhonebookEntry(uuid)
    if (phonebookEntryIndex !== -1) {
      this.member.entries.splice(phonebookEntryIndex, 1)
      return this.update()
    } else {
      return false
    }
  }

  bulkConnectEntries () {
    return this.member.entries.filter(entry => entry.bulkConnect)
  }

  async bulkConnect () {
    this.member.connect = true
    return this.update()
  }

  async hangUp () {
    this.member.hangup = true
    return this.update()
  }

  async toggleEditLockByEntryIndex (index) {
    if (index === undefined) {
      return new Error('index is missing')
    }
    this.member.entries[index].editingLocked = !this.member.entries[index].editingLocked
    return this.update()
  }

  async toggleBulkConnectByEntryIndex (index) {
    if (index === undefined) {
      return new Error('index is missing')
    }
    this.member.entries[index].bulkConnect = !this.member.entries[index].bulkConnect
    return this.update()
  }
}
