
angular.module('webremote.directive.streams.elementIcon', [])
  .controller('elementIconCtrl', ['$scope', function ($scope) {
    $scope.icon = ''

    $scope.$watch('data', function (newValue, oldValue) {
      $scope.icon = ''

      switch ($scope.elementCategory) {
        case 'source':
          switch (Object.keys($scope.data.type.source.type)[0]) {
            case 'jack':
              $scope.icon = 'oi oi-microphone'
              break
            case 'dtmf':
              $scope.icon = 'oi oi-grid-three-up'
              break
            case 'file':
              $scope.icon = 'oi oi-file'
              break
            case 'test':
              $scope.icon = 'oi oi-audio'
              break
            case 'rtp':
              $scope.icon = 'oi oi-globe'
              break
            case 'uri':
              $scope.icon = 'oi oi-link-intact'
              break
            case 'ref':
              $scope.icon = 'oi oi-share'
              break
            case 'aoip':
              $scope.icon = 'oi oi-cloud-download'
              break
            case 'monitor':
              $scope.icon = 'oi oi-monitor'
              break
          }
          break
        case 'encoder':
          $scope.icon = 'oi oi-audio-spectrum'
          break
        case 'processing':
          switch (Object.keys($scope.data.type.processing.type)[0]) {
            case 'volumecontrol':
              $scope.icon = 'oi oi-volume-high'
              break
            case 'levelmeter':
              $scope.icon = 'glyphicon glyphicon-equalizer'
              break
            case 'metadataInserter':
              $scope.icon = 'oi oi-info'
              break
            case 'delay':
              $scope.icon = 'oi oi-project'
              break
            case 'resampler':
              $scope.icon = 'oi oi-pulse'
              break
            case 'mixMatrix':
              $scope.icon = 'oi oi-vertical-align-center'
              break
            case 'loudnesscontrol':
              $scope.icon = 'oi oi-dashboard'
              break
          }
          break
        case 'postProcessing':
          switch (Object.keys($scope.data.type.postProcessing.type)[0]) {
            case 'hlsDashUpload':
            case 'archiveUpload':
            case 'onDemand':
              $scope.icon = 'oi oi-cloud-upload'
              break
            case 'encryption':
              $scope.icon = 'oi oi-lock-locked'
              break
          }
          break
        case 'sink':
          switch (Object.keys($scope.data.type.sink.type)[0]) {
            case 'file':
              $scope.icon = 'oi oi-file'
              break
            case 'jack':
              $scope.icon = 'oi oi-headphones'
              break
            case 'ybrid':
              $scope.icon = 'assets/img/ybrid-logo.svg'
              break
            default:
              $scope.icon = 'oi oi-globe'
          }
          break
        default:
          $scope.icon = ''
      }

      $scope.type = ($scope.icon.endsWith('.svg') ? 'svg' : 'icon')
    })
  }])

  .directive('elementIcon', function () {
    return {
      restrict: 'E',
      scope: {
        data: '<data',
        elementCategory: '<elementCategory'
      },
      templateUrl: 'directives/streams/elementIcon/template.html'
    }
  })
