angular.module('webremote.dashboard').controller('widget_availableUsers', 
function ($scope) {

    $scope.users = {
        0 : {
            "id": 0,
            "name": "Bill Krewitz",
            "mute": false
        },
        1 : {
            "id": 1,
            "name": "George Austin",
            "mute": false
        },
        2 : {
            "id": 2,
            "name": "Kerry Westing",
            "mute": false
        },
        3 : {
            "id": 3,
            "name": "Drake Costa",
            "mute": false
        }
    };

    $scope.toggleMute = function(id) {
        $scope.users[id].mute = !$scope.users[id].mute;
    }

});
