'use strict'

import ProxyClass from '../../class/Proxy.class'

angular.module('webremote.service.objectManager', []).factory('objectManagerService', function ($log, $http, $injector, httpService, webSocketService, toastr, schemeParser) {
  class ObjectManager {
    constructor () {
      this.obj = this
      this.objects = {}
      this.methods = {}
      this.scheme = new Map()
      this.default = new Map()
      this.locks = new Map()
      this.initialized = new Map()
      this.dependencies = { httpService: httpService, toastr: toastr, $http: $http, $injector: $injector }
    }

    async initialize () {
      // Load Objects
      return this.getMetaObjects().then((v) => {
        for (const objTitle of v) {
          // Create hashMap for objects
          this.objects[objTitle] = new Map()

          // Create empty objects for schemes
          this.scheme.set(objTitle, {})

          this.locks.set(objTitle, false)
        }

        // Load Methods
        this.getMetaMethods().then((v) => {
          for (const methodTitle of v) {
            // Create hashMap for methods
            this.methods[methodTitle] = {}

            // Create empty methods for schemes
            this.scheme.set(methodTitle, {})
            this.locks.set(methodTitle, false)
          }
        })
      })
    }

    getClassOf (obj) {
      switch (obj.objectClass) {
        case 'sipCall':
          return 'SipCall'
        case 'sipAccount':
          return 'SipAccount'
        case 'user':
          return 'User'
        case 'role':
          return 'Role'
        case 'dashboard':
          return 'Dashboard'
        case 'stream':
          return 'Stream'
        case 'streamGroup':
          return 'StreamGroup'
        case 'audioInputPort':
        case 'audioOutputPort':
          return 'AudioPort'
        case 'applet':
          return 'Applet'
        case 'license':
          return 'License'
        case 'callPreset':
          return 'CallPreset'
        case 'phoneBookEntry':
          return 'PhoneBookEntry'
        case 'elementPreset':
          return 'ElementPreset'
        case 'session':
          return 'Session'
        case 'onDemandJobPool':
          return 'OnDemandJobPool'
        case 'onDemandStatistics':
          return 'OnDemandStatistics'
        default:
          return 'BaseClass'
      }
    }

    async getDefault (objTitle) {
      if (this.default.has(objTitle)) {
        return angular.copy(this.default.get(objTitle))
      } else {
        const objType = service.getObjectType(objTitle)
        return httpService.callFunction('meta/schema/' + objType + '/' + objTitle).then(function (payload) {
          const parsedData = schemeParser.parse(payload.data.fields)
          this.default.set(objTitle, parsedData)
          return angular.copy(parsedData)
        }.bind(this), function (errorData) {
          console.error('[ObjectManager] Cannot get default of: ' + objTitle, errorData)
        })
      }
    }

    async getScheme (objTitle) {
      if (this.scheme.has(objTitle) && Object.keys(this.scheme.get(objTitle)).length > 0) {
        return angular.copy(this.scheme.get(objTitle))
      } else {
        const objType = service.getObjectType(objTitle)
        return httpService.callFunction('meta/schema/' + objType + '/' + objTitle).then(function (payload) {
          const parsedValidation = schemeParser.parseValidation(payload.data.fields)
          this.scheme.set(objTitle, parsedValidation)
          return angular.copy(parsedValidation)
        }.bind(this), function (errorData) {
          console.error('[ObjectManager]', 'Cannot get scheme of: ' + objTitle, errorData)
        })
      }
    }

    async getMetaObjects () {
      return httpService.callFunction('meta/list/objects').then(function (payload) {
        return payload.data
      }, function (errorData) {
        toastr.error("Couldn't load ObjectClasses", 'Error', {
          closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
        })
      })
    }

    async getMetaMethods () {
      return httpService.callFunction('meta/list/methods').then(function (payload) {
        return payload.data
      }, function (errorData) {
        toastr.error("Couldn't load MethodClasses", 'Error', {
          closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
        })
      })
    }

    isCollectionInitialized (type) {
      return this.objects[type] !== undefined &&
        this.initialized.get(type)
    }

    async initializeCollection (type) {
      if (!this.locks.get(type)) {
        this.locks.set(type, true)
        await this.collection(type, true)
        this.locks.set(type, false)
      }
      return true
    }

    rawCollectionSync (objectName) {
      if (!this.isCollectionInitialized(objectName)) {
        this.initializeCollection(objectName)
      }

      if (this.objects[objectName] !== undefined) {
        return Array.from(this.objects[objectName].values())
      } else {
        return []
      }
    }

    collectionSync (objectName) {
      return this.rawCollectionSync(objectName).filter(x => x.visible)
    }

    async rawCollection (objectName, refetch) {
      // Return collection of type 'objectName'
      if (this.objects[objectName] && this.objects[objectName].size > 0 && !refetch) {
        return this.objects[objectName]

        // Request collection of type 'objectName'
      } else {
        return httpService.callFunction('objects/' + objectName).then(function (serviceData) {
          for (const obj of serviceData.data) {
            this.objects[objectName].set(obj.uuid, new ProxyClass(service.getClassOf(obj), { dependencies: this.dependencies, obj: obj }))
          }
          this.initialized.set(objectName, true)
          return this.objects[objectName]
        }.bind(this), function (error) {
          toastr.error("Couldn't load objects for " + objectName, 'Error', {
            closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
          })
          $log.debug(error)
          throw new Error(error)
        })
      }
    }

    async collection (objectName, refetch) {
      return this.rawCollection(objectName, refetch)
    }

    objectSync (uuid, type) {
      if (!this.isCollectionInitialized(type)) {
        this.initializeCollection(type)
      }

      try {
        return this.objects[type].get(uuid)
      } catch (e) {
        return undefined
      }
    }

    async object (uuid, type, refetch) {
      if (this.objects[type] !== undefined && this.objects[type].has(uuid) && !refetch) {
        return this.objects[type].get(uuid)
      } else {
        return this.collection(type, refetch).then(function (v) {
          return this.objects[type].get(uuid)
        }.bind(this)).catch((error) => {
          throw new Error(error)
        })
      }
    }

    async createDefaultObject (objectClass) {
      const obj = { objectClass: objectClass }
      return new ProxyClass(service.getClassOf(obj), { dependencies: this.dependencies, obj: await this.getDefault(objectClass) })
    }

    async create (objectClass, payload, notificationEnabled) {
      let obj = {}

      payload !== undefined ? obj = angular.copy(payload) : obj = await this.getDefault(objectClass)

      obj.objectClass = objectClass

      return new Promise(function (resolve, reject) {
        return httpService.callFunction('objects/' + objectClass, obj, 'POST').then(function (serviceData) {
          if (notificationEnabled) {
            toastr.success('creating' + ' ' + objectClass + ' successful', 'Success', {
              closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
            })
          }
          resolve(serviceData.headers().location.split('/').pop())
        }, function (errorData) {
          if (notificationEnabled) {
            toastr.error(errorData.message.text, 'Error creating ' + objectClass, {
              closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
            })
          }
          reject(errorData)
        })
      })
    }

    async callMethod (methodClass, payload, options) {
      payload.methodClass = methodClass
      return new Promise(function (resolve, reject) {
        return httpService.callFunction('methods/' + methodClass, payload, 'POST').then(function (serviceData) {
          if (options.notifications || options.notificationsSuccess) {
            toastr.success('calling' + ' ' + methodClass + ' successful', 'Success', {
              closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
            })
          }
          resolve(serviceData.data)
        }, function (errorData) {
          if (options.notifications || options.notificationsError) {
            toastr.error(errorData.message.text, 'Error calling ' + methodClass, {
              closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
            })
          }
          if (options.exceptions) {
            reject(errorData)
          } else {
            console.log(errorData)
          }
        })
      })
    }

    getObjectType (type) {
      if (type in this.methods) {
        return 'method'
      }
      return 'object'
    }

    // =====================
    //  WebSocket Callbacks
    // =====================

    onDestroy (object) {
      if (this.isCollectionInitialized(object.objectClass)) {
        if (service.objects[object.objectClass].has(object.uuid)) {
          service.objects[object.objectClass].delete(object.uuid)
        }
      }
    }

    async onCreate (object) {
      // Check, if Map is already existing
      if (!this.isCollectionInitialized(object.objectClass)) {
        await this.initializeCollection(object.objectClass)
      } else {
        service.objects[object.objectClass].set(object.uuid, new ProxyClass(service.getClassOf(object), { dependencies: this.dependencies, obj: object }))
      }
    }

    async onUpdate (object) {
      // Check, if collection is already initialized
      if (!this.isCollectionInitialized(object.objectClass)) {
        await this.initializeCollection(object.objectClass)
      } else {
        // Check, if object is already existing
        if (service.objects[object.objectClass].get(object.uuid) !== undefined) {
          service.objects[object.objectClass].get(object.uuid).updateCallback(object)
        } else {
          service.objects[object.objectClass].set(object.uuid, new ProxyClass(service.getClassOf(object), { dependencies: this.dependencies, obj: object }))
        }
      }
    }
  }

  var service = new ObjectManager()

  service.GetObjectManager = function () {
    return service
  }

  service.getObjectManager = function () {
    return service
  }

  service.subscribeObjects = function () {
    let objectList = Array.from(Object.keys(service.objects))
    objectList = objectList.filter(e => e !== 'session')
    webSocketService.emit('object signals', objectList)
  }

  service.initialize().then(() => {
    // Initial: Subscribe Object Signals
    service.subscribeObjects()

    service.getScheme('stream').then(() => {
    })

    service.collectionSync('systemConfig')
  })

  // Subscribe Object Signals @ Ws connect event
  webSocketService.on('connect', function () {
    service.subscribeObjects()
  })

  webSocketService.on('ObjectOnCreate', service.onCreate.bind(service))
  webSocketService.on('ObjectOnDestroy', service.onDestroy.bind(service))
  webSocketService.on('ObjectOnUpdate', service.onUpdate.bind(service))

  return service
})

require('./referenceResolver.filter')
