angular.module('webremote.dashboard').controller('widget_volume',
  function ($scope, $timeout, objectManagerService) {
    $scope.om = objectManagerService

    $scope.changeTab = function () {
      // Workaround to fix render issue for slider
      $timeout(function () {
        $scope.$broadcast('rzSliderForceRender')
        $scope.$broadcast('reCalcViewDimensions')
      }, 200)
    }

    $scope.filterVolumeStreams = function (item) {
      return item.member.state === 'playing' && item.hasElement('processing', 'volumecontrol') && !item.isTemplate
    }

    // Workaround to fix render issue for slider
    $timeout(function () {
      $scope.$broadcast('rzSliderForceRender')
    }, 200)

    $scope.getVolumePresets = function () {
      return Array.from($scope.om.collectionSync('elementPreset')).filter(preset => Object.prototype.hasOwnProperty.call(preset.member.data.type, 'processing') && Object.prototype.hasOwnProperty.call(preset.member.data.type.processing.type, 'volumecontrol'))
    }

    $scope.getStreamsOfPreset = function (uuid) {
      return $scope.om.collectionSync('stream').filter(stream => stream.hasElement('processing', 'volumecontrol') && stream.getElement('processing', 'volumecontrol').preset === uuid)
    }
  })
