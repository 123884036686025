angular.module('webremote.dashboard').controller('widget_quickActions_small',
  function ($scope, toastr, objectManagerService, $uibModal) {
    $scope.gridOptions = {
      sm: 'col-xs-6 col-sm-2 col-md-1point5 col-lg-1point5',
      md: 'col-xs-12 col-sm-3 col-md-2 col-lg-2',
      lg: 'col-xs-12 col-sm-6 col-md-4 col-lg-3'
    }

    $scope.objectManager = objectManagerService.getObjectManager
    $scope.editMode = false

    $scope.$watch('activeDashboard', function () {
      $scope.objectManager().object($scope.activeDashboard, 'dashboard').then((v) => {
        $scope.$evalAsync(() => {
          $scope.dashboard = v
        })
      })
    })

    $scope.objectManager().object($scope.activeDashboard, 'dashboard').then((v) => {
      $scope.$evalAsync(() => {
        $scope.dashboard = v

        // Wait, until widgetIndex is set
        $scope.$watch('widgetIndex', function () {
          $scope.loadStoredOptions($scope.widgetIndex)
          if (!$scope.quickActions || $scope.quickActions.length === 0) {
            $scope.editMode = true
          }
        })
      })
    })

    $scope.sortableListOptions = {
      helper: 'clone',
      'ui-floating': true,
      disabled: $scope.editMode,
      start: function () {
        $scope.dragActive = true
      },
      stop: function () {
        $scope.dragActive = false
      }
    }

    $scope.toggleEditMode = function () {
      if (!$scope.dragActive && !$scope.protected) {
        $scope.editMode = !$scope.editMode
      }

      if ($scope.protected) {
        toastr.warning('Please disable protection in widget settings before editing.', 'Quick Actions are protected!', {
          closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
        })
      }
    }

    $scope.toggleActive = async function (actionObj) {
      if ($scope.editMode) {
        $scope.editMode = false
      } else {
        if (actionObj.member !== undefined) {
          actionObj.successNotifications = false
          if (actionObj.member.quickActionType === 'switch') {
            switch (Object.keys(actionObj.member.monitorState)[0]) {
              case 'stream':
                if (actionObj.member.monitorState.stream.uuid) {
                  const stream = $scope.objectManager().objectSync(actionObj.member.monitorState.stream.uuid, 'stream')
                  if (stream !== undefined) {
                    if (actionObj.member.active === true && stream.member.state !== 'playing' ||
                      actionObj.member.active === false && stream.member.state === 'playing') {
                      actionObj.member.active = !actionObj.member.active
                    }
                  }
                }
                break
              case 'streamGroup':
                if (actionObj.member.monitorState.streamGroup.uuid) {
                  const streamGroup = $scope.objectManager().objectSync(actionObj.member.monitorState.streamGroup.uuid, 'streamGroup')
                  if (streamGroup !== undefined) {
                    if (actionObj.member.active === true && streamGroup.member.state !== 'playing' ||
                      actionObj.member.active === false && streamGroup.member.state === 'playing') {
                      actionObj.member.active = !actionObj.member.active
                    }
                  }
                }
                break
            }
          }
          actionObj.member.active = !actionObj.member.active
          actionObj.update()
        }
      }
    }

    $scope.$watch('editMode', function (newValue) {
      $scope.sortableListOptions.disabled = !newValue
    })

    $scope.$watch('protected', function (newValue) {
      if (newValue) {
        $scope.editMode = false
      }
      $scope.objectManager().collection('quickAction').then(collection => {
        $scope.removeUndefined(Array.from(collection.values()))
      })
      $scope.saveStoredOptions()
    })

    $scope.$watchCollection('quickActions', function (newValue) {
      if (newValue !== undefined) {
        $scope.saveStoredOptions()
      }
    })

    $scope.$watchCollection('objectManager().collectionSync("quickAction")', collection => {
      $scope.removeUndefined(collection)
    })

    $scope.setGridDimension = function (dim) {
      $scope.gridDimension = dim
      $scope.saveStoredOptions()
    }

    $scope.getStateClass = function (actionObj) {
      if (actionObj !== undefined) {
        switch (Object.keys(actionObj.monitorState)[0]) {
          case 'switch':
            if (actionObj.quickActionType === 'switch' && actionObj.active) {
              return 'stateful-active'
            }
            break
          case 'stream':
            if (actionObj.monitorState.stream.uuid) {
              const stream = $scope.objectManager().objectSync(actionObj.monitorState.stream.uuid, 'stream')
              if (stream !== undefined && stream.member.state === 'playing') {
                return 'stateful-active'
              }
            }
            break
          case 'streamGroup':
            if (actionObj.monitorState.streamGroup.uuid) {
              const streamGroup = $scope.objectManager().objectSync(actionObj.monitorState.streamGroup.uuid, 'streamGroup')
              if (streamGroup !== undefined && streamGroup.member.state === 'playing') {
                return 'stateful-active'
              }
            }
            break
        }
      }
      return 'tile-stateless'
    }

    $scope.loadStoredOptions = function (widgetIndex) {
      $scope.storedOptions = $scope.dashboard.getInformationForWidget(widgetIndex)
      // Default: Set gridDimension to 'md'
      if ($scope.storedOptions.gridDimension === undefined) {
        $scope.storedOptions.gridDimension = 'md'
      }

      // Default: Set protected to false
      if ($scope.storedOptions.protected === undefined) {
        $scope.storedOptions.protected = false
      }

      // Default: Set quickActions to empty array
      if ($scope.storedOptions.quickActions === undefined) {
        $scope.storedOptions.quickActions = []
      }

      $scope.gridDimension = $scope.storedOptions.gridDimension
      $scope.protected = $scope.storedOptions.protected
      $scope.quickActions = $scope.storedOptions.quickActions

      $scope.saveStoredOptions()
    }

    $scope.saveStoredOptions = function () {
      if ($scope.storedOptions !== undefined) {
        $scope.storedOptions.gridDimension = $scope.gridDimension
        $scope.storedOptions.protected = $scope.protected
        $scope.storedOptions.quickActions = $scope.quickActions

        $scope.dashboard.storeInformationForWidget($scope.widgetIndex, JSON.stringify($scope.storedOptions))
      }
    }

    $scope.removeUndefined = function (collection) {
      if (collection.length > 0 && $scope.quickActions !== undefined) {
        for (var index = $scope.quickActions.length - 1; index >= 0; index--) {
          if (!collection.some(x => x.member.uuid === $scope.quickActions[index])) {
            $scope.quickActions.splice(index, 1)
          }
        }
        if (!$scope.quickActions || $scope.quickActions.length === 0) {
          $scope.editMode = true
        }
      }
    }
    $scope.addQuickAction = (data) => {
      $scope.quickActions.push(data.member.uuid)
      $scope.saveStoredOptions()
    }
  })
