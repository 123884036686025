'use strict'

angular.module('webremote.monitoring').controller('webremote.monitoring.fm.controller',
  function ($scope, $log, $filter, objectManagerService) {
    $scope.interfaces = []
    $scope.objectManager = objectManagerService.getObjectManager()

    $scope.$watch('$parent.active', (uuid) => {
      $scope.getChartData(uuid)
    })

    $scope.getChartData = async function (uuid) {
      const fmChannels = $scope.objectManager.objectSync(uuid, 'demux').member.source.fernFm.fmChannels
      var snr = {}
      snr.data = []
      snr.meta = {}
      snr.meta.legend = []
      var rssi = {}
      rssi.data = []
      rssi.meta = {}
      rssi.meta.legend = []
      var stereo = {}
      stereo.data = []
      stereo.meta = {}
      stereo.meta.legend = []
      var psUpdateTime = {}
      psUpdateTime.data = []
      psUpdateTime.meta = {}
      psUpdateTime.meta.legend = []
      for (let i = 1; i <= 4; i++) {
        if (fmChannels[i-1].freq === 0) {
          continue
        }
        await $scope.objectManager.callMethod('readStats', { source: { fernFm: { demuxUuid: uuid, fmChannel: i } } }, { notificationsError: true }).then(function (serviceData) {
          const data = JSON.parse(serviceData.data)
          snr.data.push(data.data.map(function (value, index) { return Math.round(value[0] * 10) / 10 }))
          snr.meta.start = data.meta.start
          snr.meta.end = data.meta.end
          snr.meta.step = data.meta.step
          snr.meta.legend.push(fmChannels[i - 1].name)
          rssi.data.push(data.data.map(function (value, index) { return Math.round(value[1] * 10) / 10 }))
          rssi.meta.start = data.meta.start
          rssi.meta.end = data.meta.end
          rssi.meta.step = data.meta.step
          rssi.meta.legend.push(fmChannels[i - 1].name)
          stereo.data.push(data.data.map(function (value, index) { return Math.round(value[2] * 10) / 10 }))
          stereo.meta.start = data.meta.start
          stereo.meta.end = data.meta.end
          stereo.meta.step = data.meta.step
          stereo.meta.legend.push(fmChannels[i - 1].name)
          psUpdateTime.data.push(data.data.map(function (value, index) { return Math.round(value[3] * 10) / 10 }))
          psUpdateTime.meta.start = data.meta.start
          psUpdateTime.meta.end = data.meta.end
          psUpdateTime.meta.step = data.meta.step
          psUpdateTime.meta.legend.push(fmChannels[i - 1].name)
        }, function (errorData) {
          console.error(errorData)
        })
      }
      snr.data = snr.data[0].map((_, colIndex) => snr.data.map(row => row[colIndex]))
      rssi.data = rssi.data[0].map((_, colIndex) => rssi.data.map(row => row[colIndex]))
      stereo.data = stereo.data[0].map((_, colIndex) => stereo.data.map(row => row[colIndex]))
      psUpdateTime.data = psUpdateTime.data[0].map((_, colIndex) => psUpdateTime.data.map(row => row[colIndex]))
      if ($scope.graph1 !== undefined) {
        $scope.graph1.instance.destroy()
        $scope.graph2.instance.destroy()
        $scope.graph3.instance.destroy()
        $scope.graph4.instance.destroy()
      }
      $scope.graph1 = new Graph('myChart1', 'line', Graph.scaleType.DYNAMIC, snr.meta, snr.data, 'SNR (in dB)', 'dB')
      $scope.graph2 = new Graph('myChart2', 'line', Graph.scaleType.DYNAMIC, rssi.meta, rssi.data, 'RSSI (in dBμV)', 'dBμV')
      $scope.graph3 = new Graph('myChart3', 'line', Graph.scaleType.DYNAMIC, stereo.meta, stereo.data, 'Stereo', '')
      $scope.graph4 = new Graph('myChart4', 'line', Graph.scaleType.DYNAMIC, psUpdateTime.meta, psUpdateTime.data, 'Last time since RDS PS Update (in s)', 's')
    }

    $scope.getBase64 = function (graph) {
      try {
        return graph.instance.toBase64Image()
      } catch (e) {
        return ''
      }
    }

    class Graph {
      constructor(cssId, type, scalingType, meta, data, title, unit, interactive, yUnit) {
        this.cssId = cssId
        this.data = data
        this.type = type
        this.ctx = document.getElementById(cssId).getContext('2d')
        this.datasets = []
        this.interactive = (interactive === undefined ? true : interactive)
        this.yUnit = yUnit

        // Clear canvas
        this.ctx.clearRect(0, 0, this.ctx.width, this.ctx.height)

        this.fillColors = ['rgba(224,67,3,0.2)', 'rgba(10, 194, 136,0.2)', 'rgba(32, 190, 243, 0.2)', 'rgba(243, 176, 32, 0.2)', 'rgba(42, 234, 58, 0.2)', 'rgba(243, 32, 110, 0.1)', 'rgba(160, 152, 246, 0.2)', 'rgba(217, 230, 71, 0.2)', 'rgba(193, 26, 57, 0.2)', 'rgba(64, 64, 64, 0.2)', 'rgb(66, 134, 244, 0.2)', 'rgb(119, 9, 229, 0.2)', 'rgb(191, 216, 4, 0.2)', 'rgb(219, 132, 100, 0.2)', 'rgb(219, 100, 100, 0.2)']
        this.borderColors = ['rgba(224,67,3,1.0)', 'rgba(10, 194, 136,1.0)', 'rgba(32, 190, 243, 1.0)', 'rgba(243, 176, 32, 1)', 'rgba(42, 234, 58, 1)', 'rgba(243, 32, 110, 1)', 'rgba(160, 152, 246, 1)', 'rgba(217, 230, 71, 1)', 'rgba(193, 26, 57, 1)', 'rgba(64, 64, 64, 1)', 'rgb(66, 134, 244, 1.0)', 'rgb(119, 9, 229, 1.0)', 'rgb(191, 216, 4, 1.0)', 'rgb(219, 132, 100, 1.0)', 'rgb(219, 100, 100, 1.0)']

        for (const index in meta.legend) {
          const dataset = {}
          dataset.label = meta.legend[index]
          dataset.fill = (index === '0' ? 'origin' : '-1')
          dataset.backgroundColor = this.fillColors[index]
          dataset.borderColor = this.borderColors[index]
          dataset.borderWidth = 1
          dataset.pointRadius = 1
          dataset.pointHitRadius = 5
          dataset.pointBackgroundColor = this.borderColors[index]
          dataset.lineTension = 0
          dataset.data = []
          for (const dataLine of data) {
            dataset.data.push(dataLine[index])
          }

          this.datasets.push(dataset)
        }

        this.xLabels = []

        for (var value = meta.start; value <= meta.end; value = value + meta.step) {
          this.xLabels.push($filter('date')(value * 1000, 'shortTime'))
        }

        this.options = {
          type: type !== undefined ? type : 'line',
          data: {
            labels: this.xLabels,
            datasets: this.datasets
          },
          options: {
            animation: {
              duration: 0,
              easing: 'linear'
            },
            title: {
              display: true,
              text: title
            },
            legend: {
              position: 'bottom'
            },
            tooltips: {
              enabled: this.interactive,
              mode: 'single',
              callbacks: {
                label: function (tooltipItems, data) {
                  return tooltipItems.yLabel + ' ' + (unit === undefined ? '' : unit)
                }
              }
            },
            scales: {
              yAxes: [{
                ticks: {
                  beginAtZero: true,
                  autoSkip: true,
                  callback: function (value, index, values) {
                    return this.yUnit !== undefined ? value + this.yUnit : value
                  }
                }

              }],
              xAxes: [{
                ticks: {
                  maxTicksLimit: 25,
                  autoSkip: true,
                  fontSize: 11,
                  padding: 8
                }
              }]
            }
          }
        }

        if (!this.interactive) {
          this.options.options.legend = {
            onClick: (e) => e.stopPropagation()
          }
          this.options.options.legend.position = 'bottom'
        }

        if (scalingType === Graph.scaleType.PERCENT) {
          this.options.options.scales.yAxes[0].ticks.max = 100
          this.options.options.scales.yAxes[0].ticks.min = 0
        }

        this.instance = new Chart(this.ctx, this.options)
      }

      static get scaleType() {
        return Object.freeze({ PERCENT: 1, DYNAMIC: 2 })
      }
    }
  })
