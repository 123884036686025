'use strict'

angular.module('webremote.objectManager.schemeParser', []).factory('schemeParser', function () {
  var service = {}

  service.parse = function (obj) {
    const output = {}

    function defaultOrFallback (schema, fallbackValue) {
      if (Object.prototype.hasOwnProperty.call(schema, 'default')) {
        return schema.default
      } else {
        return fallbackValue
      }
    }
    for (const attr in obj) {
      switch (obj[attr].type) {
        case 'bool':
          output[attr] = defaultOrFallback(obj[attr], false)
          break
        case 'text':
          output[attr] = defaultOrFallback(obj[attr], '')
          break
        case 'uint8':
        case 'uint16':
        case 'uint32':
        case 'int8':
        case 'int16':
        case 'int32':
        case 'float8':
        case 'float16':
        case 'float32':
          output[attr] = defaultOrFallback(obj[attr], 0)
          break
        case 'list':
          output[attr] = defaultOrFallback(obj[attr], [])
          break
        case 'enum':
          output[attr] = defaultOrFallback(obj[attr], undefined)
          break
        case 'struct':
          output[attr] = service.parse(obj[attr].fields)
          break
        case 'void':
          output[attr] = {}
          break
        case 'union': {
          const options = service.parse(obj[attr].fields)

          // Remove hidden union options
          for (const key in options) {
            if (obj[attr].fields[key].hidden) {
              delete options[key]
            }
          }

          output[attr] = {}
          output[attr][Object.keys(options)[0]] = options[Object.keys(options)[0]]
          break
        }
        case 'uuid':
          output[attr] = defaultOrFallback(obj[attr], '')
      }
    }
    return output
  }

  service.parseValidation = function (obj) {

    function defaultOrFallback (schema, fallbackValue) {
      if (Object.prototype.hasOwnProperty.call(schema, 'validPattern')) {
        return schema.validPattern
      } else {
        if (schema.type.includes('int') && schema.decimals !== undefined) {
          return '[+-]?([0-9]*[.])?[0-9]+'
        } else {
          return fallbackValue
        }
      }
    }
    for (const attr in obj) {
      switch (obj[attr].type) {
        case 'uint8':
          obj[attr].validPattern = defaultOrFallback(obj[attr], '^(\\d{1,2}|1\\d{2}|2([0-4]\\d|5[0-5]))$')
          break
        case 'uint16':
          obj[attr].validPattern = defaultOrFallback(obj[attr], '^(\\d{1,4}|[1-5]\\d{4}|6([0-4]\\d{3}|5([0-4]\\d{2}|5([0-2]\\d|3[0-5]))))$')
          break
        case 'uint32':
        obj[attr].validPattern = defaultOrFallback(obj[attr], '(^(\\d{1,9}|[1-3]\\d{9}|4([01]\\d{8}|2([0-8]\\d{7}|9([0-3]\\d{6}|4([0-8]\\d{5}|9([0-5]\\d{4}|6([0-6]\\d{3}|7([01]\\d{2}|2([0-8]\\d|9[0-5]))))))))))$')
        break
        case 'int8':
          obj[attr].validPattern = defaultOrFallback(obj[attr], '^-?(\\d{1,2}|1([0-1]\\d|2[0-7]))$|^-128$')
          break
        case 'int16':
          obj[attr].validPattern = defaultOrFallback(obj[attr], '^-?(\\d{1,4}|[12]\\d{4}|3([01]\\d{3}|2([0-6]\\d{2}|7([0-5]\\d|6[0-7]))))$|^-32768$')
          break
        case 'int32':
          obj[attr].validPattern = defaultOrFallback(obj[attr], '(^-?(\\d{1,9}|1\\d{9}|2(0\\d{8}|1([0-3]\\d{7}|4([0-6]\\d{6}|7([0-3]\\d{5}|4([0-7]\\d{4}|8([0-2]\\d{3}|3([0-5]\\d{2}|6([0-3]\\d|4[0-7]))))))))))$|^-2147483648$')
          break
        case 'float8':
        case 'float16':
        case 'float32':
          obj[attr].validPattern = defaultOrFallback(obj[attr], '[+-]?([0-9]*[.])?[0-9]+')
          break
        case 'list':
          if (obj[attr].elementType.type === 'struct' || obj[attr].elementType.type === 'union') {
            obj[attr].elementType.fields = service.parseValidation(obj[attr].elementType.fields)
          } else {
            const tmp = {}
            tmp['tmp'] = obj[attr].elementType
            obj[attr].elementType = service.parseValidation(tmp)['tmp']
          }
          break
        case 'struct':
        case 'union':
          obj[attr].fields = service.parseValidation(obj[attr].fields)
          break
      }
    }
    return obj
  }

  return service
})
