angular.module('webremote.layout').controller('webremote.modules.layout.changePassword.controller',
  function ($scope, $uibModalInstance, objectManagerService) {
    objectManagerService.collection('session').then(sessions => {
      const activeSession = Array.from(sessions.values())[0]
      objectManagerService.object(activeSession.member.user_uuid, 'user').then(user => {
        $scope.user = angular.copy(user)
      })
    })

    $scope.onFormInvalid = () => {
      $scope.actions.update.disabled = true
    }

    $scope.onFormValid = () => {
      $scope.actions.update.disabled = false
    }

    $scope.actions = {
      update: {
        disabled: false,
        cb: () =>
          // Validate object
          $scope.user.validate().then((errors) => {
            // Check if result includes entries with "error" level
            if ($scope.user.validationErrors(['error', 'critical']).length === 0) {
              // Update user object, if anything is fine
              $scope.user.update().then(() =>
                $uibModalInstance.close()
              )
            }
          }, () => {
          })

      },
      close: {
        cb: () => $uibModalInstance.close()
      }
    }
  })
