angular.module('webremote.dashboard').controller('widget_delay',
  function ($scope, objectManagerService, $timeout, authenticationService) {
    $scope.om = objectManagerService
    $scope.dashboard = authenticationService.dashboardObject

    $scope.$watch('activeDashboard', function () {
      $scope.objectManager().object($scope.activeDashboard, 'dashboard').then((v) => {
        $scope.dashboard = v
      })
    })

    $scope.objectManager().object($scope.activeDashboard, 'dashboard').then((v) => {
      $scope.dashboard = v

      $scope.$watch('widgetIndex', function () {
        $scope.storedOptions = $scope.dashboard.getInformationForWidget($scope.widgetIndex)
        $scope.minDelay = ($scope.storedOptions.minDelay === undefined ? 0 : $scope.storedOptions.minDelay)
        $scope.maxDelay = ($scope.storedOptions.maxDelay === undefined ? 10 : $scope.storedOptions.maxDelay)
        $scope.showNonPlayingPipes = ($scope.storedOptions.showNonPlayingPipes === undefined ? false : $scope.storedOptions.showNonPlayingPipes)
      })
    })

    // Workaround to fix render issue for slider
    $timeout(function () {
      $scope.$broadcast('rzSliderForceRender')
    }, 200)

    $scope.updateWidgetOptions = function () {
      if ($scope.storedOptions !== undefined) {
        $scope.storedOptions.minDelay = $scope.minDelay
        $scope.storedOptions.maxDelay = $scope.maxDelay
        $scope.storedOptions.showNonPlayingPipes = $scope.showNonPlayingPipes
        $scope.dashboard.storeInformationForWidget($scope.widgetIndex, JSON.stringify($scope.storedOptions))
      }
    }

    $scope.updateMinMax = function (stream) {
      if (!isNaN($scope.minDelay) && $scope.minDelay !== '') {
        stream.delay.slider.options.floor = parseFloat($scope.minDelay)
      }
      if (!isNaN($scope.maxDelay) && $scope.maxDelay !== '') {
        stream.delay.slider.options.ceil = parseFloat($scope.maxDelay)
      }
      return true
    }
  })
