'use strict'

import DetailedDirectory from './DetailedDirectory'

angular.module('webremote.service.file', []).factory('fileService', function (httpService, objectManagerService, toastr, $injector) {
  class FileService {
    async files (path, options) {
      const optionParams = []

      if (!path) path = ''

      if (options) {
        if (options.page) optionParams.push('page=' + options.page)
        if (options.fileCount) optionParams.push('fileCount=' + options.fileCount)
        if (options.orderBy) optionParams.push('orderBy=' + options.orderBy)
        if (options.searchText) optionParams.push('searchText=' + options.searchText)
        if (options.dateTo) optionParams.push('dateTo=' + options.dateTo)
        if (options.dateFrom) optionParams.push('dateFrom=' + options.dateFrom)
      }

      let data = {}
      data = (await httpService.callFunction('archive/' + path + '?' + optionParams.join('&'))).data
      const result = DetailedDirectory.init(this.decodePath(path), data, $injector)
      return result
    }

    async createDirectory (path, name) {
      return httpService.callFunction('archive/' + this.encodePath(path) + '/' + name, '', 'POST').then(function (data) {
        return true
      }, function (errorData) {
        toastr.error('Couldn\'t create folder "' + name + '"', 'Error', {
          closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
        })
        return false
      })
    }

    async removeDirectory (path, name) {
      return httpService.callFunction('archive/' + path + name, '', 'DELETE').then(function (data) {
        return true
      }, function (errorData) {
        toastr.error("Couldn't delete directory", 'Error', {
          closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
        })
        return false
      })
    }

    encodePath (pathArr) {
      if (!Array.isArray(pathArr)) throw new TypeError('array type is needed as input')
      let result = ''
      for (const element of pathArr.filter(x => x !== '')) {
        result += element + '/'
      }
      return result
    }

    decodePath (pathStr) {
      if (typeof pathStr !== 'string') throw new TypeError('string type is needed as input')
      const result = []
      for (const element of pathStr.split('/').filter(x => x.length > 0)) {
        result.push(element)
      }
      return result
    }
  }

  const service = new FileService()

  return service
})
