'use strict'

angular.module('webremote.service.sip', []).factory('sipService', function (httpService, webSocketService, toastr, Notification, $window, $log, $rootScope, modalService, objectManagerService) {
  class SipAccount {
    constructor (sipAccountData) {
      const obj = this

      // Store all remote member inside local object
      for (var k in sipAccountData) {
        obj[k] = sipAccountData[k]
      }
    }

    remove () {
      httpService.callFunction('objects/sipAccount/' + this.uuid, [], 'DELETE').then(function (serviceData) {
      }, function (errorData) {
        toastr.error("Can't delete sip account", 'Error', {
          closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
        })
      })
    }

    update () {
      const json = JSON.stringify(this)
      httpService.callFunction('objects/sipAccount/' + this.uuid, json, 'PUT').then(function (serviceData) {
      }, function (errorData) {
        toastr.error("Can't update sip account", 'Error', {
          closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
        })
      })
    }

    updateCb (obj) {
      // Store all remote member inside local object
      for (var k in obj) {
        this[k] = obj[k]
      }
    }

    activeCalls () {
      let counter = 0

      for (const element in service.sipCallManager.calls) {
        if (service.sipCallManager.calls[element].account === this.uuid && service.sipCallManager.calls[element].state !== 'idle' && !service.sipCallManager.calls[element].isTemplate) {
          counter++
        }
      }

      return counter
    }
  }

  class SipAccountManager {
    constructor () {
      const obj = this
      obj.accounts = {}

      obj.requestSipAccounts = function () {
        httpService.callFunction('objects/sipAccount', []).then(function (serviceData) {
          obj.accounts = {}
          for (const element of serviceData.data) {
            obj.accounts[element.uuid] = new SipAccount(element)
          }
        }, function (errorData) {
          toastr.error("Can't load sip accounts", 'Error', {
            closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
          })
        })
      }

      obj.create = function (sipAccountData) {
        const json = JSON.stringify(sipAccountData)
        httpService.callFunction('objects/sipAccount', json, 'POST').then(function (serviceData) {
        }, function (errorData) {
          toastr.error("Can't create sip account", 'Error', {
            closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
          })
        })
      }
    }
  }

  class SipPreset {
    constructor (sipPresetData) {
      const obj = this

      // Store all remote member inside local object
      for (var k in sipPresetData) {
        obj[k] = sipPresetData[k]
      }
    }

    activeCalls () {
      return this.entries.filter(slot => service.sipCallManager.calls[slot.call] !== undefined)
    }

    callAll () {
      for (const slot of this.entries) {
        if ((slot.call === undefined || slot.call.length === 0) && service.sipCallManager.calls[slot.callTemplate].bulkConnect &&
          service.sipCallManager.calls[slot.callTemplate].peerUri.length > 0 &&
          service.sipAccountManager.accounts[service.sipCallManager.calls[slot.callTemplate].account].enabled &&
          service.sipAccountManager.accounts[service.sipCallManager.calls[slot.callTemplate].account].state === 'registerOk') {
          service.sipCallManager.calls[slot.callTemplate].instantiate().then(v => {
            this.connectTemplateToInstance(slot.callTemplate, v)
          })
        }
      }
    }

    hangupAll () {
      const modalConfig = {
        title: 'Confirm',
        text: 'Are you sure to hangup all calls in current preset "' + this.name + '"?'
      }
      const obj = this
      modalService.confirmModal(modalConfig, $rootScope, function (response) {
        if (response) {
          for (const slot of obj.entries) {
            if (service.sipCallManager.calls[slot.call] !== undefined) {
              service.sipCallManager.calls[slot.call].hangup()
            }
          }
        }
      })
    }

    connectTemplateToInstance (templateUuid, instanceUuid) {
      function getSlotByUuid (slot) {
        return slot.callTemplate === templateUuid
      }

      if (templateUuid.length > 0 && instanceUuid.length > 0) {
        const index = this.entries.findIndex(getSlotByUuid)

        if (index === -1) {
          this.entries.push({ callTemplate: templateUuid, call: instanceUuid })
        } else {
          this.entries[index].call = instanceUuid
        }
      }
      this.update()
    }

    addSipTemplate () {
      this.entries.push({ phoneBookEntry: '', bulkConnect: false, editingLocked: false })
      this.update()
    }

    remove () {
      httpService.callFunction('objects/sipPreset/' + this.uuid, [], 'DELETE').then(function (serviceData) {
      }, function (errorData) {
        toastr.error("Can't delete sip preset", 'Error', {
          closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
        })
      })
    }

    update () {
      const json = JSON.stringify(this)
      httpService.callFunction('objects/sipPreset/' + this.uuid, json, 'PUT').then(function (serviceData) {
      }, function (errorData) {
        toastr.error("Can't update sip preset", 'Error', {
          closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
        })
      })
    }

    updateCb (obj) {
      // Store all remote member inside local object
      for (var k in obj) {
        this[k] = obj[k]
      }
    }
  }

  class SipPresetManager {
    constructor () {
      const obj = this
      obj.presets = {}

      obj.requestSipPresets = function () {
        httpService.callFunction('objects/sipPreset', []).then(function (serviceData) {
          obj.presets = {}
          for (const element of serviceData.data) {
            obj.presets[element.uuid] = new SipPreset(element)
          }
          console.log(obj.presets)
        }, function (errorData) {
          toastr.error("Can't load sip presets", 'Error', {
            closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
          })
        })
      }

      obj.create = function (sipPresetData) {
        httpService.callFunction('objects/sipPreset', sipPresetData, 'POST').then(function (serviceData) {
        }, function (errorData) {
          toastr.error("Can't create sip preset", 'Error', {
            closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
          })
        })
      }
    }
  }

  class SipCall {
    constructor (sipCallData) {
      const obj = this
      for (var k in sipCallData) {
        obj[k] = sipCallData[k]
      }

      obj.lock = function (state) {
        const payload = [{ op: 'replace', path: '/editingLocked', value: state }]
        obj.patch(payload)
      }

      obj.instantiate = async function () {
        const call = angular.copy(obj)
        call.isTemplate = false
        call.outgoing = true

        // call.rfc5109FecEncMode = service.sipAccountManager.accounts[obj.account].rfc5109FecEncMode
        // call.useRfc5109FecDec = service.sipAccountManager.accounts[obj.account].useRfc5109FecDec
        // call.rfc5109FecPercent = service.sipAccountManager.accounts[obj.account].rfc5109FecPercent
        // call.rfc5109FecPeriod = service.sipAccountManager.accounts[obj.account].rfc5109FecPeriod
        // call.jbSize = service.sipAccountManager.accounts[obj.account].jbSize

        return service.sipCallManager.create(call).then(uuid => {
          return uuid
        }, (err) => {
          return err
        })
      }

      obj.getInstances = function () {
        // If call is not a template, return empty array
        if (!obj.isTemplate) {
          return []
        }

        // Collect all instances of template
        const result = []
        for (const uuid in service.sipCallManager.calls) {
          if (service.sipCallManager.calls[uuid].templateRef === obj.uuid) {
            result.push(uuid)
          }
        }
        return result
      }

      obj.patch = async function (payload) {
        await httpService.callFunction('objects/' + this.objectClass + '/' + this.uuid, payload, 'PATCH').then(function (serviceData) {
          return true
        }, function (errorData) {
          toastr.error('Can\'t patch ' + this.objectClass.toLowerCase(), 'Error', {
            closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
          })
          return errorData
        })
      }

      obj.acceptCall = function () {
        const payload = [{ op: 'replace', path: '/accept', value: true }]
        obj.patch(payload)
      }

      obj.declineCall = function () {
        obj.remove()
      }

      obj.hangup = function () {
        obj.remove()
      }

      obj.remove = function () {
        httpService.callFunction('objects/sipCall/' + obj.uuid, [], 'DELETE').then(function (serviceData) {
        }, function (errorData) {
          toastr.error("Can't hang up call", 'Error', {
            closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
          })
        })
      }

      obj.update = function () {
        const json = JSON.stringify(obj)
        httpService.callFunction('objects/sipCall/' + obj.uuid, json, 'PUT').then(function (serviceData) {
        }, function (errorData) {
          toastr.error("Can't update call", 'Error', {
            closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
          })
        })
      }
    }
  }

  class SipCallManager {
    constructor () {
      const obj = this
      obj.calls = {}
      obj.archivedCalls = {}

      obj.removeCall = function (element) {
        try {
          // Hide Notification
          service.notifications[element.uuid].then(function (notification) {
            notification.kill()
          })

          // Remove Notification Object
          delete service.notifications[element.uuid]
        } catch (e) {

        }

        // Remove from Calls
        delete obj.calls[element.uuid]
        delete obj.archivedCalls[element.uuid]
      }

      obj.updateCall = function (element) {
        if (element.accept === true && service.notifications.hasOwnProperty(element.uuid)) {
          // Hide Notification
          service.notifications[element.uuid].then(function (notification) {
            notification.kill()
          })
        }

        if (element.archived === true && service.notifications.hasOwnProperty(element.uuid)) {
          try {
            // Hide Notification
            service.notifications[element.uuid].then(function (notification) {
              notification.kill()
            })

            // Remove Notification Object
            delete service.notifications[element.uuid]
          } catch (e) {

          }
          obj.archivedCalls[element.uuid] = new SipCall(element)
          delete obj.calls[element.uuid]
        } else {
          obj.calls[element.uuid] = new SipCall(element)
        }
      }

      obj.requestSipCalls = function () {
        httpService.callFunction('objects/sipCall', []).then(function (serviceData) {
          obj.calls = {}
          obj.archivedCalls = {}

          for (const element of serviceData.data) {
            if (element.archived === true) {
              obj.archivedCalls[element.uuid] = new SipCall(element)
            } else {
              obj.calls[element.uuid] = new SipCall(element)
            }
          }

          obj.checkCallDialogs()
        }, function (errorData) {
          toastr.error("Can't load sip calls", 'Error', {
            closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
          })
        })
      }

      obj.checkCallDialogs = function () {
        let activeCalls = false
        for (const call in obj.calls) {
          if (!obj.calls[call].archived && !obj.calls[call].outgoing && !obj.calls[call].isTemplate && !obj.calls[call].accept && !service.notifications.hasOwnProperty(call)) {
            activeCalls = true

            // Create scope for notification
            var scope = $rootScope.$new(false)
            scope.calls = obj.calls
            scope.call = call

            // Check answer mode
            if (obj.calls[call].account.length > 0 && !service.sipAccountManager.accounts[obj.calls[call].account].autoAnswer) {
              // Show and store notification
              service.notifications[call] = Notification.primary({ templateUrl: 'template_notification_sipCall.html', scope: scope })

              // Activate Vibration
              if (service.vibrationReference === undefined) {
                service.vibrationReference = setInterval(function () { navigator.vibrate(500) }, 2000)
              }
            }
          }
        }

        if (!activeCalls) {
          clearInterval(service.vibrationReference)
          service.vibrationReference = undefined
        }
      }

      obj.create = async function (obj) {
        return objectManagerService.getObjectManager().getDefault('sipCall').then((defaultObject) => {
          const payload = Object.assign(defaultObject, obj)

          delete payload.uuid
          payload.outgoing = true
          // payload.rfc5109FecEncMode = service.sipAccountManager.accounts[obj.account].rfc5109FecEncMode
          // payload.useRfc5109FecDec = service.sipAccountManager.accounts[obj.account].useRfc5109FecDec
          // payload.rfc5109FecPercent = service.sipAccountManager.accounts[obj.account].rfc5109FecPercent
          // payload.rfc5109FecPeriod = service.sipAccountManager.accounts[obj.account].rfc5109FecPeriod
          // payload.jbSize = service.sipAccountManager.accounts[obj.account].jbSize
          return objectManagerService.getObjectManager().create('sipCall', payload)
        }, function (errorData) {
          return errorData
        })
      }
    }
  }

  const service = {}
  service.sipAccountManager = new SipAccountManager()
  service.sipAccountManager.requestSipAccounts()
  service.sipCallManager = new SipCallManager()
  service.sipCallManager.requestSipCalls()
  service.sipPresetManager = new SipPresetManager()
  // WORKAROUND: Suppress can't load sip presets toastr => SipService is deprecated
  // service.sipPresetManager.requestSipPresets()

  service.vibrationReference
  service.notifications = {}

  // DECORATORS FOR EXTERNAL ACCESS
  service.getSipAccountManager = function () {
    return service.sipAccountManager
  }

  service.getSipPresetManager = function () {
    return service.sipPresetManager
  }

  service.getSipCallManager = function () {
    return service.sipCallManager
  }

  // WebSocket Functions
  service.updateObject = function (obj) {
    if (obj.hasOwnProperty('objectClass') && obj.objectClass === 'sipCall') {
      service.sipCallManager.updateCall(obj)
      if (obj.lastError !== '' && !obj.lastError.includes('486 Rejected')) {
        toastr.error(obj.peerUri + '<br/>' + obj.lastError, 'State on SIP call:', {
          closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
        })
      }
      service.sipCallManager.checkCallDialogs()
    } else if (obj.hasOwnProperty('objectClass') && obj.objectClass === 'sipAccount') {
      service.sipAccountManager.accounts[obj.uuid].updateCb(obj)
    } else if (obj.hasOwnProperty('objectClass') && obj.objectClass === 'sipPreset') {
      service.sipPresetManager.presets[obj.uuid].updateCb(obj)
    }
  }

  service.removeObject = function (obj) {
    if (obj.hasOwnProperty('objectClass') && obj.objectClass === 'sipCall') {
      service.sipCallManager.removeCall(obj)
      service.sipCallManager.checkCallDialogs()
    } else if (obj.hasOwnProperty('objectClass') && obj.objectClass === 'sipAccount') {
      delete service.sipAccountManager.accounts[obj.uuid]
    } else if (obj.hasOwnProperty('objectClass') && obj.objectClass === 'sipPreset') {
      delete service.sipPresetManager.presets[obj.uuid]
    }
  }

  service.addObject = function (obj) {
    if (obj.hasOwnProperty('objectClass') && obj.objectClass === 'sipCall') {
      service.sipCallManager.calls[obj.uuid] = new SipCall(obj)
      service.sipCallManager.checkCallDialogs()
    } else if (obj.hasOwnProperty('objectClass') && obj.objectClass === 'sipAccount') {
      service.sipAccountManager.accounts[obj.uuid] = new SipAccount(obj)
    } else if (obj.hasOwnProperty('objectClass') && obj.objectClass === 'sipPreset') {
      service.sipPresetManager.presets[obj.uuid] = new SipPreset(obj)
    }
  }

  // WebSocket Callbacks
  webSocketService.on('ObjectOnCreate', service.addObject)
  webSocketService.on('ObjectOnDestroy', service.removeObject)
  webSocketService.on('ObjectOnUpdate', service.updateObject)

  return service
})
