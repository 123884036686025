'use strict';

(function () {
  var app = angular.module('webremote.dashboard', [
    'ui.sortable',
    'webremote.dashboard.widgets.quickActions'
  ])

  require('./widgets/audioInterfaceControl/controller_audioInterfaceControl')
  require('./widgets/availableUsers/controller_availableUsers')
  require('./widgets/balanceControl/controller_balanceControl')
  require('./widgets/dtmf/controller_dtmf')
  require('./widgets/levelmeter/controller_levelmeterTextual')
  require('./widgets/liveMonitoring/controller_liveMonitoring')
  require('./widgets/networkStatus/controller_networkStatus')
  require('./widgets/quickRecord/controller_quickRecord')
  require('./widgets/recorderGroups/controller_recorderGroups')
  require('./widgets/recorderStreams/controller_recorderStreams')
  require('./widgets/streamGroups/controller_streamGroups')
  require('./widgets/connectionOverview/controller_connectionOverview')
  require('./widgets/syncd/controller_syncd')
  require('./widgets/systemHealth/controller_systemHealth')
  require('./widgets/tipOfTheDay/controller_tipModal')
  require('./widgets/tipOfTheDay/controller_tipOfTheDay')
  require('./widgets/transmissionCockpit/controller_transmissionCockpit')
  require('./widgets/transmissionStatistics/controller_transmissionStatistics')
  require('./widgets/volume/controller_volume')
  require('./widgets/monitoring/controller_monitoring')
  require('./widgets/sipOverview/controller')
  require('./widgets/quickActions/controller')
  require('./widgets/sipDial/controller_sipDial')
  require('./widgets/onDemandStatistics/controller')
  require('./widgets/listenIn/controller_listenIn')
  require('./widgets/delay/controller_delay')
  require('./widgets/liveAudio/controller')
  require('./widgets/quickActionsSmall/controller')
  require('./widgets/playlist/controller')
  require('./widgets/fernReport/controller')
  require('./widgets/fernReport/playlist/playlist_edit_controller')
})()
