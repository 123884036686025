import SipAccount from '../../../../class/SipAccount.class'

angular.module('webremote.settings').controller('webremote.settings.sipAccounts.controller',
  function ($scope, $uibModal, toastr, $document, modalService, objectManagerService) {
    $scope.viewLevel = { levelArray: [] }
    $scope.selectedSipAccount = {}
    $scope.objectManager = objectManagerService.getObjectManager()
    $scope.schema = {}

    $scope.objectManager.getScheme('sipAccount').then((schema) => {
      $scope.$evalAsync(function () {
        $scope.schema = schema
      })
    })

    $scope.newSipAccount = function () {
      $scope.objectManager.getDefault('sipAccount').then(v => {
        $scope.$evalAsync(function () {
          $scope.selectedSipAccount = new SipAccount({ obj: v, dependencies: $scope.objectManager.dependencies })
          $scope.activePill = 'new'
          $scope.scrollToTop()
        })
      })
    }

    $scope.selectSipAccount = function (uuid) {
      $scope.objectManager.object(uuid, 'sipAccount').then(sipAccount => {
        $scope.$evalAsync(function () {
          $scope.selectedSipAccount = angular.copy(sipAccount)
        })
      })
    }

    $scope.createAction = {
      cb: () => {
        $scope.objectManager.create('sipAccount', $scope.selectedSipAccount.member).then(v => {
          $scope.newSipAccount()
          $scope.scrollToTop()
          toastr.success('Sip Account added.', 'Success', {
            closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
          })
        }, (errorData) => {
          toastr.error(errorData.message.text, 'Error', {
            closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
          })
        })
      }
    }

    $scope.destroyAction = {
      cb: () => {
        const modalConfig = {
          title: 'Confirm',
          text: 'Are you sure to delete this SIP Account?'
        }

        modalService.confirmModal(modalConfig, $scope, function (response) {
          if (response) {
            $scope.selectedSipAccount.destroy()
            $scope.newSipAccount()
          }
        })
      }
    }

    $scope.updateAction = {
      cb: () => {
        $scope.selectedSipAccount.update()
        $scope.scrollToTop()
      }
    }

    $scope.cloneAction = {
      cb: () => {
        $scope.selectedSipAccount.clone()
        $scope.scrollToTop()
      }
    }

    $scope.scrollToTop = function () {
      $document.scrollTopAnimated(0, 400)
    }

    $scope.newSipAccount()
  })
