'use strict';

(function () {
  angular.module('webremote.settings', ['angular-timezone-selector'])
})()

require('./tabs/callProfiles/controller')
require('./tabs/audio/controller')
require('./tabs/sipAccounts/controller')
require('./tabs/licenses/controller')
require('./tabs/log/controller')
require('./tabs/user/controller')
require('./tabs/system/controller')
require('./tabs/system/controller_update')
require('./tabs/system/controller_modalFactoryResetOptions')
require('./tabs/network/controller')
require('./tabs/userRoles/controller')
require('./tabs/demux/controller')
require('./tabs/metadata/controller')
require('./tabs/archiveUploader/controller')

require('./controller_tabs')
require('./controller_thirdPartyLicenses')
