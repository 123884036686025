
angular.module('webremote.directive.objectmanager.utilities')
  .controller('webremote.directive.utilities.viewLevel.controller', ['$scope', function ($scope) {
    $scope.availableViewLevel = [
      { key: 'default', prettyName: 'Default', value: 'default' },
      { key: 'expert', prettyName: 'Expert', value: 'default,advanced,expert' }
    ]
    $scope.liveUpdate = false

    $scope.selectedLevel = $scope.availableViewLevel[0]
    $scope.level.levelArray = $scope.selectedLevel.value.split(',')

    $scope.getSuffix = () => {
      if ($scope.suffix) {
        return '_' + $scope.suffix
      } else {
        return ''
      }
    }

    $scope.rotate = () => {
      const currIndex = $scope.availableViewLevel.indexOf($scope.selectedLevel)
      if (currIndex + 1 < $scope.availableViewLevel.length) {
        $scope.selectedLevel = $scope.availableViewLevel[currIndex + 1]
      } else {
        $scope.selectedLevel = $scope.availableViewLevel[0]
      }

      $scope.level.levelArray = $scope.selectedLevel.value.split(',')
      if ($scope.preset !== undefined && typeof $scope.preset.setAttribute === 'function' && $scope.preset.member.uuid.length > 0) {
        $scope.preset.setAttribute('viewLevel' + $scope.getSuffix(), $scope.selectedLevel.key, $scope.liveUpdate)
      } else if ($scope.obj !== undefined && typeof $scope.obj.setAttribute === 'function' && $scope.obj.member.uuid.length > 0) {
        $scope.obj.setAttribute('viewLevel' + $scope.getSuffix(), $scope.selectedLevel.key, $scope.liveUpdate)
      }
    }

    $scope.loadLevel = function (v) {
      if (v && typeof v.getAttribute === 'function' && v.member.uuid.length > 0) {
        const levelKey = v.getAttribute('viewLevel' + $scope.getSuffix())
        if (levelKey) {
          const levelObj = $scope.availableViewLevel.find(x => x.key === levelKey.value)

          if (levelObj) {
            $scope.selectedLevel = levelObj
          } else {
            $scope.selectedLevel = $scope.availableViewLevel[0]
          }

          $scope.level.levelArray = $scope.selectedLevel.value.split(',')
        }
      }
    }

    $scope.$watch('obj', (v) => {
      if ($scope.preset === undefined || $scope.preset.member.uuid === undefined) {
        $scope.loadLevel(v)
      }
    })
    $scope.$watch('preset', (v) => {
      $scope.loadLevel(v)
    })
  }])

  .directive('omViewLevel', function () {
    return {
      restrict: 'E',
      scope: {
        obj: '=',
        preset: '=',
        suffix: '<',
        level: '=',
        liveUpdate: '=',
        size: '<'
      },
      templateUrl: 'directives/objectmanager/utilities/viewLevel/template.html'
    }
  })
