angular.module('webremote.dashboard').controller('widget_fernReport',
  function ($scope, $timeout, $uibModal, audioInterfaceService, deviceService, objectManagerService, audioElementLibraryService) {
    // TODO ?
    $scope.om = objectManagerService
    $scope.interfaceCollection = audioInterfaceService.GetInterfaceColleciton
    $scope.soundCards = audioInterfaceService.GetSoundCardColleciton
    $scope.deviceService = deviceService
    $scope.playlistStream = ''

    // Workaround to fix render issue for slider
    $timeout(function () {
      $scope.$broadcast('rzSliderForceRender')
    })


    $scope.$watch('activeDashboard', function () {
      $scope.om.object($scope.activeDashboard, 'dashboard').then((v) => {
        $scope.dashboard = v
      })
    })

    $scope.om.object($scope.activeDashboard, 'dashboard').then((v) => {
      $scope.$evalAsync(() => {
        $scope.dashboard = v

        // Wait, until widgetIndex isset
        $scope.$watch('widgetIndex', function () {
          $scope.$evalAsync(() => {
            $scope.storedOptions = $scope.dashboard.getInformationForWidget($scope.widgetIndex)
            if ($scope.storedOptions.filename === undefined) {
              $scope.storedOptions.filename = "Recording-%L.mp3"
            }
            if ($scope.storedOptions.selectedSipAccount === undefined) {
              $scope.om.collection('sipAccount').then((sipAccounts) => {
                for (const sipAccount of sipAccounts.values()) {
                  if (sipAccount.member.state === 'registerOk') {
                    $scope.storeSelectedSipAccount(sipAccount)
                    break
                  }
                }
              })
            }
            if ($scope.storedOptions.selectedSipAccount !== undefined) {
              $scope.om.object($scope.storedOptions.selectedSipAccount, 'sipAccount').then((v) => {
                $scope.$evalAsync(() => {
                  $scope.selSipAccObj = v
                })
              })
            }
            if ($scope.storedOptions.selectedPbE !== undefined && $scope.storedOptions.selectedPbE.length > 0) {
              $scope.om.object($scope.storedOptions.selectedPbE, 'phoneBookEntry').then((v) => {
                $scope.$evalAsync(() => {
                  if (v !== undefined) {
                    $scope.phoneBookEntry = v
                    $scope.SipLastAndContact = v.member.name
                  }
                })
              })
            }
            if ($scope.storedOptions.lastCalls === undefined) {
              $scope.storedOptions.lastCalls = []
            }
          })
        })
      })
    })

    $scope.$watchCollection('storedOptions', storedOptions => {
      if (storedOptions !== undefined) {
        $scope.dashboard.storeInformationForWidget($scope.widgetIndex, JSON.stringify(storedOptions))
      }
    })

    ///////////////////////////
    // Quick Actions Section //
    ///////////////////////////
    $scope.quickActions = ['9686c841-51f4-489a-b072-660bd0ed1b8d',
      '1d7c9789-8d84-476d-ae6d-86a540ae6da6',
      '19e17cc8-b691-4200-9f66-c8b4763522b9',
      'ae9e7fd6-55fe-4566-87ba-5ccda587cdba']

    $scope.getStateClass = function (actionObj) {
      if (actionObj !== undefined) {
        switch (Object.keys(actionObj.monitorState)[0]) {
          case 'switch':
            if (actionObj.quickActionType === 'switch' && actionObj.active) {
              return 'stateful-active'
            }
            break
          case 'stream':
            if (actionObj.monitorState.stream.uuid) {
              const stream = $scope.om.objectSync(actionObj.monitorState.stream.uuid, 'stream')
              if (stream !== undefined && stream.member.state === 'playing') {
                return 'stateful-active'
              }
            }
            break
          case 'streamGroup':
            if (actionObj.monitorState.streamGroup.uuid) {
              const streamGroup = $scope.om.objectSync(actionObj.monitorState.streamGroup.uuid, 'streamGroup')
              if (streamGroup !== undefined && streamGroup.member.state === 'playing') {
                return 'stateful-active'
              }
            }
            break
        }
      }
      return 'tile-stateless'
    }

    $scope.toggleActive = async function (actionObj) {
      if (actionObj.member !== undefined) {
        actionObj.successNotifications = false
        if (actionObj.member.quickActionType === 'switch') {
          switch (Object.keys(actionObj.member.monitorState)[0]) {
            case 'stream':
              if (actionObj.member.monitorState.stream.uuid) {
                const stream = $scope.om.objectSync(actionObj.member.monitorState.stream.uuid, 'stream')
                if (stream !== undefined) {
                  if (actionObj.member.active === true && stream.member.state !== 'playing' ||
                    actionObj.member.active === false && stream.member.state === 'playing') {
                    actionObj.member.active = !actionObj.member.active
                  }
                }
              }
              break
            case 'streamGroup':
              if (actionObj.member.monitorState.streamGroup.uuid) {
                const streamGroup = $scope.om.objectSync(actionObj.member.monitorState.streamGroup.uuid, 'streamGroup')
                if (streamGroup !== undefined) {
                  if (actionObj.member.active === true && streamGroup.member.state !== 'playing' ||
                    actionObj.member.active === false && streamGroup.member.state === 'playing') {
                    actionObj.member.active = !actionObj.member.active
                  }
                }
              }
              break
          }
        }
        actionObj.member.active = !actionObj.member.active
        actionObj.update()
      }
    }


    /////////////
    // Sliders //
    /////////////
    $scope.startup = true
    $scope.widgetTag = function (stream) {
      if (stream !== undefined && stream.member.tags.includes('widget')) {
        if (stream.volumeControl.channel_1 !== undefined) {
          stream.volumeControl.channel_1.slider.options.showSelectionBar = false
          stream.volumeControl.channel_1.slider.options.hideLimitLabels = true
          stream.volumeControl.channel_1.slider.options.vertical = true
          stream.volumeControl.channel_1.slider.options.hidePointerLabels = true
          stream.volumeControl.channel_1.slider.options.showTicks = 5
          stream.volumeControl.channel_1.slider.options.showTicksValues = 20
          stream.volumeControl.channel_1.slider.options.onlyBindHandles = true
        }
        return true
      } else {
        return false
      }
    }

    $scope.toogleFile = function (stream) {
      if (stream.member.state === 'playing') {
        stream.member.pendingState = 'inactive'
      } else {
        stream.member.pendingState = 'playing'
      }
      stream.update()
    }

    $scope.getCurrentTitle = function (stream) {
      title = ''

      if (stream.member.elements[0].type.source.type.file.currentFile > 0
        && stream.member.elements[0].type.source.type.file.currentFile <= stream.member.elements[0].type.source.type.file.playlist.length
        && stream.member.state === 'playing') {
        title = stream.member.elements[0].type.source.type.file.playlist[stream.member.elements[0].type.source.type.file.currentFile - 1].name
      }

      if (stream.member.state !== 'playing'
        && stream.member.elements[0].type.source.type.file.seekToFile > 0
        && stream.member.elements[0].type.source.type.file.seekToFile <= stream.member.elements[0].type.source.type.file.playlist.length) {
        title = stream.member.elements[0].type.source.type.file.playlist[stream.member.elements[0].type.source.type.file.seekToFile - 1].name
      }

      if (title !== '') {
        title = title.split('/').slice(-1)[0]
      }
      return title
    }


    ///////////////////
    // Playlist Edit //
    ///////////////////

    $scope.editPlaylist = function (stream) {
      $scope.playlistStream = stream;
      $scope.modalInstance = $uibModal.open({
        templateUrl: 'modules/dashboard/widgets/fernReport/playlist/playlist_edit.html',
        controller: 'webremote.dashboard.widgets.fernreport.playlist_edit.controller',
        size: 'md',
        scope: $scope,
        backdrop: 'true',
        keyboard: false
      })
    }


    //////////
    // Call //
    //////////
    $scope.storeSelectedSipAccount = function (account) {
      $scope.selSipAccObj = account
      if (account.member.uuid.length > 0) {
        $scope.storedOptions.selectedSipAccount = account.member.uuid
      }
    }

    $scope.storeSelectedPhoneBookEntry = function (pBE) {
      $scope.storedOptions.selectedPbE = pBE.member.uuid
      $scope.phoneBookEntry = pBE
      if (pBE.member.sip.account !== undefined && pBE.member.sip.account !== "") {
        $scope.storedOptions.selectedSipAccount = pBE.member.sip.account
        $scope.om.object($scope.storedOptions.selectedSipAccount, 'sipAccount').then((v) => {
          $scope.$evalAsync(() => {
            $scope.selSipAccObj = v
          })
        })
      }
      $scope.SipLastAndContact = pBE.member.name
    }

    $scope.storeSelectedLastCall = function (lastCall) {
      if (lastCall.hasOwnProperty('phoneBookEntry') && lastCall.phoneBookEntry !== "") {
        $scope.storedOptions.selectedPbE = lastCall.phoneBookEntry
        $scope.om.object($scope.storedOptions.selectedPbE, 'phoneBookEntry').then((phoneBookEntry) => {
          $scope.storeSelectedPhoneBookEntry(phoneBookEntry)
        })
      } else {
        $scope.storedOptions.selectedPbE = ""
        if (lastCall.sipAccount !== undefined && lastCall.sipAccount !== "") {
          $scope.storedOptions.selectedSipAccount = lastCall.sipAccount
          $scope.om.object($scope.storedOptions.selectedSipAccount, 'sipAccount').then((v) => {
            $scope.$evalAsync(() => {
              $scope.selSipAccObj = v
            })
          })
        }
        $scope.phoneBookEntry = {}
      }
      $scope.SipLastAndContact = lastCall.name
    }

    $scope.getLastCallSipAccObj = function (lastCall) {
      if (lastCall.sipAccount !== undefined && lastCall.sipAccount !== "") {
        $scope.storedOptions.selectedSipAccount = lastCall.sipAccount
        $scope.om.object($scope.storedOptions.selectedSipAccount, 'sipAccount').then((v) => {
          $scope.$evalAsync(() => {
            return v
          })
        })
      }
      return {}
    }

    $scope.resetSelectedPhoneBookEntry = function () {
      $scope.storedOptions.selectedPbE = {}
      $scope.phoneBookEntry = {}
    }

    $scope.$watchCollection('om.collectionSync("sipCall")', collection => {
      if (collection.length > 0) {
        if (collection[0].member.phoneBookEntry !== undefined) {
          $scope.om.object(collection[0].member.phoneBookEntry, 'phoneBookEntry').then((phoneBookEntry) => {
            $scope.phoneBookEntry = phoneBookEntry
            $scope.SipLastAndContact = collection[0].member.peerName
          })
        }
      } else {
        $scope.SipLastAndContact = ""
        $scope.phoneBookEntry = {}
        if ($scope.storedOptions !== undefined) {
          $scope.storedOptions.selectedPbE = {}
        }
      }
    })

    $scope.getLastCallsFiltered = function () {
      if ($scope.storedOptions !== undefined) {
        lastCalls = $scope.storedOptions.lastCalls
        if ($scope.SipLastAndContact) {
          lastCalls = lastCalls.filter((lastCall) => lastCall.name.toLowerCase().includes($scope.SipLastAndContact.toLowerCase()))
        }
        return lastCalls.slice(0, 3)
      } else {
        return []
      }
    }

    $scope.getPhoneBookEntrysFiltered = function () {
      PBEntries = $scope.om.collectionSync('phoneBookEntry')
        .filter((phoneBookEntry) => $scope.notUnknownCaller(phoneBookEntry))
        .sort((a, b) => a.member.name.localeCompare(b.member.name))
      if ($scope.SipLastAndContact) {
        PBEntries = PBEntries.filter((PBEntry) => PBEntry.member.name.toLowerCase().includes($scope.SipLastAndContact.toLowerCase()))
      }
      return PBEntries.slice(0, 5)
    }

    $scope.createPhoneBookEntry = async function () {
      return new Promise((resolve, reject) => {
        try {
          $scope.$evalAsync(async () => {
            if ($scope.phoneBookEntry === undefined || Object.keys($scope.phoneBookEntry).length === 0 || $scope.om.objectSync($scope.phoneBookEntry.member.uuid, 'phoneBookEntry') === undefined) {
              const phoneBookEntry = await $scope.om.getDefault('phoneBookEntry')
              const newEntry = angular.copy(phoneBookEntry)
              newEntry.name = $scope.SipLastAndContact
              newEntry.sip.peerUri = $scope.SipLastAndContact
              const pBEuuid = await $scope.om.create('phoneBookEntry', newEntry, false)
              $scope.phoneBookEntry = await $scope.om.object(pBEuuid, 'phoneBookEntry', true)
            }
            const phoneBookEntrys = await $scope.om.collection('phoneBookEntry')
            const pBEWildcard = Array.from(phoneBookEntrys.values()).find(x => x.member.sip.account === $scope.selSipAccObj.member.uuid && x.member.sip.peerUri === '')
            $scope.phoneBookEntry.member.sip.account = pBEWildcard.member.sip.account
            $scope.phoneBookEntry.member.sip.profile = pBEWildcard.member.sip.profile
            Object.assign($scope.phoneBookEntry.member.audio, pBEWildcard.member.audio)

            $scope.phoneBookEntry.member.name = $scope.SipLastAndContact
            $scope.phoneBookEntry.member.sip.peerUri = $scope.SipLastAndContact
            $scope.phoneBookEntry.member.temporary = true
            $scope.phoneBookEntry.update()
            resolve($scope.phoneBookEntry)
          })
        } catch (e) {
          reject(e)
        }
      })
    }

    $scope.startSipCall = async function () {
      lastCall = {}
      if ($scope.SipLastAndContact !== "") {
        if ($scope.phoneBookEntry && Object.keys($scope.phoneBookEntry).length > 0) {
          lastCall.name = $scope.phoneBookEntry.member.name
          lastCall.phoneBookEntry = $scope.phoneBookEntry.member.uuid
          $scope.updateLastCalls(lastCall)
          $scope.phoneBookEntry.createSipCall()
        } else {
          lastCall.name = $scope.SipLastAndContact
          lastCall.sipAccount = $scope.storedOptions.selectedSipAccount
          $scope.updateLastCalls(lastCall)
          $scope.createPhoneBookEntry().then(() => {
            $scope.phoneBookEntry.createSipCall()
          }, function (errorData) {
            toastr.error(errorData.message.text, 'Error calling createSipCall', {
              closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
            })
          })
        }
      }
    }

    $scope.updateLastCalls = async function (lastCall) {
      const currentDate = new Date()
      lastCall.time = currentDate.getTime()
      $scope.storedOptions.lastCalls.unshift(lastCall)
      $scope.storedOptions.lastCalls = $scope.storedOptions.lastCalls.filter(
        (obj, index) =>
          $scope.storedOptions.lastCalls.findIndex((item) => item.name === obj.name) === index
      );
      $scope.storedOptions.lastCalls = $scope.storedOptions.lastCalls.slice(0, 10)
    }

    $scope.getSipCall = function () {
      if ($scope.phoneBookEntry) {
        try {
          return $scope.om.objectSync($scope.phoneBookEntry.member.sip.activeCall, 'sipCall')
        } catch (e) {
          return undefined
        }
      }
    }

    $scope.notUnknownCaller = function (phoneBookEntry) {
      if (phoneBookEntry.member.name !== "<unknown caller>" && !phoneBookEntry.member.temporary) {
        return true
      }
      return false
    }

    ///////////////
    // Recording //
    ///////////////
    $scope.recordingStreamID = 'cf8b5ffa-64a0-4570-b33d-55e3bae2e97f'
    $scope.om.object($scope.recordingStreamID, 'stream').then((stream) => {
      $scope.$evalAsync(function () {
        $scope.recordingStream = stream
      })
    })

    $scope.startRecording = function () {
      let encoder = {}
      const bitrate = 128
      const fileextension = $scope.storedOptions.filename.split('.').slice(-1)[0]
      if (fileextension === 'mp3') {
        encoder = angular.copy(audioElementLibraryService.audioElementLibrary.filter(x => Object.prototype.hasOwnProperty.call(x, 'type') && Object.prototype.hasOwnProperty.call(x.type, 'encoder')) || []).filter(codec => codec.displayName === 'MP3')[0]
        encoder.type.encoder.type.mp3.bitrate = bitrate
        $scope.recordingStream.elements.splice(2, 1)
        $scope.recordingStream.elements.splice(2, 0, encoder)
      } else if (fileextension === 'ogg') {
        encoder = angular.copy(audioElementLibraryService.audioElementLibrary.filter(x => Object.prototype.hasOwnProperty.call(x, 'type') && Object.prototype.hasOwnProperty.call(x.type, 'encoder')) || []).filter(codec => codec.displayName === 'Vorbis')[0]
        encoder.type.encoder.type.vorbis.bitrate = bitrate * 1000
        $scope.recordingStream.elements.splice(2, 1)
        $scope.recordingStream.elements.splice(2, 0, encoder)
      }

      // check if filename does contain of numeric modifiers otherwise add on
      const numModifiers = ['%N', '%T', '%L']
      $scope.recordingStream.getElement('sink', 'file').type.sink.type.file.filenameTemplate = $scope.storedOptions.filename.split('.').slice(0, -1)[0]
      if (!numModifiers.some(el => $scope.storedOptions.filename.includes(el))) {
        $scope.recordingStream.getElement('sink', 'file').type.sink.type.file.filenameTemplate = $scope.storedOptions.filename.split('.').slice(0, -1)[0] + '-%N'
      }

      $scope.recordingStream.getElement('sink', 'file').type.sink.type.file.persistentTempFiles = true;
      $scope.recordingStream.member.pendingState = 'playing'
      $scope.recordingStream.update()
    }

    $scope.stopRecording = function () {
      $scope.recordingStream.member.pendingState = 'inactive'
      $scope.recordingStream.update()
    }

    $scope.om.object($scope.recordingStreamID, 'stream').then((stream) => {
      $scope.recordingStream = stream
    })

    ////////////////////
    // Local Mix part //
    ////////////////////
    $scope.MicSoundCards = [
      '3117e13b-49d2-43e7-bd34-bea49b927c56',
      '81496193-3f43-4ba3-a3bf-20cf88bbceec'
    ]
    $scope.predefinedPipes = {
      HP1: {
        mic1pipe: '0b17d6e4-44fd-41b0-9133-af781b61f916',
        mic2pipe: '35bf39e7-49c6-460d-b83b-d77bfad36d70',
        filepipe: '1585825a-5102-4fa0-abb6-8c3038de46d8',
        sippipe: '7a86de7e-d57a-4577-a3fc-64b44528a9cb'
      },
      HP2: {
        mic1pipe: '91cf66e8-1060-42c1-a28a-a7782d9d2c25',
        mic2pipe: '27d95a6a-c102-4cf6-8592-0a58d0a1ef22',
        filepipe: '6d4e40d1-da18-4824-87e9-e903b4a1e32d',
        sippipe: 'abfccd19-b0ab-4ab4-9c41-032b7cbfdf8e'
      },
      Rec: {
        mic1pipe: '4fde931f-3af5-4792-957c-6c6449685482',
        mic2pipe: '86096c49-b37f-4b25-89f2-85610aa724b9',
        filepipe: '986432fc-9eec-42fd-94d5-5fc5d90b0359',
        sippipe: '4a73644f-69a9-497f-9e1c-53b5e2489fcd'
      },
      Remote: {
        mic1pipe: 'b1a809ec-5777-4b9c-842b-4575ae3420c2',
        mic2pipe: '64ae2741-135b-48e3-9828-d051413a3dec',
        filepipe: '2774680b-8931-430c-8c8b-ca8dabd49537',
        sippipe: 'dc73b682-ad88-4227-bad0-6d64e9eaaf08'
      }
    }

    $scope.selectedPipes = $scope.predefinedPipes['HP1']
    $scope.selectedPipesPreset = 'HP1'

    $scope.selectPipes = function (localMix) {
      $scope.selectedPipes = $scope.predefinedPipes[localMix]
      $scope.selectedPipesPreset = localMix
    }
    $scope.timeoutCode
    $scope.delayInMs = 300

    $scope.validPipes = function () {
      return $scope.om.collectionSync('stream').filter(element => element.hasElement('processing', 'volumecontrol'))
    }

    $scope.updatePipe = function (uuid, angle, distanceToCenterPercent, resetToStart) {
      clearTimeout($scope.timeoutCode)

      $scope.timeoutCode = setTimeout(function () {
        let l = 10 * Math.log(Math.sqrt(1 - (angle / 180))) + 3 - (distanceToCenterPercent / 100) * 30
        let r = 10 * Math.log(Math.sqrt(angle / 180)) + 3 - (distanceToCenterPercent / 100) * 30

        if (l <= -80) l = -80
        if (r <= -80) r = -80

        if (resetToStart) {
          l = -80
          r = -80
        }

        var stream = $scope.om.objectSync(uuid, 'stream')
        if (stream !== undefined) {
          stream.errorNotifications = false
          stream.successNotifications = false
          stream.getElement('processing', 'volumecontrol').type.processing.type.volumecontrol.locked = false
          stream.getElement('processing', 'volumecontrol').type.processing.type.volumecontrol.volumes[0] = l
          stream.getElement('processing', 'volumecontrol').type.processing.type.volumecontrol.volumes[1] = r
          stream.update().then(() => {
            stream.errorNotifications = true
            stream.successNotifications = true
          })
        }
      }, $scope.delayInMs)
    }

    $scope.hpAvailable = function (index) {
      for (const soundCard of $scope.om.collectionSync('soundCard')) {
        if (soundCard.member !== undefined && soundCard.member.userName.startsWith('fernreport') && Number(soundCard.member.userName.split('fernreport')[1]) === index + 1 && soundCard.member.available) {
          return true
        }
      }
      return false
    }

    $scope.inputAvailable = function (index) {
      if (index === 0 || index === 1) {
        for (const soundCard of $scope.om.collectionSync('soundCard')) {
          if (soundCard.member !== undefined && soundCard.member.userName.startsWith('fernreport') && Number(soundCard.member.userName.split('fernreport')[1]) === index + 1 && soundCard.member.available) {
            return true
          }
        }
        return false
      }
      if (index === 2) {
        for (const stream of $scope.om.collectionSync('stream')) {
          if (stream.member.tags.includes('widget')) {
            return true
          }
        }
        return false
      }
      return true
    }
  }).directive('ngDraggableLocalMix', function ($document) {
    async function makeDraggableFernReport(scope, element, attr) {
      var offsetLeft = 0
      var offsetTop = 0
      var x = 0
      var y = 0

      const resetX = scope.x
      const resetY = scope.y
      let resetToStart = false

      await updateXY()

      element.css({
        position: 'absolute',
        cursor: 'pointer',
        top: y + 'px',
        left: x + 'px'
      })

      element.on('mousedown', function (event) {
        event.preventDefault()
        startX = event.pageX - x
        startY = event.pageY - y

        $document.on('mousemove', mouseMoving)
        $document.on('mouseup', mouseDropped)
      })

      element.on('touchstart', function (event) {
        event.preventDefault()
        startX = event.changedTouches[0].pageX - x
        startY = event.changedTouches[0].pageY - y

        $document.on('touchmove', touchMoving)
        $document.on('touchend', touchDropped)
      })

      async function updateXY() {
        await scope.om.object(scope.uuid, 'stream').then(function (stream) {
          if (stream !== undefined) {
            const l = stream.getElement('processing', 'volumecontrol').type.processing.type.volumecontrol.volumes[0]
            const r = stream.getElement('processing', 'volumecontrol').type.processing.type.volumecontrol.volumes[1]
            distanceCenterPercent = -10 / 3 * (-5 * Math.log(Math.exp(r / 5) / (Math.exp(l / 5) + Math.exp(r / 5))) + r - 3)
            offsetLeft = document.getElementById('canvasBalanceContainer').offsetLeft
            offsetTop = document.getElementById('canvasBalanceContainer').offsetTop
            if (distanceCenterPercent < 110 && distanceCenterPercent > -20) {
              const width = document.getElementById('canvasBalanceContainer').offsetWidth
              const height = document.getElementById('canvasBalanceContainer').offsetHeight
              distanceToCenter = distanceCenterPercent / 100 * (width / 2.24 - width / 4) + ((width / 4) - 5)
              angle = (Math.PI * Math.exp(r / 5)) / (Math.exp(l / 5) + Math.exp(r / 5))
              const elemWidth = element[0].clientWidth
              const elemHeight = element[0].clientHeight
              const centerWidth = (width - elemWidth) / 2 + offsetLeft
              const centerHeight = (height + offsetTop - elemHeight - 20)
              x = centerWidth - distanceToCenter * Math.cos(angle)
              y = centerHeight - distanceToCenter * Math.sin(angle)
            } else {
              x = resetX + offsetLeft
              y = resetY + offsetTop
            }
            draw()
            element.css({
              top: y + 'px',
              left: x + 'px'
            })
          }
        }.bind(this))
      }

      function draw() {
        var canvas = document.getElementById('canvas')
        var ctx = canvas.getContext('2d')

        ctx.canvas.width = document.getElementById('canvasBalanceContainer').clientWidth
        ctx.canvas.height = document.getElementById('canvasBalanceContainer').clientHeight

        ctx.strokeStyle = '#7D7D7D'

        ctx.beginPath()
        ctx.arc(ctx.canvas.width / 2, ctx.canvas.height - 40, ctx.canvas.width / 4, 355, 2 * Math.PI)
        ctx.stroke()

        ctx.beginPath()
        ctx.arc(ctx.canvas.width / 2, ctx.canvas.height - 40, ctx.canvas.width / 2.86, 355, 2 * Math.PI)
        ctx.stroke()

        ctx.beginPath()
        ctx.arc(ctx.canvas.width / 2, ctx.canvas.height - 40, ctx.canvas.width / 2.24, 355, 2 * Math.PI)
        ctx.stroke()

        ctx.font = '15px Verdana'
        ctx.fillStyle = '#7D7D7D'
        ctx.fillText('100', 146, ctx.canvas.height - 20)
        ctx.fillText('50', 87, ctx.canvas.height - 20)
        ctx.fillText('0', 30, ctx.canvas.height - 20)

        ctx.fillText('100', 461, ctx.canvas.height - 20)
        ctx.fillText('50', 527, ctx.canvas.height - 20)
        ctx.fillText('0', 593, ctx.canvas.height - 20)
      }

      window.addEventListener('resize', updateXY)
      element.on('$destroy', function (event) {
        window.removeEventListener('resize', updateXY)
      })

      function mouseMoving(event) {
        dragMoving(event)
      }

      function touchMoving(event) {
        const eventTouch = event.changedTouches[0]
        dragMoving(eventTouch)
      }

      scope.$watch('uuid', function (newValue, oldValue) {
        updateXY()
      });

      function dragMoving(event) {
        resetToStart = false
        const width = document.getElementById('canvasBalanceContainer').offsetWidth
        const height = document.getElementById('canvasBalanceContainer').offsetHeight

        x = event.pageX - startX
        y = event.pageY - startY
        // get real element size
        const elemWidth = element[0].clientWidth
        const elemHeight = element[0].clientHeight

        if (y >= (height + offsetTop - elemHeight - 20)) {
          y = (height + offsetTop - elemHeight - 20)
        } else if (y <= offsetTop) {
          y = offsetTop
        }

        if (x >= (width - elemWidth + offsetLeft)) {
          x = (width - elemWidth + offsetLeft)
        } else if (x <= offsetLeft) {
          x = offsetLeft
        }

        element.css({
          top: y + 'px',
          left: x + 'px'
        })

        const centerWidth = (width - elemWidth) / 2 + offsetLeft
        const centerHeight = (height + offsetTop - elemHeight - 20)

        const distanceToCenter = Math.sqrt(
          Math.pow((centerWidth - x), 2) + Math.pow((centerHeight - y), 2)
        )

        const angle = (Math.atan2(centerHeight - y, centerWidth - x) * 180 / Math.PI)
        let distanceCenterPercent = (distanceToCenter - ((width / 4) - 5)) / (width / 2.24 - width / 4) * 100
        if (distanceCenterPercent > 110 || distanceCenterPercent < -20) {
          resetToStart = true
        }
        if (distanceCenterPercent < 0) {
          distanceCenterPercent = 0
        } else if (distanceCenterPercent > 100) {
          distanceCenterPercent = 100
        }

        scope.callback(scope.uuid, angle, distanceCenterPercent, resetToStart)
      }

      function resetPosition() {
        if (resetToStart) {
          x = resetX + offsetLeft
          y = resetY + offsetTop
          element.css({
            top: y + 'px',
            left: x + 'px'
          })
        }
      }

      function mouseDropped() {
        resetPosition()
        $document.unbind('mousemove', mouseMoving)
        $document.unbind('mouseup', mouseDropped)
      }

      function touchDropped() {
        resetPosition()
        $document.unbind('touchmove', touchMoving)
        $document.unbind('touchend', touchDropped)
      }
    }

    return {
      scope: {
        callback: '=callback',
        uuid: '=uuid',
        x: '=x',
        y: '=y',
        om: '='
      },
      link: makeDraggableFernReport
    }
  })
