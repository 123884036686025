
angular.module('webremote.directive.objectmanager.utilities')
  .controller('webremote.directive.utilities.formElementErrors.controller', function ($scope) {

  })

  .directive('omFormErrors', function () {
    return {
      restrict: 'E',
      scope: {
        object: '<',
        path: '<',
        level: '<'
      },
      templateUrl: 'directives/objectmanager/utilities/formElementErrors/template.html'
    }
  })
