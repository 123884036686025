
angular.module('webremote.directive.objectmanager.formElements')
  .controller('webremote.directive.objectmanager.formElements.list.controller', function ($scope, schemeParser, omFormService) {
    $scope.newItem = {}
    $scope.omFormService = omFormService

    $scope.generateItemSchema = () => {
      const result = angular.copy($scope.schema)
      Object.assign(result, $scope.schema.elementType)
      delete result.elementType
      return result
    }

    $scope.parse = (schema) => {
      return schemeParser.parse(schema)
    }

    $scope.parseDefaultEntry = () => {
      $scope.newItemSchema = angular.copy($scope.generateItemSchema().fields)
      $scope.newItemSchema.key = 'value'
      $scope.newItem.value = $scope.parse($scope.newItemSchema)
    }

    if ($scope.schema.elementType.type === 'struct' || $scope.schema.elementType.type ==='union') {
      $scope.parseDefaultEntry()
    }

    $scope.moveItem = function (from, to) {
      // remove `from` item and store it
      var f = $scope.data[$scope.schema.key].splice(from, 1)[0]
      // insert stored item into position `to`
      $scope.data[$scope.schema.key].splice(to, 0, f)
    }

    $scope.$watch('objectPathInput', v => {
      if (v) $scope.objectPath = v
    })

    $scope.$watch('selector', v => {
      if (!$scope.objectPathInput) {
        if (selector) {
          $scope.objectPath = '/' + omFormService.cleanPath(v).replace(/\./gi, '/') + '/'
        } else {
          $scope.objectPath = '/'
        }
      }
    })
  })

  .directive('omConfigformList', function () {
    return {
      restrict: 'E',
      transclude: 'true',
      scope: {
        data: '=',
        schema: '<',
        selector: '=',
        debug: '<',
        objectPathInput: '<objectPath',
        object: '<',
        expertLevel: '=expertLevel',
        type: '='
      },
      templateUrl: 'directives/objectmanager/formElements/list/template.html'
    }
  })
