angular.module('webremote.dashboard').controller('widget_sipOverview',
  function ($scope, objectManagerService, authenticationService, modalService) {
    $scope.dashboard = authenticationService.dashboardObject
    $scope.newPreset = { member: {} }
    $scope.objectManager = objectManagerService.getObjectManager
    $scope.selectedSipCall = undefined
    $scope.selectedCallPreset = undefined

    $scope.removeWildcardEntries = function (entry) {
      return entry.member.sip.peerUri.length > 0
    }

    $scope.objectManager().getDefault('callPreset').then((defaultObj) => {
      $scope.newPreset.member = angular.copy(defaultObj)
    })

    $scope.$watch('activeDashboard', function () {
      $scope.objectManager().object($scope.activeDashboard, 'dashboard').then((v) => {
        $scope.$evalAsync(() => {
          $scope.dashboard = v
        })
      })
    })

    $scope.objectManager().object($scope.activeDashboard, 'dashboard').then((v) => {
      $scope.$evalAsync(() => {
        $scope.dashboard = v

        // Wait, until widgetIndex isset
        $scope.$watch('widgetIndex', function () {
          $scope.storedOptions = $scope.dashboard.getInformationForWidget($scope.widgetIndex)

          // Default: Select Unassigned Tab
          if ($scope.storedOptions.selectedCallPreset === undefined) {
            $scope.setTab('')
          } else {
            // Load from Settings
            $scope.setTab($scope.storedOptions.selectedCallPreset)
          }

          // Default: Select Unassigned Tab
          if ($scope.storedOptions.filter === undefined) {
            $scope.filter = {
              paused: false,
              playing: true,
              active: false,
              inactive: false,
              incomplete: false,
              eos: false,
              waiting: false
            }
          } else {
            $scope.filter = $scope.storedOptions.filter
          }
        })
      })
    })

    $scope.setTab = function (val) {
      $scope.selectedCallPreset = val
      $scope.storedOptions.selectedCallPreset = val
      $scope.dashboard.storeInformationForWidget($scope.widgetIndex, JSON.stringify($scope.storedOptions))
    }

    $scope.confirmDeletionOfPreset = function (preset) {
      const modalConfig = {
        title: 'Confirm',
        text: 'Are you sure to remove this preset?'
      }

      modalService.confirmModal(modalConfig, $scope, function (response) {
        if (response) {
          preset.destroy()
          preset.edit = false
          $scope.setTab('')
        }
      })
    }

    $scope.hangUpPreset = function () {
      const modalConfig = {
        title: 'Confirm',
        text: 'Are you sure to hang up multiple calls?'
      }

      modalService.confirmModal(modalConfig, $scope, function (response) {
        if (response) {
          $scope.objectManager().object($scope.selectedCallPreset, 'callPreset').then((preset) => {
            preset.hangUp()
          })
        }
      })
    }

    $scope.setWorkingCopyOfPreset = function (preset) {
      $scope.workingCopyPreset = angular.copy(preset)
    }

    $scope.isPresetSelected = function () {
      return !($scope.selectedCallPreset === undefined || $scope.selectedCallPreset.length === 0 || $scope.selectedCallPreset === 'new')
    }

    $scope.getSipCalls = function () {
      try {
        if (!$scope.isPresetSelected()) {
          return $scope.getUnassignedCalls().map(x => x.member.uuid)
        } else {
          return $scope.objectManager().objectSync($scope.selectedCallPreset, 'callPreset').member.entries.map(x => x.phoneBookEntry)
        }
      } catch (e) {
        return []
      }
    }

    $scope.getUnassignedCalls = function () {
      return $scope.objectManager().collectionSync('phoneBookEntry').filter((x) => {
        // Check, if active sipCall is existing
        if (x.member.sip.activeCall.length === 0 || x.member.sip.activeCall === undefined) {
          return false
        }

        // Check state of sipCall
        const sipCall = $scope.objectManager().objectSync(x.member.sip.activeCall, 'sipCall')
        if (sipCall !== undefined && sipCall.member.state !== 'established' && sipCall.member.state !== 'ringing') {
          return false
        }

        // Check, if phoneBookEntry is associated to any callPreset
        const presets = $scope.objectManager().collectionSync('callPreset')
        let phoneBookEntryFoundInPreset = false
        presets.map((preset) => {
          preset.member.entries.map((entry) => {
            if (entry.phoneBookEntry === x.member.uuid) {
              phoneBookEntryFoundInPreset = true
            }
          })
        })

        if (phoneBookEntryFoundInPreset) {
          return false
        }

        return true
      })
    }

    $scope.createPreset = function ($event) {
      $event.preventDefault()
      $event.stopPropagation()
      $scope.objectManager().create('callPreset', $scope.newPreset.member).then(uuid => {
        $scope.$evalAsync(() => {
          $scope.objectManager().object(uuid, 'callPreset', true).then((preset) => {
            $scope.setTab(uuid)
            $scope.newPreset.member.name = ''
            $scope.newPreset.edit = false
          })
        })
      })
    }

    $scope.cancelNewPreset = function ($event) {
      $event.preventDefault()
      $event.stopPropagation()
      $scope.newPreset.member.name = ''
      $scope.newPreset.edit = false
      $scope.setTab('')
    }

    $scope.newPhoneBookEntry = function (name) {
      $scope.objectManager().getDefault('phoneBookEntry').then((defaultObj) => {
        const newEntry = angular.copy(defaultObj)
        if (name === undefined) { name = '' }
        newEntry.name = name || 'New entry'
        newEntry.sip.peerUri = name
        $scope.objectManager().create('phoneBookEntry', newEntry).then(uuid => {
          $scope.objectManager().objectSync($scope.selectedCallPreset, 'callPreset').addPhonebookEntry(uuid, false)
        })
      })
    }

    $scope.sortableListOptions = {
      helper: 'clone',
      'ui-floating': false,
      handle: '.drag-handler',
      stop: function (e, ui) {
        $scope.objectManager().objectSync($scope.selectedCallPreset, 'callPreset').update()
      }
    }
  })
