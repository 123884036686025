import BaseClass from './Base.class'

export default class OnDemandJobPool extends BaseClass {
  constructor (data) {
    super(data)
    try {
      this._tooltip = JSON.parse(data.obj.tooltip)
    } catch (e) {}
  }

  async enable () {
    return this.patch([
      { op: 'replace', path: '/enabled', value: true },
      { op: 'replace', path: '/disableOnIdle', value: false }
    ])
  }

  get tooltip () {
    return this._tooltip
  }

  async disable (force) {
    if (force) {
      return this.patch([
        { op: 'replace', path: '/enabled', value: false },
        { op: 'replace', path: '/errorMessage', value: '' }
      ])
    } else {
      return this.patch([
        { op: 'replace', path: '/disableOnIdle', value: true },
        { op: 'replace', path: '/errorMessage', value: '' }
      ])
    }
  }

  updateCallback (data) {
    super.updateCallback(data)
    this._tooltip = JSON.parse(this.member.tooltip)
  }
}
