'use strict'

angular.module('webremote.service.stream', []).factory('streamService', function (objectManagerService) {
  const service = {}

  service.generateUniqueName = async function () {
    const prefix = 'New Pipe '
    return objectManagerService.collection('stream').then(v => {
      v = Array.from(v.values())
      let index = v.length
      while (v.find(x => x.member.name === prefix + index)) {
        index++
      }
      return prefix + index
    })
  }

  return service
})
