
angular.module('webremote.directive.sip.sipCall.listing', [])
  .controller('webremote.directive.sip.sipCall.listing.controller', function ($scope, $location, $timeout, objectManagerService, $uibModal, modalService, audioPlayerService) {
    $scope.options = ($scope.options === undefined ? {} : $scope.options)
    $scope.options.ui = ($scope.options.ui === undefined ? {} : $scope.options.ui)
    $scope.options.updateEnabled = ($scope.options.updateEnabled === undefined ? true : $scope.options.updateEnabled)

    $scope.om = objectManagerService
    $scope.audioPlayer = audioPlayerService

    $scope.historyCollapsed = true
    $scope.phonebookCollapsed = true
    $scope.sourceMode = { tx: false, rx: false }
    $scope.modals = {}

    $scope.isCallActive = function () {
      try {
        return $scope.getPhoneBookEntry().member.sip.activeCall.length > 0
      } catch (e) {
        return false
      }
    }

    $scope.getSipCall = function () {
      try {
        return $scope.om.objectSync($scope.getPhoneBookEntry().member.sip.activeCall, 'sipCall')
      } catch (e) {
        return {}
      }
    }

    $scope.monitorActive = function () {
      return $scope.om.collectionSync('stream').filter(x => x.isMonitor && x.member.state === 'playing').length > 0
    }

    $scope.toggleMonitorMode = function (stream) {
      $scope.$evalAsync(() => {
        if (stream.member.monitor === 'active') {
          stream.monitor = ''
        } else {
          stream.monitor = 'active'
        }
      })
    }

    $scope.listenIn = function (streamUuid) {
      $scope.om.object(streamUuid, 'stream').then(stream => {
        audioPlayerService.control.setPlayObject(stream, { common: { title: stream.member.name } })
      })
    }

    $scope.clearError = function () {
      $scope.$evalAsync(() => {
        $scope.getPhoneBookEntry().clearError()
      })
    }

    $scope.getCallPreset = function () {
      return $scope.om.objectSync($scope.selectedCallPresetUuid, 'callPreset')
    }

    $scope.getPhoneBookEntry = function () {
      return $scope.om.objectSync($scope.phoneBookEntryUuid, 'phoneBookEntry')
    }

    $scope.updateItem = function () {
      if ($scope.options.updateEnabled !== false) {
        $scope.getPhoneBookEntry().update()
      }
    }

    $scope.presetSelected = function () {
      return $scope.selectedCallPresetUuid !== undefined && $scope.selectedCallPresetUuid.length > 0
    }

    $scope.manageCodecProfiles = function () {
      $location.url('settings?module=callProfiles')
    }

    $scope.manageSipAccounts = function () {
      $location.url('settings?module=sipAccounts')
    }

    $scope.remove = function () {
      const modalConfig = {
        title: 'Confirm',
        text: 'Are you sure to remove this phonebook entry from sip preset?'
      }

      modalService.confirmModal(modalConfig, $scope, function (response) {
        if (response) {
          $scope.om.object($scope.selectedCallPresetUuid, 'callPreset').then((preset) => {
            preset.removePhonebookEntry($scope.getPhoneBookEntry().member.uuid)
          })
        }
      })
    }

    $scope.delay = (function () {
      var promise = null
      return function (callback, ms) {
        $timeout.cancel(promise) // clearTimeout(timer);
        promise = $timeout(callback, ms) // timer = setTimeout(callback, ms);
      }
    })()

    $scope.getBorderStyleClass = function () {
      let state = 'idle'

      try {
        state = $scope.getSipCall().member.state
      } catch (e) {
        state = 'idle'
      }

      let feedback = ''

      switch (state) {
        case 'idle':
          feedback = 'stateBorder-default'
          break
        case 'terminated':
          feedback = 'stateBorder-red'
          break
        case 'trying':
          feedback = 'stateBorder-orange-blinking'
          break
        case 'ringing':
        case 'outgoing':
          feedback = 'stateBorder-orange'
          break
        case 'established':
          feedback = 'stateBorder-green'
          break
        default:
          feedback = 'stateBorder-default'
      }

      try {
        if ($scope.getPhoneBookEntry().member.sip.pendingRetry) {
          feedback = 'stateBorder-orange-blinking'
        }
      } catch (e) {

      }

      return feedback
    }

    $scope.showLog = function () {
      $scope.showLogForCallUuid = $scope.getPhoneBookEntry().member.uuid
      $scope.modalInstance = $uibModal.open({
        templateUrl: 'directives/debug/journal/modalTemplate.html',
        controller: 'journalSipCall',
        size: 'lg',
        scope: $scope
      })
    }

    $scope.isPresetSelected = function () {
      if ($scope.selectedCallPresetUuid === undefined || $scope.selectedCallPresetUuid.length === 0) {
        return false
      } else {
        return true
      }
    }

    $scope.resolveChannelIdToName = function (type, soundCard, id) {
      let collection = []
      if (type === 'audioInputPort') {
        collection = $scope.om.collectionSync('audioInputPort')
      }

      if (type === 'audioOutputPort') {
        collection = $scope.om.collectionSync('audioOutputPort')
      }

      for (const audioInterface of collection) {
        if (!(audioInterface.getInformationForChannel(soundCard, id) instanceof Error)) {
          return audioInterface.getInformationForChannel(soundCard, id)
        }
      }
    }

    // Modal functions

    $scope.openModal_channelMode = function (channel) {
      $scope.showChannel = channel
      $scope.sipCallBackup = angular.copy($scope.item)

      $scope.modals.autoReconnect = $uibModal.open({
        templateUrl: 'directives/sip/sipCall/listing/modals/channelMode/template.html',
        controller: 'ctrlModalChannelMode',
        size: 'md',
        scope: $scope
      })

      // Get Feedback!
      $scope.modals.autoReconnect.result.then(function () {
        $scope.updateItem()
      }, function (errorData) {
      })
    }

    $scope.openModal_autoReconnect = function () {
      $scope.sipCallBackup = angular.copy($scope.item)

      $scope.modals.autoReconnect = $uibModal.open({
        templateUrl: 'directives/sip/sipCall/listing/modals/autoReconnect/template.html',
        controller: 'ctrlModalAutoReconnect',
        size: 'md',
        scope: $scope
      })

      // Get Feedback!
      $scope.modals.autoReconnect.result.then(function () {
        $scope.updateItem()
      }, function (errorData) {
      })
    }
  }).controller('journalSipCall', ['$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {
    $scope.journal = {}

    $scope.journal.title = 'Sip Call'
    $scope.journal.ids = [$scope.showLogForCallUuid]

    $scope.journal.classes = ['phoneBookEntry']

    $scope.closeModal = function () {
      $uibModalInstance.close()
    }
  }])

  .directive('sipCall', function () {
    return {
      restrict: 'E',
      transclude: false,
      scope: {
        phoneBookEntryUuid: '=phoneBookEntry',
        selectedCallPresetUuid: '=preset',
        presetEntryIndex: '=presetEntryIndex',
        options: '=options'
      },
      templateUrl: 'directives/sip/sipCall/listing/template.html'
    }
  })

// Import Modals
require('./modals/channelMode/controller')
