'use strict'

angular.module('webremote.service', [
  'webremote.service.webSocket',
  'webremote.service.authentication',
  'webremote.service.objectManager',
  'webremote.service.audioElementLibrary',
  'webremote.service.audioInterface',
  'webremote.service.audioPlayer',
  'webremote.service.audioport',
  'webremote.service.device',
  'webremote.service.http',
  'webremote.service.sip',
  'webremote.service.stream',
  'webremote.service.stats',
  'webremote.service.streamgroup',
  'webremote.service.modal',
  'webremote.service.clientInfo',
  'webremote.service.file'
])

require('./websocket/websocket.service')
require('./authentication/authentication.service')
require('./objectmanager/objectmanager.service')
require('./audioelementlibrary/audioelementlibrary.service')
require('./audiointerface/audiointerface.service')
require('./audioport/audioport.service')
require('./audioplayer/audioplayer.service')
require('./http/http.service')
require('./device/device.service')
require('./sip/sip.service')
require('./stream/stream.service')
require('./stats/stats.service')
require('./streamgroup/streamgroup.service')
require('./modal/modal.service')
require('./clientInfo/clientInfo.service')
require('./file/file.service')
