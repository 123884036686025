'use strict'

angular.module('webremote.service.stats', []).factory('statsService', function (webSocketService, $interval) {
  var service = {}

  class Stats {
    constructor () {
      this.cpuAverage = 0.00
      this.cpu = {}
      this.cpuTooltip = ''
      this.cpu_temp = '--.-'
      this.memAvailable = 0.00
      this.memTotal = 0.00
      this.diskTotal = 0.00
      this.diskFree = 0.00
      this.diskFreeMin = 0.00
      this.diskFreeWarn = 0.00
      this.pipes = 0
      this.recorder = 0
      this.phonebookEntries = 0
      this.netstat = []
      this.wifi = []
      this.time = undefined
    }

    AddZero (number) {
      if (number < 10) {
        number = '0' + number
      }
      return number
    }

    GetTimeFormat () {
      if (this.time === undefined) {
        return '--:--:--'
      } else {
        const hh = this.AddZero(this.time.getHours())
        const mm = this.AddZero(this.time.getMinutes())
        const ss = this.AddZero(this.time.getSeconds())
        return hh + ':' + mm + ':' + ss
      }
    }

    GetWiFiQuality() {
      if (Object.entries(this.wifi)[0] === undefined) {
        return 0
      } else {
        return Object.entries(this.wifi)[0][1]
      }
    }

    UpdateTimer() {
      $interval(function () {
        if (service.stats.time !== undefined) {
          service.stats.time.setSeconds(service.stats.time.getSeconds() + 1)
        }
      }, 1000)
    }
  }

  service.stats = new Stats()
  service.stats.UpdateTimer()

  service.UpdateStats = function (msg) {
    // Calculate CPU Average in Percent
    service.stats.cpuAverage = Math.round((msg.cpu_load.average * 100))

    // Copy CPU list
    service.stats.cpu = msg.cpu_load.load

    // Copy Temperature in Celsius
    if (msg.cpu_temp == null) {
      service.stats.cpu_temp = '--.-'
    } else {
      service.stats.cpu_temp = msg.cpu_temp
    }

    service.stats.netstat = msg.netstat

    for (var i in service.stats.cpu) {
      service.stats.cpu[i] = Math.round((service.stats.cpu[i] * 100))
    }

    service.stats.memTotal = msg.mem_total
    service.stats.memAvailable = msg.mem_available
    service.stats.diskTotal = msg.disk_total
    service.stats.diskFree = msg.disk_free
    service.stats.diskFreeWarn = msg.disk_free_warn
    service.stats.diskFreeMin = msg.disk_free_min

    service.stats.wifi = msg.wifi
  }

  service.UpdateTime = function (msg) {
    service.stats.time = new Date(msg.time)
  }

  service.getStats = function () {
    return service.stats
  }

  webSocketService.on('TimeUpdate', service.UpdateTime)
  webSocketService.on('StatsUpdate', service.UpdateStats)

  return service
})
