angular.module('webremote.monitoring').controller('webremote.monitoring.dataWatch.controller',
  function ($scope, $uibModal, toastr, $document, modalService, objectManagerService) {
    $scope.viewLevel = { levelArray: [] }
    $scope.selectedDataWatch = {}
    $scope.objectManager = objectManagerService.getObjectManager()
    $scope.schema = {}

    $scope.objectManager.getScheme('dataWatch').then((schema) => {
      $scope.$evalAsync(function () {
        $scope.schema = schema
      })
    })

    $scope.newDataWatch = function () {
      $scope.objectManager.createDefaultObject('dataWatch').then(obj => {
        $scope.$evalAsync(function () {
          $scope.selectedDataWatch = obj
          $scope.scrollToTop()
        })
      })
    }

    $scope.selectDataWatch = function (uuid) {
      $scope.objectManager.object(uuid, 'dataWatch').then(dataWatch => {
        $scope.$evalAsync(function () {
          $scope.selectedDataWatch = angular.copy(dataWatch)
        })
      })
    }

    $scope.createAction = {
      cb: () => {
        $scope.objectManager.create('dataWatch', $scope.selectedDataWatch.member).then(v => {
          $scope.newDataWatch()
          $scope.scrollToTop()
          toastr.success('DataWatch added.', 'Success', {
            closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
          })
        }, (errorData) => {
          toastr.error(errorData.message.text, 'Error', {
            closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
          })
        })
      }
    }

    $scope.destroyAction = {
      cb: () => {
        const modalConfig = {
          title: 'Confirm',
          text: 'Are you sure to delete this DataWatch?'
        }

        modalService.confirmModal(modalConfig, $scope, function (response) {
          if (response) {
            $scope.selectedDataWatch.destroy()
            $scope.newDataWatch()
          }
        })
      }
    }

    $scope.updateAction = {
      cb: () => {
        $scope.selectedDataWatch.update()
        $scope.scrollToTop()
      }
    }

    $scope.cloneAction = {
      cb: () => {
        $scope.selectedDataWatch.clone()
        $scope.scrollToTop()
      }
    }

    $scope.scrollToTop = function () {
      $document.scrollTopAnimated(0, 400)
    }

    $scope.newDataWatch()
  })
