angular.module('webremote.settings').controller('ThirdPartyLicensesCtrl',
  function ($scope, $http, $log, authenticationService, httpService, toastr, $rootScope, $sce) {
    $scope.recipes = {}

    // Load all Components
    httpService.callFunction('license/recipes').then(function (serviceData) {
      for (const title of serviceData.data) {
        $scope.recipes[title] = { name: title, files: [], text: 'no information available', version: '' }
      }
    }, function (errorData) {
      toastr.error("Can't load the licenses", 'Error', {
        closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
      })
    })

    // Load Detail Information
    $scope.loadFileList = function (name) {
      httpService.callFunction('license/recipes/' + name).then(function (serviceData) {
        var text = ''

        for (const title in serviceData.data.files) {
          text += '<strong>' + title + '</strong><br>'
          text += serviceData.data.files[title]
          text += '<br><br>'
        }

        $scope.recipes[name].text = $sce.trustAsHtml(text)
        $scope.recipes[name].license = serviceData.data.license
        $scope.recipes[name].version = serviceData.data.version
      }, function (errorData) {
        toastr.error("Can't load the license information", 'Error', {
          closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
        })
      })
    }
  })
