import Directory from '../../../../services/file/Directory'

angular.module('webremote.directive.objectmanager.formElements')
  .controller('webremote.directive.objectmanager.formElements.filebrowser', ['$scope', '$injector', 'fileService', function ($scope, $injector, fileService) {
    $scope.navigation = {
      path: [],
      currentPath: {},
      highlightedItems: []
    }

    $scope.sortOptions = {
      fileCount: 5,
      page: 1,
      searchText: ''
    }

    $scope.openFile = (file) => {
      $scope.model[$scope.key] = fileService.encodePath(file.parent.path) + file.name
      $scope.navigation.highlightedItems = [fileService.encodePath(file.parent.path) + file.name]
    }

    $scope.uiOptions = {
      viewSwitch: false,
      numberOfEntries: false,
      entry: {
        mtime: false,
        select: false
      }
    }

    $scope.$watch('model[key]', (v) => {
      if (v.length > 0) {
        const path = fileService.decodePath(v)
        path.pop()
        $scope.navigation.path = path.map(x => Directory.init({ path: path }, { name: x }, $injector))
      }

      $scope.navigation.highlightedItems = [v]
    })
  }])

  .directive('fileBrowser', function () {
    return {
      restrict: 'E',
      scope: {
        model: '=',
        key: '='
      },
      templateUrl: 'directives/objectmanager/formElements/filebrowser/template.html'
    }
  })
