
angular.module('webremote.directive.archive').controller('webremote.directive.archive.fileUpload.controller', function ($scope, fileService, Upload, toastr) {
  $scope.uploadFiles = function () {
    if ($scope.upload.queue && $scope.upload.queue.length) {
      $scope.upload.inProgress = true
      // or send them all together for HTML5 browsers:
      Upload.upload({ url: encodeURI('webapi/http/archive/' + fileService.encodePath($scope.navigation.path.map(x => x.name))), data: { file: $scope.upload.queue } }).then(function (resp) {
        $scope.upload.queue = []
        $scope.upload.inProgress = false
        $scope.upload.progress = 0
      }, function (resp) {
        toastr.error('Upload failed! Maybe your file collection is too large. ', 'Error', {
          closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
        })

        $scope.upload.queue = []
        $scope.upload.inProgress = false
        $scope.upload.progress = 0
      }, function (evt) {
        $scope.upload.progress = parseInt(100.0 * evt.loaded / evt.total)
      })
    }
  }
}).directive('fileUpload', function () {
  return {
    restrict: 'E',
    scope: {
      upload: '=',
      navigation: '='
    },
    templateUrl: 'directives/archive/fileUpload/template.html'
  }
})
