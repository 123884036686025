import BaseClass from './Base.class'

export default class User extends BaseClass {
  constructor (data) {
    super(data)

    // We do not need update messages on success for users
    this.successNotifications = false
  }
}
