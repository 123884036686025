
angular.module('webremote.directive.objectmanager.formElements')
  .controller('webremote.directive.objectmanager.formElements.toggle.controller', function ($scope, omFormService) {
    $scope.omFormService = omFormService

    $scope.ngInit = function () {
      if ($scope.data !== undefined && ($scope.data[$scope.schema.key] === undefined || $scope.data[$scope.schema.key].length === 0)) {
        if ($scope.schema.default !== undefined) {
          $scope.data[$scope.schema.key] = $scope.schema.default
        }
      }
    }

    $scope.$watch('data[schema.key]', $scope.ngInit)
  })

  .directive('omConfigformToggle', function () {
    return {
      restrict: 'E',
      transclude: 'true',
      scope: {
        data: '=data',
        schema: '<schema',
        selector: '<',
        objectPath: '<',
        object: '<'
      },
      templateUrl: 'directives/objectmanager/formElements/toggle/template.html'
    }
  })
