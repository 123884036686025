import BaseClass from './Base.class'

export default class PhoneBookEntry extends BaseClass {
  async createSipCall () {
    var oms = this._dependencies.$injector.get('objectManagerService')

    await oms.getDefault('sipCall').then(async (defaultSipCall) => {
      const payload = defaultSipCall

      // Assign call to phonebook
      payload.phoneBookEntry = this.member.uuid

      // Mark call as outgoing
      payload.outgoing = true

      await oms.create('sipCall', payload).then(uuid => {
        return uuid
      })
    })
  }

  async makePermanent () {
    this.member.temporary = false
    return this.patch([
      { op: 'replace', path: '/temporary', value: this.member.temporary }
    ])
  }

  async clearError () {
    this.member.lastError = ''
    return this.patch([
      { op: 'replace', path: '/lastError', value: this.member.lastError }
    ])
  }

  async cancelSipRetry () {
    this.member.temporary = false
    return this.patch([
      { op: 'replace', path: '/sip/pendingRetry', value: false }
    ])
  }
}
