import BaseClass from './Base.class'

export default class ElementPreset extends BaseClass {
  constructor (data) {
    super(data)
    this.initializeSlider()
  }

  updateCallback (data) {
    super.updateCallback(data)
    this.initializeSlider()
  }

  initializeSlider () {
    if (this.member.data && Object.prototype.hasOwnProperty.call(this.member.data.type, 'processing') && Object.prototype.hasOwnProperty.call(this.member.data.type.processing.type, 'volumecontrol')) {
      this.slider = []
      for (let i = 0; i < this.member.data.type.processing.type.volumecontrol.volumes.length; i++) {
        this.slider.push({
          id: this.member.uuid + '_' + i,
          floor: -80,
          ceil: 20,
          step: 1,
          showSelectionBar: true,
          vertical: true,
          showTicks: false,
          showTicksValues: false,
          keyboardSupport: true,
          onChange: function () { },

          onEnd: function (sliderId, modelValue, highValue, pointerType) {
            this.patch([{ op: 'replace', path: '/data/type/processing/type/volumecontrol/volumes/' + sliderId.split('_')[1], value: modelValue }])
            // this.update()
          }.bind(this)
        })
      }
    }
  }

  get volumeSliderConfig () {
    if (Object.prototype.hasOwnProperty.call(this.member.data.type, 'processing') && Object.prototype.hasOwnProperty.call(this.member.data.type.processing.type, 'volumecontrol')) {
      return this._volumeSliderConfig
    } else {
      return new Error('No configuration available, because it is not a volume control preset.')
    }
  }
}
