angular.module('webremote.dashboard').controller('modalConfigCtrl',
  function ($scope, $log, $uibModalInstance, $http, objectManagerService, deviceService, modalService) {
    $scope.tempWidgetData = []
    $scope.widgetList = []
    $scope.storeWidgetList = []
    $scope.objectManager = objectManagerService

    if ($scope.modeNewDashboard) {
      // Get empty Dashboard object
      $scope.objectManager.getDefault('dashboard').then(v => {
        $scope.currentDashboardEditingCopy = {}
        $scope.currentDashboardEditingCopy.member = angular.copy(v)
      })

      $scope.actions = {
        create: {
          cb: () => {
            const newWidgets = []
            // Store new widget
            for (const widget of $scope.tempWidgetData) {
              newWidgets.push({ id: widget.id, options: '' })
            }

            $scope.currentDashboardEditingCopy.member.widgets = newWidgets
            $scope.objectManager.create('dashboard', $scope.currentDashboardEditingCopy.member).then(() => {
              $uibModalInstance.dismiss()
            })
          }
        }
      }
    } else {
      $scope.objectManager.object($scope.activeDashboard, 'dashboard').then((v) => {
        $scope.currentDashboardEditingCopy = angular.copy(v)
        $scope.tempWidgetData = angular.copy(v.readWidgets())
      })

      $scope.actions = {
        update: {
          cb: () => {
            // Backup data
            const oldWidgets = angular.copy($scope.currentDashboardEditingCopy.member.widgets)
            const newWidgets = []

            // Store new widget
            for (const widget of $scope.tempWidgetData) {
              newWidgets.push({ id: widget.id, options: '' })
            }

            // Restore options for widgets
            for (const widget of newWidgets) {
              const result = oldWidgets.find(function (oldWidget) {
                return oldWidget.id === widget.id
              })

              if (result) {
                widget.options = result.options
              }
            }
            $scope.currentDashboardEditingCopy.member.widgets = newWidgets
            $scope.currentDashboardEditingCopy.update().then(() => {
              $uibModalInstance.dismiss()
            })
          }
        },
        destroy: {
          cb: () => {
            const modalConfig = {
              title: 'Confirm',
              text: 'Are you sure to stop this dashboard?'
            }

            modalService.confirmModal(modalConfig, $scope, function (response) {
              if (response) {
                $scope.currentDashboardEditingCopy.destroy().then(() => {
                  $uibModalInstance.dismiss()
                })
              }
            })
          }
        },
        clone: {
          cb: () => {
            $scope.currentDashboardEditingCopy.clone().then(() => {
              $uibModalInstance.dismiss()
            })
          }
        }
      }
    }

    // load widgets
    $http.get('modules/dashboard/widgets/widgets.json', { cache: false }).then(function (data) {
      data = data.data
      var l = data.length
      for (var i = 0; i < l; i++) {
        // hide widget if hidden is set
        if (data[i].hidden) {
          data.splice(i, 1)
          i--; l--
        } else {
          // hide widget if device || options is undefined
          if (data[i].options !== undefined && data[i].options.device !== undefined) {
            // hide widget if device is not in list
            if (data[i].options.device instanceof Array) {
              if (data[i].options.device.indexOf('*') === -1 && data[i].options.device.indexOf(deviceService.meta.machine) === -1) {
                data.splice(i, 1)
                i--; l--
              }
            } else {
              if (data[i].options.device !== '*' && data[i].options.device !== deviceService.meta.machine) {
                data.splice(i, 1)
                i--; l--
              }
            }
            // if device is fernreport or it has less than 5 monitor channels the audio interface widget needs only to be half size
            const collection = $scope.objectManager.collectionSync('audioInputPort')
            var nrAudioInputPort = 0
            for (const audioInputPort of collection) {
              if ((!Object.prototype.hasOwnProperty.call(audioInputPort.member.type, 'virtual') && audioInputPort.member.monitorLevel) || Object.prototype.hasOwnProperty.call(audioInputPort.member.type, 'geminiAnalogMic') || Object.prototype.hasOwnProperty.call(audioInputPort.member.type, 'geminiAnalogLine') || Object.prototype.hasOwnProperty.call(audioInputPort.member.type, 'geminiAes')) {
                nrAudioInputPort += 1
              }
            }
            if (data[i].id === 1 && nrAudioInputPort <= 4) {
              data[i].size = 6
            } else if (data[i].id === 1 && (nrAudioInputPort <= 6)) {
              data[i].size = 8
            } else if (data[i].id === 1) {
              data[i].size = 12
            }
          } else {
            data.splice(i, 1)
            i--; l--
          }
        }
      }

      // store remaining widgets
      $scope.widgetList = data
      $scope.storeWidgetList = angular.copy($scope.widgetList)
    })

    function createOptions (listName) {
      var options = {
        placeholder: 'app',
        connectWith: '.apps-container',
        helper: function (e, item) {
          return item
        },
        stop: function (e, ui) {
          $scope.widgetList = angular.copy($scope.storeWidgetList)
        }
      }
      return options
    }

    $scope.sortableOptionsList = [createOptions('A'), createOptions('B')]
  })
