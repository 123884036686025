import BaseClass from './Base.class'
import SipCall from './SipCall.class'
import SipAccount from './SipAccount.class'
import User from './User.class'
import Role from './Role.class'
import Dashboard from './Dashboard.class'
import Stream from './stream/Stream.class'
import AudioPort from './AudioPort.class'
import Applet from './Applet.class'
import License from './License.class'
import CallPreset from './CallPreset.class'
import PhoneBookEntry from './PhoneBookEntry.class'
import ElementPreset from './ElementPreset.class'
import Session from './Session.class'
import OnDemandJobPool from './OnDemandJobPool.class'
import OnDemandStatistics from './OnDemandStatistics.class'
import StreamGroup from './StreamGroup.class'

const classes = {
  BaseClass,
  SipCall,
  SipAccount,
  CallPreset,
  User,
  Role,
  Dashboard,
  Stream,
  AudioPort,
  Applet,
  License,
  PhoneBookEntry,
  ElementPreset,
  Session,
  OnDemandJobPool,
  OnDemandStatistics,
  StreamGroup
}

class ProxyClass {
  constructor (className, opts) {
    return new classes[className](opts)
  }
}

export default ProxyClass
