'use strict'

angular.module('webremote.service.clientInfo', []).factory('clientInfoService', function ($window) {
  class ClientInfoService {
    get screenSize () {
      const width = $window.innerWidth
      if (width < 768) return 'xs'
      if (width >= 768 && width < 992) return 'sm'
      if (width >= 992 && width < 1200) return 'md'

      return 'lg'
    }
  }

  var service = new ClientInfoService()

  return service
})
