import BaseClass from './Base.class'

export default class License extends BaseClass {
  constructor (data) {
    super(data)
  }

  get type () {
    return Object.keys(this.member.type)[0]
  }
}
