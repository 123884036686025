angular.module('webremote.connect').controller('groupEditModalCtrl',
  function ($scope, $uibModalInstance, objectManagerService, modalService) {
    $scope.om = objectManagerService

    $scope.om.object($scope.selectedGroup, 'streamGroup').then(streamGroup => {
      $scope.group = angular.copy(streamGroup)
    })

    $scope.destroyGroup = function (group) {
      if (group.member.streams.length > 0) {
        const modalConfig = {
          title: 'Confirm',
          text: 'Are you sure to delete this group and all its PIPEs?'
        }

        modalService.confirmModal(modalConfig, $scope, function (response) {
          if (response) {
            group.destroy().then(() => {
              $scope.closeModal()
            })
          }
        })
      } else {
        group.destroy().then(() => {
          $scope.closeModal()
        })
      }
    }

    $scope.closeModal = function () {
      $uibModalInstance.close()
    }
  })
