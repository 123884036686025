
angular.module('webremote.directive.objectmanager', [
  'webremote.directive.objectmanager.formElements',
  'webremote.directive.objectmanager.wizard',
  'webremote.directive.objectmanager.utilities',
  'webremote.directive.objectmanager.views'
])
require('./views/module')
require('./formElements/module')
require('./utilities/module')
require('./wizard/module')
require('./genericEditModal/controller')
require('./configform/configform')
