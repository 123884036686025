'use strict'

angular.module('webremote.service.modal', [])

  .factory('modalService', function ($uibModal, $log) {
    var service = {}

    const modalMethod = function (modalConfig, scope, callback) {
      if (scope !== undefined) {
        scope.modalConfig = modalConfig

        const modalInstance = $uibModal.open({
          templateUrl: 'services/modal/modalTemplate.html',
          controller: 'modalServiceController',
          size: modalConfig.size,
          scope: scope,
          backdrop: modalConfig.backdrop,
          keyboard: false
        })

        modalInstance.result.then(function (returnValue) {
          callback(returnValue)
        }, function () {
          callback('backdrop')
        })
      } else {
        $log.debug('[modalService]: missing scope')
      }
    }

    service.customModal = function (modalConfig, scope, callback) {
      modalMethod(modalConfig, scope, callback)
    }

    service.confirmModal = function (modalConfig, scope, callback) {
      modalConfig.size = 'sm'
      modalConfig.backdrop = false
      modalConfig.backdrop = 'static'
      modalConfig.buttons = [
        {
          title: 'Confirm',
          style: 'btn btn-warning',
          returnValue: true
        },
        {
          title: 'Cancel',
          style: 'btn btn-default',
          returnValue: false
        }
      ]

      modalMethod(modalConfig, scope, callback)
    }

    return service
  })

  .controller('modalServiceController', ['$scope', '$uibModalInstance', 'modalService', function ($scope, $uibModalInstance, modalService) {
    $scope.closeModal = function (returnValue) {
      $uibModalInstance.close(returnValue)
    }
  }])
