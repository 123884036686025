angular.module('webremote.directive.archive', [])

require('./playerToolbar/controller')
require('./fileDetails/controller')
require('./fileUpload/controller')
require('./fileViewer/controller')
require('./filePager/controller')
require('./fileNavigation/controller')
require('./fileSearch/controller')
require('./fileMultiAction/controller')
require('./fileGenericActions/controller')
