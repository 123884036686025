import FileSystemElement from './FileSystemElement'

export default class Directory extends FileSystemElement {
  static init (parent, data, $injector) {
    const result = new Directory()
    result.parent = parent
    result.$injector = $injector
    result.selected = false
    Object.assign(result, data)

    result.resolvePrettyName(result.parent.path, result.name).then(prettyName => {
      result._prettyName = prettyName
    })

    return result
  }

  get prettyName () {
    if (!this._prettyName) {
      return this.name
    } else {
      return this._prettyName
    }
  }
}
