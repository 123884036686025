angular.module('webremote.applets').controller('listAppletsController', function ($scope, $uibModal, objectManagerService, toastr, modalService) {
  $scope.objectManager = objectManagerService.GetObjectManager
  $scope.newApplet = {}

  $scope.resetNewApplet = function () {
    $scope.objectManager().getDefault('applet').then(v => {
      $scope.newApplet = angular.copy(v)
    })
  }

  $scope.createApplet = function () {
    $scope.objectManager().create('applet', $scope.newApplet).then((v) => {
      $scope.resetNewApplet()
    }, (errorData) => {
      toastr.error(errorData.message.text, 'Error', {
        closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
      })
    })
  }

  $scope.removeApplet = function (applet) {
    const modalConfig = {
      title: 'Confirm',
      text: 'Are you sure to delete this applet?'
    }

    modalService.confirmModal(modalConfig, $scope, function (response) {
      if (response) {
        applet.silent(applet.destroy)
      }
    })
  }

  $scope.objectManager().collection('applet')

  $scope.collapseStates = new Map()

  $scope.showLog = function (uuid) {
    $scope.selectedApplet = uuid
    $scope.modalInstance = $uibModal.open({
      templateUrl: 'directives/debug/journal/modalTemplate.html',
      controller: 'appletJournalController',
      size: 'lg',
      scope: $scope
    })
  }
}).controller('appletJournalController', ['$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {
  $scope.journal = {}
  $scope.journal.ids = [$scope.selectedApplet]
  $scope.journal.classes = ['applet']

  $scope.objectManager().object($scope.selectedApplet, 'applet').then((v) => {
    $scope.journal.title = v.member.name
  })

  $scope.closeModal = function () {
    $uibModalInstance.close()
  }

  $scope.resetNewApplet()
}])
