import BaseClass from './Base.class'

export default class Session extends BaseClass {
  get user () {
    var oms = this._dependencies.$injector.get('objectManagerService')
    return oms.objectSync(this.member.user_uuid, 'user')
  }

  async refresh () {
    return this._dependencies.httpService.callFunction('objects/session', '', 'HEAD')
  }
}
