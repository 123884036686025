angular.module('webremote.dashboard').controller('widget_sipDial',
  function ($scope, $uibModal, httpService, toastr, objectManagerService) {
    // init values
    $scope.storedOptions = {}
    $scope.phoneNr = ''
    $scope.objectManager = objectManagerService.getObjectManager
    $scope.regex = '[a-zA-Z0123456789+#*@_.-]*'

    $scope.objectManager().collection('session').then((v) => {
      $scope.objectManager().object(Array.from(v.values())[0].member.user_uuid, 'user').then((v) => {
        $scope.user = v
      })
    })

    $scope.$watch('activeDashboard', function () {
      $scope.objectManager().object($scope.activeDashboard, 'dashboard').then((v) => {
        $scope.dashboard = v
      })
    })

    $scope.objectManager().object($scope.activeDashboard, 'dashboard').then((v) => {
      $scope.$evalAsync(() => {
        $scope.dashboard = v

        // Wait, until widgetIndex isset
        $scope.$watch('widgetIndex', function () {
          $scope.$evalAsync(() => {
            $scope.storedOptions = $scope.dashboard.getInformationForWidget($scope.widgetIndex)
            if ($scope.storedOptions.selectedSipAccount === undefined) {
              $scope.objectManager().collection('sipAccount').then((sipAccounts) => {
                for (const sipAccount of sipAccounts.values()) {
                  if (sipAccount.member.state === 'registerOk') {
                    $scope.storeSelectedSipAccount(sipAccount)
                    break
                  }
                }
              })
            }
            if ($scope.storedOptions.selectedSipAccount !== undefined) {
              $scope.objectManager().object($scope.storedOptions.selectedSipAccount, 'sipAccount').then((v) => {
                $scope.$evalAsync(() => {
                  $scope.selSipAccObj = v
                })
              })
            }
            if ($scope.storedOptions.selectedPbE !== undefined && $scope.storedOptions.selectedPbE.length > 0) {
              $scope.phoneBookEntry = $scope.objectManager().objectSync($scope.storedOptions.selectedPbE, 'phoneBookEntry')
            }
          })
        })
      })
    })

    $scope.storeSelectedSipAccount = function (account) {
      $scope.selSipAccObj = account
      if (account.member.uuid.length > 0) {
        $scope.storedOptions.selectedSipAccount = account.member.uuid
        $scope.dashboard.storeInformationForWidget($scope.widgetIndex, JSON.stringify($scope.storedOptions))
      }
    }

    $scope.storePhoneBookEntry = function () {
      if ($scope.phoneBookEntry !== undefined && $scope.phoneBookEntry.member.uuid.length > 0) {
        $scope.storedOptions.selectedPbE = $scope.phoneBookEntry.member.uuid
        $scope.dashboard.storeInformationForWidget($scope.widgetIndex, JSON.stringify($scope.storedOptions))
      }
    }

    $scope.addDigit = function (digit) {
      $scope.phoneNr = $scope.phoneNr + digit
    }

    $scope.removeDigit = function () {
      $scope.phoneNr = $scope.phoneNr.slice(0, -1)
    }

    $scope.getPhoneBookEntry = async function () {
      return new Promise((resolve, reject) => {
        try {
          $scope.$evalAsync(async () => {
            if ($scope.phoneBookEntry === undefined || $scope.objectManager().objectSync($scope.phoneBookEntry.member.uuid, 'phoneBookEntry') === undefined) {
              const phoneBookEntry = await $scope.objectManager().getDefault('phoneBookEntry')
              const newEntry = angular.copy(phoneBookEntry)
              const pBEuuid = await $scope.objectManager().create('phoneBookEntry', newEntry, false)
              $scope.phoneBookEntry = await $scope.objectManager().object(pBEuuid, 'phoneBookEntry', true)
            }
            const phoneBookEntrys = await $scope.objectManager().collection('phoneBookEntry')
            const pBE = Array.from(phoneBookEntrys.values()).find(x => x.member.sip.account === $scope.selSipAccObj.member.uuid && x.member.sip.peerUri === '')
            $scope.phoneBookEntry.member.sip.account = pBE.member.sip.account
            $scope.phoneBookEntry.member.sip.profile = pBE.member.sip.profile
            Object.assign($scope.phoneBookEntry.member.audio, pBE.member.audio)

            $scope.phoneBookEntry.member.name = $scope.phoneNr
            $scope.phoneBookEntry.member.sip.peerUri = $scope.phoneNr
            $scope.phoneBookEntry.member.temporary = true
            $scope.phoneBookEntry.update()
            $scope.storePhoneBookEntry()
            resolve($scope.phoneBookEntry)
          })
        } catch (e) {
          reject(e)
        }
      })
    }

    $scope.startSipCall = function () {
      $scope.getPhoneBookEntry().then(() => {
        $scope.phoneBookEntry.createSipCall()
      }, function (errorData) {
        toastr.error(errorData.message.text, 'Error calling createSipCall', {
          closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
        })
      })
    }

    $scope.getSipCall = function () {
      try {
        return $scope.objectManager().objectSync($scope.phoneBookEntry.member.sip.activeCall, 'sipCall')
      } catch (e) {
        return undefined
      }
    }

    $scope.clearError = function () {
      $scope.phoneBookEntry.member.lastError = ''
    }
  })
