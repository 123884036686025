'use strict'

var io = require('socket.io-client')

angular.module('webremote.service.webSocket', []).factory('webSocketService', function ($rootScope, $location, $log, httpService, audioPlayerService) {
  $rootScope.deviceError = {
    status: {},
    message: ''
  }
  class SocketIOWrapper {
    constructor () {
      if (httpService.protocol === 'https') { this.url = 'wss://' + httpService.rootUrl } else { this.url = 'ws://' + httpService.rootUrl }

      this.fallbackEnabled = true

      this.connect()
    }

    connect () {
      this.socket = io(this.url, {
        path: '/webapi/socket.io',
        transports: this.getTransportMode(),
        upgrade: this.getTransportModeUpgrade()
      })
    }

    getTransportMode () {
      const defaultValue = ['polling', 'websocket']
      if (window.sessionStorage.getItem('transportMode') !== null && window.sessionStorage.getItem('transportMode').length > 0) {
        try {
          return Array.from(window.sessionStorage.getItem('transport'))
        } catch (e) {
          return defaultValue
        }
      } else {
        return defaultValue
      }
    }

    getTransportModeUpgrade () {
      if (window.sessionStorage.getItem('transportModeUpgrade') !== null) {
        return window.sessionStorage.getItem('transportModeUpgrade') === 'true'
      } else {
        return true
      }
    }

    on (event, callback) {
      if (callback === undefined) return
      this.socket.on(event, callback)
    }

    off (event, callback) {
      this.socket.removeListener(event, callback)
    }

    emit (event, data, callback) {
      this.socket.emit(event, data, function () {
        var args = arguments
        $rootScope.$evalAsync(function () {
          if (callback) {
            callback.apply(null, args)
          }
        })
      })
    }

    EnableFallback (value) {
      this.fallbackEnabled = value
    }
  }

  var service = {}

  service = new SocketIOWrapper()
  service.userReload = false

  // Handler for Page-Reload / Page-Exit
  window.onbeforeunload = function () {
    service.userReload = true
  }

  // Heartbeat
  service.on('pong', (v) => {
    // Log last ping
    $rootScope.deviceError.lastPing = v
  })

  service.evaluateError = function (e) {
    if (e.description !== 401 && service.fallbackEnabled) {
      $rootScope.deviceError.status.user = false
      $rootScope.deviceError.status.ethernet = true
      $rootScope.deviceError.status.codec = true
      $rootScope.deviceError.message = 'WebSocket Connection lost.'

      // Hide audio player
      try {
        audioPlayerService.control.close()
      } catch (e) {}

      // Don't show error page, if disconnect is triggered by page reload
      if (!service.userReload) {
        $location.path('/fallback')
      }
    }
  }

  // Navigate to Fallback, if error occured (except 401)
  service.on('connect_error', service.evaluateError)
  service.on('connect_timeout', service.evaluateError)
  service.on('disconnect', service.evaluateError)
  service.on('error', service.evaluateError)

  return service
})
