angular.module('webremote.settings').controller('webremote.settings.licenses.controller',
  function ($scope, httpService, toastr, objectManagerService) {
    $scope.licenseFile = {}
    $scope.unlics = []
    $scope.objectManager = objectManagerService.getObjectManager()

    $scope.lics = $scope.objectManager.collectionSync('license')
    $scope.objectManager.getScheme('license').then((v) => {
      $scope.scheme = v
      $scope.$evalAsync()
    })

    $scope.systemConfig = $scope.objectManager.objectSync('d7da1bf7-284d-4b6b-a3bc-34791a050bc3', 'systemConfig')

    $scope.uploadLicense = function () {
      $scope.objectManager.callMethod('importLicense', { data: $scope.licenseFile.base64.replace('data:application/octet-stream;base64,', '') }, { notifications: true }).then(function (serviceData) {
        $scope.licenseFile = {}
        $scope.lics = $scope.objectManager.collectionSync('license')
        $scope.$evalAsync()
      })
    }

    $scope.triggerNetlicense = function () {
        $scope.objectManager.callMethod('netlicenseTrigger', {}, { notifications: true })
    }

    $scope.getLicensePrettyName = function (key) {
      return $scope.scheme.type.fields[key].prettyName
    }

    $scope.$watch('[scheme, lics]', function () {
      $scope.unlics = []
      if ($scope.scheme !== undefined && $scope.lics !== undefined) {
        for (const val in $scope.scheme.type.fields) {
          found = false
          for (const val2 of $scope.lics.entries()) {
            if (Object.prototype.hasOwnProperty.call(val2[1].member.type, val)) {
              found = true
            }
          }
          if($scope.scheme.type.fields[val].hidden) {
            found = true
          }
          if (!found) {
            $scope.unlics.push({
              type: val,
              prettyName: $scope.scheme.type.fields[val].prettyName
            })
          }
        }
      }
    })
  })
