const module = angular.module('webremote.directive.objectmanager.wizard')

module.controller('webremote.directive.objectmanager.wizard.pagination.controller', ['$scope', 'objectManagerService', 'httpService', '$uibModal', function ($scope, objectManagerService, httpService, $uibModal) {
  $scope.previousButton = {
    disabled: false,
    text: 'Previous',
    class: 'btn btn-xs btn-default',
    action: () => {
      if (
        $scope.pointer.subMenuIndex > 0
      ) {
        $scope.pointer.subMenuIndex--
      } else {
        $scope.pointer.mainMenuIndex--
        $scope.pointer.subMenuIndex = $scope.getSubItemQuantity($scope.menuConfig[$scope.pointer.mainMenuIndex])
      }
    }
  }

  $scope.nextButton = {
    disabled: false,
    text: 'Next',
    class: 'btn btn-xs btn-default',
    action: () => {
      if (
        $scope.getSubItemQuantity($scope.menuConfig[$scope.pointer.mainMenuIndex]) > 0 &&
        $scope.pointer.subMenuIndex < $scope.getSubItemQuantity($scope.menuConfig[$scope.pointer.mainMenuIndex])
      ) {
        $scope.pointer.subMenuIndex++
      } else {
        $scope.pointer.mainMenuIndex++
        $scope.pointer.subMenuIndex = 0
      }
    }
  }

  $scope.getSubItemQuantity = (mainMenuItem) => {
    try {
      if (Object.keys(mainMenuItem.subMenu)[0] === 'items') {
        return mainMenuItem.subMenu.items.length
      }

      if (Object.keys(mainMenuItem.subMenu)[0] === 'genericList') {
        return require('object-path').get($scope.object.member, mainMenuItem.subMenu.genericList.content.configForm.path.replace(/\.fields/g, '').replace(/\.elementType/g, '')).length
      }
    } catch (e) {
      return 0
    }
  }

  $scope.$watch('pointer', (newValue) => {
    // Check if the back button should be deactivated
    if ($scope.pointer.mainMenuIndex === 0 && $scope.pointer.subMenuIndex === 0) {
      $scope.previousButton.disabled = true
    } else {
      $scope.previousButton.disabled = false
    }

    // Check if the next button should be deactivated
    if (
      Array.isArray($scope.menuConfig) &&
      $scope.pointer.mainMenuIndex === ($scope.menuConfig.length - 1) &&
      $scope.pointer.subMenuIndex === $scope.getSubItemQuantity($scope.menuConfig[$scope.menuConfig.length - 1])
    ) {
      $scope.nextButton.disabled = true
    } else {
      $scope.nextButton.disabled = false
    }
  }, true)
}])

  .directive('omWizardPagination', function () {
    return {
      restrict: 'E',
      scope: {
        menuConfig: '<menuConfig',
        pointer: '=pointer',
        object: '<object'
      },
      controller: 'webremote.directive.objectmanager.wizard.pagination.controller',
      templateUrl: 'directives/objectmanager/wizard/pagination/template.html'
    }
  })
