angular.module('webremote.dashboard').controller('widget_liveAudio',
  function ($scope, toastr, objectManagerService, $uibModal) {
    $scope.om = objectManagerService

    $scope.$watch('activeDashboard', function () {
      $scope.om.object($scope.activeDashboard, 'dashboard').then((v) => {
        $scope.$evalAsync(() => {
          $scope.dashboard = v
        })
      })
    })

    $scope.om.object($scope.activeDashboard, 'dashboard').then((v) => {
      $scope.$evalAsync(() => {
        $scope.dashboard = v

        // Wait, until widgetIndex is set
        $scope.$watch('widgetIndex', function () {
          $scope.loadStoredOptions($scope.widgetIndex)
        })
      })
    })

    $scope.call = function () {
      const pbe = $scope.om.objectSync($scope.phonebookEntry, 'phoneBookEntry')
      pbe.createSipCall()
    }

    $scope.toogleStream = function (streamId) {
      const stream = $scope.om.objectSync(streamId, 'stream')
      if (stream.member.state === 'playing') {
        stream.member.pendingState = 'inactive'
        stream.update()
      } else {
        stream.member.pendingState = 'playing'
        stream.update()
      }
    }

    $scope.getClassByState = function (streamId) {
      const stream = $scope.om.objectSync(streamId, 'stream')
      if (stream !== undefined && stream.member.state === 'playing') {
        return 'onair-flashing'
      }
      return ''
    }

    $scope.$watch('onAirStream', function (newValue) {
      if (newValue !== undefined) {
        $scope.saveStoredOptions()
      }
    })
    $scope.$watch('recordingStream', function (newValue) {
      if (newValue !== undefined) {
        $scope.saveStoredOptions()
      }
    })
    $scope.$watch('phonebookEntry', function (newValue) {
      if (newValue !== undefined) {
        $scope.saveStoredOptions()
      }
    })

    $scope.loadStoredOptions = function (widgetIndex) {
      $scope.storedOptions = $scope.dashboard.getInformationForWidget(widgetIndex)
      // Default: Set onAirStream to 'md'
      if ($scope.storedOptions.onAirStream === undefined) {
        $scope.storedOptions.onAirStream = ''
      }

      // Default: Set recordingStream to false
      if ($scope.storedOptions.recordingStream === undefined) {
        $scope.storedOptions.recordingStream = ''
      }

      // Default: Set phonebookEntry to empty array
      if ($scope.storedOptions.phonebookEntry === undefined) {
        $scope.storedOptions.phonebookEntry = ''
      }

      $scope.onAirStream = $scope.storedOptions.onAirStream
      $scope.recordingStream = $scope.storedOptions.recordingStream
      $scope.phonebookEntry = $scope.storedOptions.phonebookEntry

      $scope.saveStoredOptions()
    }

    $scope.saveStoredOptions = function () {
      if ($scope.storedOptions !== undefined) {
        $scope.storedOptions.onAirStream = $scope.onAirStream
        $scope.storedOptions.recordingStream = $scope.recordingStream
        $scope.storedOptions.phonebookEntry = $scope.phonebookEntry

        $scope.dashboard.storeInformationForWidget($scope.widgetIndex, JSON.stringify($scope.storedOptions))
      }
    }
  })
