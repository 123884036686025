
angular.module('webremote.directive.objectmanager.views')
  .controller('webremote.directive.objectmanager.views.manager.controller', ['$scope', 'objectService', 'httpService', '$uibModal', function ($scope, objectService, httpService, $uibModal) {

  }])

  .directive('omView', function () {
    return {
      restrict: 'E',
      transclude: {
        title: '?omViewTitle',
        content: 'omViewContent',
        icon: '?omViewIcon'
      },
      scope: {
        omViewSchema: '=omViewSchema'
      },
      templateUrl: 'directives/objectmanager/views/manager/template.html'
    }
  })

require('../default/controller')
require('../nested/controller')
require('../collapse/controller')
