
angular.module('webremote.module.ondemand').factory('onDemandRouterService', function ($location) {
  class OnDemandRouterService {
    constructor () {
      this._activeObject = ''
      this.createObject = false
    }

    get activeObject () {
      return this._activeObject
    }

    set activeObject (v) {
      this._activeObject = v
      return this._activeObject
    }

    editObject (v) {
      this.activeObject = v
      $location.url('/ondemand/wizard')
    }

    create () {
      this.activeObject = ''
      $location.url('/ondemand/wizard')
    }
  }

  return new OnDemandRouterService()
})
