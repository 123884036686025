angular.module('webremote.settings').controller('webremote.settings.archiveUploader.controller',
  function ($scope, $uibModal, toastr, $document, modalService, objectManagerService) {
    $scope.viewLevel = { levelArray: [] }
    $scope.selectedArchiveUploader = {}
    $scope.objectManager = objectManagerService.getObjectManager()
    $scope.schema = {}

    $scope.objectManager.getScheme('archiveUploader').then((schema) => {
      $scope.$evalAsync(function () {
        $scope.schema = schema
      })
    })

    $scope.newArchiveUploader = function () {
      $scope.objectManager.createDefaultObject('archiveUploader').then(obj => {
        $scope.$evalAsync(function () {
          $scope.selectedArchiveUploader = obj
          $scope.scrollToTop()
        })
      })
    }

    $scope.selectArchiveUploader = function (uuid) {
      $scope.objectManager.object(uuid, 'archiveUploader').then(archiveUploader => {
        $scope.$evalAsync(function () {
          $scope.selectedArchiveUploader = angular.copy(archiveUploader)
        })
      })
    }

    $scope.createAction = {
      cb: () => {
        $scope.objectManager.create('archiveUploader', $scope.selectedArchiveUploader.member).then(v => {
          $scope.newArchiveUploader()
          $scope.scrollToTop()
          toastr.success('ArchiveUploader added.', 'Success', {
            closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
          })
        }, (errorData) => {
          toastr.error(errorData.message.text, 'Error', {
            closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
          })
        })
      }
    }

    $scope.destroyAction = {
      cb: () => {
        const modalConfig = {
          title: 'Confirm',
          text: 'Are you sure to delete this ArchiveUploader?'
        }

        modalService.confirmModal(modalConfig, $scope, function (response) {
          if (response) {
            $scope.selectedArchiveUploader.destroy()
            $scope.newArchiveUploader()
          }
        })
      }
    }

    $scope.updateAction = {
      cb: () => {
        $scope.selectedArchiveUploader.update()
        $scope.scrollToTop()
      }
    }

    $scope.cloneAction = {
      cb: () => {
        $scope.selectedArchiveUploader.clone()
        $scope.scrollToTop()
      }
    }

    $scope.scrollToTop = function () {
      $document.scrollTopAnimated(0, 400)
    }

    $scope.newArchiveUploader()
  })
