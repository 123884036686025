var WaveSurfer = require('wavesurfer.js')

angular.module('webremote.archive').controller('webremote.archive.analyzer.controller',
  function ($scope, $uibModalInstance, httpService, fileService) {
    window.AudioContext = window.AudioContext || window.webkitAudioContext || window.mozAudioContext
    $scope.loading = true
    $scope.playBackRate = 1
    $scope.currentTimeMark = 0.000
    $scope.audioElement = undefined
    $scope.canvasElement = undefined
    $scope.sourceFile = ''
    $scope.playing = false
    $scope.state = {}
    $scope.state.filter = 'none'
    $scope.state.pixelPerSecond = 10
    $scope.refreshIntervalRef = undefined

    $scope.fileExtension = $scope.file.name.substr(($scope.file.name.lastIndexOf('.') + 1))

    $scope.zoomIn = function () {
      $scope.wavesurfer.toggleScroll()
      $scope.state.pixelPerSecond = $scope.state.pixelPerSecond + 20
      $scope.wavesurfer.zoom($scope.state.pixelPerSecond)
    }

    $scope.zoomOut = function () {
      $scope.wavesurfer.toggleScroll()
      if ($scope.state.pixelPerSecond >= 20) { $scope.state.pixelPerSecond = $scope.state.pixelPerSecond - 20 }
      $scope.wavesurfer.zoom($scope.state.pixelPerSecond)
    }

    $scope.setFilter = function () {
      if ($scope.state.filter === 'lowpass') {
        const lowpass = $scope.wavesurfer.backend.ac.createBiquadFilter()
        lowpass.type = 'lowpass'
        $scope.wavesurfer.backend.setFilter(lowpass)
      }

      if ($scope.state.filter === 'highpass') {
        const highpass = $scope.wavesurfer.backend.ac.createBiquadFilter()
        highpass.type = 'highpass'
        highpass.frequency.value = 5000
        $scope.wavesurfer.backend.setFilter(highpass)
      }

      if ($scope.state.filter === 'none') {
        $scope.wavesurfer.backend.setFilter()
      }
    }

    window.setTimeout(() => {
      $scope.wavesurfer = WaveSurfer.create({
        container: '#waveform',
        waveColor: '#83c9ba',
        scrollParent: true,
        responsive: true,
        progressColor: '#2ea389',
        hideScrollbar: true
      })

      $scope.wavesurfer.load($scope.getFilePath())

      $scope.wavesurfer.on('play', function () {
        $scope.playing = true
      })

      $scope.wavesurfer.on('pause', function () {
        $scope.playing = false
      })

      $scope.wavesurfer.on('finish', function () {
        $scope.playing = false
      })

      $scope.wavesurfer.on('error', function (e) {
        $scope.error = true
        $scope.loading = false
      })

      $scope.wavesurfer.on('loading', function (e) {
        $scope.progress = { width: e + '%' }
      })

      $scope.wavesurfer.on('ready', function () {
        $scope.loading = false
        $scope.wavesurfer.play()
      })

      $scope.refreshIntervalRef = window.setInterval(function () {
        $scope.currentTimeMark = $scope.wavesurfer.getCurrentTime().toFixed(2)
        $scope.$evalAsync()
      }, 70)
    }, 500)

    $scope.getFilePath = function () {
      var file = $scope.file
      return encodeURI(httpService.webapiUrl + 'archive/' + fileService.encodePath(file.parent.path) + '/' + file.name)
    }

    $scope.setAudio = function () {
      $scope.audioElement = angular.element(document).find('audio')[0]
      $scope.audioElement.volume = 0.5

      if ($scope.canvasElement) {
        $scope.playAudio()
      }
    }

    $scope.playAudio = function () {
      $scope.sourceFile = $scope.getFilePath()
    }

    // modal Functions
    $scope.checkKey = function (keyEvent) {
      if (keyEvent.which === 13) { $scope.closeModal() }
    }

    $scope.closeModal = function () {
      clearInterval($scope.refreshIntervalRef)
      $scope.wavesurfer.destroy()
      $uibModalInstance.close()
    }

    $scope.dismissModal = function () {
      clearInterval($scope.refreshIntervalRef)
      $scope.wavesurfer.destroy()
      $uibModalInstance.dismiss()
    }

    $scope.$on('modal.closing', function (event, reason, closed) {
      clearInterval($scope.refreshIntervalRef)
      $scope.wavesurfer.destroy()
    })
  })
