
angular.module('webremote.directive.streamGroups.pipepicker', [])
  .controller('webremote.directive.streamGroups.pipepicker.controller', function ($scope, $uibModal) {
    $scope.choosePipe = function () {
      const searchedPipes = $scope.pipePickerStreamgroup().streamsWithElement($scope.pipePickerElementCategory, $scope.pipePickerElementType)

      // If only one pipe is existing, choose directly
      if (searchedPipes.length === 1) {
        if ($scope.pipePickerCallback !== undefined) {
          $scope.pipePickerCallback()({ ...$scope.pipePickerCallbackArgs, uuid: searchedPipes[0].member.uuid })
        }
      // Otherwise open modal to select the pipe
      } else if (searchedPipes.length > 1) {
        $scope.searchedPipes = searchedPipes
        $scope.modalInstance = $uibModal.open({
          templateUrl: 'directives/streamGroups/pipepicker/modal/template.html',
          controller: 'modals.pipepicker.controller',
          size: 'sm',
          scope: $scope
        })

        $scope.modalInstance.result.then(function (uuid) {
          if ($scope.pipePickerCallback !== undefined) {
            $scope.pipePickerCallback()({ ...$scope.pipePickerCallbackArgs, uuid: uuid })
          }
        }, function (errorData) {
          return ''
        })
      }
    }
  }).directive('pipePicker', function () {
    return {
      restrict: 'A',
      scope: {
        pipePickerElementCategory: '@',
        pipePickerElementType: '@',
        pipePickerCallback: '&',
        pipePickerCallbackArgs: '<',
        pipePickerStreamgroup: '&'
      },
      controller: 'webremote.directive.streamGroups.pipepicker.controller',
      link: function (scope, element, attrs) {
        $(element).on('click', function (e) {
          scope.choosePipe()
        })
      }
    }
  })

require('./modal/controller')
