export default class LevelMeter {
  constructor (channel) {
    // Initialize LevelMeter Components
    this.active = true
    this.level = 0.0
    this.levelPercent = 0.0
    this.levelDecayPercent = 0.0
    this.blocked = false
    this.blocked_verbose = ''
    this.channel = channel

    this.activate = this.activate.bind(this)
    this.deactivate = this.deactivate.bind(this)
  }

  update (data, decayData) {
    if (this.active) {
      var peakDbMapped = (Math.round(parseFloat(data) * 10) / 10)
      var decayDbMapped = (Math.round(parseFloat(decayData) * 10) / 10)

      if (this.blocked === 'true') { this.blocked_verbose = 'ON HOLD' } else { this.blocked_verbose = '' }

      this.level = peakDbMapped
      this.levelPercent = ((peakDbMapped + 80.0) / 80) * 100
      this.levelPercent = this.levelPercent > 100 ? 100 : this.levelPercent < 1 ? 1 : this.levelPercent
      this.levelDecayPercent = ((decayDbMapped + 80.0) / 80) * 100
      this.levelDecayPercent = this.levelDecayPercent > 100 ? 100 : this.levelDecayPercent < 1 ? 1 : this.levelDecayPercent
    }
  }

  deactivate () {
    this.active = false
    this.levelPercent = 0.00
    this.levelDecayPercent = 0.00
    this.level = 0.0
  }

  activate () {
    this.active = true
  }
}
