angular.module('webremote.debug').controller('DebugTabController',
  function ($scope, $http) {
    $scope.tabs = [
      { title: 'ObjectClasses', path: 'modules/debug/tabs/objectClasses.html', hidden: false },
      { title: 'MethodClasses', path: 'modules/debug/tabs/methodClasses.html', hidden: false }
    ]

    $scope.changeTab = function (index) {
      if (!$scope.tabs[index].hidden) {
        $scope.path = $scope.tabs[index].path
      }
    }
  }
)
