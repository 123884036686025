'use strict'

angular.module('webremote.directive.rightsManagement.uac', [])
  .directive('uac', function ($compile, $log, objectManagerService) {
    // uac-action='disable'
    // uac-objects='[{"object": "stream", "rights": ["create"]}]'
    // uac-methods='["reboot"]'
    // uac-licenses='["smartControl"]'
    return {
      restrict: 'A',
      scope: {},
      link: function (scope, element, attributes) {
        attributes.$observe('uacObjects', () => {
          scope.evaluate()
        })

        attributes.$observe('uacMethods', () => {
          scope.evaluate()
        })

        attributes.$observe('uacLicenses', () => {
          scope.evaluate()
        })

        scope.evaluate = function () {
        // Compile Function, which in handle render options
          scope.compileFunc = function (displayType, value) {
            if ((displayType === 'hide' && value) || scope.$evalAsync(attributes.ngHide)) {
              element.addClass('uac-hidden')
            } else {
              element.removeClass('uac-hidden')
            }

            if (displayType === 'disable') {
              if (value) {
                element.attr('disabled', true)
              } else {
                element.attr('disabled', false)
              }
            }

            return function (scope) {
              $compile(element)(scope)
            }
          }

          scope.action = attributes.uacAction === undefined ? scope.action = 'hide' : scope.action = attributes.uacAction

          try {
            scope.objects = JSON.parse(attributes.uacObjects)
          } catch (e) {
            scope.objects = []
          }
          try {
            scope.methods = JSON.parse(attributes.uacMethods)
          } catch (e) {
            scope.methods = []
          }

          try {
            scope.licenses = JSON.parse(attributes.uacLicenses)
          } catch (e) {
            scope.license = []
          }

          // Get UserId
          objectManagerService.getObjectManager().object('5ff1c07e-5cd7-4d94-94ef-79a28bb189e4', 'session').then(v => {
            scope.userUuid = v.member.user_uuid

            // Get userRoles
            objectManagerService.getObjectManager().object(scope.userUuid, 'user').then(user => {
              objectManagerService.getObjectManager().collection('license').then(licenses => {
                scope.userRoles = user.member.roles === undefined ? [] : user.member.roles

                // Manipulate UI, if user is not root
                if (user.member.uuid !== 'a79b9852-506f-48ad-8ccb-d268197e0266') {
                  objectManagerService.getObjectManager().collection('role').then(v => {
                    scope.roles = v

                    // Disable Element?
                    let result = true

                    // Loop through objects, if array size > 0
                    if (scope.objects && scope.objects.length > 0) {
                      for (const i in scope.objects) {
                        // Loop through rights for object
                        for (const right of scope.objects[i].rights) {
                          // Loop through userRoles
                          for (const uuid of scope.userRoles) {
                            if (scope.roles.has(uuid) && scope.roles.get(uuid).checkPermissionForObject(right, scope.objects[i].object)) {
                              result = false
                            }
                          }
                        }
                      }
                    }

                    // Loop through methods, if array size > 0
                    if (scope.methods && scope.methods.length > 0) {
                      for (const method of scope.methods) {
                      // Loop through userRoles
                        for (const uuid of scope.userRoles) {
                          if (scope.roles.has(uuid) && scope.roles.get(uuid).checkPermissionForMethod(method)) {
                            result = false
                          }
                        }
                      }
                    }

                    // Loop through licenses, if array size > 0

                    if (scope.licenses && scope.licenses.length > 0) {
                      let licenseFound = false
                      for (const license of scope.licenses) {
                      // Loop through activeLicenses
                        for (const activeLicense of Array.from(licenses.values())) {
                          if (license === Object.keys(activeLicense.member.type)[0] && activeLicense.member.type[Object.keys(activeLicense.member.type)[0]].instances > 0) {
                            licenseFound = true
                            break
                          }
                        }
                      }

                      result = !licenseFound
                    }

                    return scope.compileFunc(scope.action, result)
                  })
                } else {
                  return scope.compileFunc(scope.action, false)
                }
              }, (error) => {
                $log.error('[webremote.directive.rightsManagement.uac]', 'Can\'t get user:', error)
                return scope.compileFunc(scope.action, true)
              })
            }, (error) => {
              $log.error('[webremote.directive.rightsManagement.uac]', 'Can\'t get licenses:', error)
              return scope.compileFunc(scope.action, true)
            })
          }, (error) => {
            $log.error('[webremote.directive.rightsManagement.uac]', 'Can\'t get session:', error)
            return scope.compileFunc(scope.action, true)
          })
        }

        scope.evaluate()
      }
    }
  })
