const module = angular.module('webremote.directive.onDemand')

module.controller('webremote.directive.onDemand.onDemandJobPool.itemOverview.controller', ['$scope', 'objectManagerService', '$uibModal', 'modalService', 'onDemandRouterService', function ($scope, objectManagerService, $uibModal, modalService, onDemandRouterService) {
  $scope.om = objectManagerService
  $scope.onDemandRouterService = onDemandRouterService
  $scope.advancedView = false

  $scope.borderColor = () => {
    function hexToRGB (hex, alpha) {
      var r = parseInt(hex.slice(1, 3), 16)
      var g = parseInt(hex.slice(3, 5), 16)
      var b = parseInt(hex.slice(5, 7), 16)

      if (alpha) {
        return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')'
      } else {
        return 'rgb(' + r + ', ' + g + ', ' + b + ')'
      }
    }

    return hexToRGB($scope.object.member.displayColor, 0.4)
  }

  $scope.statistics = () => {
    try {
      return $scope.om.objectSync($scope.object.member.statistics, 'onDemandStatistics')
    } catch (e) {
      return {}
    }
  }

  $scope.currentJobs = () => {
    try {
      return $scope.om.objectSync($scope.object.member.statistics, 'onDemandStatistics').member.currentJobs.length
    } catch (e) {
      return 0
    }
  }

  $scope.connectedDevices = () => {
    try {
      return $scope.object.tooltip.groupSync.hwids.length
    } catch (e) {
      return 0
    }
  }

  $scope.forceStop = function (item) {
    const modalConfig = {
      title: 'Confirm',
      text: 'Are you sure to force stop ' + item.member.name + '?'
    }

    modalService.confirmModal(modalConfig, $scope, function (response) {
      if (response) {
        item.disable(true)
      }
    })
  }

  $scope.handlerKeyDown = function (e) {
    // if shift key, enable advanced mode
    if (e.keyCode === 16) {
      $scope.advancedView = true
      $scope.$digest()
    }
  }

  $scope.handlerKeyUp = function (e) {
    // if shift key, disable advanced mode
    if (e.keyCode === 16) {
      $scope.advancedView = false
      $scope.$digest()
    }
  }

  $scope.showLog = function () {
    $scope.modalInstance = $uibModal.open({
      templateUrl: 'directives/debug/journal/modalTemplate.html',
      controller: 'logModalController',
      size: 'lg',
      scope: $scope
    })
  }

  var $doc = angular.element(document)

  $doc.on('keydown', $scope.handlerKeyDown)
  $doc.on('keyup', $scope.handlerKeyUp)
}]).directive('ondemandItemOverview', function () {
  return {
    restrict: 'E',
    controller: 'webremote.directive.onDemand.onDemandJobPool.itemOverview.controller',
    scope: {
      object: '<'
    },
    templateUrl: 'directives/onDemand/onDemandJobPool/itemOverview/template.html'
  }
}).controller('logModalController', function ($scope, $uibModalInstance) {
  $scope.journal = {}
  $scope.journal.title = $scope.object.member.name
  $scope.journal.ids = [$scope.object.member.uuid]
  $scope.journal.classes = ['onDemandJobPool']

  $scope.closeModal = function () {
    $uibModalInstance.close()
  }
})
