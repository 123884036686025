
angular.module('webremote.directive.archive').controller('webremote.directive.archive.fileViewer.controller', function ($scope, fileService, webSocketService) {
  $scope.createNewFolder = false
  $scope.layout = 'list'

  $scope.openDirectory = (directory) => {
    // Navigation
    $scope.navigation.path.push(directory)

    // Reset states
    $scope.createNewFolder = false
    if ($scope.upload) { $scope.upload.queue = [] }
    $scope.sortOptions.page = 1

    // Trigger
    $scope.onDirectorySelect({ directory: directory })
  }

  $scope.returnToParent = () => {
    $scope.navigation.path.pop()
    $scope.createNewFolder = false
    $scope.onFileDeselect()
    if ($scope.upload) { $scope.upload.queue = [] }
  }

  $scope.getActivePath = () => {
    fileService.files(fileService.encodePath($scope.navigation.path.map(x => x.name)), $scope.sortOptions).then(data => {
      $scope.$evalAsync(() => {
        $scope.navigation.currentPath = data
      })
    })
  }

  $scope.createDirectory = (name) => {
    fileService.createDirectory($scope.navigation.path.map(x => x.name), name).then(() => {
      $scope.createNewFolder = false
    })
  }

  $scope.getActivePath()

  $scope.$watch('sortOptions', () => {
    $scope.getActivePath()
  }, true)

  $scope.$watchCollection('navigation.path', () => {
    $scope.sortOptions.page = 1
    $scope.getActivePath()
  })

  $scope.isHighlighted = (filePath) => {
    return $scope.navigation.highlightedItems.includes(filePath)
  }

  $scope.evaluatePathUpdate = (data) => {
    if (data.path + '/' === fileService.encodePath($scope.navigation.currentPath.path) || data.path === fileService.encodePath($scope.navigation.currentPath.path)) {
      $scope.getActivePath()
    }
  }

  webSocketService.on('DirectoryUpdate', $scope.evaluatePathUpdate)

  $scope.$on('$destroy', function () {
    webSocketService.off('DirectoryUpdate', $scope.evaluatePathUpdate)
  })
}).directive('fileViewer', function () {
  return {
    restrict: 'E',
    scope: {
      navigation: '=',
      sortOptions: '=',
      uiOptions: '=',
      upload: '=',
      onFileOpen: '&',
      onFileDeselect: '&',
      onDirectorySelect: '&'
    },
    templateUrl: 'directives/archive/fileViewer/template.html'
  }
})
