angular.module('webremote.connect').controller('groupCreateModalCtrl',
  function ($scope, $uibModalInstance, objectManagerService) {
    $scope.specialFieldScheme = {}
    $scope.groupType = 'none'
    $scope.specialValue = {}
    $scope.om = objectManagerService

    $scope.om.getDefault('streamGroup').then(v => {
      $scope.newGroup = angular.copy(v)

      if ($scope.createStreamGroupOptions.templateGroup) {
        $scope.newGroup.tags.push('template')
      }
    })

    $scope.createGroup = async function () {
      $scope.om.create('streamGroup', $scope.newGroup, true).then(result => {
        if (result.length > 0) $uibModalInstance.close()
      })
    }

    $scope.closeModal = function () {
      $uibModalInstance.close()
    }
  })
