
angular.module('webremote.settings').controller('webremote.settings.callProfiles.controller',
  function ($scope, toastr, $document, $timeout, modalService, objectManagerService) {
    $scope.viewLevel = { levelArray: [] }
    $scope.selectedCallProfile = {}
    $scope.objectManager = objectManagerService.getObjectManager()
    $scope.schema = {}

    $scope.objectManager.getScheme('callProfile').then((schema) => {
      $scope.$evalAsync(function () {
        $scope.schema = schema
      })
    })

    $scope.newCallProfile = function () {
      $scope.objectManager.createDefaultObject('callProfile').then(obj => {
        $scope.$evalAsync(() => {
          $scope.selectedCallProfile = obj
        })
      })
    }

    $scope.selectCallProfile = function (uuid) {
      $scope.objectManager.object(uuid, 'callProfile').then(callProfile => {
        $scope.$evalAsync(function () {
          $scope.selectedCallProfile = angular.copy(callProfile)
        })
      })
    }

    $scope.createAction = {
      cb: () => {
        $scope.objectManager.create('callProfile', $scope.selectedCallProfile.member).then(v => {
          $scope.newCallProfile()
          $scope.scrollToTop()
          toastr.success('Call Profile added.', 'Success', {
            closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
          })
        }, (errorData) => {
          toastr.error(errorData.message.text, 'Error', {
            closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
          })
        })
      }
    }

    $scope.destroyAction = {
      cb: () => {
        const modalConfig = {
          title: 'Confirm',
          text: 'Are you sure to delete this call profile?'
        }

        modalService.confirmModal(modalConfig, $scope, function (response) {
          if (response) {
            $scope.selectedCallProfile.destroy()
            $scope.newCallProfile()
          }
        })
      }
    }

    $scope.updateAction = {
      cb: () => {
        $scope.selectedCallProfile.update()
      }
    }

    $scope.cloneAction = {
      cb: () => {
        $scope.selectedCallProfile.clone()
      }
    }

    $scope.scrollToTop = function () {
      $document.scrollTopAnimated(0, 400)
    }

    $scope.valid = () => {
      $scope.updateAction.disabled = false
      $scope.cloneAction.disabled = false
      $scope.createAction.disabled = false
    }

    $scope.invalid = () => {
      $scope.updateAction.disabled = true
      $scope.cloneAction.disabled = true
      $scope.createAction.disabled = true
    }

    $scope.newCallProfile()
  })
