
angular.module('webremote.directive.objectmanager.formElements')
  .controller('webremote.directive.objectmanager.formElements.channelSelector.createVirtualPort.controller',
    ['$scope', 'toastr', 'objectManagerService', function ($scope, toastr, objectManagerService) {
      $scope.objectManager = objectManagerService.getObjectManager()
      $scope.mono = false

      $scope.instantiatePortDefault = function () {
        $scope.objectManager.getDefault('audioOutputPort').then((v) => {
          $scope.$evalAsync(() => {
            $scope.newVirtualPort = angular.copy(v)
            $scope.newVirtualPort.type = { virtual: {} }
          })
        })
      }

      $scope.instantiatePortDefault()

      $scope.getChannels = function () {
        if ($scope.mono) {
          return { mono: {} }
        } else {
          return { stereo: {} }
        }
      }

      $scope.createPort = function () {
        // Get channels struct
        $scope.newVirtualPort.channels = $scope.getChannels()
        $scope.newVirtualPort.card = $scope.soundcard
        // Create port
        $scope.objectManager.create('audioOutputPort', $scope.newVirtualPort).then((v) => {
          toastr.success('Virtual port created.', 'Success', {
            closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
          })
          $scope.instantiatePortDefault()
        }, (errorData) => {
          toastr.error(errorData.message.text, 'Error', {
            closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
          })
        })
      }
    }])

  .directive('channelSelectorCreateVirtualPort', function () {
    return {
      restrict: 'E',
      transclude: 'true',
      scope: {
        soundcard: '=soundcard'
      },
      templateUrl: 'directives/objectmanager/formElements/channelSelector/createVirtualPort/template.html'
    }
  })
