'use strict'

angular.module('webremote.directive.archive', []).controller('webremote.directive.archive.playerToolbar.controller', function ($scope, audioPlayerService) {
  $scope.audioPlayerService = audioPlayerService

  $scope.setAudio = function (objectPl) {
    $scope.audioPlayerService.control.setAudioElement(angular.element(document).find('audio')[0])
  }

  $('.progress').first().mousedown(function (e) {
    // Disable seeking for live streams
    if (!$scope.audioPlayerService.control.isLivestream()) {
      var perc = e.offsetX / $(this).width() * 100
      const timestamp = $scope.audioPlayerService.control.audioElement.duration / 100 * parseInt(perc)
      $scope.audioPlayerService.control.seekTo(timestamp)
    }
  })

  $scope.formatTime = function (time) {
    let min = Math.floor(Math.floor(time) / 60)
    let sec = Math.floor(time) - (min * 60)

    if (sec < 10) {
      sec = '0' + sec
    }

    if (min < 10) {
      min = '0' + min
    }

    if (isNaN(min))min = '-'
    if (isNaN(sec))sec = '-'
    return min + ':' + sec
  }

  $scope.formatBitrate = function (value) {
    if (isNaN(Math.round(value / 1000))) {
      return '-'
    } else {
      return Math.round(value / 1000) + ' kbit/s'
    }
  }

  $scope.formatSampleRate = function (value) {
    if (isNaN(value / 1000)) {
      return '-'
    } else {
      return value / 1000 + ' kHz'
    }
  }

  $scope.getNetworkTooltip = function () {
    try {
      switch (audioPlayerService.control.audioElement.networkState) {
        case 0:
          return 'No data existing.'
        case 1:
          return 'Using buffer, network idle.'
        case 2:
          return 'Streaming, network active.'
        case 3:
          return 'No playback object existing.'
        default:
          return 'Unkown state.'
      }
    } catch (e) {
      return ''
    }
  }
}).directive('playerToolbar', function () {
  return {
    restrict: 'E',
    templateUrl: 'directives/archive/playerToolbar/template.html'
  }
})
