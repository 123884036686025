angular.module('webremote.monitoring').controller('monitoringTabsCtrl',
  function ($scope) {
    $scope.reloadTab = false
    $scope.loading = true
    $scope.nrTabs = 0
    $scope.tabs = {}

    $scope.tabs.system = {
      path: 'modules/monitoring/tabs/system/template.html',
      title: 'System'
    }

    // ToDo: Do watch on collection all the time to adapt to changes,
    // but at the moment this is not necessary since fernFM is not plug an play
    $scope.objectManager.collection('demux').then((v) => {
      $scope.nrTabs = 0
      $scope.hasFM = false
      for (const demux of v.values()) {
        if (Object.prototype.hasOwnProperty.call(demux.member.source, 'fernFm')) {
          $scope.nrTabs += 1
          $scope.hasFM = true
          $scope.tabs[demux.member.uuid] = {
            path: 'modules/monitoring/tabs/fm/template.html',
            title: demux.member.name
          }
        }
      }
      if ($scope.hasFM) {
        $scope.nrTabs += 1
        $scope.tabs.dataWatch = {
          path: 'modules/monitoring/tabs/dataWatch/template.html',
          title: 'Data Watch Settings'
        }
      }
    })

    $scope.selectConfigMenu = function (id) {
      $scope.active = id
      $scope.path = $scope.tabs[id].path
    }

    $scope.loading = false
    $scope.selectConfigMenu('system')
  })
