angular.module('webremote.directive.sip.sipCall.listing').controller('ctrlModalChannelMode', function ($scope, $uibModalInstance, objectManagerService) {
  $scope.om = objectManagerService

  $scope.workingCopy = angular.copy($scope.getPhoneBookEntry())
  if ($scope.workingCopy.member.audio.rxPipeTemplate === '') { $scope.workingCopy.member.audio.rxPipeTemplate = null }
  if ($scope.workingCopy.member.audio.txPipeTemplate === '') { $scope.workingCopy.member.audio.txPipeTemplate = null }

  $scope.filterTemplatePipesTx = function (item) {
    return item.isTemplate && item.getElement('sink', 'sip').hasOwnProperty('type') && item.member.validationState === 'valid'
  }

  $scope.filterTemplatePipesRx = function (item) {
    return item.isTemplate && item.getElement('source', 'sip').hasOwnProperty('type') && item.member.validationState === 'valid'
  }

  $scope.$watch('workingCopy.member.audio.txPipeTemplate', () => {
    if ($scope.showChannel === 'tx') {
      if ($scope.workingCopy.member.audio.txPipeTemplate !== null) {
        $scope.displayChannelSelection = $scope.om.objectSync($scope.workingCopy.member.audio.txPipeTemplate, 'stream').hasElement('source', 'jack')
      } else {
        $scope.displayChannelSelection = true
      }
    }
  })

  $scope.$watch('workingCopy.member.audio.rxPipeTemplate', () => {
    if ($scope.showChannel === 'rx') {
      if ($scope.workingCopy.member.audio.rxPipeTemplate !== null) {
        $scope.displayChannelSelection = $scope.om.objectSync($scope.workingCopy.member.audio.rxPipeTemplate, 'stream').hasElement('sink', 'jack')
      } else {
        $scope.displayChannelSelection = true
      }
    }
  })

  $scope.channelImport = function () {
    if ($scope.showChannel === 'tx') {
      $scope.workingCopy.member.audio.inputChannels.mapping = []
      if ($scope.workingCopy.member.audio.txPipeTemplate !== null && $scope.om.objectSync($scope.workingCopy.member.audio.txPipeTemplate, 'stream').hasElement('source', 'jack')) {
        $scope.workingCopy.member.audio.inputChannels = angular.copy($scope.om.objectSync($scope.workingCopy.member.audio.txPipeTemplate, 'stream').getElement('source', 'jack').type.source.type.jack.mapping)
      }
    }

    if ($scope.showChannel === 'rx') {
      $scope.workingCopy.member.audio.outputChannels.mapping = []
      if ($scope.workingCopy.member.audio.rxPipeTemplate !== null && $scope.om.objectSync($scope.workingCopy.member.audio.rxPipeTemplate, 'stream').hasElement('sink', 'jack')) {
        $scope.workingCopy.member.audio.outputChannels = angular.copy($scope.om.objectSync($scope.workingCopy.member.audio.rxPipeTemplat, 'stream').getElement('sink', 'jack').type.sink.type.jack.mapping)
      }
    }
  }

  $scope.closeModal = function () {
    $scope.getPhoneBookEntry().member.audio.rxPipeTemplate = $scope.workingCopy.member.audio.rxPipeTemplate
    if ($scope.getPhoneBookEntry().member.audio.rxPipeTemplate === null) { $scope.getPhoneBookEntry().member.audio.rxPipeTemplate = '' }
    $scope.getPhoneBookEntry().member.audio.txPipeTemplate = $scope.workingCopy.member.audio.txPipeTemplate
    if ($scope.getPhoneBookEntry().member.audio.txPipeTemplate === null) { $scope.getPhoneBookEntry().member.audio.txPipeTemplate = '' }
    $scope.getPhoneBookEntry().member.audio.inputChannels = $scope.workingCopy.member.audio.inputChannels
    $scope.getPhoneBookEntry().member.audio.outputChannels = $scope.workingCopy.member.audio.outputChannels

    $uibModalInstance.close()
  }

  $scope.dismissModal = function () {
    $uibModalInstance.dismiss()
  }
})
