angular.module('webremote.settings').controller('TabController',
  function ($scope, $location, $http, $log, httpService, toastr, authenticationService) {
    $scope.reloadTab = false
    $scope.loading = true
    $scope.tabs = []

    $http.get('modules/settings/tabs.json', { cache: false }).then(function (res) {
      $scope.tabs = res.data
      $scope.loading = false

      if ($location.search().module === 'sipAccounts') {
        $scope.selectConfigMenu('sipAccounts')
      } else if ($location.search().module === 'network') {
        $scope.selectConfigMenu('network')
      } else if ($location.search().module === 'callProfiles') {
        $scope.selectConfigMenu('callProfiles')
      } else {
        $scope.selectConfigMenu('system')
      }
    })

    $scope.selectConfigMenu = function (id) {
      $scope.active = id
      $scope.path = $scope.tabs[id].path
    }
  })
