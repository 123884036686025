angular.module('webremote.dashboard').controller('widget_systemHealth',
  function ($scope, statsService) {
    $scope.statsFromService = statsService.getStats

    $scope.getStyle = function () {
      var transform = ($scope.isSemi ? '' : 'translateY(-50%) ') + 'translateX(-50%)'

      return {
        top: $scope.isSemi ? 'auto' : '50%',
        bottom: $scope.isSemi ? '5%' : 'auto',
        left: '50%',
        transform: transform,
        'font-size': $scope.radius / 3.5 + 'px'
      }
    }

    $scope.diskColor = () => {
      const stats = statsService.getStats()
      if (stats.diskFree <= stats.diskFreeWarn && stats.diskFree > stats.diskFreeMin) {
        return '#ffba00'
      } else if (stats.diskFree <= stats.diskFreeMin) {
        return '#e32c2c'
      }
      return '#26ed01'
    }
  }).filter('bytes', function () {
  return function (bytes, precision) {
    if (isNaN(parseFloat(bytes)) || !isFinite(bytes)) return '-'
    if (typeof precision === 'undefined') precision = 1
    var units = ['bytes', 'kiB', 'MiB', 'GiB', 'TiB', 'PiB']

    var number = Math.floor(Math.log(bytes) / Math.log(1024))
    return (bytes / Math.pow(1024, Math.floor(number))).toFixed(precision) + ' ' + units[number]
  }
})
