export default class Column {
  constructor (name, states) {
    this._xPosition = 0
    this._name = name
    this._states = states
    this._activeJobs = 0
  }

  set xPosition (v) {
    this._xPosition = Math.ceil(v)
  }

  get xPosition () {
    return this._xPosition
  }

  set activeJobs (v) {
    this._activeJobs = v
  }

  get activeJobs () {
    return this._activeJobs
  }

  get name () {
    return this.name
  }

  get states () {
    return this._states
  }

  get cssId () {
    return '#column_' + this._name
  }

  calcxPosition () {
    this.xPosition = (angular.element(this.cssId).position().left + angular.element(this.cssId).width() / 2) - 4
  }

  countActiveJobs (onDemandStatistics) {
    let count = 0
    for (const pool of onDemandStatistics) {
      for (const index in pool.member.currentJobs) {
        if (this._states.includes(pool.member.currentJobs[index].state)) {
          count++
        }
      }
    }
    this._activeJobs = count
    return count
  }
}
