angular.module('webremote.dashboard').controller('widget_listenIn',
  function ($scope, objectManagerService, audioPlayerService) {
    $scope.om = objectManagerService

    $scope.$watch('activeDashboard', function () {
      $scope.om.object($scope.activeDashboard, 'dashboard').then((v) => {
        $scope.dashboard = v
      })
    })

    $scope.listenIn = function (streamUuid) {
      $scope.om.object(streamUuid, 'stream').then(stream => {
        audioPlayerService.control.setPlayObject(stream, { common: { title: stream.member.name } })
      })
    }
    $scope.om.object($scope.activeDashboard, 'dashboard').then((v) => {
      $scope.dashboard = v
    })

    $scope.filterPlaying = function (item) {
      return item.member.state === 'playing'
    }

  })
