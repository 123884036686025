
angular.module('webremote.directive.objectmanager.formElements')
  .controller('webremote.directive.objectmanager.formElements.union.controller', function ($scope, objectManagerService, omFormService, schemeParser) {
    $scope.objectManager = objectManagerService.getObjectManager()
    $scope.manualChange = false
    // Filter visible structs
    $scope.filterVisibleStructs = function (options) {
      const result = {}
      for (const name in options) {
        if (options[name].hidden !== true) {
          result[name] = options[name]
        }
      }
      return result
    }

    // Watch data variable
    $scope.$watch('data[schema.key]', function (newValue) {
      if (newValue === undefined && !$scope.manualChange) {
        const options = schemeParser.parse($scope.schema.fields)
        const key = Object.keys(options)[0]
        $scope.data[$scope.schema.key] = {}
        $scope.data[$scope.schema.key][key] = options[key]
        $scope.selectedOption = key
      } else {
        $scope.selectedOption = Object.keys($scope.data[$scope.schema.key])[0]
      }
    }, true)

    // Setter
    $scope.setData = function (key) {
      $scope.data[$scope.schema.key] = {}
      $scope.data[$scope.schema.key][key] = schemeParser.parse($scope.schema.fields[key].fields)
      $scope.manualChange = false
    }

    $scope.selectedOption = undefined
  })

  .directive('omConfigformUnion', function () {
    return {
      restrict: 'E',
      transclude: 'true',
      scope: {
        data: '=',
        schema: '<',
        selector: '=',
        object: '<',
        debug: '<',
        objectPath: '<',
        expertLevel: '=',
        type: '='
      },
      templateUrl: 'directives/objectmanager/formElements/union/template.html'
    }
  })
