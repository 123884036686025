angular.module('webremote.dashboard').controller('dashboard',
  function ($scope, $rootScope, $http, $uibModal, httpService, toastr, authenticationService, objectManagerService, statsService) {
    $scope.statsServiceManager = statsService.getStats
    $scope.authenticationService = authenticationService
    $scope.objectManager = objectManagerService.GetObjectManager
    $scope.activeDashboard = undefined
    $scope.onLoadFinished = false

    $scope.objectManager().collection('systemConfig').then((v) => {
      $scope.systemConfig = Array.from(v.values())[0]
    })

    $scope.$watch('activeDashboard', function (newValue, oldValue) {
      // workaround (someone is undefining activeDashboard without need)
      if (newValue === undefined && oldValue !== undefined) {
        $scope.activeDashboard = oldValue
      }
    })

    $scope.objectManager().collection('session').then((v) => {
      $scope.objectManager().object(Array.from(v.values())[0].member.user_uuid, 'user').then((v) => {
        $scope.objectManager().collection('dashboard').then((dashboards) => {
          if (dashboards.size === 1) {
            $scope.activeDashboard = dashboards.entries().next().value[0]
            $scope.selectDashboard($scope.activeDashboard)
          } else {
            $scope.activeDashboard = v.member.dashboard
          }
        })
        $scope.onLoadFinished = true
      })
    })

    $scope.selectDashboard = function (uuid) {
      $scope.objectManager().collection('session').then((v) => {
        $scope.objectManager().object(Array.from(v.values())[0].member.user_uuid, 'user').then((v) => {
          $scope.$evalAsync(() => {
            v.member.dashboard = uuid
            v.update()
          })
        })
      })
    }

    $scope.editDashboard = function (modeNewDashboard) {
      $scope.modeNewDashboard = modeNewDashboard

      if ($scope.modeNewDashboard) {
        $scope.currentDashboardEditingCopy = angular.copy($scope.newDashboard)
      } else {
        $scope.objectManager().object($scope.activeDashboard, 'dashboard').then((v) => {
          $scope.currentDashboardEditingCopy = angular.copy(v)
        })
      }

      $scope.modalInstance = $uibModal.open({
        templateUrl: 'modules/dashboard/modalConfigDashboard.html',
        controller: 'modalConfigCtrl',
        size: 'md',
        scope: $scope
      })
      $scope.modalInstance.result.then(function (returnedData) {
      }, function (errorData) {})
    }
  })
