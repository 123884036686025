'use strict'

angular.module('webremote.monitoring', []).controller('webremote.monitoring.system.controller',
  function ($scope, $log, $filter, objectManagerService) {
    $scope.interfaces = []
    $scope.objectManager = objectManagerService.getObjectManager()

    $scope.objectManager.collection('netPhy').then((v) => {
      $scope.interfaces = Array.from(v.values())
      $scope.showInterfacePlot($scope.interfaces[0].member.uuid)
    })

    $scope.getChartData = function () {
      $scope.objectManager.callMethod('readStats', { source: { memory: null } }, { notificationsError: true }).then(function (serviceData) {
        const data = JSON.parse(serviceData.data)
        for (const a in data.data) {
          // round values and transform to megabyte
          data.data[a][0] = Math.round(data.data[a][0] / 1000000)
          data.data[a][1] = Math.round(data.data[a][0] + data.data[a][1] / 1000000)
          data.data[a][2] = Math.round(data.data[a][1] + data.data[a][2] / 1000000)
          data.data[a][3] = Math.round(data.data[a][2] + data.data[a][3] / 1000000)
        }
        $scope.graph1 = new Graph('myChart1', 'line', Graph.scaleType.DYNAMIC, data.meta, data.data, 'Memory Usage (in MB)', 'MB', false)
      }, function (errorData) {
        console.error(errorData)
      })

      $scope.objectManager.callMethod('readStats', { source: { load: null } }, { notificationsError: true }).then(function (serviceData) {
        const data = JSON.parse(serviceData.data)
        for (const a in data.data) {
          for (const b in data.data[a]) {
            data.data[a][b] = Math.round(data.data[a][b] * 100) / 100
          }
        }
        $scope.graph2 = new Graph('myChart2', 'line', Graph.scaleType.DYNAMIC, data.meta, data.data, 'Load')
      }, function (errorData) {
        console.error(errorData)
      })

      $scope.objectManager.callMethod('readStats', { source: { tcp: null } }, { notificationsError: true }).then(function (serviceData) {
        const data = JSON.parse(serviceData.data)
        $scope.graph3 = new Graph('myChart3', 'line', Graph.scaleType.DYNAMIC, data.meta, data.data, 'TCP Connections')
      }, function (errorData) {
        console.error(errorData)
      })

      $scope.objectManager.callMethod('readStats', { source: { cpu: null } }, { notificationsError: true }).then(function (serviceData) {
        const cpuData = JSON.parse(serviceData.data)

        const resultCpu = []
        const resultLabel = []
        for (const index in cpuData.meta.legend) {
          if (index % 3 === 0) {
            resultLabel.push(cpuData.meta.legend[index])
          }
        }

        cpuData.meta.legend = resultLabel

        for (const arr of cpuData.data) {
          const resultArr = []
          for (let count = 0; count < arr.length; count = count + 3) {
            resultArr.push(Math.ceil(arr[count] + arr[count + 1] + arr[count + 2]))
          }

          resultCpu.push(resultArr)
        }
        cpuData.data = resultCpu

        $scope.graph5 = new Graph('myChart5', 'line', Graph.scaleType.PERCENT, cpuData.meta, cpuData.data, 'CPU Load (in %)', '%')
      }, function (errorData) {
        console.error(errorData)
      })
    }

    $scope.getChartData()

    $scope.showInterfacePlot = function (uuid) {
      $scope.active = uuid
      objectManagerService.getObjectManager().object(uuid, 'netPhy').then((v) => {
        if ($scope.graph4_1 != null) {
          $scope.graph4_1.instance.destroy()
        }

        if ($scope.graph4_2 != null) {
          $scope.graph4_2.instance.destroy()
        }

        if ($scope.graph4_3 != null) {
          $scope.graph4_3.instance.destroy()
        }

        $scope.objectManager.callMethod('readStats', { source: { interface: { devName: v.member.devName, type: 'errors' } } }, { notificationsError: true }).then(function (serviceData) {
          const data = JSON.parse(serviceData.data)
          if ($scope.graph4_1 !== undefined) {
            $scope.graph4_1.instance.destroy()
          }
          $scope.graph4_1 = new Graph('myChart4-1', 'line', Graph.scaleType.DYNAMIC, data.meta, data.data, 'Errors per second at: ' + v.member.name, 'errors/s')
        }, function (errorData) {
          console.error(errorData)
        })

        $scope.objectManager.callMethod('readStats', { source: { interface: { devName: v.member.devName, type: 'packets' } } }, { notificationsError: true }).then(function (serviceData) {
          const data = JSON.parse(serviceData.data)

          // Round packet amount
          for (const a in data.data) {
            for (const b in data.data[a]) {
              data.data[a][b] = Math.round(data.data[a][b])
            }
          }

          if ($scope.graph4_2 !== undefined) {
            $scope.graph4_2.instance.destroy()
          }
          $scope.graph4_2 = new Graph('myChart4-2', 'line', Graph.scaleType.DYNAMIC, data.meta, data.data, 'Packets per second at: ' + v.member.name, 'packets/s')
        }, function (errorData) {
          console.error(errorData)
        })

        $scope.objectManager.callMethod('readStats', { source: { interface: { devName: v.member.devName, type: 'octets' } } }, { notificationsError: true }).then(function (serviceData) {
          const data = JSON.parse(serviceData.data)

          // Form octets to megabit
          for (const a in data.data) {
            for (const b in data.data[a]) {
              data.data[a][b] = Math.round(data.data[a][b] * 8 / 1000000 * 100) / 100
            }
          }
          if ($scope.graph4_3 !== undefined) {
            $scope.graph4_3.instance.destroy()
          }
          $scope.graph4_3 = new Graph('myChart4-3', 'line', Graph.scaleType.DYNAMIC, data.meta, data.data, 'Megabit per second at: ' + v.member.name, 'Mb/s')
        }, function (errorData) {
          console.error(errorData)
        })
      })
    }

    $scope.getBase64 = function (graph) {
      try {
        return graph.instance.toBase64Image()
      } catch (e) {
        return ''
      }
    }

    class Graph {
      constructor (cssId, type, scalingType, meta, data, title, unit, interactive, yUnit) {
        this.cssId = cssId
        this.data = data
        this.type = type
        this.ctx = document.getElementById(cssId).getContext('2d')
        this.datasets = []
        this.interactive = (interactive === undefined ? true : interactive)
        this.yUnit = yUnit

        // Clear canvas
        this.ctx.clearRect(0, 0, this.ctx.width, this.ctx.height)

        this.fillColors = ['rgba(224,67,3,0.2)', 'rgba(10, 194, 136,0.2)', 'rgba(32, 190, 243, 0.2)', 'rgba(243, 176, 32, 0.2)', 'rgba(42, 234, 58, 0.2)', 'rgba(243, 32, 110, 0.1)', 'rgba(160, 152, 246, 0.2)', 'rgba(217, 230, 71, 0.2)', 'rgba(193, 26, 57, 0.2)', 'rgba(64, 64, 64, 0.2)', 'rgb(66, 134, 244, 0.2)', 'rgb(119, 9, 229, 0.2)', 'rgb(191, 216, 4, 0.2)', 'rgb(219, 132, 100, 0.2)', 'rgb(219, 100, 100, 0.2)']
        this.borderColors = ['rgba(224,67,3,1.0)', 'rgba(10, 194, 136,1.0)', 'rgba(32, 190, 243, 1.0)', 'rgba(243, 176, 32, 1)', 'rgba(42, 234, 58, 1)', 'rgba(243, 32, 110, 1)', 'rgba(160, 152, 246, 1)', 'rgba(217, 230, 71, 1)', 'rgba(193, 26, 57, 1)', 'rgba(64, 64, 64, 1)', 'rgb(66, 134, 244, 1.0)', 'rgb(119, 9, 229, 1.0)', 'rgb(191, 216, 4, 1.0)', 'rgb(219, 132, 100, 1.0)', 'rgb(219, 100, 100, 1.0)']

        for (const index in meta.legend) {
          const dataset = {}
          dataset.label = meta.legend[index]
          dataset.fill = (index === '0' ? 'origin' : '-1')
          dataset.backgroundColor = this.fillColors[index]
          dataset.borderColor = this.borderColors[index]
          dataset.borderWidth = 1
          dataset.pointRadius = 1
          dataset.pointHitRadius = 5
          dataset.pointBackgroundColor = this.borderColors[index]
          dataset.lineTension = 0
          dataset.data = []
          for (const dataLine of data) {
            dataset.data.push(dataLine[index])
          }

          this.datasets.push(dataset)
        }

        this.xLabels = []

        for (var value = meta.start; value <= meta.end; value = value + meta.step) {
          this.xLabels.push($filter('date')(value * 1000, 'shortTime'))
        }

        this.options = {
          type: type !== undefined ? type : 'line',
          data: {
            labels: this.xLabels,
            datasets: this.datasets
          },
          options: {
            animation: {
              duration: 0,
              easing: 'linear'
            },
            title: {
              display: true,
              text: title
            },
            legend: {
              position: 'bottom'
            },
            tooltips: {
              enabled: this.interactive,
              mode: 'single',
              callbacks: {
                label: function (tooltipItems, data) {
                  return tooltipItems.yLabel + ' ' + (unit === undefined ? '' : unit)
                }
              }
            },
            scales: {
              yAxes: [{
                ticks: {
                  beginAtZero: true,
                  autoSkip: true,
                  callback: function (value, index, values) {
                    return this.yUnit !== undefined ? value + this.yUnit : value
                  }
                }

              }],
              xAxes: [{
                ticks: {
                  maxTicksLimit: 25,
                  autoSkip: true,
                  fontSize: 11,
                  padding: 8
                }
              }]
            }
          }
        }

        if (!this.interactive) {
          this.options.options.legend = {
            onClick: (e) => e.stopPropagation()
          }
          this.options.options.legend.position = 'bottom'
        }

        if (scalingType === Graph.scaleType.PERCENT) {
          this.options.options.scales.yAxes[0].ticks.max = 100
          this.options.options.scales.yAxes[0].ticks.min = 0
        }

        this.instance = new Chart(this.ctx, this.options)
      }

      static get scaleType () {
        return Object.freeze({ PERCENT: 1, DYNAMIC: 2 })
      }
    }
  })
