
angular.module('webremote.directive.objectmanager.formElements')
  .controller('webremote.directive.objectmanager.formElements.channelSelector.controller', ['$scope', 'objectManagerService', '$uibModal', function ($scope, objectManagerService, $uibModal) {
    $scope.objectManager = objectManagerService.getObjectManager()

    $scope.modals = {}
    $scope.type = ($scope.type === undefined ? 'audioInputPort' : $scope.type)

    $scope.resolveChannelIdToName = function (soundCard, id) {
      let collection = []
      if ($scope.type === 'audioInputPort') {
        collection = $scope.objectManager.collectionSync('audioInputPort')
      }

      if ($scope.type === 'audioOutputPort') {
        collection = $scope.objectManager.collectionSync('audioOutputPort')
      }

      for (const audioInterface of collection) {
        if (!(audioInterface.getInformationForChannel(soundCard, id) instanceof Error)) {
          return audioInterface.getInformationForChannel(soundCard, id)
        }
      }
    }

    $scope.openPicker = function () {
      $scope.modals.channelPicker = $uibModal.open({
        templateUrl: 'directives/objectmanager/formElements/channelSelector/modals/channelPicker/template.html',
        controller: 'webremote.directive.objectmanager.formElements.channelSelector.modals.channelPicker.controller',
        size: 'lg',
        scope: $scope
      })

      // Get Feedback!
      $scope.modals.channelPicker.result.then(function (data) {
        $scope.data[$scope.key] = data
      }, function (errorData) {

      })
    }
  }])

  .directive('channelSelector', function () {
    return {
      restrict: 'E',
      transclude: 'true',
      scope: {
        data: '=data',
        key: '=key',
        type: '=type',
        ui: '=ui'
      },
      templateUrl: 'directives/objectmanager/formElements/channelSelector/template.html'
    }
  })

require('./modals/channelPicker/controller')
require('./createVirtualPort/controller')
