export default class RtpSink {
  constructor (stream) {
    this.stream = stream
    this.id = this.stream.member.uuid
    this.ssrc = ''
    this.octetsSent = 0
    this.packetsSent = 0
    this.bitrate = 0.0
  }

  update (rtpStatsTx) {
    this.ssrc = rtpStatsTx.ssrc
    this.octetsSent = rtpStatsTx.octetsSent
    this.packetsSent = rtpStatsTx.packetsSent
    this.bitrate = Math.round(rtpStatsTx.bitrate * 10000) / 10000
  }
}
