angular.module('webremote.directive.general.logComment').controller('modals.logComment.controller', function ($scope, $uibModalInstance, objectManagerService) {
  $scope.om = objectManagerService
  $scope.comment = ''

  $scope.om.collection('systemConfig').then((obj) => {
    $scope.systemConfig = Array.from(obj.values())[0]
    $scope.preset = $scope.systemConfig.member.commentPresets[0]
  })

  // append preset to comment
  $scope.appendPreset = function () {
    $scope.comment = $scope.comment + $scope.preset
    $scope.preset = $scope.systemConfig.member.commentPresets[0]
  }

  $scope.addPreset = function () {
    $scope.systemConfig.member.commentPresets.push($scope.comment)
    $scope.systemConfig.update()
  }

  $scope.saveComment = function () {
    if ($scope.logCommentCancelable()) {
      $scope.logType = 'recorder'
    } else {
      $scope.logType = 'recorderForced'
    }
    const payload = { message: $scope.comment, type: $scope.logType, objectUuid: $scope.logCommentUuid(), objectClass: $scope.logCommentObjectclass() }
    $scope.om.callMethod('logComment', payload, { notifications: true })
  }

  // Modal Methods
  $scope.closeModal = function () {
    $scope.saveComment()
    $uibModalInstance.close()
  }

  $scope.dismissModal = function () {
    $uibModalInstance.dismiss()
  }
})
