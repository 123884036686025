angular.module('webremote.settings').controller('webremote.settings.network.controller',
  function ($scope, $document, $uibModal, toastr, objectManagerService, modalService) {
    $scope.objectManager = objectManagerService.getObjectManager
    $scope.networkInterfaces = []
    $scope.bondingInterfaces = []
    $scope.networkConfigExport = undefined
    $scope.selectedItem = {}
    $scope.selectedNetwork = { network: {} }
    $scope.useDhcp = true
    $scope.objectManager().getDefault('captureNetwork').then(obj => {
      $scope.capture = {}
      $scope.capture.member = obj
      $scope.capture.title = 'captureNetwork'
    })
    $scope.capturePopover = false
    $scope.hasNetworkAddrs = {}
    $scope.invalidForms = 0

    $scope.activeMarker = {
      generic: '',
      netWifi: '',
      wireGuard: '',
      netBond: '',
      netVlan: ''
    }

    $scope.objectManager().getScheme('network').then(networkSchema => {
      $scope.newRouteMetricDefault = networkSchema.routes.elementType.fields.metric.default || 0
      $scope.dhcpRouteMetricDefault = networkSchema.config.fields.dhcp.fields.routeMetric.default || 0
      $scope.newRouteMetric = $scope.newRouteMetricDefault
    })

    $scope.objectManager().callMethod('networkConfigExport', {}, { notificationsError: true }).then((v) => {
      // Decode Base64
      $scope.networkConfigExport = window.encodeURIComponent(window.atob(v.data))
    })

    $scope.generateConfigStructure = function () {
      if ($scope.useDhcp) {
        $scope.network.member.config = { dhcp: { useDns: true, useNtp: true, useRoutes: true, routeMetric: $scope.dhcpRouteMetricDefault } }
      } else {
        $scope.network.member.config = { static: { address: '', gateway: '' } }
      }
    }

    $scope.createInterface = function (type) {
      // Create wireGuard netPhy Object
      $scope.objectManager().getDefault('netPhy').then(netPhyDefault => {
        const Interface = angular.copy(netPhyDefault)
        Interface.name = 'Unnamed ' + type + ' Interface'
        Interface.enabled = false
        Interface.type = {}
        Interface.type[type] = {}

        $scope.objectManager().create('netPhy', Interface).then(netPhyUuid => {
          // Create network object for netPhy object
          $scope.objectManager().getDefault('network').then(networkDefault => {
            const network = angular.copy(networkDefault)
            network.device = netPhyUuid
            network.name = 'Unnamed ' + type + ' network'
            if (type === 'wireGuard') {
              network.config = { static: { address: '', gateway: '' } }
            } else {
              network.config = { dhcp: { useDns: true, useNtp: true, useRoutes: true, routeMetric: $scope.dhcpRouteMetricDefault } }
            }

            $scope.objectManager().create('network', network).then(networkUuid => {
              $scope.selectItem(netPhyUuid)
            })
          })
        })
      })
    }

    $scope.selectItem = function (uuid) {
      if (uuid !== undefined) {
        $scope.newRouteGateway = ''
        $scope.newRouteDestination = ''
        $scope.newDnsServer = ''
        $scope.newNtpServer = ''
        $scope.objectManager().object(uuid, 'netPhy').then(netDev => {
          $scope.objectManager().collection('network', true).then(networks => {
            $scope.$evalAsync(function () {
              $scope.setActiveMarker(uuid)
              $scope.selectedItem = angular.copy(netDev)
              $scope.capture.member.netPhy = uuid
              $scope.network = angular.copy(Array.from(networks.values()).find((e) => { return e.member.device === uuid }))
              $scope.useDhcp = Object.prototype.hasOwnProperty.call($scope.network.member.config, 'dhcp')
              if (Object.prototype.hasOwnProperty.call($scope.selectedItem.member.type, 'netBond') || Object.prototype.hasOwnProperty.call($scope.selectedItem.member.type, 'netVlan') || Object.prototype.hasOwnProperty.call($scope.selectedItem.member.type, 'wireGuard') || $scope.selectedItem.member.devName.length === 0) {
                $scope.deleteInterface = $scope.deleteInterface_template
              } else {
                $scope.deleteInterface = undefined
              }
            })
          })
        })
      }
    }

    $scope.setActiveMarker = function (uuid) {
      $scope.activeMarker = {
        generic: uuid,
        netWifi: uuid,
        wireGuard: uuid,
        netBond: uuid,
        netVlan: uuid
      }
    }

    $scope.selectFirstInterfaceInList = function () {
      $scope.objectManager().collection('netPhy').then(netPhyCollection => {
        const uuid = Array.from(netPhyCollection.values())
          .filter($scope.filterNetPhyByTypes(['generic', 'modem', 'netWifi', 'wireGuard', 'netBond', 'netVlan']))
          .sort($scope.compareNetPhys)[0].member.uuid
        $scope.selectItem(uuid)
      })
    }

    $scope.readNetworkAddresses = function () {
      $scope.objectManager().collection('network').then(networkCollection => {
        Array.from(networkCollection.values()).forEach(item => {
          $scope.hasNetworkAddrs[item.member.device] = (item.member.address.length > 0)
        })
      })
    }

    $scope.applyChanges = function () {
      const modalConfig = {
        title: 'Confirm',
        text: 'Are you sure to apply the network settings? If the settings are incorrect, you may lose access to the WebRemote.'
      }

      modalService.confirmModal(modalConfig, $scope, function (response) {
        if (response) {
          // Apply Changes
          $scope.objectManager().callMethod('applyNetworkSettings', {}, { notifications: true }).then(function (serviceData) {
            $scope.selectedItem = {}
            $scope.readNetworkAddresses()
          })
        }
      })
    }

    $scope.saveInterface = {
      disabled: false,
      cb: () => {
        $scope.network.update().then(v => {
          $scope.selectedItem.update().then(v => {
            toastr.success('Interface and network updated!', 'Success', {
              closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
            })
          })
          $scope.readNetworkAddresses()
          $scope.selectItem($scope.selectedItem.member.uuid)
        })
        $scope.scrollToTop()
      }
    }

    $scope.scrollToTop = function () {
      $document.scrollTopAnimated(0, 400)
    }

    $scope.deleteInterface = undefined

    $scope.deleteInterface_template = {
      label: 'Delete',
      cb: () => {
        const modalConfig = {
          title: 'Confirm',
          text: 'Are you sure to delete this interface?'
        }

        modalService.confirmModal(modalConfig, $scope, function (response) {
          if (response) {
            $scope.selectFirstInterfaceInList()
            $scope.selectedItem.destroy()
            $scope.scrollToTop()
          }
        })
      }
    }

    $scope.onFormValid = function () {
      if ($scope.invalidForms > 0) {
        $scope.invalidForms -= 1
      }
      $scope.saveInterface.disabled = ($scope.invalidForms !== 0)
    }

    $scope.onFormInvalid = function () {
      $scope.saveInterface.disabled = true
      $scope.invalidForms += 1
    }

    $scope.filterNetPhyByTypes = function (types) {
      return function (item) {
        for (const type of types) {
          if (Object.prototype.hasOwnProperty.call(item.member.type, type) && !item.member.usedInNetBond) {
            return true
          }
        }
        return false
      }
    }

    $scope.compareNetPhys = function (netPhy1, netPhy2) {
      const typeMap = new Map([['generic', 1], ['modem', 2], ['netWifi', 3], ['wireGuard', 4], ['netBond', 5], ['netVlan', 6]])
      return typeMap.get(Object.keys(netPhy1.member.type)[0]) - typeMap.get(Object.keys(netPhy2.member.type)[0])
    }

    $scope.readNetworkAddresses()
    $scope.selectFirstInterfaceInList()

    $scope.startCapture = function () {
      // Start Capture Network Packets
      $scope.objectManager().callMethod('captureNetwork', { netPhy: $scope.capture.member.netPhy, timeLimit: $scope.capture.member.timeLimit, packetLimit: $scope.capture.member.packetLimit }, { notifications: true })
      $scope.capturePopover = false
    }
  })
