import BaseClass from './Base.class'

export default class Role extends BaseClass {
  checkPermissionForObject (right, objectAskedFor) {
    let result = false
    for (const object of this.member[right]) {
      if (object.key === objectAskedFor) {
        result = true
        break
      }
    }
    return result
  }

  checkPermissionForMethod (methodAskedFor) {
    let result = false
    for (const method of this.member.call) {
      if (method.key === methodAskedFor) {
        result = true
        break
      }
    }
    return result
  }
}
