
// Set company
window.company = 'ferncast'

// Load application
require('./app')

// Load theme
require('./assets/scss/themes/default/theme.scss')
require('./assets/scss/themes/ferncast/theme.scss')
