angular.module('webremote.dashboard').controller('widget_recorderStreams',
  function ($scope, $uibModal, $interval, objectManagerService, audioPlayerService) {
    $scope.customComment = true
    $scope.commentPresets = []
    $scope.om = objectManagerService
    $scope.audioPlayerService = audioPlayerService

    $scope.isRecorderStream = function (stream) {
      return stream.hasElement('sink', 'file')
    }

    $scope.showOnTogglePause = true

    $scope.$watch('activeDashboard', function () {
      $scope.om.object($scope.activeDashboard, 'dashboard').then((v) => {
        $scope.dashboard = v
      })
    })

    $scope.om.object($scope.activeDashboard, 'dashboard').then((v) => {
      $scope.dashboard = v

      // Widget Options
      $scope.$watch('widgetIndex', function () {
        $scope.storedOptions = $scope.dashboard.getInformationForWidget($scope.widgetIndex)
        $scope.largeView = $scope.storedOptions.largeView
      })
    })

    $scope.updateWidgetOptions = function () {
      $scope.storedOptions.largeView = $scope.largeView
      $scope.dashboard.storeInformationForWidget($scope.widgetIndex, JSON.stringify($scope.storedOptions))
    }

    // Modal Fucntion
    $scope.open = function () {
      $scope.modalInstance = $uibModal.open({
        templateUrl: 'modules/dashboard/widgets/recorderGroups/modalComment.html',
        controller: 'modalCommentCtrl',
        backdrop: 'static',
        keyboard: false,
        scope: $scope
      })
    }

    $scope.intervalReference = $interval(function () {
      $scope.showOnTogglePause = !$scope.showOnTogglePause
    }, 1000)

    $scope.$on('$destroy', function () {
      $interval.cancel($scope.intervalReference)
    })
  })
