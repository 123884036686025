
angular.module('webremote.directive.objectmanager.views')
  .controller('webremote.directive.objectmanager.views.nested.controller', ['$scope', 'objectManagerService', 'httpService', '$uibModal', function ($scope, objectManagerService, httpService, $uibModal) {

  }])

  .directive('omViewNested', function () {
    return {
      restrict: 'E',
      transclude: {
        title: '?omViewTitle',
        content: 'omViewContent',
        icon: '?omViewIcon'
      },
      templateUrl: 'directives/objectmanager/views/nested/template.html'
    }
  })
