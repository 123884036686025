angular.module('webremote.login').controller('loginCtrl',
  function ($scope, $rootScope, $location, authenticationService, $window, $uibModal, objectManagerService, toastr, webSocketService) {
    $scope.https = $location.protocol() === 'https'
    $scope.company = window.company
    $scope.showHttpsOption = true

    webSocketService.EnableFallback(false)

    if ($location.port() !== 443 && $location.port() !== 80) {
      $scope.showHttpsOption = false
    }
    if ($location.host() === '127.0.0.1') {
      $scope.showHttpsOption = false
    }

    authenticationService.validateSession().then(result => {
      if (result) {
        $window.location.href = $location.absUrl().replace('#!/login', '#!/dashboard')
      }
    })

    $scope.changeProtocol = function () {
      if ($location.protocol() !== 'https') {
        $scope.https = true
        $window.location.href = $location.absUrl().replace('http:', 'https:')
      } else {
        $scope.https = false
        $window.location.href = $location.absUrl().replace('https:', 'http:')
      }
    }

    $scope.login = function () {
      $scope.dataLoading = true

      authenticationService.login($scope.loginForm.username, $scope.loginForm.password).then(function (obj) {
        delete $scope.error

        // Remove Loading Image
        $scope.dataLoading = false

        // Check, if password has to be changed
        if (false && obj.changePassword) {
        } else {
          // Preload roles
          objectManagerService.getObjectManager().collection('role')
          $window.location.href = $location.absUrl().replace('#!/login', '')
        }
      }, function (error) {
        $scope.$evalAsync(() => { $scope.error = error.message.text })
        $scope.dataLoading = false
      })
    }

    $scope.changePasswordModal = function () {
      $scope.modalInstance = $uibModal.open({
        templateUrl: 'modules/layout/changePassword.html',
        controller: 'changePasswortCtrl',
        size: 'md',
        scope: $scope,
        backdrop: 'static',
        keyboard: false
      })

      // Get Feedback!
      $scope.modalInstance.result.then(function (returnedData) {
        webSocketService.EnableFallback(true)
        toastr.success('You will be redirected immediately', 'Success', {
          closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
        })
        $window.location.href = $location.absUrl().replace('#!/login', '')
      }, function (errorData) {
        webSocketService.EnableFallback(true)
        // What should happen on 'cancel'
        // prevent angularjs error (dropback)
      })
    }
  })
