export default class FileSystemElement {
  async destroy () {
    const fileService = this.$injector.get('fileService')
    const path = fileService.encodePath(this.parent.path)
    return this.$injector.get('httpService').callFunction('archive/' + path + this.name, '', 'DELETE').then(function () {
      return true
    }, function (errorData) {
      this.$injector.get('toastr').error(errorData.message.text + ': ' + this.name, 'Error', {
        closeButton: true,
        progressBar: true,
        positionClass: 'toast-bottom-right'
      })
      return false
    }.bind(this))
  }

  async resolvePrettyName (pathArr, name) {
    // Resolve UUID to stream name
    if (pathArr.find(x => x === 'Recordings')) {
      const stream = await this.$injector.get('objectManagerService').object(name, 'stream')
      if (stream) {
        return stream.member.name
      } else {
        return name
      }
    }

    // Resolve UUID to applet name
    if (pathArr.find(x => x === 'Applets')) {
      const applet = await this.$injector.get('objectManagerService').object(name, 'applet')
      if (applet) {
        return applet.member.name
      } else {
        return name
      }
    }

    // Resolve UUID to onDemandJobPool name
    if (pathArr.find(x => x === 'Job Archive' || x === 'Defective')) {
      const onDemandJobPool = await this.$injector.get('objectManagerService').object(name, 'onDemandJobPool')
      if (onDemandJobPool) {
        return onDemandJobPool.member.name
      } else {
        return name
      }
    }
    return name
  }
}
