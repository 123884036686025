angular.module('webremote.dashboard').controller('widget_syncd',
function ($scope, $http, $uibModal, $location, httpService, toastr) {

    $scope.moduleName = "Settings";
    $scope.settings = [];

    $scope.addSettings = function(id) {
        httpService.callFunction("ConfigGetSettings", [id]).then(function(serviceData) {
            $scope.settings[$scope.settings.length] = serviceData.data;
        }, function(errorData) {
            toastr.error("Couldn't load configuration", 'Error', {
                closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
            });
        });
    };

    $scope.toggle = function(settingsObj) {
        if (settingsObj.enabled !== undefined) {
            if (settingsObj.enabled !== "Error") { // fehlerhandling
                if (settingsObj.enabled === "True")
                    settingsObj.enabled = "False";
                else if (settingsObj.enabled === "False")
                    settingsObj.enabled = "True";

                httpService.callFunction("ConfigSetSettings", [JSON.stringify(settingsObj)]).then(function(serviceData) {
                    settingsObj = serviceData.data; // success updated
                }, function(errorData) {
                    toastr.error("Couldn't set configuration", 'Error', {
                        closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
                    });
                });
            }
            return settingsObj.enabled;
        }
        else {
            alert("cannot toggle state, variable 'enabled' is undefined");
            return "Error";
        }
    };

    $scope.enable = function(settingsobj) {
        settingsobj.enabled = $scope.toggle(settingsobj);
    };

    $scope.disable = function(settingsobj) {
        settingsobj.enabled = $scope.toggle(settingsobj);
    };

    $scope.addSettings("syncd");

});
