angular.module('webremote.debug').controller('objectClassesController',
  function ($scope, $uibModal, toastr, $log, $document, objectManagerService) {
    var ClipboardJS = require('clipboard')
    $scope.clipboardManager = new ClipboardJS('.btn')
    $scope.objectManager = objectManagerService.getObjectManager()
    $scope.classes = []
    $scope.selectedClass = undefined
    $scope.selectedObject = {}
    $scope.activePill = 0
    $scope.viewLevel = { levelArray: [] }

    $scope.objectManager.getMetaObjects().then((v) => {
      for (const objTitle of v) {
        $scope.objectManager.collection(objTitle).then((objectData) => {
          const classObject = objectData
          classObject.title = objTitle
          $scope.classes.push(classObject)
        })
      }
    })

    $scope.newObject = function () {
      $scope.objectManager.createDefaultObject($scope.selectedClass.title).then(v => {
        $scope.$evalAsync(function () {
          $scope.selectedObject = v
          $scope.activePill = 0
        })
      })
    }
    $scope.selectClass = function (classObj) {
      $scope.selectedClass = classObj
      $scope.newObject()
    }

    $scope.selectObject = function (uuid) {
      $scope.objectManager.object(uuid, $scope.selectedClass.title).then(Obj => {
        $scope.$evalAsync(function () {
          $scope.selectedObject = angular.copy(Obj)
        })
      })
    }

    $scope.createObject = {
      cb: () => {
        $scope.selectedObject.member.objectClass = $scope.selectedClass.title
        $scope.objectManager.create($scope.selectedObject.member.objectClass, $scope.selectedObject.member)
          .then(uuid => {
            $scope.objectManager.object(uuid, $scope.selectedClass.title).then(Obj => {
              $scope.$evalAsync(function () {
                $scope.selectedObject = Obj
                $scope.activePill = Obj.member.uuid
              })
            })
          })
        $scope.scrollToTop()
      }
    }

    $scope.updateObject = {
      cb: () => {
        $scope.selectedObject.update()
        $scope.scrollToTop()
      }
    }

    $scope.removeObject = {
      cb: () => {
        $scope.selectedObject.destroy()
        $scope.newObject()
        $scope.activePill = 0
        $scope.scrollToTop()
      }
    }

    $scope.cloneObject = {
      cb: () => {
        $scope.selectedObject.clone()
        $scope.scrollToTop()
      }
    }

    $scope.scrollToTop = function () {
      $document.scrollTopAnimated(0, 400)
    }
  })
