angular.module('webremote.dashboard').controller('widget_streamGroups',
  function ($scope, $location, objectManagerService, streamGroupService) {
    $scope.om = objectManagerService
    $scope.streamGroupService = streamGroupService

    $scope.editButton = function () {
      $location.url('connect?tab=2')
    }

    $scope.nrPlaying = new Map()
    $scope.nrPipes = new Map()
    $scope.nrError = new Map()

    $scope.getPlayingPipes = function (streamgroup) {
      var nrPlaying = 0
      for (const stream of streamgroup.member.streams) {
        const streamObj = $scope.om.objectSync(stream, 'stream')
        if (streamObj !== undefined && streamObj.member.state === 'playing' && !streamObj.member.tags.includes('template')) {
          nrPlaying++
        }
      }
      $scope.nrPlaying.set(streamgroup.member.uuid, nrPlaying)
      return nrPlaying
    }

    $scope.getAllPipes = function (streamgroup) {
      var nrPipes = 0
      for (const stream of streamgroup.member.streams) {
        const streamObj = $scope.om.objectSync(stream, 'stream')
        if (streamObj !== undefined && !streamObj.member.tags.includes('template')) {
          nrPipes++
        }
      }
      $scope.nrPipes.set(streamgroup.member.uuid, nrPipes)
      return nrPipes
    }

    $scope.getErrorPipes = function (streamgroup) {
      var nrError = 0
      for (const stream of streamgroup.member.streams) {
        const streamObj = $scope.om.objectSync(stream, 'stream')
        if (streamObj !== undefined && streamObj.member.errorMessage !== '' && !streamObj.member.tags.includes('template')) {
          nrError++
        }
      }
      $scope.nrError.set(streamgroup.member.uuid, nrError)
      if (nrError === 0) return ''
      return nrError
    }

    $scope.filterTemplateGroups = function (item) {
      return item.member.tags.find(t => t === 'template') !== undefined
    }

    $scope.filterRegularGroups = function (item) {
      return item.member.tags.find(t => t === 'template') === undefined
    }

    $scope.filterPlayingGroups = function (item) {
      return item.member.state === 'playing'
    }

    $scope.filterErrorGroups = function (item) {
      return item.member.errorMessage !== ''
    }
  })
