
angular.module('webremote.directive.debug.journalList', [])
  .controller('journalListCtrl', function ($scope, $filter, $document, $location, httpService, toastr, objectManagerService, deviceService) {
    const moment = require('moment')

    $scope.availableLogLevel = [
      {
        key: 'debug',
        prettyName: 'Debug',
        indicatorClass: 'state-ind-default'
      },
      {
        key: 'info',
        prettyName: 'Info',
        indicatorClass: 'state-ind-info'
      },
      {
        key: 'warning',
        prettyName: 'Warning',
        indicatorClass: 'state-ind-warning'
      },
      {
        key: 'error',
        prettyName: 'Error',
        indicatorClass: 'state-ind-danger'
      },
      {
        key: 'critical',
        prettyName: 'Critical',
        indicatorClass: 'state-ind-critical'
      }
    ]

    $scope.logEntries = []
    $scope.eventCodes = []
    $scope.selectedLogLevel = $scope.availableLogLevel.find(x => x.key === 'warning')
    $scope.descending = true
    $scope.divider = ($scope.divider === undefined ? true : $scope.divider)
    $scope.filter = {}
    $scope.startDate = null
    $scope.isLoading = false
    $scope.limit = 50
    $scope.cursor = ''
    $scope.exportFileName = ''
    $scope.exportActiveTimePeriod = ''
    $scope.objectManager = objectManagerService.getObjectManager()

    $scope.objectManager.getDefault('exportLog').then(v => {
      $scope.$evalAsync(function () {
        $scope.exportPayload = v
        $scope.restoreDefaultExportOptions()
      })
    })

    $scope.getMode = function () {
      if ($scope.mode === 'modal') {
        return 'modal'
      } else {
        return 'page'
      }
    }

    $scope.resetFilter = function () {
      // Reset Cursor
      $scope.resetCursor()

      // Reset Filter
      $scope.objectList.forEach(function (element) {
        $scope.filter[element] = false
      })

      // Get Log Entries
      $scope.readLog().then((v) => {
        $scope.logEntries = v
        $scope.$evalAsync()
      })
    }

    $scope.resetCursor = function () {
      $scope.cursor = ''
    }

    $scope.countActiveFilter = function () {
      let counter = 0
      for (const element in $scope.filter) {
        if ($scope.filter[element]) {
          counter++
        }
      }
      return counter
    }

    $scope.getResultsFromBegin = function () {
      $scope.resetCursor()
      $scope.readLog({ now: null }).then((v) => {
        $scope.logEntries = v
        $scope.$evalAsync()
      })
    }

    $scope.$watch('startdate', function (newVal, oldVal) {
      if (newVal !== undefined && newVal !== null) {
        newVal.setHours('23')
        newVal.setMinutes('59')
        newVal.setSeconds('59')
        newVal.setMilliseconds('59')

        $scope.startDate = newVal.toISOString()
        $scope.readLog({ time: $scope.startDate }).then((v) => {
          $scope.logEntries = v
          $scope.$evalAsync()
        })
      } else {
        $scope.readLog().then((v) => {
          $scope.logEntries = v
          $scope.$evalAsync()
        })
      }
    })

    $scope.scrollToTop = function () {
      if ($scope.getMode() === 'modal') {
        $('.modal').scrollTop(0)
      } else {
        $document.scrollTopAnimated(0, 400)
      }
    }

    $scope.loadMoreItems = function () {
      if ($scope.isLoading) return
      $scope.isLoading = true

      if ($scope.cursor.length > 0) {
        $scope.readLog({ cursor: $scope.cursor }).then((v) => {
          for (const entry of v) {
            $scope.logEntries.push(entry)
          }

          $scope.isLoading = false
          $scope.$evalAsync()
        })
      } else {
        $scope.isLoading = false
      }
    }

    $scope.readLog = async function (start) {
      const json = {}
      json.methodClass = 'readLog'
      json.objectClasses = []
      json.objectUuids = []
      json.events = []
      json.limit = $scope.limit
      json.level = $scope.selectedLogLevel.key
      json.start = {}

      if (start !== undefined) {
        json.start = start
      } else {
        json.start.now = null
      }

      if ($scope.selectedObjectType !== undefined && $scope.selectedObjectType !== 'none') {
        json.objectClasses.push($scope.selectedObjectType)
      }

      if ($scope.objecttype) {
        $scope.objecttype.forEach(function (element) {
          json.objectClasses.push(element)
        })
      }

      if ($scope.eventCodes.length > 0) {
        json.events = $scope.eventCodes
      }

      if ($scope.objectid) {
        $scope.objectid.forEach(function (element) {
          json.objectUuids.push(element)
        })
      }

      for (var key in $scope.filter) {
        if ($scope.filter.hasOwnProperty(key)) {
          if ($scope.filter[key] === true) {
            json.objectClasses.push(key)
          }
        }
      }

      return $scope.objectManager.callMethod('readLog', json, { notificationsError: true }).then(function (serviceData) {
        const tempEntries = serviceData.entries
        $scope.cursor = serviceData.cursor
        for (let i = 0, logLength = tempEntries.length; i < logLength; i++) {
          tempEntries[i].timestamp = parseInt((parseInt(tempEntries[i].timestamp)) / 1000) + ''
        }

        return tempEntries
      }, function (errorData) {
        toastr.error("Couldn't call 'readLog'", 'Error', {
          closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
        })
      })
    }

    httpService.callFunction('meta/list/objects').then(function (serviceData) {
      $scope.objectList = serviceData.data

      $scope.resetFilter()
    }, function (errorData) {
      toastr.error("Couldn't load ObjectClasses", 'Error', {
        closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
      })
      $log.debug(errorData)
    })

    $scope.restoreDefaultExportOptions = function () {
      const currentDate = new Date()
      $scope.exportPayload.end = moment(currentDate).seconds(0).milliseconds(0).toDate()
      $scope.setTimePeriod('30min')
      if (deviceService.meta.machine === 'fernsecurity') {
        $scope.exportPayload.format.csv.replaceUuids = true
        $scope.exportPayload.format.csv.dateFormat = 'localSeparate'
      }
    }

    $scope.getDifferenceInMinutes = function () {
      return moment.duration(moment($scope.exportPayload.end).seconds(0).milliseconds(0).diff(moment($scope.exportPayload.begin).seconds(0).milliseconds(0))).asMinutes()
    }

    $scope.setTimePeriod = function (value) {
      const currentDate = new Date()
      switch (value) {
        case '30min':
          $scope.exportPayload.begin = moment(currentDate).subtract(30, 'minutes').seconds(0).milliseconds(0).toDate()
          $scope.exportPayload.end = moment(currentDate).seconds(0).milliseconds(0).toDate()
          break
        case '24h':
          $scope.exportPayload.begin = moment(currentDate).subtract(24, 'hours').seconds(0).milliseconds(0).toDate()
          $scope.exportPayload.end = moment(currentDate).seconds(0).milliseconds(0).toDate()
          break
        case '1w':
          $scope.exportPayload.begin = moment(currentDate).subtract(1, 'week').seconds(0).milliseconds(0).toDate()
          $scope.exportPayload.end = moment(currentDate).seconds(0).milliseconds(0).toDate()
      }
    }

    $scope.exportLog = function () {
      const payload = angular.copy($scope.exportPayload)
      payload.begin = payload.begin.toISOString()
      payload.end = payload.end.toISOString()

      $scope.objectManager.callMethod('exportLog', payload, { notificationsError: true }).then(v => {
        toastr.success('Export data to archive : Log Exports/' + v.filename, 'Success', {
          closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
        })
        $scope.exportFileName = v.filename
      })
    }

    $scope.navigateToArchive = function (file) {
      $location.url('archive?path=Log%20Exports&file=' + file)
    }
  })

  .directive('journalList', function () {
    return {
      restrict: 'E',
      scope: {
        objecttype: '=objtype',
        objectid: '=objid',
        divider: '=divider',
        mode: '=mode'
      },
      templateUrl: 'directives/debug/journal/template.html'
    }
  })
