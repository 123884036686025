import BaseClass from '../Base.class'
import LevelMeter from './Levelmeter.class'
import VolumeControl from './VolumeControl.class'
import Delay from './Delay.class'
import RtpSource from './RtpSource.class'
import RtpSink from './RtpSink.class'

import moment from 'moment'
export default class Stream extends BaseClass {
  constructor (data) {
    super(data)

    this.levelmeter = {}
    this.volumeControl = {}
    this.delay = {}
    this.rtpSource = {}
    this.rtpSink = {}

    this._createLevelmeterDelayAndVolumeControl()

    this._processStreamElementUpdates = this._processStreamElementUpdates.bind(this)
    this._dependencies.$injector.get('webSocketService').on('StreamElementUpdates', this._processStreamElementUpdates)
  }

  async destroy () {
    return super.destroy().then(() => {
      this._dependencies.$injector.get('webSocketService').off('StreamElementUpdates', this._processStreamElementUpdates)
    })
  }

  updateCallback (data) {
    super.updateCallback(data)
    this._createLevelmeterDelayAndVolumeControl()
    this._checkResetLevelmeter()
    this._updateDelay()
  }

  get visible () {
    return !this.member.hidden
  }

  get isTemplate () {
    return this.hasTag('template')
  }

  get monitor () {
    return this.hasElement('source', 'monitor')
  }

  set monitor (state) {
    this.patch([{ op: 'replace', path: '/monitor', value: state }])
  }

  get lock () {
    return this.member.editingLocked
  }

  set lock (state) {
    this.patch([{ op: 'replace', path: '/editingLocked', value: state }])
  }

  get elements () {
    return this.member.elements
  }

  get startTimestamp () {
    moment.locale()
    return moment(this.member.lastPlayingTime).format('LLL')
  }

  get playingTime () {
    if (this.member.lastPlayingTime.length > 0) {
      const startDate = Date.parse(this.member.lastPlayingTime)
      const currentDate = Date.now()

      const diff = currentDate - startDate
      const minutes = Math.floor(diff / 60e3)
      if (minutes / 60 > 1) {
        return Math.floor(minutes / 60) + 'h ' + minutes % 60 + ' min'
      } else {
        return minutes + ' min'
      }
    } else {
      return undefined
    }
  }

  get channels () {
    var res = ''
    var delim = ''
    for (var ch = 1; ch <= this.member.nChannels; ch++) {
      res += delim + ch.toString()
      delim = ' '
    }
    return res.trim().split(' ').filter(x => x !== '')
  };

  get targetLabel () {
    const element = this.getElement('sink', '*')
    return Object.prototype.hasOwnProperty.call(element, 'displayName') ? element.displayName : '-'
  }

  get encoderType () {
    const element = this.getElement('encoder', '*')
    if (Object.prototype.hasOwnProperty.call(element, 'type')) {
      return Object.prototype.hasOwnProperty.call(element.type, 'encoder') ? element.displayName : '-'
    } else {
      return '-'
    }
  }

  get decoderType () {
    const element = this.getElement('source', '*')
    if (Object.prototype.hasOwnProperty.call(element, 'type') && Object.prototype.hasOwnProperty.call(element.type, 'source')) {
      return Object.prototype.hasOwnProperty.call(element.type.source, 'codecName') && element.type.source.codecName.length > 0 ? element.type.source.codecName : '-'
    } else {
      return '-'
    }
  };

  get decoderBitrate () {
    const element = this.getElement('source', '*')
    return Object.prototype.hasOwnProperty.call(element.type.source, 'codecBitrate') && element.type.source.codecBitrate > 0 ? element.type.source.codecBitrate : '-'
  };

  get stateLabelClass () {
    switch (this.member.state) {
      case 'incomplete':
        return 'label-danger'
      case 'playing':
        return 'label-success'
      case 'paused':
      case 'waiting':
        return 'label-warning'
      case 'active':
        return 'label-success'
      case 'inactive':
      case 'eos':
        return 'label-danger'
    }
  }

  get actionIcon () {
    switch (this.member.state) {
      case 'incomplete':
        return 'warning' // TODO need 'blink' and 'danger-color'?
      case 'playing':
        return 'stop'
      case 'paused':
        return 'play_arrow'
      case 'active':
        return 'volume_up'
      case 'inactive':
        return 'play_arrow'
      case 'waiting':
      case 'eos':
        return 'hourglass_empty'
    }
  }

  getElementCategory (element) {
    if (Object.prototype.hasOwnProperty.call(element.type, 'encoder')) return 'encoder'
    if (Object.prototype.hasOwnProperty.call(element.type, 'sink')) return 'sink'
    if (Object.prototype.hasOwnProperty.call(element.type, 'source')) return 'source'
    if (Object.prototype.hasOwnProperty.call(element.type, 'processing')) return 'processing'
    if (Object.prototype.hasOwnProperty.call(element.type, 'postProcessing')) return 'postProcessing'
    return ''
  }

  hasTag (tag) {
    return this.member.tags.indexOf(tag) >= 0
  }

  // TODO: USE PATCH METHOD
  playDTMFSequence (sequence) {
    const element = this.getElement('source', 'dtmf')

    if (element instanceof Error) {
      return element
    } else {
      element.type.source.type.dtmf.keysToPlay = sequence
      this.update()
    }
  }

  element (category, type) {
    for (const element of this.elements) {
      if (Object.prototype.hasOwnProperty.call(element.type, category)) {
        if (type === '*' || Object.prototype.hasOwnProperty.call(element.type[category].type, type)) {
          return element
        }
      }
    }
    return {}
  }

  isAssignedToGroup () {
    return this.member.streamGroup.length > 0
  }

  restart () {
    this.setState('inactive').then(() => {
      this.setState('playing')
    })
  }

  hasElement (category, type) {
    if (this.getElement(category, type) instanceof Error) {
      return false
    } else {
      return true
    }
  }

  getElement (category, type) {
    for (const element of this.elements) {
      if (Object.prototype.hasOwnProperty.call(element.type, category)) {
        if (type === '*' || Object.prototype.hasOwnProperty.call(element.type[category].type, type)) {
          return element
        }
      }
    }
    return new Error('Element not found')
  }

  getValidationErrorForElement (uuid) {
    for (const error of this.member.validationErrors) {
      if (uuid === error.element) {
        return error
      }
    }
    return {}
  }

  isElement (element, category, type) {
    if (Object.prototype.hasOwnProperty.call(element.type, category)) {
      if (type === '*' || Object.prototype.hasOwnProperty.call(element.type[category].type, type)) {
        return true
      }
    }
  }

  getTooltip (element) {
    var tooltip = ''
    if ((this.isElement(element, 'source', 'rtp') || this.isElement(element, 'source', 'aoip')) && this.rtpSource.packetsReceived > 0) {
      tooltip = element.tooltip + '\n' +
        'SSRC: ' + this.rtpSource.ssrc + '\n' +
        'Octets received: ' + this.rtpSource.octetsReceived + '\n' +
        'Packets received: ' + this.rtpSource.packetsReceived + '\n' +
        'Bitrate: ' + this.rtpSource.bitrate + ' kbps\n' +
        'Jitter: ' + this.rtpSource.jitter + ' ms\n' +
        'Packets lost: ' + this.rtpSource.packetsLost + '\n' +
        'Time of last lost packet: ' + this.rtpSource.lastLossTime
      return tooltip
    } else if ((this.isElement(element, 'sink', 'rtp') || this.isElement(element, 'sink', 'aoip')) && this.rtpSink.packetsSent > 0) {
      tooltip = element.tooltip + '\n' +
        'SSRC: ' + this.rtpSink.ssrc + '\n' +
        'Octets sent: ' + this.rtpSink.octetsSent + '\n' +
        'Packets sent: ' + this.rtpSink.packetsSent + '\n' +
        'Bitrate: ' + this.rtpSink.bitrate + ' kbps'
      return tooltip
    }

    return element.tooltip
  }

  async dump () {
    return this._dependencies.$injector.get('objectManagerService').callMethod('audioDumpPipeline', { pipelineStatus: 'dot', pipelineId: this.member.uuid }, { notificationsError: true }).then(function (serviceData) {
      var hiddenElement = document.createElement('a')
      hiddenElement.setAttribute('href', 'data:text/vnd.graphviz,' + encodeURIComponent(serviceData.pipelineStatus))
      hiddenElement.setAttribute('download', 'dump.dot')
      hiddenElement.style.display = 'none'
      document.body.appendChild(hiddenElement)
      hiddenElement.click()
      document.body.removeChild(hiddenElement)
    })
  }

  openRecordingDirectory () {
    this._dependencies.$injector.get('$location').url('archive?path=Recordings/' + this.member.uuid)
  }

  elementCategory (element) {
    if (Object.prototype.hasOwnProperty.call(element.type, 'encoder')) return 'encoder'
    if (Object.prototype.hasOwnProperty.call(element.type, 'sink')) return 'sink'
    if (Object.prototype.hasOwnProperty.call(element.type, 'source')) return 'source'
    if (Object.prototype.hasOwnProperty.call(element.type, 'processing')) return 'processing'
    if (Object.prototype.hasOwnProperty.call(element.type, 'postProcessing')) return 'postProcessing'
    return ''
  }

  toggleState () {
    if (this.member.state === 'playing') { this.setState('inactive') } else { this.setState('playing') }
  };

  async setState (newState, clearError) {
    if (typeof clearError === 'undefined') { clearError = false }
    const payload = [
      {
        op: 'replace',
        path: '/pendingState',
        value: this.hasTag('template') ? 'incomplete' : newState
      }
    ]

    if (clearError) {
      payload.push(
        {
          op: 'replace',
          path: '/errorMessage',
          value: ''
        }
      )
    }

    // this.CheckResetLevelmeter()

    if (newState === 'inactive') {
      this._dependencies.$injector.get('audioPlayerService').control.streamStoppedCallback(this.member.uuid)
    }

    await this.patch(payload).then(() => {
      return true
    },
    (errorData) => {
      this._dependencies.$injector.get('toastr').error(errorData.message.text, 'Error', {
        closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
      })

      return false
    })
  };

  async setVolume (channel, value) {
    if (this.hasElement('processing', 'volumecontrol')) {
      // Disable Notification for volume update
      this._notifications.error = false
      this._notifications.success = false

      // Prepare patch
      const path = '/elements/' + this.elements.indexOf(this.getElement('processing', 'volumecontrol')) + '/type/processing/type/volumecontrol/volumes/' + (channel - 1)
      const payload = [{ op: 'replace', path: path, value: value }]

      return this.patch(payload).then(() => {
        this._notifications.error = true
        this._notifications.success = true
      })
    }
  }

  async setDelay (value) {
    if (this.hasElement('processing', 'delay')) {
      // Disable Notification for volume update
      this._notifications.error = false
      this._notifications.success = false

      // Prepare patch
      const path = '/elements/' + this.elements.indexOf(this.getElement('processing', 'delay')) + '/type/processing/type/delay/targetDelay'
      const payload = [{ op: 'replace', path: path, value: value }]

      return this.patch(payload).then(() => {
        this._notifications.error = true
        this._notifications.success = true
      })
    }
  }

  async connectPreset (elementIndex, presetUuid) {
    return new Promise(function (resolve, reject) {
      const path = '/elements/' + elementIndex + '/preset'
      const payload = [{ op: 'replace', path: path, value: presetUuid }]

      // Apply patch, enable notifications
      this.patch(payload).then(() => {
        resolve(true)
      }, (errorData) => {
        reject(errorData)
      })
    }.bind(this))
  }

  async disconnectPreset (elementIndex) {
    return new Promise(function (resolve, reject) {
      const path = '/elements/' + elementIndex + '/preset'
      const payload = [{ op: 'replace', path: path, value: '' }]

      // Apply patch, enable notifications
      this.patch(payload).then(() => {
        resolve(true)
      }, (errorData) => {
        reject(errorData)
      })
    }.bind(this))
  }

  get sliderLocked () {
    if (this.hasElement('processing', 'volumecontrol')) {
      return this.getElement('processing', 'volumecontrol').type.processing.type.volumecontrol.locked
    } else {
      return false
    }
  }

  async setSliderLock (state) {
    // Prepare patch
    const path = '/elements/' + this.elements.indexOf(this.getElement('processing', 'volumecontrol')) + '/type/processing/type/volumecontrol/locked'
    const payload = [{ op: 'replace', path: path, value: state }]

    const channels = angular.copy(this.channels)
    channels.shift()

    if (state) {
      for (const channel of channels) {
        this.volumeControl['channel_' + channel].slider.options.disabled = true
      }
    } else {
      for (const channel of channels) {
        this.volumeControl['channel_' + channel].slider.options.disabled = false
      }
    }

    return this.patch(payload)
  }

  _processStreamElementUpdates (obj) {
    for (const update of obj.streamElementUpdates) {
      if (update.stream === this.member.uuid && Object.prototype.hasOwnProperty.call(update.type, 'level')) {
        for (const level in update.type.level.peakDb) {
          try {
            this.levelmeter['channel_' + (+level + 1)].update(update.type.level.peakDb[level], update.type.level.decayDb[level])
          } catch (e) {
          }
        }
      } else if (update.stream === this.member.uuid && Object.prototype.hasOwnProperty.call(update.type, 'rtpStatsRx')) {
        try {
          this.rtpSource.update(update.type.rtpStatsRx)
        } catch (e) {
        }
      } else if (update.stream === this.member.uuid && Object.prototype.hasOwnProperty.call(update.type, 'rtpStatsTx')) {
        try {
          this.rtpSink.update(update.type.rtpStatsTx)
        } catch (e) {
        }
      }
    }
  }

  _updateDelay () {
    if (this.hasElement('processing', 'delay')) {
      const d = this.getElement('processing', 'delay').type.processing.type.delay
      this.delay.slider.options.showSelectionBarFromValue = d.actualDelay / 1000
    }
  }

  _checkResetLevelmeter () {
    if (this.member.state === 'paused' || this.member.state === 'inactive' || this.member.state === 'incomplete') {
      for (const key in this.levelmeter) {
        this.levelmeter[key].deactivate()
      }
    } else {
      for (const key in this.levelmeter) {
        this.levelmeter[key].activate()
      }
    }
  }

  _cleanLevelmeterDelayVolumeControl () {
    const validChannelnames = new Set()
    for (let channel = 1; channel < this.member.nChannels; channel++) {
      validChannelnames.add('channel_' + channel)
    }

    Object.keys(this.volumeControl).forEach((key) => {
      if (!validChannelnames.has(key)) {
        delete this.volumeControl[key]
      }
    })

    Object.keys(this.levelmeter).forEach((key) => {
      if (!validChannelnames.has(key)) {
        delete this.levelmeter[key]
      }
    })

    Object.keys(this.delay).forEach((key) => {
      delete this.delay[key]
    })
  }

  _createLevelmeterDelayAndVolumeControl () {
    this._cleanLevelmeterDelayVolumeControl()

    // Iterate through elements
    for (const element of this.elements) {
      // Process levelmeter elements
      if (this.isElement(element, 'processing', 'levelmeter')) {
        this.levelmeter = {}
        for (let u = 0; u < this.channels.length; u++) {
          // Create LevelMeter, associated Array with prefix
          if (this.levelmeter['channel_' + this.channels[u]] === undefined) {
            this.levelmeter['channel_' + this.channels[u]] = new LevelMeter(this.channels[u])
          }
        }

        this._checkResetLevelmeter()
      }

      // Process delay elements
      if (this.isElement(element, 'processing', 'delay')) {
        this.delay = new Delay(element.type.processing.type.delay.targetDelay, this)
      }

      // Process rtpSource elements
      if (this.isElement(element, 'source', 'rtp') || this.isElement(element, 'source', 'aoip')) {
        this.rtpSource = new RtpSource(this)
      }

      // Process rtpSink elements
      if (this.isElement(element, 'sink', 'rtp') || this.isElement(element, 'sink', 'aoip')) {
        this.rtpSink = new RtpSink(this)
      }

      // Process volumecontrol elements
      if (this.isElement(element, 'processing', 'volumecontrol') && element.type.processing.type.volumecontrol.volumes.length > 0) {
        for (let u = 0; u < this.channels.length; u++) {
          // Update, if existing
          if (this.volumeControl['channel_' + this.channels[u]] !== undefined) {
            this.volumeControl['channel_' + this.channels[u]].updateCallback(element.type.processing.type.volumecontrol.volumes[u])
          } else {
            // Otherwise: Ceate new volumeControl
            this.volumeControl['channel_' + this.channels[u]] = new VolumeControl(this.channels[u], element.type.processing.type.volumecontrol.volumes[u], this)
          }
        }
      }
    }
  }
}
