
angular.module('webremote.directive.archive').controller('webremote.directive.archive.fileSearch.controller', function ($scope, fileService, Upload, toastr) {
  $scope.searchOptionsCollapsed = true

  $scope.applySearch = () => {
    const encodedSortOptions = {}

    if ($scope.sortOptionsCopy.searchText) {
      encodedSortOptions.searchText = encodeURIComponent($scope.sortOptionsCopy.searchText)
    } else {
      encodedSortOptions.searchText = ''
    }

    if ($scope.sortOptionsCopy.dateFrom) {
      encodedSortOptions.dateFrom = $scope.sortOptionsCopy.dateFrom.toISOString()
    } else {
      encodedSortOptions.dateFrom = undefined
    }

    if ($scope.sortOptionsCopy.dateTo) {
      encodedSortOptions.dateTo = $scope.sortOptionsCopy.dateTo.toISOString()
    } else {
      encodedSortOptions.dateTo = undefined
    }

    Object.assign($scope.sortOptions, encodedSortOptions)
  }

  $scope.clearSearch = () => {
    const clearedSortOptions = {
      searchText: '',
      dateFrom: undefined,
      dateTo: undefined
    }
    Object.assign($scope.sortOptionsCopy, clearedSortOptions)
    Object.assign($scope.sortOptions, clearedSortOptions)
  }

  $scope.countActiveFilters = () => {
    let counter = 0
    if ($scope.sortOptions.searchText && $scope.sortOptions.searchText.length > 0) counter++
    if ($scope.sortOptions.dateFrom && $scope.sortOptions.dateFrom.length > 0) counter++
    if ($scope.sortOptions.dateTo && $scope.sortOptions.dateTo.length > 0) counter++
    return counter
  }

  $scope.$watch('sortOptions', () => {
    if ($scope.sortOptions) { $scope.sortOptionsCopy = angular.copy($scope.sortOptions) }
  })
}).directive('fileSearch', function () {
  return {
    restrict: 'E',
    scope: {
      sortOptions: '='
    },
    templateUrl: 'directives/archive/fileSearch/template.html'
  }
})
