'use strict'

angular.module('webremote.service.device', []).factory('deviceService', function (httpService) {
  class DeviceService {
    constructor () {
      this.meta = {}
      httpService.callFunction('DeviceInfo').then(function (response) {
        this.meta = response.data
      }.bind(this))
    }
  }

  var service = new DeviceService()

  return service
})
