
angular.module('webremote.directive.sip.sipCall.streamLabel', [])
  .controller('webremote.directive.sip.sipCall.streamLabel.controller', function ($scope, objectManagerService) {
    $scope.om = objectManagerService
    $scope.resolveChannelIdToName = function (type, soundCard, id) {
      let collection = []
      if (type === 'audioInputPort') {
        collection = $scope.om.collectionSync('audioInputPort')
      }

      if (type === 'audioOutputPort') {
        collection = $scope.om.collectionSync('audioOutputPort')
      }

      for (const audioInterface of collection) {
        if (!(audioInterface.getInformationForChannel(soundCard, id) instanceof Error)) {
          return audioInterface.getInformationForChannel(soundCard, id)
        }
      }
    }

    $scope.getChannels = function (phoneBookEntry, direction) {
      const empty = { mapping: [], soundcard: '' }
      try {
        if (direction === 'tx') {
          if (Object.prototype.hasOwnProperty.call(phoneBookEntry, 'member') && phoneBookEntry.member.audio.inputChannels.mapping.length > 0) {
            return phoneBookEntry.member.audio.inputChannels
          }
          if (Object.prototype.hasOwnProperty.call(phoneBookEntry, 'member') && phoneBookEntry.member.audio.txPipeTemplate.length > 0 && $scope.om.objectSync(phoneBookEntry.member.audio.txPipeTemplate, 'stream').hasElement('source', 'jack')) {
            return $scope.om.objectSync(phoneBookEntry.member.audio.txPipeTemplate, 'stream').getElement('source', 'jack').type.source.type.jack.mapping
          }
          return empty
        } else {
          if (Object.prototype.hasOwnProperty.call(phoneBookEntry, 'member') && phoneBookEntry.member.audio.outputChannels.mapping.length > 0) {
            return phoneBookEntry.member.audio.outputChannels
          }
          if (Object.prototype.hasOwnProperty.call(phoneBookEntry, 'member') && phoneBookEntry.member.audio.rxPipeTemplate.length > 0 && $scope.om.objectSync(phoneBookEntry.member.audio.rxPipeTemplate, 'stream').hasElement('sink', 'jack')) {
            return $scope.om.objectSync(phoneBookEntry.member.audio.rxPipeTemplate, 'stream').getElement('sink', 'jack').type.sink.type.jack.mapping
          }
          return empty
        }
      } catch (e) {
        return empty
      }
    }
  }).directive('streamLabel', function () {
    return {
      restrict: 'E',
      transclude: true,
      scope: {
        phoneBookEntry: '=phoneBookEntry',
        active: '=active',
        direction: '=direction'
      },
      templateUrl: 'directives/sip/sipCall/streamLabel/template.html'
    }
  })
