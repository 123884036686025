
import levenary from 'levenary'
import _ from 'lodash'
const module = angular.module('webremote.directive.objectmanager.wizard')

module.controller('webremote.directive.objectmanager.wizard.navigation.controller', ['$scope', 'objectManagerService', 'modalService', function ($scope, objectManagerService, modalService) {
  $scope.invalidSections = []
  $scope.selectItem = (item, mainMenuIndex, subMenuIndex, $event) => {
    $scope.pointer.mainMenuIndex = mainMenuIndex
    $scope.pointer.subMenuIndex = subMenuIndex
  }

  $scope.processListItem = (item) => {
    const itemCopy = angular.copy(item)
    itemCopy.content.configForm.path = item.content.configForm.path + '.elementType.fields'
    itemCopy.content.configForm.itemIndex = $scope.pointer.subMenuIndex - 1
    return itemCopy
  }

  $scope.removeItem = (path, index) => {
    const modalConfig = {
      title: 'Confirm deletion',
      text: 'Are you sure you want to delete the entry ' + ($scope.getList(path)[index].name || 'Unnamed') + ' ?'
    }

    modalService.confirmModal(modalConfig, $scope, function (response) {
      if (response) {
        $scope.getList(path).splice(index, 1)
      }
    })
  }

  $scope.evaluateValidationErrors = () => {
    // TODO: Improvement
    function collectPaths (menuConfig) {
      if (menuConfig === undefined) return
      const result = []
      for (const index in menuConfig) {
        const section = menuConfig[index]
        let paths = []

        // Main path
        if (section.content.configForm) {
          paths.push(section.content.configForm.path)
        }

        // Sub paths - config forms
        if (section.subMenu && section.subMenu.items && section.subMenu.items.length > 0) {
          for (const item of section.subMenu.items.filter(x => x.content.configForm)) {
            if (item.content.configForm !== undefined) {
              paths.push(item.content.configForm.path)
            }
          }
        }

        // Sub path - generic list
        if (section.subMenu && section.subMenu.genericList) {
          paths.push(section.subMenu.genericList.content.configForm.path)
        }
        paths = paths.map(x => x.replace(/\.fields/g, '').replace(/\.elementType/g, ''))
        result.push(paths)
      }

      return result.flat()
    }

    const paths = collectPaths($scope.menuConfig)
    $scope.invalidSections = []
    $scope.$evalAsync(() => {
      for (const error of $scope.object.validationErrors(['error', 'critical'])) {
        $scope.invalidSections.push(levenary(error.path, paths))
      }
    })
  }

  $scope.sectionInvalid = (section) => {
    // TODO: Improvement
    if (section.content.configForm && $scope.invalidSections.includes(section.content.configForm.path.replace(/\.fields/g, '').replace(/\.elementType/g, ''))) {
      return true
    }

    if (
      section.subMenu &&
      section.subMenu.items &&
      section.subMenu.items.filter(x => x.content.configForm).some(x => $scope.invalidSections.includes(x.content.configForm.path.replace(/\.fields/g, '').replace(/\.elementType/g, '')))
    ) {
      return true
    }

    if (
      section.subMenu &&
      section.subMenu.genericList &&
      section.subMenu.genericList.content.configForm &&
      $scope.invalidSections.includes(section.subMenu.genericList.content.configForm.path.replace(/\.fields/g, '').replace(/\.elementType/g, ''))) {
      return true
    }

    return false
  }

  $scope.$watch('object', () => {
    if ($scope.object.member !== undefined && $scope.object.member.objectClass.length > 0) {
      objectManagerService.getScheme($scope.object.member.objectClass).then(schema => { $scope.schema = schema })
    }
  }, true)

  $scope.$watch('object', _.debounce(function () {
    $scope.object.validate().then(() =>
      $scope.evaluateValidationErrors())
  }, 1000)
  , true)

  $scope.$watch('pointer', () => {
    // Process subMenu
    if ($scope.pointer.subMenuIndex > 0 && Number.isInteger($scope.pointer.subMenuIndex)) {
      const type = Object.keys($scope.menuConfig[$scope.pointer.mainMenuIndex].subMenu)[0]

      // Modify List Item before Callback is triggered
      if (type === 'genericList') {
        const item = $scope.menuConfig[$scope.pointer.mainMenuIndex].subMenu[type]
        $scope.selectedItem = $scope.processListItem(item)
      } else {
        // Trigger Callback for list item
        const item = $scope.menuConfig[$scope.pointer.mainMenuIndex].subMenu[type][$scope.pointer.subMenuIndex - 1]
        $scope.selectedItem = item
      }
    // Process mainMenu
    } else if (Number.isInteger($scope.pointer.mainMenuIndex)) {
      $scope.selectedItem = $scope.menuConfig[$scope.pointer.mainMenuIndex]
    }
  }, true)

  $scope.getList = (path) => {
    if (path) {
      return require('object-path').get($scope.object.member, path.replace(/\.fields/g, '').replace(/\.elementType/g, ''))
    } else {
      return []
    }
  }

  $scope.isSubMenuAvailable = (menuItem, type) => {
    return menuItem.subMenu !== undefined && Object.prototype.hasOwnProperty.call(menuItem.subMenu, type)
  }
}])

  .directive('omWizardNavigation', function () {
    return {
      restrict: 'E',
      controller: 'webremote.directive.objectmanager.wizard.navigation.controller',
      scope: {
        menuConfig: '<menuConfig',
        selectedItem: '=selectedItem',
        pointer: '=',
        object: '='
      },
      templateUrl: 'directives/objectmanager/wizard/navigation/template.html'
    }
  })
