'use strict'
import OnDemandJobPool from '../../../class/OnDemandJobPool.class'
angular.module('webremote.module.ondemand').controller('wizardController', function ($scope, $http, $location, objectManagerService, modalService, onDemandRouterService) {
  $scope.item = {}
  $scope.selectedMenuEntry = {}
  $scope.om = objectManagerService
  $scope.activeTab = ''
  $scope.viewLevel = { levelArray: [] }

  $scope.navigationElements = {
    return: {
      label: 'Return to overview',
      disabled: false,
      cb: () => {
        $location.url('/ondemand')
      }
    }
  }

  $http.get('modules/ondemand/menu.json', { cache: false }).then(function (data) {
    $scope.menuConfig = data.data
    $scope.pointer = {
      mainMenuIndex: 0,
      subMenuIndex: 0
    }
  })

  $scope.$watch('onDemandRouterService.activeObject', () => {
    // Get default object, if a new object is to be created
    if (onDemandRouterService.activeObject.length === 0) {
      objectManagerService.getDefault('onDemandJobPool').then(v => {
        $scope.$evalAsync(() => {
          $scope.item = new OnDemandJobPool({ dependencies: objectManagerService.dependencies, obj: angular.copy(v) })
        })
      })
    } else {
    // Get Object, if an existing object is to be edited
      $scope.om.object(onDemandRouterService.activeObject, 'onDemandJobPool').then(v => {
        $scope.item = angular.copy(v)
      })
    }
  })

  $scope.$watch('item', () => {
    // Manage available actions
    if ($scope.item.member === undefined) {
      $scope.actions = {}
    } else {
      if ($scope.item.member.uuid === '') {
        $scope.actions = {
          create: {
            cb: () => {
              objectManagerService.create($scope.item.member.objectClass, $scope.item.member, true).then(() => {
                $location.url('/ondemand')
              })
            }
          }
        }
      } else {
        $scope.actions = {
          update: {
            cb: () => {
              $scope.item.update().then(() => $location.url('/ondemand'))
            }
          },
          destroy: {
            cb: () => {
              const modalConfig = {
                title: 'Confirm deletion',
                text: 'Are you sure you want to delete ' + $scope.item.member.name + ' ?'
              }

              modalService.confirmModal(modalConfig, $scope, function (response) {
                if (response) {
                  $scope.item.destroy().then(() => $location.url('/ondemand'))
                }
              })
            }
          },
          clone: {
            cb: () => {
              $scope.item.clone().then(() => $location.url('/ondemand'))
            }
          }
        }
      }
    }
  }, true)
})
