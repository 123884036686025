angular.module('webremote.dashboard').controller('widget_monitoring', function ($scope, objectManagerService, authenticationService) {
  $scope.om = objectManagerService
  $scope.dashboard = authenticationService.dashboardObject

  $scope.$watch('activeDashboard', function () {
    $scope.objectManager().object($scope.activeDashboard, 'dashboard').then((v) => {
      $scope.dashboard = v
    })
  })

  $scope.objectManager().object($scope.activeDashboard, 'dashboard').then((v) => {
    $scope.dashboard = v

    $scope.$watch('widgetIndex', function () {
      $scope.storedOptions = $scope.dashboard.getInformationForWidget($scope.widgetIndex)
      $scope.pipeView = ($scope.storedOptions.pipeView === undefined ? true : $scope.storedOptions.pipeView)
      $scope.minimized = ($scope.storedOptions.minimized === undefined ? false : $scope.storedOptions.minimized)
    })
  })

  $scope.checkView = function () {
    if (!$scope.pipeView) $scope.minimized = true
  }

  $scope.updateWidgetOptions = function () {
    $scope.storedOptions.pipeView = $scope.pipeView
    $scope.storedOptions.minimized = $scope.minimized
    $scope.dashboard.storeInformationForWidget($scope.widgetIndex, JSON.stringify($scope.storedOptions))
  }
})
