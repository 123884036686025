
angular.module('webremote.directive.streams.listing', [])
  .controller('webremote.directive.streams.listing.controller', function ($scope, $location, PIPELINE, $uibModal, modalService, audioPlayerService, deviceService, clientInfoService, objectManagerService) {
    /*
    'options': {
      'visibility': {
        'dividingLine': true,
        'editor': true,
        'actionBar': true,
        'elements': true,
        'statusIndicator': true,
        'elements': true,
        'multiSelect': true,
        'buttons':{
          'removeFromGroup': true
        }
        'displayMode': 'default'
      }
    }
  */
    $scope.editMode = false
    $scope.audioPlayerService = audioPlayerService
    $scope.clientInfoService = clientInfoService
    $scope.om = objectManagerService

    $scope.$watch('options.displayMode', () => {
      $scope.displayMode = $scope.options.displayMode || 'default'
    })

    $scope.createWorkingCopy = function () {
      $scope.workingCopy = angular.copy($scope.obj)
    }

    $scope.getBorderStyleClass = function (obj) {
      const state = (typeof obj === 'undefined' ? 'invalid' : obj.member.state)
      const pendingState = (typeof obj === 'undefined' ? 'invalid' : obj.member.pendingState)
      const errorMessage = (typeof obj === 'undefined' ? '' : obj.member.errorMessage)
      let feedback = ''
      switch (state) {
        case 'invalid':
        case 'incomplete':
          feedback = 'stateBorder-default'
          break

        case 'inactive':
          if (errorMessage.length > 0) {
            feedback = 'stateBorder-red-blinking'
          } else {
            feedback = 'stateBorder-default'
          }
          break

        case 'waiting':
          feedback = 'stateBorder-orange'
          break

        case 'trying':
          if (pendingState === 'pause' || pendingState === 'playing') {
            feedback = 'stateBorder-orange-blinking'
          } else {
            feedback = 'stateBorder-default'
          }
          break

        case 'paused':
          feedback = 'stateBorder-orange'
          break

        case 'playing':
          feedback = 'stateBorder-green'
          break

        case 'eos':
          feedback = 'stateBorder-default'
          break

        default:
          feedback = 'stateBorder-default'
      }
      return feedback
    }

    $scope.getIcon = function (button, state, pendingState, advancedMode) {
      if (button === 'play') {
        if (state === 'waiting' && pendingState === 'playing') {
          return 'blink glyphicon glyphicon-play'
        } else if (state === 'trying' && pendingState === 'playing') {
          return 'blink glyphicon glyphicon-retweet'
        } else {
          return 'glyphicon glyphicon-play'
        }
      } else if (button === 'pause') {
        if (state === 'trying' && pendingState === 'paused') {
          return 'blink glyphicon glyphicon-retweet'
        } else {
          return 'glyphicon glyphicon-pause'
        }
      } else if (button === 'stop') {
        if (advancedMode) {
          return 'glyphicon glyphicon-refresh'
        } else if (state === 'eos') {
          return 'glyphicon glyphicon-check'
        } else {
          return 'glyphicon glyphicon-stop'
        }
      } else {
        return ''
      }
    }

    $scope.firstKey = (obj) => {
      if (obj !== undefined) {
        return Object.keys(obj)[0]
      } else {
        return ''
      }
    }

    $scope.stopPipe = function (obj) {
      if (obj.member.editingLocked) {
        const modalConfig = {
          title: 'Confirm',
          text: 'Are you sure to stop this pipe?'
        }

        modalService.confirmModal(modalConfig, $scope, function (response) {
          if (response) {
            obj.setState('inactive')
          }
        })
      } else {
        obj.setState('inactive', true)
      }
    }

    $scope.removeStream = function (obj) {
      const modalConfig = {
        title: 'Confirm',
        text: 'Are you sure to delete ' + obj.member.name + '?'
      }

      modalService.confirmModal(modalConfig, $scope, function (response) {
        if (response) {
          obj.destroy()
        }
      })
    }

    $scope.openConfigDialog = function (index) {
      if (!$scope.obj.member.editingLocked) {
        const data = $scope.$new(true)
        data.obj = angular.copy($scope.obj)
        data.elementIndex = index

        $scope.modalInstance = $uibModal.open({
          templateUrl: 'modules/connect/edit/modals/elementConfig/template.html',
          controller: 'modalCtrl',
          scope: data
        })

        $scope.modalInstance.result.then(function (returnedData) {
          // Update element, if no preset is assigned
          if (returnedData.elements[data.elementIndex].preset.length === 0) {
            const payload = []
            payload.push({ op: 'replace', path: '/elements/' + data.elementIndex, value: returnedData.elements[data.elementIndex] })
            payload.push({ op: 'replace', path: '/attributes', value: returnedData.member.attributes })
            $scope.obj.patch(payload)
          }
        }, function (errorData) {
        })
      }
    }

    $scope.handlerKeyDown = function (e) {
      // if shift key, enable advanced mode
      if (e.keyCode === 16) {
        $scope.advancedMode = true
        $scope.$digest()
      }
    }

    $scope.handlerKeyUp = function (e) {
      // if shift key, disable advanced mode
      if (e.keyCode === 16) {
        $scope.advancedMode = false
        $scope.$digest()
      }
    }

    var $doc = angular.element(document)

    $doc.on('keydown', $scope.handlerKeyDown)
    $doc.on('keyup', $scope.handlerKeyUp)

    $scope.advancedMode = false

    $scope.downloadSdp = function (filename, text) {
      var element = document.createElement('a')
      element.setAttribute('download', filename + '.sdp')
      element.setAttribute('href', 'data:application/sdp;charset=utf-8,' + encodeURIComponent(text))

      if (document.createEvent) {
        var event = document.createEvent('MouseEvents')
        event.initEvent('click', true, true)
        element.dispatchEvent(event)
      } else {
        element.click()
      }
    }

    $scope.showLog = function () {
      $scope.modalInstance = $uibModal.open({
        templateUrl: 'directives/debug/journal/modalTemplate.html',
        controller: 'journalOverview',
        size: 'lg',
        scope: $scope
      })
    }
    $scope.isDebug = function () {
      return deviceService.meta.debug
    }
    $scope.edit = function (id) {
      $location.url('connect/edit')
      PIPELINE.setPipeline(id)
    }

    $scope.$on('$destroy', function () {
      $doc.off('keydown', $scope.handlerKeyDown)
      $doc.off('keyup', $scope.handlerKeyUp)
    })
  })

  .controller('journalOverview', function ($scope, $uibModalInstance) {
    $scope.journal = {}
    $scope.journal.title = $scope.obj.member.name
    $scope.journal.ids = [$scope.obj.member.uuid]
    $scope.journal.classes = ['stream']

    $scope.closeModal = function () {
      $uibModalInstance.close()
    }
  })

  .directive('stream', function () {
    return {
      restrict: 'E',
      scope: {
        obj: '=',
        options: '=options'
      },
      templateUrl: 'directives/streams/listing/template.html'
    }
  })
