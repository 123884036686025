
angular.module('webremote.directive.archive').controller('webremote.directive.archive.fileMultiAction.controller', function ($scope, modalService) {
  $scope.multiActionCollapsed = true

  $scope.deleteAction = () => {
    const modalConfig = {
      title: 'Confirm',
      text: 'Are you sure to remove ' + $scope.navigation.currentPath.selectedItems().length + ' elements?'
    }

    modalService.confirmModal(modalConfig, $scope, function (response) {
      if (response) {
        for (const item of $scope.navigation.currentPath.selectedItems()) {
          item.destroy()
        }
      }
    })
  }

  $scope.$watchCollection('navigation.currentPath.selectedItems()', (v) => {
    if (v && v.length > 0) {
      $scope.multiActionCollapsed = false
    } else {
      $scope.multiActionCollapsed = true
    }
  })
}).directive('fileMultiAction', function () {
  return {
    restrict: 'E',
    scope: {
      navigation: '<'
    },
    templateUrl: 'directives/archive/fileMultiAction/template.html'
  }
})
