
angular.module('webremote.directive.streams.multiControl', [])
  .controller('webremote.directive.streams.multiControl.controller', function ($scope, modalService, objectManagerService) {
    $scope.om = objectManagerService
    $scope.confirmMultiControlAction = function (text, action) {
      const modalConfig = {
        title: 'Confirm',
        text: text
      }

      modalService.confirmModal(modalConfig, $scope, function (response) {
        if (response) {
          $scope.multiControl(action)
        }
      })
    }

    $scope.confirmMultiControlStreamGroupAction = function (text, action) {
      const modalConfig = {
        title: 'Confirm',
        text: text
      }

      modalService.confirmModal(modalConfig, $scope, function (response) {
        if (response) {
          $scope.multiControlGroups(action)
        }
      })
    }

    $scope.multiControlGroups = function (state) {
      $scope.om.collection('streamGroup').then(streamGroups => {
        for (const group of $scope.streamGroups) {
          streamGroups.get(group).setState(state)
        }
      })
    }

    $scope.multiControl = function (state) {
      $scope.om.collection('stream').then(streams => {
        for (const stream of Array.from(streams.values())) {
          if (stream.selected) {
            if (state === 'play') {
              stream.setState('playing')
            }

            if (state === 'stop') {
              stream.setState('inactive')
            }

            if (state === 'pause') {
              stream.setState('paused')
            }

            if (state === 'remove') {
              stream.destroy()
            }

            if (state === 'restart') {
              stream.restart()
            }

            if (state === 'lock') {
              stream.lock = true
            }

            if (state === 'unlock') {
              stream.lock = false
            }
          }
        }
      })
    }
  }).directive('streamMultiControl', function () {
    /**
 * @param {string[]} streams - Array of UUIDs from selected Streams
 * @param {string[]} steamActions - Array of visible stream actions
 * @param {string[]} streamGroupAction - Array of visible stream group actions
 */
    return {
      restrict: 'E',
      scope: {
        streams: '=streams',
        streamGroups: '=streamGroups',
        streamActions: '=streamActions',
        streamGroupActions: '=streamGroupActions'
      },
      templateUrl: 'directives/streams/multiControl/template.html'
    }
  })
