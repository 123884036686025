angular.module('webremote.dashboard').controller('widget_playlist',
  function ($scope, toastr, objectManagerService, $uibModal) {
    $scope.om = objectManagerService

    $scope.$watch('activeDashboard', function () {
      $scope.om.object($scope.activeDashboard, 'dashboard').then((v) => {
        $scope.$evalAsync(() => {
          $scope.dashboard = v
        })
      })
    })

    $scope.om.object($scope.activeDashboard, 'dashboard').then((v) => {
      $scope.$evalAsync(() => {
        $scope.dashboard = v

        // Wait, until widgetIndex is set
        $scope.$watch('widgetIndex', function () {
          $scope.loadStoredOptions($scope.widgetIndex)
        })
      })
    })

    $scope.$watch('playlistStream', function (newValue) {
      if (newValue !== undefined) {
        $scope.saveStoredOptions()
      }
    })

    $scope.playStopIndex = function (index) {
      const stream = $scope.om.objectSync($scope.playlistStream, 'stream')
      if (stream.member.state === 'playing' && stream.member.elements[0].type.source.type.file.currentFile === index) {
        stream.member.pendingState = 'inactive'
        stream.update()
      } else {
        if (stream.member.state !== 'inactive') {
          if (stream.member.pendingState !== 'inactive') {
            stream.member.pendingState = 'inactive'
            stream.update()
          }
          setTimeout(function () {
            $scope.playStopIndex(index)
          }, 100)
        } else {
          stream.member.elements[0].type.source.type.file.seekToFile = index
          stream.member.pendingState = 'playing'
          stream.update()
        }
      }
    }

    $scope.filterByFilesource = function (item) {
      if ('source' in item.member.elements[0].type) {
        return 'file' in item.member.elements[0].type.source.type
      }
      return false
    }

    $scope.getClassByState = function (index) {
      const stream = $scope.om.objectSync($scope.playlistStream, 'stream')
      if (stream !== undefined && stream.member.state === 'playing' && stream.member.elements[0].type.source.type.file.currentFile === index) {
        return 'circled-icon-play'
      }
      return ''
    }

    $scope.loadStoredOptions = function (widgetIndex) {
      $scope.storedOptions = $scope.dashboard.getInformationForWidget(widgetIndex)
      // Default: Set playlistStream to ''
      if ($scope.storedOptions.playlistStream === undefined) {
        $scope.storedOptions.playlistStream = ''
      }

      $scope.playlistStream = $scope.storedOptions.playlistStream

      $scope.saveStoredOptions()
    }

    $scope.saveStoredOptions = function () {
      if ($scope.storedOptions !== undefined) {
        $scope.storedOptions.playlistStream = $scope.playlistStream
        $scope.dashboard.storeInformationForWidget($scope.widgetIndex, JSON.stringify($scope.storedOptions))
      }
    }
  }
)
