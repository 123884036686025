import _ from 'lodash'

angular.module('webremote.directive.objectmanager.formElements')
  .controller('webremote.directive.objectmanager.formElements.input.controller', function ($scope, omFormService) {
    $scope.omFormService = omFormService

    $scope.workingCopy = {
      value: ''
    }

    $scope.roundValue = function (value, decimals) {
      try {
        // while typing, do not parse float value
        if (value.toString().endsWith('.') || value.toString().startsWith('-')) {
          return value
        }
        // Round value, if decimals is given
        if (decimals !== undefined) {
          return parseFloat(value).toFixed(decimals)
        } else {
          return parseFloat(value)
        }
      } catch (e) {
        return value
      }
      // Otherwise no manipulation
    }

    $scope.restoreDefaultIfEmpty = function (data, value) {
      $scope.applyValues()
      $scope.ngChange.cancel()
      if ($scope.workingCopy.value !== undefined && !$scope.workingCopy.value.length > 0) {
        $scope.workingCopy.value = $scope.shapeValue(data[value.key])
      }
      $scope.applyValues()
    }

    $scope.preventNullValue = function () {
      if ($scope.schema.type.startsWith('float') || $scope.schema.type.startsWith('int') || $scope.schema.type.startsWith('uint')) {
      // Apply new value, if field is set
        if (!$scope.workingCopy.value.toString().length > 0 || $scope.workingCopy.value.toString() === 'NaN') {
        // Restore default value, if field is empty
          $scope.data[$scope.schema.key] = $scope.schema.default
          $scope.workingCopy.value = $scope.schema.default
          $scope.workingCopy.value = $scope.shapeValue($scope.data[$scope.schema.key])
          return
        }
      }
      if (!($scope.schema.type.startsWith('int') || $scope.schema.type.startsWith('uint')) || $scope.schema.decimals === undefined) {
        $scope.data[$scope.schema.key] = $scope.workingCopy.value
      }
    }

    $scope.ngInit = function () {
      if ($scope.data !== undefined) {
        $scope.workingCopy.value = $scope.shapeValue($scope.data[$scope.schema.key])
      }
    }

    $scope.shapeValue = function (data) {
      if (($scope.schema.type.startsWith('int') || $scope.schema.type.startsWith('uint')) && $scope.schema.decimals !== undefined) {
        return angular.copy(data / Math.pow(10, $scope.schema.decimals))
      } else if ($scope.schema.type.startsWith('float')) {
        return angular.copy($scope.roundValue(data, $scope.schema.decimals))
      } else {
        return angular.copy(data)
      }
    }

    $scope.unshapeValue = function (data) {
      if (($scope.schema.type.startsWith('int') || $scope.schema.type.startsWith('uint')) && $scope.schema.decimals !== undefined) {
        return Math.round(data.toString().replace(',', '.') * Math.pow(10, $scope.schema.decimals))
      } else if ($scope.schema.type.startsWith('float')) {
        $scope.workingCopy.value = $scope.roundValue(data.toString().replace(',', '.'), $scope.schema.decimals)
        return angular.copy(data.toString().replace(',', '.'))
      } else {
        return angular.copy(data)
      }
    }

    $scope.ngChange = _.debounce(() => {
      $scope.applyValues()
      $scope.$apply()
    }, 1500)

    $scope.applyValues = function () {
      $scope.data[$scope.schema.key] = $scope.unshapeValue($scope.workingCopy.value)
      $scope.preventNullValue()
    }

    // On enter key apply settings before modal is applied and closed
    $scope.checkKey = function (keyEvent) {
      if (keyEvent.which === 13) {
        $scope.applyValues()
        $scope.ngChange.cancel()
      }
    }

    $scope.ngModelOptions = function () {
      // Model Options for numbers
      if ($scope.schema.type.startsWith('float') || $scope.schema.type.startsWith('int') || $scope.schema.type.startsWith('uint')) {
        return { updateOn: 'default', allowInvalid: true }
      } else {
        return {}
      }
    }

    $scope.$watch('data[schema.key]', $scope.ngInit)
  })

  .directive('omConfigformInput', function () {
    return {
      restrict: 'E',
      transclude: 'true',
      scope: {
        data: '=data',
        schema: '<',
        objectPath: '<',
        selector: '<',
        object: '<'
      },
      templateUrl: 'directives/objectmanager/formElements/input/template.html'
    }
  })
