
angular.module('webremote.directive.archive').controller('webremote.directive.archive.filePager.controller', function ($scope) {
  $scope.increasePage = () => {
    $scope.sortOptions.page++
  }

  $scope.decreasePage = () => {
    $scope.sortOptions.page--
  }
}).directive('filePager', function () {
  return {
    restrict: 'E',
    scope: {
      directory: '=',
      sortOptions: '='
    },
    templateUrl: 'directives/archive/filePager/template.html'
  }
})
