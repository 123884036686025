'use strict'

angular.module('webremote.service.audioInterface', []).factory('audioInterfaceService', function ($http, $q, $rootScope, toastr, httpService, webSocketService, objectManagerService) {
  class AudioInterfaceCollection {
    constructor () {
      var obj = this
      obj.input = {}
      obj.output = {}
    }

    getActiveChannels (channelType) {
      return Object.values(this[channelType]).filter(channel => {
        const portType = Object.keys(channel.audioInputPort.type)[0]

        if ((channel.audioInputPort.type[portType] !== null && channel.audioInputPort.type[portType].active) || channel.audioInputPort.monitorLevel) {
          return true
        } else {
          return false
        }
      })
    }

    sortByChannel (data) {
      return data.sort((a, b) => {
        const getChannel = function (v) {
          const channelType = Object.keys(v.audioInputPort.channels)[0]
          if (channelType === 'mono') {
            return v.audioInputPort.channels.mono.id
          } else {
            return v.audioInputPort.channels.stereo.idL
          }
        }

        if (getChannel(a).match(/\d+/)[0] !== undefined && getChannel(b).match(/\d+/)[0] !== undefined) {
          return parseInt(getChannel(a).match(/\d+/)[0]) > parseInt(getChannel(b).match(/\d+/)[0])
        } else {
          if ([getChannel(a), getChannel(b)].sort()[0] === getChannel(a)) {
            return -1
          } else {
            return 1
          }
        }
      })
    }
  }

  class SoundCardCollection {
    constructor () {
      this.soundCards = {}
    }

    isAesAvailable () {
      for (const uuid in this.soundCards) {
        if (Object.prototype.hasOwnProperty.call(this.soundCards[uuid].soundCard.type, 'geminiAes')) {
          return true
        }
      }
      return false
    }
  }

  class SoundCard {
    constructor (data) {
      this.soundCard = data

      if (Object.prototype.hasOwnProperty.call(data.type, 'geminiAes')) {
        this.aesPortArray = this.convertArray(data.type.geminiAes)
      }
    }

    updateCb (obj) {
      this.soundCard = obj

      if (Object.prototype.hasOwnProperty.call(obj.type, 'geminiAes')) {
        this.aesPortArray = this.convertArray(obj.type.geminiAes)
      }
    }

    convertArray (input) {
      const result = []
      for (const ch in input) {
        result.push({ key: ch, value: input[ch] })
      }
      return result
    }

    async update () {
      return httpService.callFunction('objects/soundCard/' + this.soundCard.uuid, this.soundCard, 'PUT').then(function (serviceData) {

      }, function (errorData) {
        toastr.error(errorData, 'Error', {
          closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
        })
      })
      // TODO: send update to backend
    }
  }

  class AudioInputPort {
    constructor (data) {
      this.audioInputPort = data
      this.levelMeter = {}

      // Store Slider Option for RZSlider
      this.sliderOptions = {
        disabled: this.Disabled,
        floor: -62,
        ceil: 0,
        step: 1,
        showSelectionBar: false,
        hideLimitLabels: true,
        vertical: true,
        hidePointerLabels: true,
        onlyBindHandles: true,
        showTicks: true,
        ticksArray: [-60, -50, -40, -30, -20, -10, 0],
        showTicksValues: true,
        id: 'audioInterfaceSlider_' + this.audioInputPort.uuid,
        onEnd: function () {
          this.update()
        }.bind(this)
      }
      this.sliderOptionsGeneric = {
        disabled: this.Disabled,
        floor: 0,
        ceil: 100,
        step: 1,
        showSelectionBar: false,
        hideLimitLabels: true,
        vertical: true,
        hidePointerLabels: true,
        onlyBindHandles: true,
        showTicks: 5,
        showTicksValues: 50,
        id: 'audioInterfaceSlider_' + this.audioInputPort.uuid,
        onEnd: function () {
          this.update()
        }.bind(this)
      }
      this.sliderOptionsDigigram = {
        disabled: false,
        floor: 0,
        ceil: 56,
        step: 1,
        showSelectionBar: false,
        hideLimitLabels: true,
        vertical: true,
        hidePointerLabels: true,
        onlyBindHandles: true,
        showTicks: 5,
        showTicksValues: 10,
        id: 'audioInterfaceSlider_' + this.audioInputPort.uuid,
        onEnd: function () {
          this.update()
        }.bind(this)
      }
    }

    get Disabled () {
      if ((Object.prototype.hasOwnProperty.call(this.audioInputPort.type, 'geminiAnalogMic') && this.audioInputPort.type.geminiAnalogMic.active) ||
        (Object.prototype.hasOwnProperty.call(this.audioInputPort.type, 'geminiAnalogLine') && this.audioInputPort.type.geminiAnalogLine.active) ||
        (Object.prototype.hasOwnProperty.call(this.audioInputPort.type, 'genericMicLevMut'))) {
        return false
      } else {
        return true
      }
    }

    set mute (state) {
      if (Object.prototype.hasOwnProperty.call(this.audioInputPort.type, 'geminiAnalogMic')) {
        this.audioInputPort.type.geminiAnalogMic.mute = state
      }
      if (Object.prototype.hasOwnProperty.call(this.audioInputPort.type, 'geminiAnalogLine')) {
        this.audioInputPort.type.geminiAnalogLine.mute = state
      }
      if (Object.prototype.hasOwnProperty.call(this.audioInputPort.type, 'focusriteScarlettiLevPam')) {
        this.audioInputPort.type.focusriteScarlettiLevPam.mute = state
      }
      if (Object.prototype.hasOwnProperty.call(this.audioInputPort.type, 'focusriteScarlettiPam')) {
        this.audioInputPort.type.focusriteScarlettiPam.mute = state
      }
      if (Object.prototype.hasOwnProperty.call(this.audioInputPort.type, 'focusriteScarlettiM')) {
        this.audioInputPort.type.focusriteScarlettiM.mute = state
      }
      if (Object.prototype.hasOwnProperty.call(this.audioInputPort.type, 'genericMicLevMut')) {
        this.audioInputPort.type.genericMicLevMut.mute = state
      }

      // TODO: mute state audioport
      this.update()
    }

    get mute () {
      if (Object.prototype.hasOwnProperty.call(this.audioInputPort.type, 'geminiAnalogMic')) {
        return this.audioInputPort.type.geminiAnalogMic.mute
      }
      if (Object.prototype.hasOwnProperty.call(this.audioInputPort.type, 'geminiAnalogLine')) {
        return this.audioInputPort.type.geminiAnalogLine.mute
      }
      if (Object.prototype.hasOwnProperty.call(this.audioInputPort.type, 'focusriteScarlettiLevPam')) {
        return this.audioInputPort.type.focusriteScarlettiLevPam.mute
      }
      if (Object.prototype.hasOwnProperty.call(this.audioInputPort.type, 'focusriteScarlettiPam')) {
        return this.audioInputPort.type.focusriteScarlettiPam.mute
      }
      if (Object.prototype.hasOwnProperty.call(this.audioInputPort.type, 'focusriteScarlettiM')) {
        return this.audioInputPort.type.focusriteScarlettiM.mute
      }
      if (Object.prototype.hasOwnProperty.call(this.audioInputPort.type, 'genericMicLevMut')) {
        return this.audioInputPort.type.genericMicLevMut.mute
      }
    }

    set phantom48v (state) {
      // TODO: set volume audioport
      this.update()
    }

    async update () {
      return httpService.callFunction('objects/audioInputPort/' + this.audioInputPort.uuid, this.audioInputPort, 'PUT').then(function (serviceData) {

      }, function (errorData) {
        toastr.error(errorData, 'Error', {
          closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
        })
      })
      // TODO: send update to backend
    }

    updateCb (obj) {
      this.audioInputPort = obj
      this.sliderOptions.disabled = this.Disabled
    }
  }

  class AudioOutputPort {
    constructor (data) {
      this.audioOutputPort = data

      /*
        // Store Slider Option for RZSlider
        this.sliderOptions = {
          floor: data.limits.min,
          ceil: data.limits.max,
          step: data.limits.step,
          showSelectionBar: true,
          hideLimitLabels: true,
          vertical: true,
          hidePointerLabels: true,
          showTicks: 10,
          showTicksValues: false,
          id: 'audioInterfaceSlider_' + this.audioInputPort.uuid,
          onEnd: function () {
            this.update()
          }
        }
        */
    }

    set muteL (state) {
      // TODO: mute state audioport
      this.update()
    }

    set muteR (state) {
      // TODO: mute state audioport
      this.update()
    }

    set volumeR (dB) {
      // TODO: set volume audioport
      this.update()
    }

    set volumeL (dB) {
      // TODO: set volume audioport
      this.update()
    }

    update () {
      // TODO: send update to backend
    }

    updateCb (obj) {
      this.audioOutputPort = obj
    }
  }

  var service = {}

  service.collection = new AudioInterfaceCollection()
  service.soundCardCollection = new SoundCardCollection()

  // Fetch audioInputPorts
  objectManagerService.collection('audioInputPort').then((collection) => {
    for (const audioInputPort of collection.values()) {
      service.collection.input[audioInputPort.member.uuid] = new AudioInputPort(audioInputPort.member)
    }
  })

  // Fetch audioOutputPort
  objectManagerService.collection('audioOutputPort').then((collection) => {
    for (const audioOutputPort of collection.values()) {
      service.collection.output[audioOutputPort.member.uuid] = new AudioOutputPort(audioOutputPort.member)
    }
  })

  // Fetch soundcards
  objectManagerService.collection('soundCard').then((collection) => {
    for (const soundcard of collection.values()) {
      service.soundCardCollection.soundCards[soundcard.member.uuid] = new SoundCard(soundcard.member)
    }
  })

  // Decorators for external access
  service.GetInterfaceColleciton = function () {
    return service.collection
  }

  service.GetSoundCardColleciton = function () {
    return service.soundCardCollection
  }

  // WebSocket callbacks

  service.add = function (obj) {
    if (obj.objectClass === 'audioOutputPort') {
      service.collection.output[obj.uuid] = new AudioOutputPort(obj)
    }

    if (obj.objectClass === 'audioInputPort') {
      service.collection.input[obj.uuid] = new AudioInputPort(obj)
    }

    if (obj.objectClass === 'soundCard') {
      service.soundCardCollection.soundCards[obj.uuid] = new SoundCard(obj)
    }
  }

  service.update = function (obj) {
    if (obj.objectClass === 'audioOutputPort') {
      service.collection.output[obj.uuid].updateCb(obj)
    }

    if (obj.objectClass === 'audioInputPort') {
      service.collection.input[obj.uuid].updateCb(obj)
    }

    if (obj.objectClass === 'soundCard') {
      service.soundCardCollection.soundCards[obj.uuid].updateCb(obj)
    }
  }

  service.remove = function (obj) {
    if (obj.objectClass === 'audioOutputPort') {
      delete service.collection.output[obj.uuid]
    }

    if (obj.objectClass === 'audioInputPort') {
      delete service.collection.input[obj.uuid]
    }

    if (obj.objectClass === 'soundCard') {
      delete service.soundCardCollection.soundCards[obj.uuid]
    }
  }

  service.updatePeaks = function (obj) {
    // Parse to array
    try {
      obj = JSON.parse(obj)
    } catch (e) { }

    for (const channel in service.collection.input) {
      if (service.collection.input[channel].audioInputPort.card === obj.soundcard && Object.prototype.hasOwnProperty.call(service.collection.input[channel].audioInputPort.channels, 'mono')) {
        const id = service.collection.input[channel].audioInputPort.channels.mono.id.replace('system:capture_', '')
        service.collection.input[channel].levelMeter.l = obj.peaks[id - 1]
      }

      if (service.collection.input[channel].audioInputPort.card === obj.soundcard && Object.prototype.hasOwnProperty.call(service.collection.input[channel].audioInputPort.channels, 'stereo')) {
        const idL = service.collection.input[channel].audioInputPort.channels.stereo.idL.replace('system:capture_', '')
        const idR = service.collection.input[channel].audioInputPort.channels.stereo.idR.replace('system:capture_', '')
        service.collection.input[channel].levelMeter.l = obj.peaks[idL - 1]
        service.collection.input[channel].levelMeter.r = obj.peaks[idR - 1]
      }
    }
  }

  // WebSocket callbacks for asynchron events
  webSocketService.on('ObjectOnCreate', service.add)
  webSocketService.on('ObjectOnDestroy', service.remove)
  webSocketService.on('ObjectOnUpdate', service.update)
  webSocketService.on('Peaks', service.updatePeaks)

  return service
})
