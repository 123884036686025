
angular.module('webremote.directive.archive').controller('webremote.directive.archive.fileDetails.controller', function ($scope, httpService, fileService, modalService, $uibModal, toastr, audioPlayerService) {
  $scope.$watch('file', () => {
    if ($scope.file !== undefined) {
      // Request metadata (ID3), if mp3
      if ($scope.file.fileType === 'mp3') {
        $scope.file.requestMetaData()
      }
    }
  })

  $scope.downloadFile = function () {
    var element = document.createElement('a')
    element.setAttribute('download', $scope.name)
    element.setAttribute('href', encodeURI(httpService.webapiUrl + 'archive/' + fileService.encodePath($scope.file.parent.path) + '/' + $scope.file.name))
    if (document.createEvent) {
      var event = document.createEvent('MouseEvents')
      event.initEvent('click', true, true)
      element.dispatchEvent(event)
    } else {
      element.click()
    }
  }

  $scope.deleteFile = function () {
    const modalConfig = {
      title: 'Confirm',
      text: 'Are you sure to delete ' + $scope.file.name + '?'
    }

    modalService.confirmModal(modalConfig, $scope, function (response) {
      if (response) {
        $scope.file.destroy()
      }
    })
  }

  $scope.playFile = function () {
    audioPlayerService.control.setPlayObject({ path: encodeURI('/' + fileService.encodePath($scope.file.parent.path)), name: encodeURI($scope.file.name) }, $scope.file.meta)
  }

  $scope.analyzerModal = function () {
    $scope.modalInstance = $uibModal.open({
      templateUrl: 'modules/archive/analyzer/template.html',
      controller: 'webremote.archive.analyzer.controller',
      size: 'lg',
      scope: $scope
    })
  }
}).directive('fileDetails', function () {
  return {
    restrict: 'E',
    scope: {
      file: '='
    },
    templateUrl: 'directives/archive/fileDetails/template.html'
  }
})
