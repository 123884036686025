import '@babel/polyfill'
import angular from 'angular'
import 'angular-ui-sortable'
import uiValidate from 'angular-ui-validate'
window.jQuery = require('jquery')
window._ = require('lodash')

require('jquery-ui/ui/widgets/sortable')
require('jquery-ui-touch-punch')
require('ng-tags-input')
require('moment')
require('moment-timezone')
require('chosen-js')
require('@kevcoq/angular-timezone-selector')
require('./assets/js/angular-codearea')
require('angular-bootstrap-toggle/src/angular-bootstrap-toggle')
require('angular-base64-upload')
require('angular-scroll')
require('angular-long-press')

// Import CSS
require('./assets/css/bootstrap.min.css')
require('./assets/css/rzslider.min.css')
require('./assets/css/angular-toastr.css')
require('./assets/css/main.css')
require('@kevcoq/angular-timezone-selector/dist/angular-timezone-selector.min.css')
require('./assets/css/angular-ui-notification.min.css')
require('./assets/css/csshake.css')
require('./assets/css/angular-bootstrap-toggle.min.css')
require('./assets/css/angular-codearea.css')

// Import Services
require('./services/module')

// Import Helper
require('./services/helper/schemeParser')

// Import Directives
require('./directives/streams/listing/stream')
require('./directives/streams/multiControl/controller')
require('./directives/sip/sipCall/listing/controller')
require('./directives/sip/sipCall/streamLabel/controller')
require('./directives/streams/elementIcon/elementIcon')
require('./directives/archive/module')
require('./directives/debug/journal/journalList')
require('./directives/debug/logHistogram/controller')
require('./directives/rightsManagement/uac')
require('./directives/objectmanager/module')
require('./directives/streamGroups/pipepicker/controller')
require('./directives/general/logComment/controller')
require('./directives/onDemand/module')
require('./directives/navigation/module')

// Import Modules
require('./app.controller')
require('./modules/fallback/controller_fallback')

require('./modules/monitoring/module')
require('./modules/ondemand/module')
require('./modules/layout/module')

require('./modules/login/module')
require('./modules/login/controller_login')

require('./modules/debug/module')

require('./modules/dashboard/module')
require('./modules/dashboard/controller_dashboard')
require('./modules/dashboard/controller_modalConfig')

require('./modules/applets/module')
require('./modules/archive/module')
require('./modules/phonebook/module')
require('./modules/connect/module')
require('./modules/settings/module')

// Namensvergabe und erstellen von Paketabhängigkeiten
var app = angular.module('webremote', [
  require('angular-route'),
  require('angular-toastr'),
  require('angular-toarrayfilter'),
  require('angular-ui-bootstrap'),
  require('angular-animate'),
  require('angular-sanitize'),
  require('angular-filter'),
  require('angularjs-slider'),
  require('angular-ui-notification'),
  require('angular-svg-round-progressbar'),
  require('ng-file-upload'),
  uiValidate,
  'pr.longpress',
  'duScroll',
  'naif.base64',
  'ui.toggle',
  'ui.sortable',
  'webremote.service',
  'ngTagsInput',
  'ivl.angular-codearea',
  'webremote.app',
  'webremote.monitoring',
  'webremote.layout',
  'webremote.fallback',
  'webremote.objectManager.schemeParser',
  'webremote.archive',
  'webremote.login',
  'webremote.settings',
  'webremote.phonebook',
  'webremote.dashboard',
  'webremote.connect',
  'webremote.debug',
  'webremote.applets',
  'webremote.module.ondemand',
  'webremote.directive.navigation',
  'webremote.directive.onDemand',
  'webremote.directive.objectmanager',
  'webremote.directive.streams.multiControl',
  'webremote.directive.streams.listing',
  'webremote.directive.streams.elementIcon',
  'webremote.directive.streamGroups.pipepicker',
  'webremote.directive.general.logComment',
  'webremote.directive.archive',
  'webremote.directive.debug.journalList',
  'webremote.directive.debug.logHistogram',
  'webremote.directive.rightsManagement.uac',
  'webremote.directive.sip.sipCall.listing',
  'webremote.directive.sip.sipCall.streamLabel'])

// Redirect, if Error Code 401 is present
app.factory('authHttpResponseInterceptor', ['$q', '$location', '$injector', function ($q, $location, $injector) {
  return {
    response: function (response) {
      if (response.status === 401) {
        $injector.get('authenticationService').authenticated = false
      }
      return response || $q.when(response)
    },
    responseError: function (rejection) {
      if (rejection.status === 401) {
        $injector.get('authenticationService').authenticated = false
        $location.path('/login')
      }
      return $q.reject(rejection)
    }
  }
}])

app.config(['$httpProvider', 'toastrConfig', '$routeProvider', 'NotificationProvider', function ($httpProvider, toastrConfig, $routeProvider, NotificationProvider) {
  $routeProvider.when('/login', {
    controller: 'loginCtrl',
    templateUrl: 'modules/login/index.html',
    hideMenus: true
  }).when('/:name*', {
    templateUrl: function (urlattr) {
      return 'modules/' + urlattr.name
    }
  }).when('/', {
    redirectTo: 'dashboard'
  }).otherwise({
    redirectTo: 'login'
  })

  $httpProvider.interceptors.push('authHttpResponseInterceptor')

  angular.extend(toastrConfig, {
    allowHtml: true,
    preventOpenDuplicates: true,
    positionClass: 'toast-bottom-right'
  })

  NotificationProvider.setOptions({
    delay: false,
    startTop: 20,
    startRight: 10,
    verticalSpacing: 20,
    horizontalSpacing: 20,
    positionX: 'right',
    positionY: 'bottom'
  })
}])

app.config(function ($logProvider) {
  $logProvider.debugEnabled(true)
})

app.run(['$rootScope', '$http', '$templateCache', function ($rootScope, $http, $templateCache) {
  $rootScope.needReboot = false
  $rootScope.deviceInfo = {}
  $rootScope.currentDate = new Date()
  $rootScope.Object = {
    keys: Object.keys
  }

  $http.get('modules/fallback', {
    cache: $templateCache
  })

  $rootScope.deviceError = {
    status: {
      user: false,
      ethernet: false,
      codec: false
    },
    message: ''
  }
}])
