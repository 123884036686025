angular.module('webremote.debug').controller('methodClassesController',
  function ($scope, $uibModal, toastr, $log, $document, objectManagerService) {
    $scope.objectManager = objectManagerService.getObjectManager()
    $scope.classes = []
    $scope.selectedClass = undefined
    $scope.selectedObject = {}
    $scope.viewLevel = { levelArray: [] }
    $scope.returnedObject = {}
    $scope.errorData = {}

    $scope.objectManager.getMetaMethods().then((v) => {
      for (const methodTitle of v) {
        $scope.objectManager.getScheme(methodTitle).then((methodData) => {
          const methodObject = {}
          methodObject.data = methodData
          methodObject.title = methodTitle
          $scope.classes.push(methodObject)
        })
      }
    })

    $scope.selectClass = function (methodObj) {
      $scope.selectedClass = methodObj
      $scope.errorData = {}
      $scope.returnedObject = {}
      $scope.newObject()
    }

    $scope.newObject = function () {
      $scope.objectManager.getDefault($scope.selectedClass.title).then(v => {
        $scope.$evalAsync(function () {
          $scope.selectedObject = { member: v }
        })
      })
    }

    $scope.callMethod = {
      cb: () => {
        $scope.selectedObject.member.methodClass = $scope.selectedClass.title
        $scope.objectManager.callMethod($scope.selectedObject.member.methodClass, $scope.selectedObject.member, { notifications: true, exceptions: true }).then(function (data) {
          $scope.returnedObject = { member: data }
          $scope.returnedObject.title = $scope.selectedClass.title
          $scope.errorData = {}
        }, function (errorData) {
          $scope.returnedObject = { }
          $scope.errorData = errorData
        })
      }
    }
  })
