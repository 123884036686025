
angular.module('webremote.directive.objectmanager')
  .controller('webremote.directive.objectmanager.genericEditModal.views.single.controller', function ($scope, $uibModalInstance, objectManagerService) {
    $scope.objectManager = objectManagerService.getObjectManager
    $scope.viewLevel = { levelArray: [] }
    $scope.object = {}
    $scope.data = {}

    if ($scope.omModalUuid && $scope.omModalClass) {
      objectManagerService.object($scope.omModalUuid, $scope.omModalClass).then(v => {
        $scope.object = v
      })
    } else {
      $scope.object = { member: { uuid: '' } }
    }

    $scope.getData = () => {
      if ($scope.omModalDataWorkingCopy) {
        return $scope.omModalDataWorkingCopy
      } else {
        return $scope.object.member
      }
    }

    $scope.actions = {
      close: {
        cb: () => {
          $uibModalInstance.dismiss()
        }
      },
      create: {
        cb: () => {
          if ($scope.omModalLiveUpdate) {
            $scope.objectManager.create($scope.omModalClass, $scope.object.member, true).then((v) => {
              $scope.object.member.uuid = v
              if ($scope.omModalEvents && $scope.omModalEvents.update) $scope.omModalEvents.update($scope.getData())
              $uibModalInstance.close()
            })
          } else {
            if ($scope.omModalEvents && $scope.omModalEvents.update) $scope.omModalEvents.update($scope.getData())
            $uibModalInstance.close()
          }
        }
      },
      update: {
        cb: () => {
          if ($scope.omModalLiveUpdate && $scope.object.member.uuid.length > 0) {
            $scope.object.update().then(() => {
              if ($scope.omModalEvents && $scope.omModalEvents.update) $scope.omModalEvents.update($scope.getData())
              $uibModalInstance.close()
            })
          } else {
            if ($scope.omModalEvents && $scope.omModalEvents.update) $scope.omModalEvents.update($scope.getData())
            $uibModalInstance.close()
          }
        }
      }
    }
  })
