angular.module('webremote.connect').controller('generalOptionsCtrl', function ($scope, $uibModalInstance) {
  $scope.invalidForm = function () { $scope.configFormValidationState = false }
  $scope.validForm = function () { $scope.configFormValidationState = true }

  $scope.checkKey = function (keyEvent) {
    if (keyEvent.which === 13) { $scope.closeModal() }
  }

  $scope.closeModal = function () {
    $uibModalInstance.close($scope.stream)
  }

  $scope.dismissModal = function () {
    $uibModalInstance.dismiss()
  }
})
