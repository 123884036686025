import { groupByOption } from '../../../../directives/debug/logHistogram/DataLoader.class'
const { default: Column } = require('./Column.class')

const module = angular.module('webremote.dashboard')

module.controller('widget_onDemandStatistics',
  function ($scope, $window, objectManagerService) {
    $scope.om = objectManagerService
    $scope.columns = {}

    // Dot options
    $scope.positionYOptions = {
      min: 70,
      max: 220,
      spacing: 10
    }

    // Histogram options
    $scope.groupBy = groupByOption.UUID

    // Preload onDemandStatistics
    $scope.om.collection('onDemandStatistics').then(v => {
      $scope.initDone = true
    })

    // Helper to flat pool/jobs arrays
    $scope.getJobs = function () {
      return $scope.om.collectionSync('onDemandStatistics')
        .flatMap(statistics =>
          statistics.member.currentJobs.map((job, index) => {
            job.pool = statistics.member.jobPool
            job.statistics = statistics.member.uuid
            job.index = index
            return job
          }))
    }

    // Helper: trigger countActiveJobs for all registered columns
    $scope.countActiveJobs = () => {
      $scope.$evalAsync(() => {
        for (const column in $scope.columns) {
          $scope.columns[column].countActiveJobs($scope.om.collectionSync('onDemandStatistics'))
        }
      })
    }

    // Animate dots
    $scope.moveDots = function (onDemandStatistics, ms) {
      $scope.$evalAsync(() => {
        for (const job of $scope.getJobs()) {
          for (const column in $scope.columns) {
            if ($scope.columns[column].states.includes(job.state)) {
              const xPosition = $scope.columns[column].xPosition
              angular.element('#' + job.pool + '_' + job.index).animate({ left: xPosition + 'px' }, ms)
            }
          }
        }
      })
    }

    // Position dot on the Y axis (prevent overlapping)
    $scope.setYPosition = function (element) {
      $scope.om.collection('onDemandStatistics').then(onDemandStatistics => {
        // Loop through any possible position
        for (let i = $scope.positionYOptions.min; i < $scope.positionYOptions.max; i += $scope.positionYOptions.spacing) {
          // Check, if any job is positioned at current position i
          const jobsAtPosition = $scope.getJobs().filter(x => {
            try {
              return angular.element('#' + x.pool + '_' + x.index)[0].style.top === i + 'px'
            } catch (error) {
              return false
            }
          })

          // If not, set current element
          if (jobsAtPosition.length === 0) {
            element[0].style.display = 'block'
            element[0].style.top = i + 'px'
            break
          }
        }
      })
    }

    $scope.$watch("om.collectionSync('onDemandStatistics')", function (newVal) {
      $scope.moveDots(newVal, 500)
      $scope.countActiveJobs()
    }, true)

    $scope.$watchCollection('columns', (newValue) => {
      $scope.countActiveJobs()
    })

    $window.onresize = function () {
      for (const column in $scope.columns) {
        $scope.columns[column].calcxPosition()
      }
      $scope.moveDots($scope.om.collectionSync('onDemandStatistics'), 0)
    }

    $scope.$on('$destroy', function () {
      // Remove onresize listener
      window.onresize = null
    })
  })

module.directive('dot', function () {
  return function (scope, element, attrs) {
    scope.setYPosition(element)

    for (const column in scope.columns) {
      scope.columns[column].calcxPosition()
    }

    scope.moveDots(scope.om.collectionSync('onDemandStatistics'), 0)
  }
})

module.directive('column', function () {
  return function (scope, element, attrs) {
    scope.columns[attrs.name] = new Column(attrs.name, attrs.states)
    element[0].id = 'column_' + attrs.name

    for (const column in scope.columns) {
      scope.columns[column].calcxPosition()
    }

    scope.moveDots(scope.om.collectionSync('onDemandStatistics'), 0)
  }
})
