
const app = angular.module('webremote.app', [])
app.controller('appCtrl', function ($scope, objectManagerService, deviceService) {
  $scope.objectManager = objectManagerService.getObjectManager()
  $scope.deviceService = deviceService


  $scope.getTitle = function (hideWebremote = false) {
    const defaultTitle = 'WebRemote'
    let title = ''
    try {
      const settingsObj = $scope.objectManager.objectSync('d7da1bf7-284d-4b6b-a3bc-34791a050bc3', 'systemConfig')
      title = settingsObj.member.webRemote.webTitle
      if (title.length === 0) {
        title = settingsObj.member.hostname
      }
    } catch (e) {
      if (deviceService.meta.hostname) {
        return deviceService.meta.hostname
      } else {
        return defaultTitle
      }
    }

    if (title.length === 0) {
      return defaultTitle
    } else if (hideWebremote) {
      return title
    } else {
      return title + ' - WebRemote'
    }
  }
})
