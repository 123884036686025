import BaseClass from './Base.class'

export default class SipCall extends BaseClass {
  async hangUp () {
    // apply changes
    return this.destroy().then((v) => {
      // Close audio player, if current playback item is txPipe or rxPipe
      const audioPlayerService = this._dependencies.$injector.get('audioPlayerService')
      audioPlayerService.control.streamStoppedCallback(this.member.txPipe)
      audioPlayerService.control.streamStoppedCallback(this.member.rxPipe)
      return true
    }, (error) => {
      this._dependencies.toastr.error("Can't hangup call", 'Error', {
        closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
      })

      return error
    })
  }

  async accept () {
    // Set values
    this.member.accept = true

    // apply changes
    return this.update().then((v) => {
      return true
    }, (error) => {
      this._dependencies.toastr.error("Can't accept call", 'Error', {
        closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
      })

      return error
    })
  }

  async decline () {
    this.destroy().then((v) => {
      return true
    }, (error) => {
      this._dependencies.toastr.error("Can't decline call", 'Error', {
        closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
      })

      return error
    })
  }
}
