const module = angular.module('webremote.directive.objectmanager.wizard')

module.controller('webremote.directive.objectmanager.wizard.content.controller', ['$scope', 'objectManagerService', 'httpService', '$uibModal', function ($scope, objectManagerService, httpService, $uibModal) {
  $scope.getContentType = function () {
    try {
      return Object.keys($scope.menuItem.content)[0]
    } catch (e) {
      return ''
    }
  }

  $scope.updateDataSet = () => {
    if ($scope.getContentType() === 'configForm') {
      // Remove schema specific information
      let path = $scope.menuItem.content.configForm.path.replace(/\.fields/g, '').replace(/\.elementType/g, '')

      // Process optional index parameter of an array
      if ($scope.menuItem.content.configForm.itemIndex !== undefined) {
        path += '.' + $scope.menuItem.content.configForm.itemIndex
      }

      // Select dataSection
      $scope.currentDataSection = require('object-path').get($scope.object.member, path)
    }
  }

  $scope.$watch('menuItem', () => {
    $scope.updateDataSet()
  })

  $scope.$watch('scope', () => {
    $scope.updateDataSet()
  }, true)
}])

  .directive('omWizardContent', function () {
    return {
      restrict: 'E',
      controller: 'webremote.directive.objectmanager.wizard.content.controller',
      scope: {
        menuItem: '<menuItem',
        object: '=',
        level: '='
      },
      templateUrl: 'directives/objectmanager/wizard/content/template.html'
    }
  })
