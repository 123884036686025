'use strict'

angular.module('webremote.service.audioport', []).factory('audioportService', function ($q, $log, httpService, webSocketService) {
  const service = {}
  service.ports = { source: {}, sink: {} }

  class AudioPort {
    constructor (data) {
      var obj = this
      obj.channel = data.channel
      obj.connections = data.connections
      obj.elm_id = data.elm_id
      obj.is_physical = data.is_physical
      obj.name = data.name
      obj.pending_connections = data.pending_connections

      obj.update = function (data) {
        obj.channel = data.channel
        obj.connections = data.connections
        obj.elm_id = data.elm_id
        obj.is_physical = data.is_physical
        obj.name = data.name
        obj.pending_connections = data.pending_connections
      }
    }
  }

  // Function: Fetch Ports
  service.fetchPorts = function () {
    return $q(function (resolve, reject) {
      httpService.callFunction('GetAudioPorts').then(function (data) {
        for (const port of data.data.sink_ports) {
          service.ports.sink[port.name] = new AudioPort(port)
        }

        for (const port of data.data.src_ports) {
          service.ports.source[port.name] = new AudioPort(port)
        }

        resolve(service.ports)
      }, function (errorData) {
        // TODO?
      })
    })
  }

  // CallBack Function for File Object
  service.updatePorts = function (portObj) {
    console.log(portObj)
    // TODO: Implementing Update
  }

  // Fetch Files at Start
  service.fetchPorts()

  webSocketService.on('AudioPortsChangedMessage', service.updatePorts)

  return service
})
