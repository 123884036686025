angular.module('webremote.dashboard').controller('widget_recorderGroups',
  function ($scope, $uibModal, $location, audioPlayerService, $interval, objectManagerService) {
    $scope.customComment = true
    $scope.commentPresets = []
    $scope.om = objectManagerService

    $scope.isRecorderGroup = function (group) {
      // Filter template groups
      if (group.member.tags.find(t => t === 'template') !== undefined) return false

      // Check streams in groups
      for (const stream of group.member.streams) {
        const streamObj = $scope.om.objectSync(stream, 'stream')
        if (streamObj !== undefined && streamObj.hasElement('sink', 'file')) {
          return true
        }
      }
      return false
    }

    $scope.showOnTogglePause = true

    $scope.$watch('activeDashboard', function () {
      $scope.om.object($scope.activeDashboard, 'dashboard').then((v) => {
        $scope.dashboard = v
      })
    })

    $scope.om.object($scope.activeDashboard, 'dashboard').then((v) => {
      $scope.dashboard = v

      // Widget Options
      $scope.$watch('widgetIndex', function () {
        $scope.storedOptions = $scope.dashboard.getInformationForWidget($scope.widgetIndex)
        $scope.largeView = $scope.storedOptions.largeView
        $scope.allowSdp = ((typeof $scope.storedOptions.allowSdp !== 'undefined') ? $scope.storedOptions.allowSdp : true)
        $scope.allowWebplayer = $scope.storedOptions.allowWebplayer
        $scope.freeComments = ((typeof $scope.storedOptions.freeComments !== 'undefined') ? $scope.storedOptions.freeComments : true)
      })
    })

    $scope.updateWidgetOptions = function () {
      $scope.storedOptions.largeView = $scope.largeView
      $scope.storedOptions.allowSdp  = $scope.allowSdp
      $scope.storedOptions.allowWebplayer  = $scope.allowWebplayer
      $scope.storedOptions.freeComments = $scope.freeComments
      $scope.dashboard.storeInformationForWidget($scope.widgetIndex, JSON.stringify($scope.storedOptions))
    }

    // Modal Fucntion
    $scope.open = function () {
      $scope.modalInstance = $uibModal.open({
        templateUrl: 'modules/dashboard/widgets/recorderGroups/modalComment.html',
        controller: 'modalCommentCtrl',
        backdrop: 'static',
        keyboard: false,
        scope: $scope
      })
    }

    $scope.startWebplayer = function (args) {
      pipeName = $scope.om.objectSync(args.uuid, 'stream').member.name
      audioPlayerService.control.setPlayObject({'member': {'objectClass': 'stream', 'uuid': args.uuid}}, {common:{title:args.streamGroupName, artist: pipeName}})
    }

    $scope.downloadSdp = function (args) {
      $scope.om.object(args.uuid, 'stream').then(stream => {
        const sdp = stream.getElement('sink', 'rtp').type.sink.type.rtp.sdp
        const filename = stream.member.name

        var element = document.createElement('a')
        element.setAttribute('download', filename + '.sdp')
        element.setAttribute('href', 'data:application/sdp;charset=utf-8,' + encodeURIComponent(sdp))
        $scope.showOnTogglePause = true

        if (document.createEvent) {
          var event = document.createEvent('MouseEvents')
          event.initEvent('click', true, true)
          element.dispatchEvent(event)
        } else {
          element.click()
        }
      })
    }

    $scope.commentStreams = function (customComment, group) {
      $scope.selectedGroup = group
      $scope.customComment = customComment
      $scope.open()
    }

    $scope.editButton = function () {
      $location.url('settings?module=streamgroups')
    }

    var timeout = $interval(function () {
      $scope.showOnTogglePause = !$scope.showOnTogglePause
    }, 1000)
  })
