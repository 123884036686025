angular.module('webremote.settings').controller('ctrlfactoryResetOptions',
  function ($scope, $uibModalInstance, $log) {
    $scope.resetOptions = { mode: '', data: '' }
    // Modal functions

    $scope.uploadFile = function (event) {
      var files = event.target.files

      if (files[0]) {
        if (files[0].type === 'application/json') {
          var reader = new window.FileReader()

          reader.onloadend = function () {
            $scope.resetOptions.data = reader.result.replace('data:application/json;base64,', '')
          }

          try {
            reader.readAsDataURL(files[0]) // reads the data as a URL
          } catch (e) {
            $log.debug('error on base64 encoding: ' + e)
          }
        } else {
          $log.debug('wrong file type')
        }
      } else {

      }
    }

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel')
    }

    $scope.closeModal = function () {
      $uibModalInstance.close($scope.resetOptions)
    }
  })
