import BaseClass from './Base.class'

export default class StreamGroup extends BaseClass {
  get visible () {
    return !this.member.hidden
  }

  /**
     * Check, if group has streams with given element.
     * @param {string} category Element Super Class
     * @param {string} type Element Specific Class
     * @return {boolean} true if some streams existing, otherwise false
     */
  hasStreamsWithElement (category, type) {
    return this.streamsWithElement(category, type).length > 0
  }

  /**
     * Get streams with given element.
     * @param {string} category Element Super Class
     * @param {string} type Element Specific Class
     * @return {Array} Array of filtered streams objects.
     */
  streamsWithElement (category, type) {
    const om = this._dependencies.$injector.get('objectManagerService')
    return this.member.streams.map(uuid => om.objectSync(uuid, 'stream')).filter(obj => obj !== undefined && obj.hasElement(category, type))
  }

  /**
     * Set pending state of stream group
     * @param {string} state next state
     */
  setState (state) {
    const payload = [{ op: 'replace', path: '/pendingState', value: state }]

    if (this.member.state === 'inactive' && state === 'inactive') payload.push({ op: 'replace', path: '/errorMessage', value: '' })

    this.patch(payload).then(v => {
      if (state === 'inactive') {
        for (const uuid in this.member.streams) {
          this._dependencies.$injector.get('audioPlayerService').control.streamStoppedCallback(uuid)
        }
      }
    })
  }

  /**
     * Toggle or override collapse state og stream group
     * @param {string} state optional state
     */
  collapse (state) {
    if (state === undefined || state === null) {
      this.member.collapsed = !this.member.collapsed
    } else {
      this.member.collapsed = state
    }

    this._notifications.error = false
    this._notifications.success = false
    const payload = [{ op: 'replace', path: '/collapsed', value: this.member.collapsed }]
    this.patch(payload).then(() => {
      this._notifications.error = true
      this._notifications.success = true
    })
  }

  /**
     * Get type of group
     * @return {string} Special type of group or none
     */
  type () {
    try {
      return Object.keys(this.member.special)[0]
    } catch (e) {
      return 'none'
    }
  }

  /**
     * Add a stream to current group object. If the stream is already assigned to a group, the assignment is cleared first.
     * @param {string} uuid stream uuid
     */
  addStream (uuid) {
    const om = this._dependencies.$injector.get('objectManagerService')
    om.object(uuid, 'stream').then(stream => {
      this.member.streams.push(uuid)
      const payload = [
        {
          op: 'replace',
          path: '/streams',
          value: this.member.streams,
        }
      ]
      if (!stream.isAssignedToGroup()) {
        this.patch(payload)
      } else {
        om.objectSync(stream.member.streamGroup, 'streamGroup').removeStream(uuid).then(() => {
          this.patch(payload)
        })
      }
    })
  }

  /**
     * Remove a stream from current group object.
     * @param {string} uuid stream uuid
     */
  async removeStream (uuid) {
    const index = this.member.streams.indexOf(uuid)
    if (index !== -1) {
      this.member.streams.splice(index, 1)
      const payload = [
        {
          op: 'replace',
          path: '/streams',
          value: this.member.streams,
        }
      ]
      return this.patch(payload)
    }
  }
}
