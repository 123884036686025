'use strict'

angular.module('webremote.service.http', []).factory('httpService', function ($q, $location, $http, toastr, $log) {
  var service = {}
  service.rootUrl = $location.host() + ':' + $location.port()
  service.protocol = $location.protocol()
  service.webapiUrl = '/webapi/http/'

  service.callFunction = function (functionName, parameter, httpMethod, contentType) {
    return $q(function (resolve, reject) {
      if (contentType === undefined) {
        contentType = 'application/json'
      }

      $http.defaults.headers.post['Content-Type'] = contentType

      let method = 'post'
      if (parameter === undefined || parameter.length === 0) {
        method = 'get'
      }

      if (httpMethod !== undefined) {
        method = httpMethod
      }

      if (method === 'get' && parameter !== undefined && parameter.length > 0) {
        functionName = functionName + '?'
        for (let i = 0; i < parameter.length; i++) {
          functionName = functionName + parameter[i]
          if (i < parameter.length - 1) {
            functionName = functionName + '&'
          }
        }
        parameter = []
      }

      $http({
        url: encodeURI(service.webapiUrl + functionName),
        headers: { 'Content-Type': contentType },
        method: method,
        data: parameter
      }).then(function (httpData) {
        if (service.evaluate(httpData) === -1) {
          reject({})
        } else {
          resolve(httpData)
        }
      }, function errorCallback (httpData) {
        // if there is no exception - show a toastr with the statuscode and the data object
        if (service.evaluate(httpData.data) !== -1) {
          $log.debug(functionName + '(' + httpData.status + '): ' + httpData.data)
        }

        // Check and Show Bad Gateway 502 HTTP Error
        if (httpData.status === 502) {
          $location.url('/login')
        }

        reject({ status: httpData.status, message: httpData.data })
      })
    })
  }

  service.evaluate = function (dataObject) {
    if (dataObject === undefined || dataObject === null) {
      return {}
    } else {
      return dataObject
    }
  }

  return service
})
