
angular.module('webremote.directive.objectmanager')
  .controller('webremote.directive.objectmanager.genericEditModal.plugins.presets.controller', ['$scope', '$uibModal', 'objectManagerService', 'audioElementLibraryService', 'toastr', function ($scope, $uibModal, objectManagerService, audioElementLibraryService, toastr) {
    // Filter correct preset types
    $scope.filterPresetTypes = function (item) {
      const element = $scope.pipeline.elements[$scope.currentElement]
      const category = $scope.pipeline.getElementCategory(element)

      return item.member.data.type.hasOwnProperty(category) && item.member.data.type[category].type.hasOwnProperty(Object.keys(element.type[category].type)[0])
    }

    $scope.resetPresetState = function () {
      $scope.newPresetFlag = false
      $scope.presetDropdownState = false
      $scope.pipeline.elements[$scope.currentElement].preset = ''
      $scope.preset = { member: { objectClass: 'elementPreset', data: {} } }
    }

    $scope.newPreset = function () {
      $scope.newPresetFlag = true
      $scope.presetDropdownState = false
      $scope.preset = { member: { objectClass: 'elementPreset', data: {} } }
    }

    $scope.destroyPreset = function (preset) {
      $scope.presetDropdownState = false
      preset.destroy().then((v) => {
        $scope.resetPresetState()
      })
    }

    $scope.connectPreset = function (preset) {
      $scope.pipeline.connectPreset($scope.currentElement, preset.member.uuid).then(result => {
        $scope.preset = preset
        $scope.presetDropdownState = false
      }, (errorData) => {
        toastr.error(errorData.message.text, 'Error', {
          closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
        })
      })
    }

    $scope.disconnectPreset = function () {
      $scope.pipeline.disconnectPreset($scope.currentElement).then(result => {
        $scope.resetPresetState()
      }, (errorData) => {
        toastr.error(errorData.message.text, 'Error', {
          closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
        })
      })
    }

    $scope.savePreset = function () {
      $scope.newPresetFlag = false
      $scope.preset.member.data = angular.copy($scope.pipeline.elements[$scope.currentElement])

      // Save / Create
      if ($scope.preset.member.uuid === undefined) {
        $scope.objectManager().create('elementPreset', $scope.preset.member).then(uuid => {
          $scope.objectManager().collection('elementPreset', true).then(presetCollection => {
            $scope.connectPreset(presetCollection.get(uuid), true)
          })
        }, (errorData) => {
          toastr.error(errorData.message.text, 'Error', {
            closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
          })
        })
      } else {
        $scope.preset.update()
      }
    }
  }])

  .directive('omEditModalPluginPresets', function () {
    return {
      restrict: 'E',
      transclude: 'true',
      scope: {
        data: '=data',
        schema: '=schema'
      },
      templateUrl: 'directives/objectmanager/genericEditModal/plugins/presets/template.html'
    }
  })
