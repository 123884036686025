
angular.module('webremote.directive.objectmanager.formElements')
  .controller('webremote.directive.objectmanager.formElements.enum.controller', function ($scope, omFormService) {
    $scope.omFormService = omFormService
    $scope.ngInit = function () {
      if ($scope.data !== undefined && ($scope.data[$scope.schema.key] === undefined || $scope.data[$scope.schema.key].length === 0)) {
        if ($scope.schema.default !== undefined) {
          $scope.data[$scope.schema.key] = $scope.schema.default
        } else {
          $scope.data[$scope.schema.key] = $scope.schema.enumerants[0].key
        }
      }
    }

    // Filter visible structs
    $scope.filterVisibleOptions = function (options) {
      const result = []
      for (const option in options) {
        if (!Object.prototype.hasOwnProperty.call(options[option], 'hidden')) {
          result.push(options[option])
        }
      }
      return result
    }

    $scope.$watch('data[schema.key]', $scope.ngInit)
  })

  .directive('omConfigformEnum', function () {
    return {
      restrict: 'E',
      transclude: 'true',
      scope: {
        data: '=',
        schema: '<',
        selector: '<',
        objectPath: '<',
        object: '<'
      },
      templateUrl: 'directives/objectmanager/formElements/enum/template.html'
    }
  })
