angular.module('webremote.settings').controller('webremote.settings.userRole.controller',
  function ($scope, $document, modalService, objectManagerService) {
    $scope.selectedRole = {}
    $scope.roleDefault = {}
    $scope.newGroupFlag = true
    $scope.rightsClasses = ['create', 'read', 'update', 'destroy']
    $scope.rights = { objects: {}, methods: {} }
    $scope.active = 0

    // Store collapse states
    $scope.collapseStates = { objects: {}, methods: {} }

    // Define Presets
    $scope.presets = {
      create: [
        { title: 'Prevent Attribute Adjustment', objectClass: '*', script: 'return #object.attributes == 0' }
      ],
      read: [
        { title: 'Only own Objects', objectClass: '*', script: 'return object.attributes["creator"] == user.uuid' }
      ],
      update: [
        { title: 'Only own Objects', objectClass: '*', script: 'return objectOld.attributes["creator"] == user.uuid' },
        { title: 'Allow editing, except role customization', objectClass: 'user', script: 'return objectOld.name == user.name and objectOld.name == objectNew.name and table.concat(objectOld.roles, ",") == table.concat(objectNew.roles, ",")' }
      ],
      destroy: [
        { title: 'Only own Objects', objectClass: '*', script: 'return object.attributes["creator"] == user.uuid' }
      ]
    }

    // ObjectManager Decorator
    $scope.objectManager = objectManagerService.getObjectManager()

    $scope.getObjects = function () {
      return Object.keys($scope.objectManager.objects)
    }

    $scope.getMethods = function () {
      return Object.keys($scope.objectManager.methods)
    }

    $scope.initializeDefaultRightsStruct = function () {
      // Initialize Objects
      for (const obj of $scope.getObjects()) {
        $scope.rights.objects[obj] = {}

        for (const rightsClass of $scope.rightsClasses) {
          $scope.rights.objects[obj][rightsClass] = {}
          $scope.rights.objects[obj][rightsClass].state = false
          $scope.rights.objects[obj][rightsClass].script = ''
        }
      }

      // Initialize Methods
      for (const method of $scope.getMethods()) {
        $scope.rights.methods[method] = {}
        $scope.rights.methods[method].state = false
        $scope.rights.methods[method].script = ''
      }
    }

    $scope.importPreset = function (rights, rightsClass, script) {
      $scope.rights.objects[rights][rightsClass].script = script
    }

    $scope.resetCollapseStates = function () {
      // Objects
      for (const obj of $scope.getObjects()) {
        $scope.collapseStates.objects[obj] = {}
        $scope.collapseStates.objects[obj].container = true

        for (const rightsClass of $scope.rightsClasses) {
          $scope.collapseStates.objects[obj][rightsClass] = {}
          $scope.collapseStates.objects[obj][rightsClass].script = true
        }
      }

      // Methods
      for (const method of $scope.getMethods()) {
        $scope.collapseStates.methods[method] = {}
        $scope.collapseStates.methods[method].container = true
        $scope.collapseStates.methods[method].script = true
      }
    }

    $scope.serializeRights = function () {
      const parseRights = function (rightsClass) {
        $scope.selectedRole.member[rightsClass] = []
        for (const obj in $scope.rights.objects) {
          // check if rights is true
          if ($scope.rights.objects[obj][rightsClass].script.length === 0 && $scope.rights.objects[obj][rightsClass].state) {
            $scope.selectedRole.member[rightsClass].push({ key: obj, value: '' })
          }

          // check if script is given
          if ($scope.rights.objects[obj][rightsClass].script.length > 0) {
            $scope.selectedRole.member[rightsClass].push({ key: obj, value: $scope.rights.objects[obj][rightsClass].script })
          }
        }
      }

      // Process objects
      parseRights('create')
      parseRights('update')
      parseRights('read')
      parseRights('destroy')

      // Process methods
      $scope.selectedRole.member.call = []
      for (const method in $scope.rights.methods) {
        // check if right is true
        if ($scope.rights.methods[method].script.length === 0 && $scope.rights.methods[method].state) {
          $scope.selectedRole.member.call.push({ key: method, value: '' })
        }

        // check if script is given
        if ($scope.rights.methods[method].script.length > 0) {
          $scope.selectedRole.member.call.push({ key: method, value: $scope.rights.methods[method].script })
        }
      }
    }

    $scope.deserializeRights = function () {
      const parseRights = function (rightsClass) {
        for (const obj of $scope.selectedRole.member[rightsClass]) {
          // check if rights are true
          if (obj.value.length === 0) {
            $scope.rights.objects[obj.key][rightsClass].state = true
          }

          // check if script is given
          if (obj.value.length > 0) {
            $scope.rights.objects[obj.key][rightsClass].script = obj.value
          }
        }
      }

      // Process objects
      parseRights('create')
      parseRights('update')
      parseRights('read')
      parseRights('destroy')

      // Process methods
      for (const obj of $scope.selectedRole.member.call) {
        // check if rights are true
        if (obj.value.length === 0) {
          $scope.rights.methods[obj.key].state = true
        }

        // check if script is given
        if (obj.value.length > 0) {
          $scope.rights.methods[obj.key].script = obj.value
        }
      }
    }

    $scope.selectRole = function (uuid) {
      $scope.objectManager.object(uuid, 'role').then(v => {
        $scope.selectedRole = angular.copy(v)
        $scope.active = 0
        $scope.resetCollapseStates()
        $scope.initializeDefaultRightsStruct()
        $scope.deserializeRights()
        $scope.$evalAsync()
      })
    }
    // Create local copy of user role
    $scope.newRole = function () {
      $scope.objectManager.getDefault('role').then(v => {
        $scope.$evalAsync(() => {
          $scope.active = 0
          $scope.activeRoleIndex = 0
          $scope.initializeDefaultRightsStruct()
          $scope.resetCollapseStates()
          $scope.selectedRole = {}
          $scope.selectedRole.member = angular.copy(v)
        })
      })
    }

    $scope.saveRole = function () {
      $scope.serializeRights()
      if ($scope.selectedRole.member.uuid === undefined || $scope.selectedRole.member.uuid.length === 0) {
        $scope.objectManager.create('role', $scope.selectedRole.member, true)
      } else {
        $scope.selectedRole.update().then(v => {
        }, function (errorData) {
        })
      }
    }

    $scope.scrollToTop = function () {
      $document.scrollTopAnimated(0, 400)
    }

    $scope.omModalEvents = {
      update: (value) => {
        $scope.selectedRole.member = value
      }
    }

    $scope.removeRole = function () {
      const modalConfig = {
        title: 'Confirm',
        text: 'Are you sure to delete this userrole?'
      }

      modalService.confirmModal(modalConfig, $scope, function (response) {
        if (response) {
          $scope.selectedRole.destroy()
          $scope.newRole()
          $scope.scrollToTop()
        }
      })
    }
    $scope.newRole()
    $scope.initializeDefaultRightsStruct()
    $scope.resetCollapseStates()
  })
