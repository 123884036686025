const module = angular.module('webremote.directive.navigation')

module.controller('webremote.directive.navigation.navigationBar.controller', ['$scope', 'objectManagerService', 'modalService', 'onDemandRouterService', function ($scope, objectManagerService, modalService, onDemandRouterService) {

}]).directive('navigationBar', function () {
  return {
    restrict: 'E',
    transclude: {
      containerLeft: '?containerLeft',
      containerCenter: '?containerCenter',
      containerRight: '?containerRight'
    },
    controller: 'webremote.directive.navigation.navigationBar.controller',
    scope: {
      elements: '='
    },
    templateUrl: 'directives/navigation/navigationBar/template.html'
  }
})

/* Example actions:
    elements: {
        back:{
            label:"",
            disabled: false,
            cb: ()=>{

            }
        }
    }
*/
