import Directory from '../../services/file/Directory'
angular.module('webremote.archive').controller('listFiles',
  function ($scope, $location, $injector, objectManagerService, audioPlayerService, fileService) {
    $scope.objectManager = objectManagerService.getObjectManager
    $scope.audioPlayerService = audioPlayerService

    $scope.navigation = {
      path: [],
      currentPath: {},
      highlightedItems: []
    }

    $scope.upload = {
      queue: [],
      progress: 0
    }

    $scope.sortOptions = {
      fileCount: 25,
      page: 1,
      orderBy: 'name',
      searchText: ''
    }

    $scope.onFileDeselect = () => {
      $scope.selectedFile = undefined
    }

    $scope.openFile = async function (file) {
      $scope.$evalAsync(() => {
        file.requestMetaData()
      })
      $scope.selectedFile = file
      $scope.selectedFolder = undefined
    }

    $scope.evaluateGetParameter = function () {
      // Navigate to path (get parameter)
      if (Object.prototype.hasOwnProperty.call($location.search(), 'path')) {
        const path = fileService.decodePath($location.search().path)
        $scope.navigation.path = path.map(x => Directory.init({ path: path }, { name: x }, $injector))
      }

      // Highlight file
      if (Object.prototype.hasOwnProperty.call($location.search(), 'file')) {
        $scope.navigation.highlightedItems = [$location.search().path + '/' + $location.search().file]
      }
    }

    $scope.evaluateGetParameter()

    $scope.$on('$routeUpdate', function () {
      $scope.evaluateGetParameter()
    })
  })
