const { entries } = require('lodash')

angular.module('webremote.directive.archive').controller('webremote.directive.archive.fileGenericActions.controller', function ($scope, objectManagerService, fileService, toastr) {
  $scope.apply = (action, prettName) => {
    const payload = {
      methodClass: 'archiveAction',
      action: action,
      path: fileService.encodePath($scope.directory.path),
      entries: $scope.directory.selectedItems().map(x => x.name)
    }
    objectManagerService.callMethod('archiveAction', payload, { notificationsError: true }).then(function (response) {
      if (payload.entries.length === 1) {
        toastr.success('Apply "' + prettName + '" to 1 entry.', 'Success', {
          closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
        })
      } else {
        toastr.success('Apply "' + prettName + '" to ' + payload.entries.length + ' entries.', 'Success', {
          closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
        })
      }
    })
  }
}).directive('fileGenericActions', function () {
  return {
    restrict: 'E',
    scope: {
      directory: '='
    },
    templateUrl: 'directives/archive/fileGenericActions/template.html'
  }
})
