angular.module('webremote.dashboard').controller('widget_audioInterfaceControl',
  function ($scope, $timeout, audioInterfaceService, deviceService, objectManagerService) {
    // TODO ?
    $scope.interfaceCollection = audioInterfaceService.GetInterfaceColleciton
    $scope.soundCards = audioInterfaceService.GetSoundCardColleciton
    $scope.deviceService = deviceService

    // Workaround to fix render issue for slider
    $timeout(function () {
      $scope.$broadcast('rzSliderForceRender')
    })

    $scope.getMeterClass = function () {
      // Adapt size of widget and meters by number of channels
      const collection = objectManagerService.collectionSync('audioInputPort')
      var nrAudioInputPort = 0
      for (const audioInputPort of collection) {
        if ((!Object.prototype.hasOwnProperty.call(audioInputPort.member.type, 'virtual') && audioInputPort.member.monitorLevel) || Object.prototype.hasOwnProperty.call(audioInputPort.member.type, 'geminiAnalogMic') || Object.prototype.hasOwnProperty.call(audioInputPort.member.type, 'geminiAnalogLine') || Object.prototype.hasOwnProperty.call(audioInputPort.member.type, 'geminiAes')) {
          nrAudioInputPort += 1
        }
      }
      if (nrAudioInputPort <= 4) {
        return 'col-xs-6 col-sm-3 col-md-3'
      } else if ((nrAudioInputPort <= 6)) {
        return 'col-xs-6 col-sm-6 col-md-2'
      } else {
        return 'col-xs-6 col-sm-6 col-md-1point5'
      }
    }
  })
