
angular.module('webremote.directive.objectmanager.formElements', ['webremote.directive.objectmanager.utilities'])

require('./form.service')
require('./input/controller')
require('./toggle/controller')
require('./enum/controller')
require('./upload/controller')
require('./uuid/controller')
require('./union/controller')
require('./struct/controller')
require('./list/controller')
require('./channelSelector/controller')
require('./passwordVerify/controller')
require('./callProfile/controller')
require('./filebrowser/filebrowser')
require('./wifiList/wifiList')
