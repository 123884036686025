import Directory from './Directory'
import File from './File'
import FileSystemElement from './FileSystemElement'

export default class DetailedDirectory extends FileSystemElement {
  static init (path, data, $injector) {
    const obj = new DetailedDirectory()
    obj.$injector = $injector
    obj.path = path
    obj.flags = {
      allowCreateDir: data.allowCreateDir,
      allowDeleteDir: data.allowDeleteDir,
      allowUpload: data.allowUpload
    }

    if (data.actions) {
      const result = []
      for (const key in data.actions) {
        result.push({ prettyName: data.actions[key], methodCall: key })
      }
      obj.actions = result
    }

    obj.pages = data.pages
    obj.entries = data.entries.map(entry => {
      if (entry.type === 'dir') return Directory.init(obj, entry, $injector)
      if (entry.type === 'file') return File.init(obj, entry, $injector)
      throw TypeError('Unkown type: ', entry.type)
    })

    return obj
  }

  get dirs () {
    return this.entries.find(x => x.type === 'dir')
  }

  get files () {
    return this.entries.find(x => x.type === 'file')
  }

  isAnyItemSelected () {
    return this.selectedItems.length > 0
  }

  selectAll () {
    for (const entry of this.entries) {
      entry.selected = true
    }
  }

  deselectAll () {
    for (const entry of this.entries) {
      entry.selected = false
    }
  }

  selectedItems () {
    return this.entries.filter(x => x.selected)
  }
}
