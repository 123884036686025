angular.module('webremote.layout').controller('webremote.modules.layout.rebootModal.controller',
  function ($scope, objectManagerService, modalService, deviceService, $interval, $uibModalInstance, $window, webSocketService, toastr) {
    $scope.disconnectDetected = false
    $scope.timeoutSeconds = 0
    $scope.maxTimeoutSeconds = 120
    $scope.om = objectManagerService

    function triggerReset (skipFactoryReset) {
      var timeout = $interval(function () {
        // prevent overflow
        if ($scope.timeoutSeconds < $scope.maxTimeoutSeconds) {
          $scope.timeoutSeconds++
        } else {
          $interval.cancel(timeout)
        }
      }, 1000)

      webSocketService.on('disconnect', function (data) {
        $scope.disconnectDetected = true
      })

      webSocketService.on('connect', function (data) {
        if ($scope.disconnectDetected) {
          $window.location.reload()
        }
      })

      webSocketService.on('reconnect', function (data) {
        if ($scope.disconnectDetected) {
          $window.location.reload()
        }
      })

      webSocketService.on('connect_error', function (e) {
        $window.location.reload()
      })

      // call rest reboot action IF not prevented

      webSocketService.EnableFallback(false)
      $scope.om.callMethod('systemReboot', { skipFactoryReset: skipFactoryReset }, { notifications: true })
    }

    if (deviceService.meta.debug === true) {
      $scope.om.callMethod('checkFactoryReset', {}, { notificationsError: true }).then((response) => {
        if (response.flag) {
          const modalConfig = {
            title: 'Warning',
            text: 'Rebooting will trigger a factory reset.\n\nDo you want to skip the factory reset?\n\nOnly do this when advised by our customer service as this may lead to unexpected results.',
            size: 'md',
            backdrop: 'static',
            buttons: [
              {
                title: 'No, do the factory reset.',
                style: 'btn btn-success',
                returnValue: false
              },
              {
                title: 'Yes, skip it.',
                style: 'btn btn-danger',
                returnValue: true
              }
            ]
          }
          modalService.customModal(modalConfig, $scope, function (response) {
            triggerReset(response)
          })
        } else {
          triggerReset(false)
        }
      })
    } else {
      triggerReset(false)
    }

    // modal close function
    $scope.closeModal = function () {
      $uibModalInstance.close()
    }
  })
