const module = angular.module('webremote.connect')

module.controller('listPipesCtrl', function ($scope, $uibModal, $location, $filter, PIPELINE, objectManagerService, audioElementLibraryService, streamGroupService, modalService) {
  $scope.searchText = ''
  $scope.streamGroupService = streamGroupService
  $scope.audioElementManager = audioElementLibraryService.GetAudioElementLibraryManager
  $scope.om = objectManagerService
  $scope.multiControl = {
    streamActions: [],
    streamGroupActions: [],
    selectedStreams: [],
    selectedStreamGroups: []
  }
  $scope.nrPlaying = 0
  $scope.nrPipes = 0
  $scope.nrError = 0

  $scope.allStreams = []
  $scope.$watchCollection('om.collectionSync("stream")', streams => {
    if (streams !== undefined) {
      $scope.allStreams = Array.from(streams.values())
      $scope.allStreams.sort((a, b) => a.member.name.localeCompare(b.member.name))
    } else {
      $scope.allStreams = []
    }
  })

  $scope.toggleStreamGroupSelection = function (uuid) {
    const index = $scope.multiControl.selectedStreamGroups.indexOf(uuid)

    if (index === -1) {
      $scope.multiControl.selectedStreamGroups.push(uuid)
    } else {
      $scope.multiControl.selectedStreamGroups.splice(index, 1)
    }
  }

  $scope.createPipeForGroup = function (groupUuid) {
    $location.url('connect/edit')
    PIPELINE.setPipeline('new')
    PIPELINE.setGroup(groupUuid)
  }

  $scope.setTab = function (id) {
    PIPELINE.setTab(id)
  }

  $scope.addTagToSearch = function (tag) {
    $scope.searchText = tag
  }

  $scope.checkSelectedPipes = function () {
    $scope.multiControl.selectedStreams = []
    $scope.om.collection('stream').then(streams => {
      $scope.$evalAsync(() => {
        for (const stream of Array.from(streams.values())) {
          if (stream.selected) {
            $scope.multiControl.selectedStreams.push(stream.member.uuid)
          }
        }
      })
    })
  }

  $scope.resetSelectedPipes = function () {
    $scope.om.collection('stream').then(streams => {
      for (const stream in streams) {
        stream.selected = false
      }
    })
  }

  $scope.choosePipeline = function (id) {
    $location.url('connect/edit')
    PIPELINE.setPipeline(id)
  }

  $scope.getPlayingPipes = function () {
    $scope.nrPlaying = 0
    const streams = $scope.om.collectionSync('stream')
    for (const stream of Array.from(streams.values())) {
      if (stream.member.state === 'playing' && stream.member.streamGroup === '' && !stream.member.tags.includes('template')) {
        $scope.nrPlaying++
      }
    }
    return $scope.nrPlaying
  }

  $scope.getAllPipes = function () {
    $scope.nrPipes = 0
    const streams = $scope.om.collectionSync('stream')
    for (const stream of Array.from(streams.values())) {
      if (stream.member.streamGroup === '' && !stream.member.tags.includes('template')) {
        $scope.nrPipes++
      }
    }
    return $scope.nrPipes
  }

  $scope.getErrorPipes = function () {
    $scope.nrError = 0
    const streams = $scope.om.collectionSync('stream')
    for (const stream of Array.from(streams.values())) {
      if (stream.member.errorMessage !== '' && stream.member.streamGroup === '' && !stream.member.tags.includes('template')) {
        $scope.nrError++
      }
    }
    if ($scope.nrError == 0) return ''
    return $scope.nrError
  }

  $scope.getStatusStyleClass = function (item) {
    const state = (typeof item === 'undefined' ? 'invalid' : item.member.state)
    const pendingState = (typeof item === 'undefined' ? 'invalid' : item.member.pendingState)
    const errorMessage = (typeof item === 'undefined' ? '' : item.member.errorMessage)
    let feedback = ''
    let allPlaying = true
    let errors = false
    for (const uuid of item.member.streams) {
      if ($scope.om.objectSync(uuid, 'stream').member.state !== 'playing') {
        allPlaying = false
      }
      if ($scope.om.objectSync(uuid, 'stream').member.errorMessage) {
        errors = true
      }
    }

    switch (state) {
      case 'invalid':
      case 'incomplete':
        feedback = 'badge'
        break

      case 'inactive':
        if (errorMessage.length > 0 || errors) {
          feedback = 'badge badge-red-grey-blinking'
        } else {
          feedback = 'badge'
        }
        break

      case 'waiting':
        feedback = 'badge badge-warning'
        break

      case 'trying':
        if (pendingState === 'pause' || pendingState === 'playing') {
          feedback = 'badge badge-orange-grey-blinking'
        } else {
          feedback = 'badge'
        }
        break

      case 'paused':
        feedback = 'badge badge-warning'
        break

      case 'playing':
        if (errorMessage.length > 0 || errors) {
          feedback = 'badge badge-green-red-blinking'
        } else if (!allPlaying) {
          feedback = 'badge badge-green-orange-blinking'
        } else {
          feedback = 'badge badge-success'
        }
        break

      case 'eos':
        feedback = 'badge'
        break

      default:
        feedback = 'badge'
    }
    return feedback
  }

  //
  //  Filter
  //

  $scope.countActiveFilter = function () {
    let counter = 0
    for (const element in $scope.filter) {
      if (!$scope.filter[element]) counter++
    }
    return counter
  }

  $scope.resetFilter = function () {
    $scope.filter = {
      paused: true,
      playing: true,
      trying: true,
      inactive: true,
      incomplete: true,
      eos: true,
      waiting: true
    }
  }

  $scope.filterStatus = function (item) {
    if ($scope.filter.inactive === true) var inactiveExpression = 'inactive'
    if ($scope.filter.eos === true) var eosExpression = 'eos'
    if ($scope.filter.waiting === true) var waitingExpression = 'waiting' // waiting
    if ($scope.filter.playing === true) var playingExpression = 'playing' // activated + playing
    if ($scope.filter.paused === true) var pausedExpression = 'paused' // pause + inactive
    if ($scope.filter.trying === true) var tryingExpression = 'trying' // pause + inactive
    if ($scope.filter.incomplete === true) var incompleteExpression = 'incomplete' // pause + inactive

    return item.member.state === inactiveExpression ||
      item.member.state === playingExpression ||
      item.member.state === pausedExpression ||
      item.member.state === tryingExpression ||
      item.member.state === waitingExpression ||
      item.member.state === incompleteExpression ||
      item.member.state === eosExpression
  }

  $scope.selectStreams = function (expression) {
    $scope.tabType = expression
    $scope.multiControl.selectedStreams = []
    $scope.multiControl.selectedStreamGroups = []
    $scope.searchText = ''
    $scope.configureMultiControl(expression)
  }

  $scope.configureMultiControl = function (tabKey) {
    switch (tabKey) {
      case 'unidirectionals':
        $scope.multiControl.streamActions = ['play', 'pause', 'stop', 'remove', 'restart', 'lock', 'unlock']
        $scope.multiControl.streamGroupActions = []
        break
      case 'stream-templates':
        $scope.multiControl.streamActions = ['remove']
        $scope.multiControl.streamGroupActions = []
        break
      case 'group-templates':
        $scope.multiControl.streamActions = []
        $scope.multiControl.streamGroupActions = []
        break
      case 'groups':
        $scope.multiControl.streamActions = []
        $scope.multiControl.streamGroupActions = ['play', 'pause', 'stop']
        break
      default:
        $scope.multiControl.streamActions = []
        $scope.multiControl.streamGroupActions = []
    }
  }

  $scope.filterFullTextInGroup = function (item) {
    // Search text in streamGroup name
    const groupNameResult = item.member.name.includes($scope.searchText)

    // Collect streams
    const streamArray = []
    for (const uuid of item.member.streams) {
      streamArray.push($scope.om.objectSync(uuid, 'stream'))
    }

    // Search text in stream objects
    const streamsResult = $filter('filter')(streamArray, $scope.searchText)
    // return result
    return groupNameResult || streamsResult.length > 0
  }

  $scope.filterTemplateGroups = function (item) {
    return item.member.tags.find(t => t === 'template') !== undefined
  }

  $scope.filterRegularGroups = function (item) {
    return item.member.tags.find(t => t === 'template') === undefined
  }

  $scope.filterPlayingGroups = function (item) {
    return item.member.state === 'playing'
  }

  $scope.filterErrorGroups = function (item) {
    return item.member.errorMessage !== ''
  }

  $scope.filterStreamType = function (obj) {
    switch ($scope.tabType) {
      case 'unidirectionals':
        return !obj.hasTag('template') && obj.member.streamGroup.length === 0
      case 'stream-templates':
        return obj.hasTag('template') && obj.member.streamGroup.length === 0
    }
  }

  $scope.stopGroup = function (group) {
    let containsLockedPipe = false

    $scope.om.collection('stream').then(streams => {
      for (const uuid of group.member.streams) {
        if (streams.get(uuid).member.editingLocked) {
          containsLockedPipe = true
          break
        }
      }

      if (containsLockedPipe) {
        const modalConfig = {
          title: 'Confirm',
          text: 'Are you sure to stop this group?'
        }

        modalService.confirmModal(modalConfig, $scope, function (response) {
          if (response) {
            group.setState('inactive')
          }
        })
      } else {
        group.setState('inactive')
      }
    })
  }

  $scope.createStreamGroupModal = function (options) {
    $scope.createStreamGroupOptions = options || {}
    $scope.modalInstanceStreamgroups = $uibModal.open({
      templateUrl: 'modules/connect/modals/createGroup/template.html',
      controller: 'groupCreateModalCtrl',
      size: 'md',
      scope: $scope
    })
  }

  $scope.editStreamGroup = function (uuid) {
    $scope.selectedGroup = uuid
    $scope.modalInstanceStreamgroups = $uibModal.open({
      templateUrl: 'modules/connect/modals/editGroup/template.html',
      controller: 'groupEditModalCtrl',
      size: 'md',
      scope: $scope
    })
  }

  $scope.showLog = function (selectedGroup) {
    $scope.selectedGroup = selectedGroup.member
    $scope.modalInstance = $uibModal.open({
      templateUrl: 'directives/debug/journal/modalTemplate.html',
      controller: 'journalStreamGroup',
      size: 'lg',
      scope: $scope
    })

    $scope.modalInstance.result.then(function (returnedData) {
    }, function (errorData) {
    })
  }

  //
  //  Initial calls
  //

  $scope.checkSelectedPipes()
  $scope.resetFilter()
  $scope.active = PIPELINE.getTab()

  //
  //  Watcher
  //
  $scope.$watch('searchText', function (newValue) {
    if ($scope.tabType === 'unidirectionals' || $scope.tabType === 'stream-templates') {
      $scope.om.collection('stream').then(streams => {
        streams = Array.from(streams.values())

        for (const stream of streams) {
          stream.selected = false
        }

        if (newValue.length > 0) {
          let searchHits = []
          searchHits = $filter('filter')(streams, newValue)
          searchHits = $filter('filter')(searchHits, $scope.filterStreamType)
          searchHits = $filter('filter')(searchHits, $scope.filterStatus)

          for (const stream of searchHits) {
            stream.selected = true
          }
        }

        $scope.checkSelectedPipes()
      })
    } else if ($scope.tabType === 'groups') {
      $scope.multiControl.selectedStreamGroups = []
      if (newValue.length > 0) {
        $filter('filter')(streamGroupService.filterByType(['none', 'hlsDash', 'dvb', 'ybrid', 'webRtc']), $scope.filterFullTextInGroup)
          .map(x => $scope.multiControl.selectedStreamGroups.push(x.member.uuid))
      }
    } else if ($scope.tabType === 'group-templates') {
      $scope.multiControl.selectedStreamGroups = []
      if (newValue.length > 0) {
        $filter('filter')(streamGroupService.filterByType(['none', 'hlsDash', 'dvb', 'ybrid', 'webRtc']), $scope.filterFullTextInGroup)
          .filter(x => x.member.tags.find(x => x === 'template')).map(x => $scope.multiControl.selectedStreamGroups.push(x.member.uuid))
      }
    }
  })
})

module.controller('journalStreamGroup', ['$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {
  $scope.journal = {}
  $scope.journal.title = $scope.selectedGroup.name
  $scope.journal.ids = [$scope.selectedGroup.uuid].concat($scope.selectedGroup.streams)
  $scope.journal.classes = ['stream', 'streamGroup']

  if (Object.prototype.hasOwnProperty.call($scope.selectedGroup.special, 'sip')) {
    $scope.journal.ids.push($scope.selectedGroup.special.sip.sipCall)
    $scope.journal.classes = $scope.journal.classes.concat(['sipCall'])
  }

  $scope.closeModal = function () {
    $uibModalInstance.close()
  }
}])
