'use strict'

angular.module('webremote.directive.objectmanager.formElements', []).factory('omFormService', function () {
  var service = {}

  service.validationErrorLevels = ['critical', 'error']
  service.validationMessageLevels = ['critical', 'error', 'warning', 'info']

  /**
     * Helper function to remove `fields` and `elementType` layer in given object path.
     * @param {string} path object path
     */
  service.cleanPath = function (path) {
    if (path) {
      return path.replace(/\.fields/g, '').replace(/\.elementType/g, '')
    } else {
      return ''
    }
  }

  return service
})
