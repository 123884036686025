import Stream from '../../../class/stream/Stream.class'

angular.module('webremote.connect').controller('editPipeCtrl', function ($scope, $filter, $location,
  $uibModal, audioElementLibraryService, objectManagerService, PIPELINE, streamService, modalService) {
  $scope.om = objectManagerService

  // stream related vars
  $scope.stream = {}
  $scope.newStream = false
  $scope.clearPipeOnDestroy = true

  // array for all elements
  $scope.availableElements = []
  $scope.tmpAvailableElements = []

  $scope.undoArray = []

  $scope.getPipeline = function () {
    return PIPELINE.getPipeline()
  }

  $scope.init = function () {
    if ($scope.getPipeline() === -1) {
      $location.url('connect')
    } else if ($scope.getPipeline() === 'new' || $scope.getPipeline() === 'template') {
      $scope.newStream = true

      $scope.om.getDefault('stream').then(streamDefault => {
        $scope.stream = new Stream({ obj: streamDefault, dependencies: $scope.om.dependencies })
        streamService.generateUniqueName().then(name => {
          $scope.stream.member.name = name

          // Add template tag, if template mode is enabled
          if ($scope.getPipeline() === 'template') $scope.stream.member.tags.push('template')

          $scope.om.create('stream', $scope.stream.member).then(uuid => {
            $scope.om.object(uuid, 'stream', true).then(v => {
              $scope.$evalAsync(() => {
                $scope.stream = v

                // If streamGroup uuid is set, assign streamGroup to stream
                if (PIPELINE.getGroup() !== null) {
                  $scope.om.object(PIPELINE.getGroup(), 'streamGroup').then(streamGroup => {
                    streamGroup.addStream($scope.stream.member.uuid)
                    PIPELINE.setGroup(null)
                  })
                }
              })
            })
          })
        })
      })
    } else {
      $scope.newStream = false

      $scope.om.object(PIPELINE.getPipeline(), 'stream').then(v => {
        $scope.$evalAsync(() => {
          $scope.stream = v
          $scope.streamBackup = angular.copy($scope.stream.member)
        })
      })
    }

    $scope.undoArray = []
  }

  $scope.addElementToStream = function (obj) {
    $scope.undoArray.push(angular.copy($scope.stream.member))
    $scope.stream.member.elements.push(obj)
    $scope.stream.update()
  }

  $scope.removeElementFromStream = function (index) {
    $scope.undoArray.push(angular.copy($scope.stream.member))
    $scope.stream.member.elements.splice(index, 1)
    $scope.stream.update()
  }

  $scope.discardChanges = function () {
    if (!$scope.newStream) {
      $scope.stream.member = $scope.streamBackup
      $scope.stream.update().then(() => {
        $location.url('connect')
      })
    } else {
      $scope.clearPipeOnDestroy = false
      $scope.stream.destroy().then(() => {
        $location.url('connect')
      })
    }
  }

  $scope.initAudioElements = function () {
    $scope.availableElements = {
      source: [],
      encoder: [],
      processing: [],
      sink: []
    }

    const audioElements = angular.copy(audioElementLibraryService.audioElementLibrary)

    // Categorize elements
    $scope.availableElements.source = $filter('orderBy')(audioElements.filter(x => Object.keys(x.type)[0] === 'source'), 'displayName', false)
    $scope.availableElements.encoder = $filter('orderBy')(audioElements.filter(x => Object.keys(x.type)[0] === 'encoder'), 'displayName', false)
    $scope.availableElements.sink = $filter('orderBy')(audioElements.filter(x => Object.keys(x.type)[0] === 'sink'), 'displayName', false)

    const processing = $filter('orderBy')(audioElements.filter(x => Object.keys(x.type)[0] === 'processing'), 'displayName', false)
    const postProcessing = $filter('orderBy')(audioElements.filter(x => Object.keys(x.type)[0] === 'postProcessing'), 'displayName', false)
    $scope.availableElements.processing = processing.concat(postProcessing)

    // FIXME: Use annotations instead of name-filter
    if (PIPELINE.getTab() !== 4 || ($scope.stream.hasOwnProperty('hasTag') && !$scope.stream.hasTag('template'))) {
      $scope.availableElements.sink = $scope.availableElements.sink.filter(item => item.displayName !== 'SIP')
      $scope.availableElements.source = $scope.availableElements.source.filter(item => item.displayName !== 'SIP')
    }
    if (PIPELINE.getTab() < 3 || ($scope.stream.hasOwnProperty('hasTag') && !$scope.stream.hasTag('template'))) {
      $scope.availableElements.processing = $scope.availableElements.processing.filter(item => item.displayName !== 'On-demand')
      $scope.availableElements.source = $scope.availableElements.source.filter(item => item.displayName !== 'On-demand')
    }

    $scope.resetAvailableElements()
  }

  $scope.resetAvailableElements = function () {
    $scope.tmpAvailableElements = angular.copy($scope.availableElements)
  }

  $scope.deleteStream = function () {
    const modalConfig = {
      title: 'Confirm',
      text: 'Are you sure to delete this stream?'
    }

    modalService.confirmModal(modalConfig, $scope, function (response) {
      if (response) {
        $scope.stream.destroy().then(() => {
          $location.url('connect')
        })
      }
    })
  }

  $scope.stopPipe = function () {
    const modalConfig = {
      title: 'Confirm',
      text: 'Are you sure to stop this stream?'
    }

    modalService.confirmModal(modalConfig, $scope, function (response) {
      if (response) {
        $scope.stream.setState('inactive')
      }
    })
  }

  $scope.updateStream = function () {
    $scope.stream.member.forcedUpdate = true
    $scope.stream.update().then((success) => {
      $scope.$evalAsync(() => {
        if (success !== undefined && success === false) {
          $scope.stream.member = $scope.streamBackup
        }
        $location.url('connect')
      })
    })
  }

  $scope.undo = function () {
    // Load the last Object
    $scope.stream.member = $scope.undoArray[$scope.undoArray.length - 1]

    // & remove it from undo-array
    if ($scope.undoArray.length >= 1) {
      $scope.undoArray.pop()
    }
  }

  $scope.longPressAddAction = function (element) {
    if ('ontouchstart' in window) {
      $scope.addElementToStream(element)
    }
  }

  $scope.longPressRemoveAction = function (index) {
    if ('ontouchstart' in window) {
      $scope.removeElementFromStream(index)
    }
  }

  // =========================
  // Drag and Drop Options
  // =========================

  $scope.dragAndDropOptions = {
    placeholder: 'app',
    connectWith: '.apps-container',
    helper: 'clone',
    items: '.pipeline_element_container:not(.locked)',
    start: function (e, ui) {
      $scope.undoArray.push(angular.copy($scope.stream.member))
    },
    stop: function (e, ui) {
      $scope.resetAvailableElements()
      $scope.stream._notifications.success = false
      $scope.stream._notifications.error = false

      $scope.stream.update().then(() => {
        $scope.stream._notifications.success = true
        $scope.stream._notifications.error = true
      })

      // if this stream equals the last stream
      if (JSON.stringify(angular.copy($scope.stream.member)) === JSON.stringify($scope.undoArray[$scope.undoArray.length - 1])) {
        // remove the last element
        $scope.undoArray.pop()
      }
    }
  }

  // =====================
  // Element Config Modal
  // =====================

  $scope.open = function (index) {
    $scope.currentElement = index

    const data = $scope.$new(true)
    data.obj = $scope.stream
    data.elementIndex = index

    $scope.modalInstance = $uibModal.open({
      templateUrl: 'modules/connect/edit/modals/elementConfig/template.html',
      controller: 'modalCtrl',
      scope: data
    })

    $scope.modalInstance.result.then(function (returnedData) {
      $scope.undoArray.push(angular.copy($scope.stream.member))

      // Update element, if no preset is assigned
      if (returnedData.elements[data.elementIndex].preset.length === 0) {
        const payload = []
        payload.push({ op: 'replace', path: '/elements/' + data.elementIndex, value: returnedData.elements[data.elementIndex] })
        payload.push({ op: 'replace', path: '/attributes', value: returnedData.member.attributes })
        $scope.stream.patch(payload)

        if (JSON.stringify(angular.copy($scope.stream.member)) === JSON.stringify($scope.undoArray[$scope.undoArray.length - 1])) {
          $scope.undoArray.pop()
        }
      }
    }, function (errorData) {})
  }

  // =====================
  // General Config Modal
  // =====================

  $scope.openGeneralOptions = function () {
    const data = $scope.$new(true)
    data.stream = angular.copy($scope.stream)

    $scope.modalInstance = $uibModal.open({
      templateUrl: 'modules/connect/edit/modals/generalOptions/template.html',
      controller: 'generalOptionsCtrl',
      scope: data
    })

    $scope.modalInstance.result.then(function (returnedData) {
      $scope.undoArray.push(angular.copy($scope.stream.member))

      $scope.stream.member = returnedData.member

      $scope.stream.update()

      if (JSON.stringify(angular.copy($scope.stream.member)) === JSON.stringify($scope.undoArray[$scope.undoArray.length - 1])) {
        $scope.undoArray.pop()
      }
    }, function (errorData) {})
  }

  $scope.renderGroupName = (group) => {
    const limitTo = 7
    if (group) {
      if (group.member.name.length > limitTo) {
        return group.member.name.substring(0, limitTo) + '...'
      } else {
        return group.member.name
      }
    } else {
      return 'None'
    }
  }

  $scope.init()
  $scope.initAudioElements()

  $scope.$on('$destroy', function () {
    if ($scope.clearPipeOnDestroy && $scope.newStream && $scope.stream.elements.length === 0) {
      $scope.stream.destroy()
    }
  })
})
