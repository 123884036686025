angular.module('webremote.dashboard').controller('widget_connectionOverview',
  function ($scope, objectManagerService) {
    $scope.om = objectManagerService

    $scope.$watch('activeDashboard', function () {
      $scope.om.object($scope.activeDashboard, 'dashboard').then((v) => {
        $scope.dashboard = v
      })
    })

    $scope.om.object($scope.activeDashboard, 'dashboard').then((v) => {
      $scope.dashboard = v

      // Wait, until widgetIndex isset
      $scope.$watch('widgetIndex', function () {
        $scope.storedOptions = $scope.dashboard.getInformationForWidget($scope.widgetIndex)

        // Default: undirectional tab
        if ($scope.storedOptions.selectedTab === undefined) {
          $scope.setTab('unidirectional')

          // Load from Settings
        } else {
          $scope.setTab($scope.storedOptions.selectedTab)
        }

        // Default: Select Unassigned Tab
        if ($scope.storedOptions.filter === undefined) {
          $scope.filter = {
            paused: false,
            playing: true,
            active: false,
            inactive: false,
            incomplete: false,
            eos: false,
            waiting: false
          }
        } else {
          $scope.filter = $scope.storedOptions.filter
        }
      })
    })

    $scope.setTab = function (val) {
      $scope.tab = val
      $scope.storedOptions.selectedTab = val
      $scope.dashboard.storeInformationForWidget($scope.widgetIndex, JSON.stringify($scope.storedOptions))
    }

    $scope.storeStreamFilter = function () {
      $scope.storedOptions.filter = $scope.filter
      $scope.dashboard.storeInformationForWidget($scope.widgetIndex, JSON.stringify($scope.storedOptions))
    }

    $scope.resetFilter = function () {
      $scope.filter = {
        paused: true,
        playing: true,
        active: true,
        inactive: true,
        incomplete: true,
        eos: true,
        waiting: true
      }

      $scope.storeStreamFilter()
    }

    $scope.filterStreamType = function (item) {
      switch ($scope.tab) {
        case 'unidirectional':
          if (!item.hasTag('template') && item.member.streamGroup.length === 0) {
            return true
          } else {
            return false
          }
        case 'template':
          if (item.hasTag('template') && item.member.streamGroup.length === 0) {
            return true
          } else {
            return false
          }
      }
    }

    $scope.filterStatus = function (item) {
      if ($scope.filter.inactive === true) var inactiveExpression = 'inactive'
      if ($scope.filter.eos === true) var eosExpression = 'eos'
      if ($scope.filter.waiting === true) var waitingExpression = 'waiting' // waiting
      if ($scope.filter.playing === true) var playingExpression = 'playing' // activated + playing
      if ($scope.filter.paused === true) var pausedExpression = 'paused' // pause + inactive
      if ($scope.filter.active === true) var activeExpression = 'active' // pause + inactive
      if ($scope.filter.incomplete === true) var incompleteExpression = 'incomplete' // pause + inactive

      return item.member.state === inactiveExpression ||
        item.member.state === playingExpression ||
        item.member.state === pausedExpression ||
        item.member.state === activeExpression ||
        item.member.state === waitingExpression ||
        item.member.state === incompleteExpression ||
        item.member.state === eosExpression
    }

    $scope.countActiveFilter = function () {
      let counter = 0
      for (const element in $scope.filter) {
        if (!$scope.filter[element]) counter++
      }
      return counter
    }
  })
