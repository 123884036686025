import FileSystemElement from './FileSystemElement'
import * as musicMetadata from 'music-metadata-browser'

export default class File extends FileSystemElement {
  static init (parent, data, $injector) {
    const result = new File()
    result.$injector = $injector
    result.parent = parent
    result.selected = false
    Object.assign(result, data)
    return result
  }

  get icon () {
    switch (this.fileType) {
      case 'mp3':
      case 'flac':
      case 'ogg vorbis':
      case 'raw audio':
      case 'wav':
        return 'audiotrack'
      case 'HLS Playlist':
        return 'queue_music'
      case 'text':
        return 'text_snippet'
      default:
        return 'description'
    }
  }

  async requestMetaData () {
    const httpService = this.$injector.get('httpService')
    this.meta = await musicMetadata.fetchFromUrl(encodeURI(httpService.protocol + '://' + httpService.rootUrl + httpService.webapiUrl + 'archive/' + this.parent.path.join('/') + '/' + this.name), { skipPostHeaders: true })

    var base64String = ''

    if (Array.isArray(this.meta.common.picture) && this.meta.common.picture[0].data !== undefined) {
      for (var i = 0; i < this.meta.common.picture[0].data.length; i++) {
        base64String += String.fromCharCode(this.meta.common.picture[0].data[i])
      }
      this.meta.common.picture[0].base64String = 'data:' + this.meta.common.picture[0].format + ';base64,' + window.btoa(base64String)
    }

    return this.meta
  }

  async destroy () {
    return super.destroy(this.parent.path.join('/'), this.name)
  }

  get absolutePath () {
    return this.$injector.get('fileService').encodePath(this.parent.path) + this.name
  }
}
